import { useMemo } from 'react';
import AdminRouter from 'src/routes/Routers/AdminRouter';
import AffiliateRouter from 'src/routes/Routers/AffiliateRouter';
import ClientRouter from 'src/routes/Routers/ClientRouter';
import UnprotectedRouter from 'src/routes/Routers/UnprotectedRouter';
import type { RoutingLevel } from 'src/routes/types';
import { getUserRole } from 'src/utils/helpers/auth';
import usePermissions from 'src/hooks/usePermissions';
import useAuthorization from 'src/hooks/useAuthorization';

const RoutingComponents: Record<RoutingLevel, () => React.ReactElement> = {
  UNPROTECTED: UnprotectedRouter,
  AFFILIATE: AffiliateRouter,
  CLIENT: ClientRouter,
  ADMIN: AdminRouter,
  SUPER_ADMIN: AdminRouter,
};

const useRoutingLevel = () => {
  const { isAuthorized } = useAuthorization();
  const { isPermissionsLoaded } = usePermissions();

  const routingLevel = useMemo<RoutingLevel>(() => {
    if (!isAuthorized) return 'UNPROTECTED';
    return getUserRole();
  }, [isAuthorized]);

  const RoutingComponent = useMemo(
    () => RoutingComponents[routingLevel],
    [routingLevel]
  );

  const isReadyToRender = useMemo(() => {
    if (!isAuthorized) return true;
    return isPermissionsLoaded;
  }, [isAuthorized, isPermissionsLoaded]);

  return {
    RoutingComponent,
    isReadyToRender,
  };
};

export default useRoutingLevel;
