import { APITypes, APIUrls } from 'src/@enums/api';
import type { CustodyResponse } from 'src/@types/BalanceInCustody/CustodyResponse';
import type { ClientStatistics } from 'src/@types/ClientStatistics';
import type { Roles } from 'src/@types/Roles';
import type {
  AdminGetSingleTransactionInfoParams,
  AdminGetSingleTransactionInfoResponse,
  AdminGetTransactionsListParams,
  AdminGetTransactionsListResponse,
  AdminGetUserTransactionsListParams,
  ClientGetTransactionsListParams,
  ClientGetTransactionsListResponse,
  ExportTransactionsPDFParams,
  SetTransactionReclaimedParams,
} from 'src/@types/Transaction/TransactionHistory';
import type {
  AdminAffiliateGetParams,
  AdminAffiliateGetResponse,
  AdminAffiliatePaymentGetParams,
  AdminAffiliatePaymentGetResponse,
  AdminGetClientsCountryMismatchParams,
  AdminGetClientsCountryMismatchResponse,
  ChangeDocumentStatusResponse,
  GetSystemCapabilitiesResponse,
  GetAdminPaymentFiltersResponse,
  GetActivityAdminParams,
  GetActivityAdminResponse,
  GetActivityClientParams,
  GetActivityClientResponse,
  GetAffiliatesAdminParams,
  GetAffiliatesAdminResponse,
  GetSurveysInfoResponse,
  GetUserDataResponse,
  SignupParameters,
  SignupResponse,
  SurveysInfoParams,
  SurveyUpdatePendingParams,
  AffiliateGetResponse,
  ApproveDocumentParams,
  RejectDocumentParams,
  WithdrawalAuthoriseAndCancelParams,
  GetPOADocumentHistoryResponse,
  AffiliateSetEarningsParams,
  AdminSetExportPermissionParams,
  ActivityFiltersResponse,
  GetActivityV2AdminParams,
  GetActivityV2AdminResponse,
  CryptoFiltersResponse,
  CountriesListResponse,
} from 'src/@types/api';
import type {
  AffiliateLogin2FARequest,
  AffiliateLoginRequest,
  Login2FARequest,
} from 'src/@types/requests/login';
import type { GetUserDataRequest } from 'src/@types/requests/user';
import { instance } from 'src/api/instance';
import { addToken, parseClient } from 'src/utils';
import type { ServerResponseStatus } from './types';
import type {
  AdminClientSearchParams,
  AdminClientSearchResponse,
  AdminForceUserResetPasswordParams,
  AdminForceUserResetPasswordResponse,
  AdminGetAllAdminsParams,
  AdminGetAllAdminsResponse,
  AdminGetClientBalanceParams,
  AdminGetClientBalanceResponse,
  AdminGetClientParams,
  AdminGetClientResponse,
  AdminGetNotesParams,
  AdminGetNotesResponse,
  AdminGetRolesResponse,
  AdminGetUserParams,
  AdminGetUserResponse,
  AdminManagementArchiveParams,
  AdminManagementArchiveResponse,
  AdminManagementSuspendParams,
  AdminManagementSuspendResponse,
  AdminManagementUnArchiveParams,
  AdminManagementUnArchiveResponse,
  AdminManagementUnSuspendParams,
  AdminManagementUnSuspendResponse,
  AdminSetUserParams,
  AdminSetUserResponse,
  AffiliateResetPasswordParams,
  AffiliateSignupParams,
  AffiliateSignupResponse,
  ClientResetPasswordParams,
  ClientResetPasswordResponse,
  DeleteMessageParams,
  GetAffiliatePaymentParams,
  GetAffiliatePaymentResponse,
  GetAffiliateStatsResponse,
  GetAffiliateTransactionsParams,
  GetAffiliateTransactionsResponse,
  GetMessagesListParams,
  GetMessagesListResponse,
  ResetAffiliatePasswordResponse,
  SecurityResetPasswordResponse,
  SendMessageParams,
  SendMessageResponse,
} from './@types/api';
import type { LogoutResponse } from 'src/@types/requests/logout';
import { sha512 } from 'js-sha512';
import type { TransactionStatusFiltersResponse } from 'src/@types/api';

/* Tables */
export const callGetActivity = (params: GetActivityClientParams) =>
  instance.post<GetActivityClientResponse>(
    APIUrls.ACTIVITY_V2,
    addToken({
      type: APITypes.ACTIVITY_GET,
      data: params,
    }),
    {
      requestSchema: 'GetActivityClientRequest',
      responseSchema: 'GetActivityClientResponse',
    }
  );

export const callAdminGetActivity = (params: GetActivityAdminParams) =>
  instance.post<GetActivityAdminResponse>(
    APIUrls.ADMIN_ACTIVITY_V2,
    addToken({
      type: APITypes.ACTIVITY_GET,
      data: params,
    }),
    {
      requestSchema: 'GetActivityAdminRequest',
      responseSchema: 'GetActivityAdminResponse',
    }
  );

export const callGetActivityV2 = (params: GetActivityV2AdminParams) => {
  return instance.post<GetActivityV2AdminResponse>(
    APIUrls.ACTIVITY_V2,
    addToken({
      type: APITypes.ACTIVITY_GET,
      data: params,
    })
  );
};

export const callGetFullActivityV2 = (rawData) =>
  instance.post(
    APIUrls.ADMIN_ACTIVITY_V2,
    addToken({
      type: APITypes.ACTIVITY_GET,
      data: rawData,
    })
  );

export const callLogout = () => {
  return instance.post<LogoutResponse>(
    APIUrls.LOGOUT,
    addToken({
      type: APITypes.LOGOUT,
    }),
    {
      requestSchema: 'LogoutRequest',
      responseSchema: 'LogoutResponse',
    }
  );
};

export const client_callGetTransactionsList = (
  params: Partial<ClientGetTransactionsListParams>
) =>
  instance.post<ClientGetTransactionsListResponse>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({
      type: APITypes.TRANSACTION_GET,
      data: params,
    }),
    {
      requestSchema: 'ClientGetTransactionsListRequest',
      responseSchema: 'ClientGetTransactionsListResponse',
    }
  );

export const callSetAdminTransactionReclaimed = (
  params: SetTransactionReclaimedParams
) =>
  instance.post<ServerResponseStatus>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({
      type: APITypes.TRANSACTION_ADMIN_SET_RECLAIMED,
      data: params,
    })
  );

export const admin_callGetTransactionsList = (
  params: Partial<AdminGetTransactionsListParams>
) =>
  instance.post<AdminGetTransactionsListResponse>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({
      type: APITypes.ADMIN_TRANSACTIONS_LIST,
      data: params,
    }),
    {
      requestSchema: 'AdminGetTransactionsListRequest',
      responseSchema: 'AdminGetTransactionsListResponse',
    }
  );

export const admin_callGetUserTransactionsList = (
  params: Partial<AdminGetUserTransactionsListParams>
) =>
  instance.post<AdminGetTransactionsListResponse>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({
      type: APITypes.ADMIN_GET_USER_TRANSACTIONS_LIST,
      data: params,
    }),
    {
      requestSchema: 'AdminGetUserTransactionsListRequest',
      responseSchema: 'AdminGetTransactionsListResponse',
    }
  );

export const admin_callGetSingleTransactionInfo = (
  params: AdminGetSingleTransactionInfoParams
) =>
  instance.post<AdminGetSingleTransactionInfoResponse>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({
      type: APITypes.ADMIN_TRANSACTION_INFO,
      data: params,
    }),
    {
      requestSchema: 'AdminGetSingleTransactionInfoRequest',
      responseSchema: 'AdminGetSingleTransactionInfoResponse',
    }
  );
export const callTransactionHashUpdate = async (params: {
  customHash: string;
  newHash: string;
}) => {
  return instance.post<ServerResponseStatus>(
    APIUrls.SUPERADMIN_UNPROCCESABLE_WITHDRAWAL,
    addToken({
      type: APITypes.WITHDRAWAL_PROBLEMATIC_HASH_UPDATE,
      data: params,
    })
  );
};

export type UnprocessableWithdrawal = {
  affiliateId: null;
  canBeAuthorised: null;
  country: string;
  countryCode: string;
  createdAt: string;
  creditCard: null;
  cryptoAmount: string;
  cryptoAmountIntegral: number;
  cryptoCurrency: string;
  cryptoFiatRate: null;
  cryptoTransactionHash: string;
  cryptoTransactionIndex: null;
  descriptionInternal: string;
  descriptionUser: string;
  email: string;
  fiatAmount: null;
  fiatCurrency: null;
  firstName: string;
  id: string;
  lastName: string;
  paymentId: null;
  paymentProviderName: null;
  paymentType: null;
  recipientWallet: string;
  refundable: null;
  sourceWallet: string;
  transactionFinishedAt: string;
  transactionStatusInternal: string;
  transactionStatusUser: string;
  transactionType: string;
  updatedAt: string;
  userId: number;
};

type UnprocessableWithdrawalsResponse = {
  data: {
    withdrawals: UnprocessableWithdrawal[];
  };
  pagination: {
    count?: number;
  };
};

export const callGetSuperAdminUnproccesableWithdrawal = async (
  params: unknown
) =>
  instance.post<UnprocessableWithdrawalsResponse>(
    APIUrls.ADMIN_CRYPTO_WITHDRAWAL,
    addToken({
      type: APITypes.UNPROCCESABLE_WITHDRAWAL_GET,
      data: params,
    })
  );

export const callWithdrawalProblematicClear = async (
  transactionHashes: string[]
) => {
  const checkSumRawInput = `[${transactionHashes
    .map((t) => `"${t}"`)
    .join(',')}]`;

  const checkSum = sha512(checkSumRawInput);

  return instance.post<{ status: string }>(
    APIUrls.SUPERADMIN_UNPROCCESABLE_WITHDRAWAL,
    addToken({
      type: APITypes.UNPROCCESABLE_WITHDRAWAL_CLEAR,
      data: {
        cs: checkSum,
        customHash: transactionHashes,
      },
    })
  );
};
export const changeTransactionStatus = (
  params: {
    cs?: string;
    transaction:
      | { id: number; status?: string }
      | {
          cryptoAmount: number;
          cryptoCurrency: string;
          cryptoPrice: number;
          id: number;
          price: number;
          status: string;
          userId?: number;
        };
  },
  type = ''
) => {
  const payload = addToken({
    type: (type as APITypes) || APITypes.BUY_BANK_TRANSFER_STATUS,
    data: params,
  });
  return instance.post(APIUrls.BUY_BANK, payload);
};

export const cancelWithdraw = (id: string) => {
  const payload = addToken({
    type: APITypes.WITHDRAWAL_CANCEL,
    data: { id },
  });
  return instance.post(APIUrls.ADMIN_CRYPTO_WITHDRAWAL, payload);
};

export const cancelPreAuthTransaction = (transactionId: string) => {
  const payload = addToken({
    type: APITypes.BUY_CANCEL,
    data: {
      trxId: transactionId,
    },
  });
  return instance.post<{ reason?: string; status: 'OK' | 'ERROR' }>(
    APIUrls.CANCEL_PREAUTH_TRANSACTION,
    payload
  );
};

/* MESSAGES */
export const loadAdminMessages = (params: GetMessagesListParams) => {
  return instance.post<GetMessagesListResponse['data']>(
    APIUrls.MESSAGES,
    addToken({
      type: APITypes.MESSAGES_LIST,
      data: params,
    }),
    {
      requestSchema: 'GetMessagesListRequest',
      responseSchema: 'GetMessagesListResponse',
    }
  );
};

export const sendMessage = (params: SendMessageParams) => {
  return instance.post<SendMessageResponse>(
    APIUrls.MESSAGES,
    addToken({
      type: APITypes.MESSAGES_SEND,
      data: params,
    }),
    {
      requestSchema: 'SendMessageRequest',
      responseSchema: 'SendMessageResponse',
    }
  );
};

export const deleteMessage = (params: DeleteMessageParams) => {
  return instance.post(
    APIUrls.MESSAGES,
    addToken({
      type: APITypes.MESSAGES_DELETE,
      data: params,
    })
  );
};

/* NOTES */
export const loadAdminNotes = (params: AdminGetNotesParams) => {
  return instance.post<AdminGetNotesResponse['data']>(
    APIUrls.NOTES,
    addToken({
      type: APITypes.NOTES_GET,
      data: params,
    }),
    {
      requestSchema: 'AdminGetNotesRequest',
      responseSchema: 'AdminGetNotesResponse',
    }
  );
};

export const exportAdminNotesToCSV = (filter: Record<string, unknown>) => {
  return instance.post(
    APIUrls.ADMIN_NOTES_EXPORT,
    addToken({
      type: APITypes.ADMIN_NOTES_EXPORT,
      data: { filter },
    }),
    { responseType: 'blob' }
  );
};
/* RECOVER PASSWORD */
export const recoverPassword = ({ email }: IRecoverPasswordParams) => {
  const payload: ClientResetPasswordParams = {
    userAccountInfo: {
      email,
    },
  };

  return instance.post<ClientResetPasswordResponse>(
    APIUrls.RECOVER_PASSWORD,
    {
      type: APITypes.SECURITY_RESET_PWD,
      data: payload,
    },
    {
      requestSchema: 'ClientResetPasswordRequest',
      responseSchema: 'ClientResetPasswordResponse',
    }
  );
};

export const recoverAffiliatePassword = (
  params: AffiliateResetPasswordParams
) => {
  return instance.post<AffiliateResetPasswordParams>(
    APIUrls.RECOVER_AFFILIATE_PASSWORD,
    {
      type: APITypes.AFFILIATE_RESET_PWD,
      data: params,
    },
    {
      requestSchema: 'AffiliateResetPasswordRequest',
      responseSchema: 'AffiliateResetPasswordResponse',
    }
  );
};

/* RESET PASSWORD */
export const resetPassword = ({
  jwt,
  password,
}: {
  jwt: string;
  password: string;
}) => {
  return instance.post<SecurityResetPasswordResponse>(
    APIUrls.SECURITY,
    {
      type: APITypes.SECURITY_RESET_PWD,
      data: {
        new: password,
      },
      jwt,
    },
    {
      requestSchema: 'SecurityResetPasswordRequest',
      responseSchema: 'SecurityResetPasswordResponse',
    }
  );
};

export const resetAffiliatePassword = ({
  jwt,
  password,
}: {
  jwt: string;
  password: string;
}) => {
  return instance.post<ResetAffiliatePasswordResponse>(
    APIUrls.AFFILIATE_SECURITY,
    {
      type: APITypes.AFFILIATE_RESET_PWD,
      data: {
        new: password,
      },
      jwt,
    },
    {
      requestSchema: 'ResetAffiliatePasswordRequest',
      responseSchema: 'ResetAffiliatePasswordResponse',
    }
  );
};

/* USER */
export const callLoadUser = (jwt?: string) => {
  const payload = addToken({
    type: APITypes.USER_DATA_GET,
    jwt,
  }) as GetUserDataRequest;
  return instance.post<GetUserDataResponse>(APIUrls.USER, payload, {
    requestSchema: 'GetUserDataRequest',
    responseSchema: 'GetUserDataResponse',
  });
};

export const loadAdminClient = (params: AdminGetUserParams) => {
  return instance.post<AdminGetUserResponse>(
    APIUrls.ADMIN_USER,
    addToken({
      type: APITypes.ADMIN_USER_GET,
      data: params,
    }),
    {
      requestSchema: 'AdminGetUserRequest',
      responseSchema: 'AdminGetUserResponse',
    }
  );
};

// TODO: Flagged to be removed
export const saveUser = (params: AdminSetUserParams) => {
  return instance.post<AdminSetUserResponse>(
    APIUrls.ADMIN_USER,
    addToken({
      type: APITypes.ADMIN_USER_SET,
      data: params,
    }),
    {
      requestSchema: 'AdminSetUserRequest',
      responseSchema: 'AdminSetUserResponse',
    }
  );
};

export const saveAdminUser = (params: AdminSetUserParams) => {
  return instance.post<AdminSetUserResponse>(
    APIUrls.ADMIN_USER,
    addToken({
      type: APITypes.ADMIN_USER_SET,
      data: params,
    }),
    {
      requestSchema: 'AdminSetUserRequest',
      responseSchema: 'AdminSetUserResponse',
    }
  );
};

export const saveSuperAdminUser = (data: unknown) => {
  const payload = addToken({
    type: APITypes.SUPERADMIN_USER_SET,
    data,
  });
  return instance.post(APIUrls.ADMIN_USER, payload);
};

export const surveyPendingApiCall = (params: SurveysInfoParams) => {
  const payload = addToken({
    type: APITypes.GET_SURVEYS_INFO,
    data: params,
  });
  return instance.post<GetSurveysInfoResponse>(
    APIUrls.SURVEY_PENDING,
    payload,
    {
      requestSchema: 'GetSurveysInfoRequest',
      responseSchema: 'GetSurveysInfoResponse',
    }
  );
};

export const surveyPendingApiCallUpdateInfo = (
  params: SurveyUpdatePendingParams
) => {
  const payload = addToken({
    type: APITypes.UPDATE_SURVEY_INFO,
    data: params,
  });
  return instance.post(APIUrls.SURVEY_PENDING, payload, {
    requestSchema: 'UpdateSurveyPendingRequest',
  });
};

export const callAffiliateLogin = ({ email, password, captcha }) => {
  const payload: AffiliateLoginRequest = {
    type: APITypes.AFFILIATE_LOGIN,
    data: {
      email,
      password,
      captcha,
    },
  };
  return instance.post(APIUrls.AFFILIATE_LOGIN, payload, {
    requestSchema: 'AffiliateLoginRequest',
  });
};

export const login2fa = (tempJwt: string, code: string) => {
  const payload: Login2FARequest = {
    type: APITypes.LOGIN_2FA,
    data: {
      code,
      token: tempJwt,
    },
  };
  return instance.post(APIUrls.LOGIN, payload, {
    requestSchema: 'Login2FARequest',
  });
};

export const affiliateLogin2fa = (tempJwt: string, code: string) => {
  const payload: AffiliateLogin2FARequest = {
    type: APITypes.AFFILIATE_LOGIN_2FA,
    data: {
      code,
      token: tempJwt,
    },
  };
  return instance.post(APIUrls.AFFILIATE_LOGIN, payload, {
    requestSchema: 'AffiliateLogin2FARequest',
  });
};

export const callSignup = (data: SignupParameters) => {
  const payload = {
    type: APITypes.SIGNUP,
    data,
  };
  return instance.post<SignupResponse>(APIUrls.SIGNUP, payload);
};

export const callAffiliateSignup = (params: AffiliateSignupParams) => {
  return instance.post<AffiliateSignupResponse>(
    APIUrls.AFFILIATE_SIGNUP,
    {
      type: APITypes.AFFILIATE_SIGNUP,
      data: params,
    },
    {
      requestSchema: 'AffiliateSignupRequest',
      responseSchema: 'AffiliateSignupResponse',
    }
  );
};

export const getPOADocumentHistory = (userId: number) => {
  const payload = addToken({
    type: APITypes.ADMIN_GET_HISTORIC_DOC,
    data: {
      userId,
    },
  });
  return instance.post<GetPOADocumentHistoryResponse>(
    APIUrls.HISTORIC_DOCUMENT,
    payload
  );
};

export const callApprovePOI = (params: ApproveDocumentParams) =>
  instance.post<ChangeDocumentStatusResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.DOC_APPROVE_POI,
      data: params,
    })
  );

export const callRejectPOI = (params: RejectDocumentParams) =>
  instance.post<ChangeDocumentStatusResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.DOC_REJECT_POI,
      data: { document: params },
    })
  );

export const admin_getClientsCountryMismatch = (
  params: AdminGetClientsCountryMismatchParams
) => {
  const payload = addToken({
    type: APITypes.CLIENTS_WITH_COUNTRY_MISMATCH,
    data: params,
  });
  return instance.post<AdminGetClientsCountryMismatchResponse>(
    APIUrls.ADMIN_KYC,
    payload
  );
};

/* ROLES */
export const getRolesApi = () => {
  return instance.post<AdminGetRolesResponse>(
    APIUrls.ROLES,
    addToken({
      type: APITypes.ROLE_LIST,
    }),
    {
      requestSchema: 'AdminGetRolesRequest',
      responseSchema: 'AdminGetRolesResponse',
    }
  );
};

/* USERS */
export const adminLoadClients = (params: AdminGetClientParams) => {
  const payload = addToken({
    data: params,
    type: APITypes.ADMIN_CLIENT_GET,
  });
  return instance
    .post<AdminGetClientResponse>(APIUrls.LOAD_CLIENTS, payload, {
      requestSchema: 'AdminGetClientRequest',
      responseSchema: 'AdminGetClientResponse',
    })
    .then((res) => ({
      data: {
        list: res?.data?.client?.map((item) => parseClient(item)),
        pagination: res.data.pagination,
      },
    }));
};

export const changeClientRole = (params: { email: string; role: Roles }) => {
  const { email, role } = params;
  const payload = addToken({
    data: {
      email,
      role,
    },
    type: APITypes.MANAGEMENT_CHANGE_ROLE,
  });
  return instance.post(APIUrls.MANAGEMENT, payload);
};

export const setClientRole = (params: AdminSetUserParams) => {
  return instance.post<AdminSetUserResponse>(
    APIUrls.ADMIN_USER,
    addToken({
      data: params,
      type: APITypes.ADMIN_USER_SET,
    }),
    {
      requestSchema: 'AdminSetUserRequest',
      responseSchema: 'AdminSetUserResponse',
    }
  );
};

export const adminSoftSuspendUser = (params: AdminManagementSuspendParams) =>
  instance.post<AdminManagementSuspendResponse>(
    APIUrls.MANAGEMENT,
    addToken({
      data: params,
      type: APITypes.MANAGEMENT_SUSPEND,
    }),
    {
      requestSchema: 'AdminManagementSuspendRequest',
      responseSchema: 'AdminManagementSuspendResponse',
    }
  );

export const adminSoftUnSuspendUser = (
  params: AdminManagementUnSuspendParams
) =>
  instance.post<AdminManagementUnSuspendResponse>(
    APIUrls.MANAGEMENT,
    addToken({
      data: params,
      type: APITypes.MANAGEMENT_UNSUSPEND,
    }),
    {
      requestSchema: 'AdminManagementUnSuspendRequest',
      responseSchema: 'AdminManagementUnSuspendResponse',
    }
  );

export const adminArchiveUser = (params: AdminManagementArchiveParams) =>
  instance.post<AdminManagementArchiveResponse>(
    APIUrls.MANAGEMENT,
    addToken({
      data: params,
      type: APITypes.MANAGEMENT_ARCHIVE,
    }),
    {
      requestSchema: 'AdminManagementArchiveRequest',
      responseSchema: 'AdminManagementArchiveResponse',
    }
  );

export const adminUnArchiveUser = (params: AdminManagementUnArchiveParams) =>
  instance.post<AdminManagementUnArchiveResponse>(
    APIUrls.MANAGEMENT,
    addToken({
      data: params,
      type: APITypes.MANAGEMENT_UNARCHIVE,
    }),
    {
      requestSchema: 'AdminManagementUnArchiveRequest',
      responseSchema: 'AdminManagementUnArchiveResponse',
    }
  );

/* GET STATISTICS */
export const adminLoadClientsKycStats = () => {
  const payload = addToken({
    type: APITypes.STATS,
  });
  return instance.post<ClientStatistics>(APIUrls.STATS, payload);
};

/* AFFILIATE */
export const callGetAffiliatePayout = (params: GetAffiliatePaymentParams) => {
  return instance.post<GetAffiliatePaymentResponse>(
    APIUrls.AFFILIATE_PAYMENT,
    addToken({
      data: params,
      type: APITypes.AFFILIATE_PAYMENT_GET,
    })
  );
};

export const callGetAdminAffiliatePayout = (
  params: AdminAffiliatePaymentGetParams
) => {
  return instance.post<AdminAffiliatePaymentGetResponse>(
    APIUrls.ADMIN_AFFILIATE,
    addToken({
      data: params,
      type: APITypes.ADMIN_AFFILIATE_PAYMENT_GET,
    }),
    {
      requestSchema: 'AdminAffiliatePaymentGetRequest',
      responseSchema: 'AdminAffiliatePaymentGetResponse',
    }
  );
};

export const callGetAffiliateTransactions = (
  params: GetAffiliateTransactionsParams
) => {
  return instance.post<GetAffiliateTransactionsResponse>(
    APIUrls.AFFILIATE_TRANSACTION,
    addToken({
      data: params,
      type: APITypes.AFFILIATE_TRANSACTION_GET,
    })
  );
};

export const callLoadAffiliate = () => {
  return instance.post<AffiliateGetResponse>(
    APIUrls.AFFILIATE,
    addToken({
      type: APITypes.AFFILIATE_GET,
    }),
    {
      requestSchema: 'AffiliateGetRequest',
      responseSchema: 'AffiliateGetResponse',
    }
  );
};

export const adminLoadAffiliate = (payload: AdminAffiliateGetParams) => {
  return instance.post<AdminAffiliateGetResponse>(
    APIUrls.ADMIN_AFFILIATE,
    addToken({
      type: APITypes.ADMIN_AFFILIATE_GET,
      data: payload,
    }),
    {
      requestSchema: 'AdminAffiliateGetRequest',
      responseSchema: 'AdminAffiliateGetResponse',
    }
  );
};

export const affiliateStats = () => {
  return instance.post<GetAffiliateStatsResponse>(
    APIUrls.AFFILIATE_STATS,
    addToken({
      type: APITypes.AFFILIATE_STATS,
    }),
    {
      requestSchema: 'GetAffiliateStatsRequest',
      responseSchema: 'GetAffiliateStatsResponse',
    }
  );
};

export const callGetAdminAffiliates = (params: GetAffiliatesAdminParams) => {
  const payload = addToken({
    data: params,
    type: APITypes.ADMIN_AFFILIATE,
  });
  return instance.post<GetAffiliatesAdminResponse>(
    APIUrls.ADMIN_AFFILIATE,
    payload,
    {
      requestSchema: 'GetAffiliatesAdminRequest',
      responseSchema: 'GetAffiliatesAdminResponse',
    }
  );
};

export const callGetAdminList = (params: AdminGetAllAdminsParams) =>
  instance.post<AdminGetAllAdminsResponse>(
    APIUrls.LOAD_CLIENTS,
    addToken({
      type: APITypes.GET_ALL_ADMINS,
      data: params,
    }),
    {
      requestSchema: 'AdminGetAllAdminsRequest',
      responseSchema: 'AdminGetAllAdminsResponse',
    }
  );

export const getUserBalance = (params: AdminGetClientBalanceParams) =>
  instance.post<AdminGetClientBalanceResponse>(
    APIUrls.LOAD_CLIENTS,
    addToken({ type: APITypes.ADMIN_CLIENT_BALANCE, data: params }),
    {
      requestSchema: 'AdminGetClientBalanceRequest',
      responseSchema: 'AdminGetClientBalanceResponse',
    }
  );

type AdminClientSearchClientDTO = AdminClientSearchResponse['client'][number];

export type AdminClientSearchClient = Pick<
  AdminClientSearchClientDTO,
  'userPersonalInfo'
>['userPersonalInfo'] & {
  country: AdminClientSearchClientDTO['userAddressInfo']['country'];
  createdAt: AdminClientSearchClientDTO['userAccountInfo']['createdAt'];
  email: AdminClientSearchClientDTO['userAccountInfo']['email'];
  status: AdminClientSearchClientDTO['userAccountInfo']['status'];
};

export const apiSearchUserBulk = (params: AdminClientSearchParams) => {
  return instance
    .post<AdminClientSearchResponse>(
      APIUrls.ADMIN_USER,
      addToken({
        type: APITypes.ADMIN_CLIENT_SEARCH,
        data: params,
      }),
      {
        requestSchema: 'AdminClientSearchRequest',
        responseSchema: 'AdminClientSearchResponse',
      }
    )
    .then((res) => {
      const list: AdminClientSearchClient[] = res.data.client.map((item) => {
        return {
          ...item.userPersonalInfo,
          email: item.userAccountInfo.email,
          createdAt: item.userAccountInfo.createdAt,
          status: item.userAccountInfo.status,
          country: item.userAddressInfo.country,
        };
      });
      const { pagination } = res.data;
      // Don't remove the `data` key.
      // The pagination expects to see it and it's normal.
      return {
        data: {
          list,
          pagination,
        },
      };
    });
};

export const apiGetBalanceInCustody = () => {
  return instance.post<CustodyResponse>(
    APIUrls.STATS,
    addToken({
      type: 'CRYPTO_UNDER_CUSTODY',
    }),
    {
      responseSchema: 'CustodyResponse',
    }
  );
};

export const callTransactionGetPdf = (trxId: string) => {
  const payload: ExportTransactionsPDFParams = { filter: { id: trxId } };

  return instance.post(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({
      type: APITypes.TRANSACTION_GET_PDF,
      data: payload,
    }),
    { responseType: 'blob' }
  );
};

export const adminForceUserResetPassword = (
  params: AdminForceUserResetPasswordParams
) =>
  instance.post<AdminForceUserResetPasswordResponse>(
    APIUrls.ADMIN_USER,
    addToken({
      type: APITypes.ADMIN_USER_FORCE_CHANGE_PWD,
      data: params,
    }),
    {
      requestSchema: 'AdminForceUserResetPasswordRequest',
      responseSchema: 'AdminForceUserResetPasswordResponse',
    }
  );

export const getCountries = () =>
  instance.post<CountriesListResponse>(APIUrls.COUNTRIES, {
    type: APITypes.COUNTRIES_LIST,
  });

export const callGetSystemCapabilities = (userId?: number) =>
  instance.post<GetSystemCapabilitiesResponse>(
    APIUrls.SYSTEM_CAPABILITIES,
    addToken({
      type: APITypes.AVAILABLE_PAYMENTS_GET,
      ...(userId ? { data: { userId } } : {}),
    }),
    {
      requestSchema: 'GetSystemCapabilitiesRequest',
      responseSchema: 'GetSystemCapabilitiesResponse',
    }
  );

export const callGetCryptoFilters = () =>
  instance.post<CryptoFiltersResponse>(
    APIUrls.SYSTEM_CAPABILITIES,
    addToken({ type: APITypes.CRYPTO_FILTERS_GET })
  );

export const callAdminPaymentFilters = () =>
  instance.post<GetAdminPaymentFiltersResponse>(
    APIUrls.ADMIN_SYSTEM_CAPABILITIES,
    addToken({
      type: APITypes.ADMIN_PAYMENT_FILTERS_GET,
    })
  );

export const callAuthoriseWithdrawal = (
  params: WithdrawalAuthoriseAndCancelParams
) =>
  instance.post(
    APIUrls.USER_ACCOUNTING_DATA,
    addToken({
      data: params,
      type: APITypes.USER_ACCOUNT_WITHDRAWAL_AUTHORISE,
    }),
    { requestSchema: 'WithdrawalAuthoriseRequest' }
  );

export const callCancelWithdrawal = (
  params: WithdrawalAuthoriseAndCancelParams
) =>
  instance.post(
    APIUrls.USER_ACCOUNTING_DATA,
    addToken({
      data: params,
      type: APITypes.USER_ACCOUNT_WITHDRAWAL_CANCEL,
    }),
    {
      requestSchema: 'WithdrawalCancelRequest',
    }
  );

export const callAffiliateSetEarnings = (
  params: AffiliateSetEarningsParams
) => {
  const { affiliateId, price, recipientWallet } = params;
  const payload = addToken({
    data: {
      affiliateId,
      price,
      recipientWallet,
    },
    type: APITypes.AFFILIATE_SET_EARNINGS,
  });
  return instance.post(APIUrls.AFFILIATE_PAYMENT, payload);
};

export const callGrantExportPermission = (
  data: AdminSetExportPermissionParams
) =>
  instance.post(
    APIUrls.MANAGEMENT,
    addToken({
      data,
      type: APITypes.MANAGEMENT_GRANT_EXPORT,
    })
  );

export const callRemoveExportPermission = (
  data: AdminSetExportPermissionParams
) =>
  instance.post(
    APIUrls.MANAGEMENT,
    addToken({
      data,
      type: APITypes.MANAGEMENT_REMOVE_EXPORT,
    })
  );

export const callGetActivityFilters = () =>
  instance.post<ActivityFiltersResponse>(
    APIUrls.ACTIVITY_V2,
    addToken({ type: 'ACTIVITY_FILTERS_GET' })
  );

export const callGetAdminActivityFilters = () =>
  instance.post<ActivityFiltersResponse>(
    APIUrls.ADMIN_ACTIVITY_V2,
    addToken({ type: 'ACTIVITY_FILTERS_GET' })
  );

export const callGetClientTransactionStatusFilters = () =>
  instance.post<TransactionStatusFiltersResponse>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({ type: APITypes.TRANSACTION_STATUS_FILTER_GET })
  );

export const callGetAdminTransactionStatusFilters = () =>
  instance.post<TransactionStatusFiltersResponse>(
    APIUrls.TRANSACTIONS_HISTORY,
    addToken({ type: APITypes.ADMIN_TRANSACTION_STATUS_FILTER_GET })
  );

export const callGetAffiliateTransactionStatusFilters = () =>
  instance.post<TransactionStatusFiltersResponse>(
    APIUrls.AFFILIATE_TRANSACTION,
    addToken({ type: APITypes.AFFILIATE_TRANSACTION_STATUS_FILTER_GET })
  );
