import { isAffiliate } from 'src/utils/helpers/roles';
import type { Path } from 'src/routes/types';
import type { TransactionID } from 'src/@types/api';
import type { Roles } from 'src/@types/Roles';
import { getUserRole } from 'src/utils/helpers/auth';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { setIcReferrer } from 'src/utils';

export function useICNavigator() {
  const navigate = useNavigate();

  const goToPath = useCallback(
    (path: string, state = {}, customSearch?: string) => {
      const searchParams = new URLSearchParams(
        customSearch ?? window.location.search
      );

      setIcReferrer(searchParams);

      navigate(
        {
          pathname: path,
          search: searchParams?.toString(),
        },
        {
          state,
        }
      );
    },
    [navigate]
  );

  const navigationFunctions = useMemo(
    () => ({
      goToPath,
      goPageUserAccounting: () => goToPath('/client/accounting'),
      goPageAdminClientById: (clientId: number) =>
        goToPath(`/admin/admins/${clientId}`),
      goPageAdminClients: () => goToPath('/admin/clients'),
      goPageLogin: () => {
        const pushUrl: Path = isAffiliate() ? '/affiliate/login' : '/login';
        goToPath(pushUrl);
      },
      goPageAuthenticate: () => goToPath('/authorize-2fa'),
      goPageAffiliateAuthenticate: () => goToPath('/affiliate/authorize-2fa'),
      goPageAffiliateSummary: () => goToPath('/affiliate/summary'),
      goPageTransactions: () => {
        const routes: { [key in Roles]: Path } = {
          SUPER_ADMIN: '/admin/transactions',
          ADMIN: '/admin/transactions',
          CLIENT: '/client/transactions',
          AFFILIATE: '/affiliate/transactions',
        };
        goToPath(routes[getUserRole()]);
      },
      goPageBuy: () => goToPath('/client/deposit'),
      goPageLogout: () => {
        const routes: { [key in Roles]: Path } = {
          SUPER_ADMIN: '/admin/logout',
          ADMIN: '/admin/logout',
          CLIENT: '/client/logout',
          AFFILIATE: '/affiliate/logout',
        };
        goToPath(routes[getUserRole()]);
      },
      goPageAddCard: () => goToPath('/client/kyc'),

      goPageTransactionInfo: (id: TransactionID) => {
        const routes: { [key in Roles]: Path } = {
          SUPER_ADMIN: '/admin/transactions/:id',
          ADMIN: '/admin/transactions/:id',
          CLIENT: '/client/transactions/:id',
          AFFILIATE: '/affiliate/transactions', // AFFILIATE role has no transaction info page
        };
        goToPath(routes[getUserRole()].replace(':id', id));
      },
      goPageRegister: () => {
        goToPath('/register');
      },
      goToClientPage: (clientId: number, newTab = false) => {
        const url = `/admin/clients/${clientId}`;
        if (newTab) return window.open(url);
        goToPath(url);
      },
      goPageSecurity: (clientId: number, newTab = true) => {
        const url = `/admin/clients/${clientId}/security`;
        if (newTab) return window.open(url);
        goToPath(url);
      },
      goPageClientDocs: (clientId: number, newTab = true) => {
        const url = `/admin/clients/${clientId}/documents`;
        if (newTab) return window.open(url);
        goToPath(url);
      },
      goPageAffiliateWithdrawal: (affiliateId: number, newTab = true) => {
        const url = `/admin/affiliates/withdrawals/${affiliateId}`;
        if (newTab) return window.open(url);
        goToPath(url);
      },

      goPageAffiliateInfo: (affiliateId: number, newTab = true) => {
        const url = `/admin/affiliates/${affiliateId}/info`;
        if (newTab) return window.open(url);
        goToPath(url);
      },
      goPageUrl: (url: string) => {
        window.open(url);
      },
      openPage: (url: string) => {
        window.open(url, '_self');
      },
      goPageBankInfo: (transactionId: TransactionID, bankInfoStr?: string) =>
        goToPath(`/client/bank-info/${transactionId}`, {
          state: bankInfoStr ? { bankInfoStr } : undefined,
        }),
      goPageAdminInfo: (id: number) => goToPath(`/admin/admins/${id}`),
      goPageAdjustment: ({
        userId,
        trxId,
      }: {
        trxId: TransactionID;
        userId: number;
      }) => goToPath(`/admin/clients/${userId}/adjustments/${trxId}`),
      goPageTransactionResult: (searchStr: string) => {
        goToPath(`/client/payment-result`, {}, searchStr);
      },
    }),
    [goToPath]
  );

  return navigationFunctions;
}
