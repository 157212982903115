import { commonInputsStyles } from 'src/components/Inputs/Styles';
import styles from 'src/components/Inputs/TextInput/TextInput.module.css';
import classNames from 'classnames';
import type { InputVariant } from 'src/components/Inputs/@types';
import ClearButton from 'src/components/Table/filters/components/ClearButton';
import React from 'react';
import type { SafeOmit } from 'src/@types/utilities/SafeOmit';

type TextInputFilterProps = {
  error?: string | boolean;
  onChange?: (updatedValue: string) => void;
  variant?: InputVariant;
} & SafeOmit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const TextInputFilter = ({
  variant = 'hero',
  disabled = false,
  className = '',
  error,
  value,
  onChange,
  ...rest
}: TextInputFilterProps): React.ReactElement => {
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex flex-col gap-y-1">
      <div className={styles.inputWrapper}>
        <input
          className={classNames(
            className,
            commonInputsStyles({
              error,
              variant,
            }),
            styles.baseSpaces
          )}
          disabled={disabled}
          value={value}
          onChange={handleChangeValue}
          {...rest}
        />
        {value !== '' && (
          <ClearButton error={!!error} onClear={() => onChange('')} />
        )}
      </div>
      {error && !disabled && (
        <span className="text-left text-xs font-medium text-red-500">
          {error}
        </span>
      )}
    </div>
  );
};

export default TextInputFilter;
