import { startOfDay } from '@arringo-npm/utility-package';
import DatePickerInput from 'src/components/Inputs/DatePickerInput';
import ClearButton from 'src/components/Table/filters/components/ClearButton';

type DateFieldProps = {
  fieldName: 'from' | 'to';
  maxFieldDate: Date;
  minFieldDate?: Date;
  onChange: (name: 'from' | 'to', value: Date) => void;
  onClear: () => void;
  placeholder: string;
  value: string;
};

const DateField = ({
  value,
  fieldName,
  placeholder,
  maxFieldDate,
  minFieldDate,
  onChange,
  onClear,
}: DateFieldProps) => {
  return (
    <div className="relative">
      <DatePickerInput
        name={fieldName}
        selected={value !== '' ? new Date(value) : null}
        value={value}
        onChange={(date) => onChange(fieldName, date as Date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        timeIntervals={15}
        dropdownMode="select"
        placeholderText={placeholder}
        maxDate={maxFieldDate || startOfDay(new Date())}
        minDate={minFieldDate}
        dateFormat="dd/MM/yyyy"
        autoComplete="off"
        type="text"
        className="text-sm"
      />
      {value !== '' && <ClearButton onClear={onClear} />}
    </div>
  );
};

export default DateField;
