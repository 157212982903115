import md5 from 'js-md5';
import { useContext, useMemo } from 'react';
import Sidebar from 'src/components/Sidebar';
import { sideBarItems } from 'src/config/side-menu';
import { UserContext } from 'src/contexts/UserContext';
import { AppContext } from 'src/features/App/context/AppContext';
import usePermissions from 'src/hooks/usePermissions';
import { getUserRole } from 'src/utils/helpers/auth';

const SidebarSection = () => {
  const userRole = getUserRole();
  const { hasPermission } = usePermissions();
  const {
    user: {
      userPersonalInfo: { firstName, lastName },
      userAccountInfo: { email },
    },
  } = useContext(UserContext);

  const { isSideBarOpen, setSideBarOpen } = useContext(AppContext);

  const emailHash = useMemo(() => md5(email || ''), [email]);

  const menuItems = useMemo(
    () =>
      sideBarItems.filter(
        (item) =>
          item.roles.includes(userRole) &&
          hasPermission(item.permissions) &&
          !item.disabled
      ),
    [userRole, hasPermission]
  );

  return (
    <>
      <Sidebar
        menuItems={menuItems}
        isOpen={isSideBarOpen}
        onChange={setSideBarOpen}
        userInfo={{
          firstName,
          lastName,
          avatar: `https://www.gravatar.com/avatar/${emailHash}?d=mp&s=80`,
        }}
      />
    </>
  );
};

export default SidebarSection;
