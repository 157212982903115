import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from 'src/components/Table/cells';

const interviewQueueStateValueMapping = {
  START: <span className="font-bold text-red-500">Not Started</span>,
  IN_PROGRESS: <span className="font-bold text-green-500">In Progress</span>,
  REVIEW: <span className="font-bold text-yellow-500">Pending Review</span>,
};

const InterviewQueueState = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  return interviewQueueStateValueMapping[value];
};

export default InterviewQueueState;
