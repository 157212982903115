import type { RowData } from '@arringo-npm/fe-table';
import CheckboxInput from 'src/components/Inputs/CheckboxInput';
import type { CellProps } from '..';

const Checkbox = <TData extends RowData>({
  cell,
}: CellProps<TData, boolean>) => {
  return (
    <div className="flex justify-center">
      <CheckboxInput name="" checked={cell.getValue()} readOnly />
    </div>
  );
};

export default Checkbox;
