import { APITypes, APIUrls } from 'src/@enums/api';
import instance from 'src/api/instance';
import { addToken } from 'src/utils';
import { prepareTableQuery } from 'src/api/helpers';

export const callAdminGetStartedSumsubFlows = (rawData) =>
  instance.post(
    APIUrls.SUMSUB_WORKFLOW,
    addToken({
      type: APITypes.ADMIN_GET_STARTED_SUMSUB_FLOWS,
      data: prepareTableQuery(rawData, []),
    })
  );

export const callAdminCancelSumsubFlow = (userId: number) =>
  instance.post(
    APIUrls.SUMSUB_WORKFLOW,
    addToken({ type: APITypes.ADMIN_CANCEL_SUMSUB_FLOW, data: { userId } })
  );
