import Ajv from 'ajv';
import type { AxiosRequestConfig } from 'axios';
import { isEnabled } from 'src/config/request-validators';
import type { RequestSchema } from 'src/requestsSchema';
import type { RequestValidationError } from 'src/utils/helpers/@types';
import { logSentryMessage } from './logging';

const importSchemaByName = (name: RequestSchema) =>
  import('src/requestsSchema/' + name + '.json');

export const validateRequest = async (
  schema: RequestSchema,
  data: AxiosRequestConfig['data'],
  url: string
) => {
  if (!schema || !isEnabled) {
    return;
  }

  const importSchemaByNameResponse = await importSchemaByName(schema).catch(
    () => {
      return console.warn(`No type for ${schema} found!`);
    }
  );
  const ajv = new Ajv({
    allErrors: true,
  });
  ajv.validate(importSchemaByNameResponse, data);
  if (ajv.errors !== null) {
    const error: RequestValidationError = new Error(ajv.errorsText());
    error.errors = ajv.errors;
    error.isRequestValidationError = true;
    error.name = url;

    logSentryMessage(error.message, {
      level: 'warning',
      extra: {
        ...error,
        /* stringify params here to be able to check them properly
         * in sentry instead of [Object]
         */
        errors: error.errors.map((error) => ({
          ...error,
          params: JSON.stringify(error.params),
        })),
      },
    });
    console.error(error);
  }
};

export const isSWRLoading = (isValidating: boolean, data: boolean) => {
  return Boolean((!isValidating && !data) || (isValidating && !data));
};
