import type { ReactNode } from 'react';
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import UserStatusContext from 'src/contexts/UserStatusContext';

const UserStepsContext = createContext<{
  pendingSteps?: number[];
  setStep: (step: number) => void;
  step: number;
}>(undefined);

const UserStepsProvider = ({ children }: { children: ReactNode }) => {
  const { screenName, firstTrxDone } = useContext(UserStatusContext);
  const [step, setStep] = useState(1);
  const [pendingSteps, setPendingSteps] = useState<number[]>([]);

  const handlePendingSteps = useCallback(
    ({ step, type }: { step: number; type: 'add' | 'remove' }) => {
      if (type === 'add') {
        if (!pendingSteps.find((item) => item === step)) {
          setPendingSteps((prevState) => [...prevState, step]);
        }
      } else if (type === 'remove') {
        setPendingSteps((prevState) =>
          prevState.filter((item) => item !== step)
        );
      }
    },
    [pendingSteps]
  );

  // Step 2 should not be marked as done if firstTrxDone is false
  useEffect(() => {
    if (firstTrxDone !== undefined) {
      handlePendingSteps({
        step: 2,
        type: firstTrxDone ? 'remove' : 'add',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTrxDone]);

  useEffect(() => {
    switch (screenName) {
      case 'BUY':
        setStep(2);
        break;
      case 'DOC_COMMENTS':
      case 'CC_COMMENTS':
      case 'DOC_UPLOAD':
      case 'DOC_UPLOAD_CONTINUE':
      case 'PENDING':
      case 'SURVEY':
      case 'WIRE_INSTRUCTIONS':
        setStep(3);
        break;

      default:
        break;
    }
  }, [screenName]);

  const value = { step, setStep, pendingSteps };

  return (
    <UserStepsContext.Provider value={value}>
      {children}
    </UserStepsContext.Provider>
  );
};

const useUserSteps = () => {
  const context = useContext(UserStepsContext);
  if (context === undefined) {
    throw new Error('useUserSteps must be used within a UserStepsProvider');
  }
  return context;
};

export { UserStepsProvider, useUserSteps };
