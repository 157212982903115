import type { Namespace, TFunctionReturn, TFunction } from 'i18next';

export function translateWithFallback<
  N extends Namespace,
  TKPrefix = undefined,
>(
  t: TFunction<N, TKPrefix>,
  namespace: 'global' | undefined,
  key:
    | TemplateStringsArray
    | TFunctionReturn<N, TKPrefix, Record<string, string>> extends infer A
    ? A
    : never,
  fallback: string
) {
  if (!key) return fallback;
  return t(key, {
    ns: namespace,
  });
}
