import { lazy } from 'react';
import type { AdminRoute, PartitionedRoutes } from 'src/routes/types';
import deprecatedRoutes from './deprecatedRoutes';

/**
 * Admin/SuperAdmin Protected Routes
 *
 * Authentication Required
 * Admin and SuperAdmin Roles
 * Permissions based on route
 */
export const adminRoutes: PartitionedRoutes<AdminRoute> = {
  ...deprecatedRoutes,
  '/admin/transactions/withdrawals/unprocessable': {
    component: lazy(
      () => import('src/pages/admin/transactions/withdrawals/unprocessable')
    ),
    name: 'Unprocessable Withdrawals',
    permissions: ['WITHDRAWAL_PROBLEMATIC_GET'],
    roles: ['SUPER_ADMIN', 'ADMIN'],
  },
  '/admin/transactions/:id': {
    component: lazy(() => import('src/pages/admin/transactions/[id]')),
    name: 'Transaction Info',
    permissions: ['ADMIN_TRANSACTION_INFO'],
    roles: ['SUPER_ADMIN', 'ADMIN'],
  },
  '/admin/transactions': {
    component: lazy(() => import('src/pages/admin/transactions')),
    name: 'Transactions',
    permissions: ['ADMIN_TRANSACTIONS_LIST'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/kyc/country': {
    component: lazy(() => import('src/pages/admin/kyc/country')),
    name: 'Pending KYC Submissions',
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/kyc/interviews': {
    component: lazy(() => import('src/pages/admin/kyc/interviews')),
    name: 'Pending KYC Submissions',
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/kyc/need-review': {
    component: lazy(() => import('src/pages/admin/kyc/need-review')),
    name: 'Pending KYC Submissions',
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/kyc/monitoring': {
    component: lazy(() => import('src/pages/admin/kyc/monitoring')),
    name: 'Pending KYC Submissions',
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/kyc/running-flows': {
    component: lazy(() => import('src/pages/admin/kyc/running-flows')),
    name: 'Running Sumsub Flows',
    permissions: [],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/kyc/pending-docs-or-ccs': {
    component: lazy(() => import('src/pages/admin/kyc/pending-docs-or-ccs')),
    name: 'Pending KYC Submissions',
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/kyc': {
    component: lazy(() => import('src/pages/admin/kyc/interviews')),
    name: 'Pending KYC Submissions',
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/bulk-operations': {
    component: lazy(() => import('src/pages/admin/clients/bulk-operations')),
    name: 'Bulk Operations on Clients',
    permissions: ['ADMIN_CLIENT_SEARCH', 'ADMIN_USERS_UPDATE'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/clients/:id/adjustments/:trxId': {
    component: lazy(
      () => import('src/pages/admin/clients/[id]/adjustments/[trxId]')
    ),
    name: 'Adjustment',
    permissions: [
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'SUPER_ADMIN_ADJUSTMENT_ADD',
      'SUPER_ADMIN_ADJUSTMENT_UPDATE',
    ],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/clients/:id/adjustments': {
    component: lazy(() => import('src/pages/admin/clients/[id]/adjustments')),
    name: 'Client Adjustments',
    permissions: [
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'SUPER_ADMIN_ADJUSTMENT_ADD',
      'SUPER_ADMIN_ADJUSTMENT_UPDATE',
    ],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/clients/:id/info': {
    component: lazy(() => import('src/pages/admin/clients/[id]/info')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/documents': {
    component: lazy(() => import('src/pages/admin/clients/[id]/documents')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/history': {
    component: lazy(() => import('src/pages/admin/clients/[id]/history')),
    name: 'Client',
    permissions: ['ADMIN_GET_HISTORIC_DOC', 'GET_HISTORIC_DOC'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/activities': {
    component: lazy(() => import('src/pages/admin/clients/[id]/activities')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/notes': {
    component: lazy(() => import('src/pages/admin/clients/[id]/notes')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/messages': {
    component: lazy(() => import('src/pages/admin/clients/[id]/messages')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/transactions': {
    component: lazy(() => import('src/pages/admin/clients/[id]/transactions')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id/security': {
    component: lazy(() => import('src/pages/admin/clients/[id]/security')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients/:id': {
    component: lazy(() => import('src/pages/admin/clients/[id]')),
    name: 'Client',
    permissions: [
      'ADMIN_USER_GET',
      'MSG_SEND',
      'MSG_DELETE',
      'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
      'DOC_GET',
      'NOTE_GET',
      'UPDATE_USER_FINANCIAL_LIMITS',
      'ADMIN_GET_USER_TRANSACTIONS_LIST',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/clients': {
    component: lazy(() => import('src/pages/admin/clients')),
    name: 'Clients',
    permissions: ['ADMIN_CLIENT_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/refunds/:id/info': {
    component: lazy(() => import('src/pages/admin/refunds/[id]/info')),
    name: 'Refund Info',
    permissions: ['GET_REFUND_LIST', 'GET_REFUND_LOG'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/refunds/:id/logs': {
    component: lazy(() => import('src/pages/admin/refunds/[id]/logs')),
    name: 'Refund Info',
    permissions: ['GET_REFUND_LIST', 'GET_REFUND_LOG'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/refunds/:id': {
    component: lazy(() => import('src/pages/admin/refunds/[id]')),
    name: 'Refund Info',
    permissions: ['GET_REFUND_LIST', 'GET_REFUND_LOG'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/refunds': {
    component: lazy(() => import('src/pages/admin/refunds')),
    name: 'Refunds',
    permissions: ['GET_REFUND_LIST'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/affiliates/withdrawals/:id': {
    component: lazy(
      () => import('src/pages/admin/affiliates/withdrawals/[id]')
    ),
    name: 'Affiliate Withdrawal Info',
    permissions: ['ADMIN_AFFILIATE_PAYMENT_GET', 'AFFILIATE_SEND_EARNINGS'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/affiliates/withdrawals': {
    component: lazy(() => import('src/pages/admin/affiliates/withdrawals')),
    name: 'Affiliates Withdrawals',
    permissions: ['ADMIN_AFFILIATE_PAYMENT_GET'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/affiliates/transactions': {
    component: lazy(() => import('src/pages/admin/affiliates/transactions')),
    name: 'Affiliates Transactions',
    permissions: ['AFFILIATE_TRANSACTION_LIST'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/affiliates/:id/info': {
    component: lazy(() => import('src/pages/admin/affiliates/[id]/info')),
    name: 'Affiliate Info',
    permissions: [
      'ADMIN_AFFILIATE_PAYMENT_GET',
      'ADMIN_AFFILIATE_GET',
      'SUPERADMIN_AFFILIATE_RESET_PSW',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/affiliates/:id/requests': {
    component: lazy(() => import('src/pages/admin/affiliates/[id]/requests')),
    name: 'Affiliate Info',
    permissions: [
      'ADMIN_AFFILIATE_PAYMENT_GET',
      'ADMIN_AFFILIATE_GET',
      'SUPERADMIN_AFFILIATE_RESET_PSW',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/affiliates/:id': {
    component: lazy(() => import('src/pages/admin/affiliates/[id]')),
    name: 'Affiliate Info',
    permissions: [
      'ADMIN_AFFILIATE_PAYMENT_GET',
      'ADMIN_AFFILIATE_GET',
      'SUPERADMIN_AFFILIATE_RESET_PSW',
    ],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/affiliates': {
    component: lazy(() => import('src/pages/admin/affiliates')),
    name: 'Affiliates',
    permissions: ['AFFILIATE_LIST'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/surveys/pending': {
    component: lazy(() => import('src/pages/admin/surveys')),
    name: 'Pending Surveys',
    permissions: ['UPDATE_SURVEY_INFO', 'GET_SURVEYS_INFO'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/surveys/missing': {
    component: lazy(() => import('src/pages/admin/surveys/missing')),
    name: 'Pending Surveys',
    permissions: ['UPDATE_SURVEY_INFO', 'GET_SURVEYS_INFO'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/surveys/denied': {
    component: lazy(() => import('src/pages/admin/surveys/denied')),
    name: 'Pending Surveys',
    permissions: ['UPDATE_SURVEY_INFO', 'GET_SURVEYS_INFO'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/surveys': {
    component: lazy(() => import('src/pages/admin/surveys')),
    name: 'Pending Surveys',
    permissions: ['UPDATE_SURVEY_INFO', 'GET_SURVEYS_INFO'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/black-list': {
    component: lazy(() => import('src/pages/admin/black-list')),
    name: 'Black List',
    permissions: ['BLACKLIST_GET_IP'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/reasons/poi': {
    component: lazy(() => import('src/pages/admin/reasons/poi')),
    name: 'Rejection Reasons',
    permissions: ['REJECTREASONS_RECEIVE', 'REASON_TEMPLATE_LIST'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/reasons/poa': {
    component: lazy(() => import('src/pages/admin/reasons/poa')),
    name: 'Rejection Reasons',
    permissions: ['REJECTREASONS_RECEIVE', 'REASON_TEMPLATE_LIST'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/reasons/notes': {
    component: lazy(() => import('src/pages/admin/reasons/notes')),
    name: 'Rejection Reasons',
    permissions: ['REJECTREASONS_RECEIVE', 'REASON_TEMPLATE_LIST'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/reasons': {
    component: lazy(() => import('src/pages/admin/reasons')),
    name: 'Rejection Reasons',
    permissions: ['REJECTREASONS_RECEIVE', 'REASON_TEMPLATE_LIST'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/settings/countries': {
    name: 'Application Settings',
    component: lazy(() => import('src/pages/admin/settings/countries')),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/settings/emailing/:identifier': {
    name: 'Application Settings',
    component: lazy(
      () => import('src/pages/admin/settings/emailing/[identifier]')
    ),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/settings/emailing': {
    name: 'Application Settings',
    component: lazy(() => import('src/pages/admin/settings/emailing')),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/settings/globalParameter': {
    name: 'Application Settings',
    component: lazy(() => import('src/pages/admin/settings/globalParameter')),
    roles: ['SUPER_ADMIN'],
    permissions: ['GLOBAL_SETTINGS_GET', 'GLOBAL_SETTINGS_SET'],
  },
  '/admin/payment-settings/financial-tiers': {
    name: 'Application Settings',
    component: lazy(
      () => import('src/pages/admin/payment-settings/financial-tiers')
    ),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/payment-settings/card-payments-distribution': {
    name: 'Application Settings',
    component: lazy(
      () =>
        import('src/pages/admin/payment-settings/card-payments-distribution')
    ),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/payment-settings/psp-settlement-currency': {
    name: 'PSP Settlement currency',
    component: lazy(
      () => import('src/pages/admin/payment-settings/psp-settlement-currency')
    ),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/settings/flags': {
    name: 'Application Settings',
    component: lazy(() => import('src/pages/admin/settings/flags')),
    roles: ['SUPER_ADMIN'],
    permissions: ['GET_ALL_FLAGS'],
  },
  '/admin/payment-settings/system-capabilities': {
    name: 'Application Settings',
    component: lazy(
      () => import('src/pages/admin/payment-settings/system-capabilities')
    ),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'ADMIN_SYS_CAPABILITIES_GET',
      'SUPER_ADMIN_SYS_CAPABILITIES_SET',
    ],
  },
  '/admin/settings': {
    name: 'Application Settings',
    component: lazy(() => import('src/pages/admin/settings')),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'ADMIN_SYS_CAPABILITIES_GET',
      'SUPER_ADMIN_SYS_CAPABILITIES_SET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/payment-settings': {
    name: 'Payment Settings',
    component: lazy(() => import('src/pages/admin/payment-settings')),
    roles: ['SUPER_ADMIN'],
    permissions: [
      'COUNTRIES_GET',
      'EXPORT_MAILING_GET',
      'ADMIN_SYS_CAPABILITIES_GET',
      'SUPER_ADMIN_SYS_CAPABILITIES_SET',
      'GET_ALL_FINANCIAL_TIERS',
      'CARD_PAYMENTS_DISTRIBUTION_GET',
      'GET_PSP_SETTLEMENTS',
    ],
  },
  '/admin/security': {
    component: lazy(() => import('src/pages/admin/security')),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['SECURITY_CHANGE_PWD', 'SECURITY_SET_STATUS_2FA'],
    name: 'Security',
  },
  '/admin/logs': {
    component: lazy(() => import('src/pages/admin/logs')),
    name: 'Account Logs',
    permissions: ['ACTIVITY_GET'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/admins/:id': {
    component: lazy(() => import('src/pages/admin/admins/[id]')),
    name: 'Admin Info',
    permissions: ['ADMIN_USER_GET'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/admins': {
    component: lazy(() => import('src/pages/admin/admins')),
    name: 'Admins',
    permissions: ['GET_ALL_ADMINS'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/widgets/v2': {
    component: lazy(() => import('src/pages/admin/widgets/v2')),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    name: 'Common Widgets',
    permissions: ['STATS'],
  },
  '/admin/widgets/wallets-bulgaria': {
    component: lazy(() => import('src/pages/admin/widgets/wallets-bulgaria')),
    name: 'Wallets Widget Bulgaria',
    permissions: ['WALLET_STATS'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/widgets/wallets-estonia': {
    component: lazy(() => import('src/pages/admin/widgets/wallets-estonia')),
    name: 'Wallets Widget Estonia',
    permissions: ['WALLET_STATS'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/widgets': {
    component: lazy(() => import('src/pages/admin/widgets')),
    name: 'Widgets v1',
    permissions: ['STATS'],
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  '/admin/export': {
    component: lazy(() => import('src/pages/admin/export')),
    name: 'Export',
    permissions: [
      'SUPERADMIN_CLIENTS_WITH_UNUSED_BALANCES_EXPORT',
      'SUPERADMIN_ARCHIVED_CLIENTS_WITH_ACTIVE_BALANCES_EXPORT',
      'SUPERADMIN_AFFILIATE_TRANSACTIONS_EXPORT',
      'SUPERADMIN_AFFILIATE_BALANCES_EXPORT',
    ],
    roles: ['SUPER_ADMIN', 'ADMIN'],
  },
  '/admin/reports': {
    component: lazy(() => import('src/pages/admin/reports')),
    name: 'Reports',
    permissions: ['CALLS_STATISTICS'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/reports/calls-statistics': {
    component: lazy(() => import('src/pages/admin/reports/calls-statistics')),
    name: 'Calls Statistics',
    permissions: ['CALLS_STATISTICS'],
    roles: ['SUPER_ADMIN'],
  },
  '/admin/logout': {
    component: lazy(() => import('src/pages/admin/logout')),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    name: 'Logout',
  },
  '/admin/system-audit': {
    component: lazy(() => import('src/pages/admin/system-audit')),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    name: 'System Audit',
  },
  '/admin/system-audit/activities': {
    component: lazy(() => import('src/pages/admin/system-audit/activities')),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    name: 'System Audit - Activities',
  },
  '/admin/system-audit/review-time': {
    component: lazy(() => import('src/pages/admin/system-audit/review-time')),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    name: 'System Audit - Review Time',
  },
  '/admin/messages': {
    component: lazy(() => import('src/pages/admin/messages/index')),
    name: 'Urgent Messages',
    roles: ['SUPER_ADMIN'],
  },
  '/admin/messages/news-management': {
    component: lazy(() => import('src/pages/admin/messages/news-management')),
    name: 'Urgent Messages',
    roles: ['SUPER_ADMIN'],
  },
  '/admin/messages/urgent': {
    component: lazy(() => import('src/pages/admin/messages/urgent')),
    name: 'Urgent Messages',
    roles: ['SUPER_ADMIN'],
  },
  '/admin/messages/email-campaigns': {
    component: lazy(() => import('src/pages/admin/messages/email-campaigns')),
    name: 'Urgent Messages',
    roles: ['SUPER_ADMIN'],
  },
  '/admin/virtual-accounts': {
    component: lazy(() => import('src/pages/admin/virtual-accounts/index')),
    roles: ['SUPER_ADMIN'],
    name: 'Virtual Accounts',
  },
  '/admin/virtual-accounts/transfers': {
    component: lazy(() => import('src/pages/admin/virtual-accounts/transfers')),
    roles: ['SUPER_ADMIN'],
    name: 'Virtual Accounts',
  },
  '/admin/virtual-accounts/transactions': {
    component: lazy(
      () => import('src/pages/admin/virtual-accounts/transactions')
    ),
    roles: ['SUPER_ADMIN'],
    name: 'Virtual Accounts',
  },
};
