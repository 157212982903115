import { useEffect } from 'react';
import ReactAdroll from 'react-adroll';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { Toaster } from 'react-hot-toast';
import 'url-search-params-polyfill';
import 'src/assets/translations';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { ADROLL_PARAMS, GOOGLE_ANALYTICS_TAG } from 'src/config';
import { App } from 'src/features/App/containers/App';
import { GTM_ID, VERSION } from './config/features';
import { isProduction } from './utils/helpers/environment';

const Application = () => {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TAG);
    ReactGA.pageview(window?.location.pathname + window?.location.search);

    if (isProduction()) {
      ReactAdroll.init(...ADROLL_PARAMS);
    }

    TagManager.initialize({
      gtmId: GTM_ID,
      events: {
        logIn: 'Log In',
        signUp: 'Sign Up',
        purchase: 'Purchase',
      },
    });

    console.info(`%cApp Version: ${VERSION}`, 'font-size: 18px');
  }, []);

  const isIE = !!document['documentMode'];

  if (isIE) {
    return (
      <h1
        style={{
          margin: '1.5em 0.5em',
          fontSize: '28px',
          textAlign: 'center',
        }}
      >
        We do not support IE, please use Edge, Chrome, Firefox or Opera.
      </h1>
    );
  }

  return (
    <ErrorBoundary>
      <Toaster />
      <App />
    </ErrorBoundary>
  );
};

export default Application;
