import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { onError, onRequest, onResponse } from 'src/api/axiosInterceptor';
import { BASE_URL } from 'src/config';

const applyInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(onResponse, onError);
};

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 0,
});

applyInterceptor(instance);

export { instance };
export default instance;
