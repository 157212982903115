import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { useContext, useMemo } from 'react';
import { getI18n } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sideBarItems } from 'src/config/side-menu';
import { AppContext } from 'src/features/App/context/AppContext';
import usePermissions from 'src/hooks/usePermissions';
import { getUserRole } from 'src/utils/helpers/auth';
import { MobileTab } from './MobileTab';

export type MobileLayoutProps = {
  children: React.ReactNode;
};

const i18n = getI18n();

export function MobileLayout({ children }: MobileLayoutProps) {
  const { hasPermission } = usePermissions();
  const { isSideBarOpen, setSideBarOpen } = useContext(AppContext);
  const navigate = useNavigate();
  const role = getUserRole();

  const tabs = useMemo(() => {
    const userRole = getUserRole();
    return [
      ...sideBarItems
        .filter(
          (item) =>
            item.mobileTab &&
            (item.mobileTab.roles ?? item.roles).includes(userRole) &&
            item.roles.includes(userRole) &&
            hasPermission(item.permissions) &&
            !item.disabled
        )
        .map((item) => ({
          ...item,
          mobileTab: {
            ...item.mobileTab,
            order:
              typeof item.mobileTab.order === 'function'
                ? item.mobileTab.order(role)
                : item.mobileTab.order ?? 0,
          },
        }))
        .sort(({ mobileTab: { order: a } }, { mobileTab: { order: b } }) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        })
        .map((menuItem) => (
          <MobileTab
            key={menuItem.mobileTab.name ?? menuItem.name}
            title={menuItem.mobileTab.name ?? menuItem.name}
            //@ts-expect-error need to omit the icon ref from menuItem.icon
            icon={menuItem.mobileTab.icon ?? menuItem.icon}
            selected={
              menuItem.mobileTab.isSelected
                ? menuItem.mobileTab.isSelected(window.location.href)
                : window.location.href.includes(menuItem.link)
            }
            onClick={(e) => {
              e.preventDefault();
              isSideBarOpen && setSideBarOpen(false);
              navigate(menuItem.link);
            }}
            as="a"
          />
        )),
      <MobileTab
        key={'menu'}
        icon={() => <Bars3Icon className="h-6" />}
        title={i18n.t('global:user.mobileNavbar.menu')}
        selected={isSideBarOpen}
        selectedIcon={() => <XMarkIcon className="h-6" />}
        onClick={() => setSideBarOpen(!isSideBarOpen)}
      />,
    ];
  }, [hasPermission, isSideBarOpen, navigate, role, setSideBarOpen]);

  const boxShadow = '0px -4px 8px rgba(59, 83, 130, 0.16)';

  return (
    <div className="flex h-screen w-full flex-col overflow-auto">
      <div
        className={` flex flex-1 ${isSideBarOpen ? 'overflow-y-hidden' : ''}`}
      >
        {children}
      </div>
      <div
        className="fixed bottom-0 z-50 flex h-14 w-full flex-row"
        style={{ boxShadow, WebkitBoxShadow: boxShadow }}
      >
        {tabs}
      </div>
    </div>
  );
}
