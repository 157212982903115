import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'src/components/Alert';
import UserStatusContext from 'src/contexts/UserStatusContext';

export function CreditCardExpiryAlert() {
  const { t } = useTranslation();
  const { hasCreditCardToBeReplaced, creditCardToReplace } =
    useContext(UserStatusContext);

  const { lastDigits, lastTimeUsed } = creditCardToReplace || {};

  const message = useMemo(() => {
    if (lastDigits && lastTimeUsed) {
      return t('global:kyc.creditCardExpiryNotificationWithDetails', {
        lastDigits,
        lastTimeUsed,
      });
    } else if (lastDigits) {
      return t('global:kyc.creditCardExpiryNotificationWithLastDigits', {
        lastDigits,
      });
    } else {
      return t('global:kyc.creditCardExpiryNotification');
    }
  }, [lastDigits, lastTimeUsed, t]);

  if (!hasCreditCardToBeReplaced) {
    return null;
  }

  return <Alert type="danger">{message}</Alert>;
}
