import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

type Props = {
  className?: string;
};

const PageFooter = ({
  className = 'text-indigo-100 opacity-50',
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return useMemo(
    () => (
      <div
        className={classNames(
          'mx-auto flex max-w-sm flex-col space-y-2 text-center text-sm',
          className
        )}
      >
        <div>
          <Trans i18nKey={'global:common.texts.termsCopyright'} t={t}>
            <a
              href="https://www.instacoins.com/terms-conditions/"
              target="_blank"
              className="font-medium"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
            |
            <a
              href="https://www.instacoins.com/privacy-policy/"
              target="_blank"
              className="font-medium"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            |
            <a
              href="https://www.instacoins.com/cookies-policy/"
              target="_blank"
              className="font-medium"
              rel="noopener noreferrer"
            >
              Cookies Policy
            </a>
          </Trans>
        </div>
        <span>&copy; {`${new Date().getFullYear()}`} Instacoins</span>
      </div>
    ),
    [t, className]
  );
};

export default PageFooter;
