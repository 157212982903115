import { useEffect, useState } from 'react';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  onChange: (name: string, value: string) => void;
  placeholder: string;
  value: string | number;
};

const NUMBER_WITH_8_DECIMALS_REGEX = /^([0-9]+(\.[0-9]{0,8})?)$/;

const TwoFieldsInput = ({ name, value, placeholder, onChange }: Props) => {
  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== '') {
      const finalValue = Number(value).toLocaleString('fullwide', {
        useGrouping: false,
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      });
      setLocalValue(finalValue);
    } else {
      setLocalValue(value);
    }
    setError(false);
  }, [value]);

  const handleChangeValue = (updatedValue: string) => {
    setLocalValue(updatedValue);
    if (
      NUMBER_WITH_8_DECIMALS_REGEX.test(updatedValue) ||
      updatedValue === ''
    ) {
      onChange(name, updatedValue);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <TextInputFilter
      type="text"
      autoComplete="off"
      onChange={handleChangeValue}
      value={localValue}
      placeholder={placeholder}
      error={
        error &&
        t('global:common.errors.errorValueMustBeXDigitsNumber', {
          digits: 8,
        })
      }
    />
  );
};

export default TwoFieldsInput;
