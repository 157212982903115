import AppWrapper from 'src/features/App/components/AppWrapper';
import { AppProvider } from 'src/features/App/context/AppContext';
import RoutesContainer from 'src/routes/Routers';
import { adminRoutes } from 'src/routes/routingObjects/adminRoutes';

const AdminRouter = () => {
  return (
    <AppProvider>
      <AppWrapper>
        <RoutesContainer
          routingObjects={adminRoutes}
          defaultPath="/admin/transactions"
        />
      </AppWrapper>
    </AppProvider>
  );
};

export default AdminRouter;
