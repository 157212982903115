import type { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';
import tailwindConfig from 'src/../tailwind.config';

export const showMsg = async (params: SweetAlertOptions) =>
  swal.fire({
    ...params,
    confirmButtonColor: tailwindConfig.theme.extend.colors.secondary,
    cancelButtonColor: tailwindConfig.theme.extend.colors.danger,
  });

export const deleteAlert = async (title: string) => {
  return await swal
    .fire({
      title,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: tailwindConfig.theme.extend.colors.secondary,
      returnInputValueOnDeny: false,
    })
    .then((result) => {
      return result;
    });
};

export const successMessage = ({
  title,
  text,
  willClose, // Refer to documentation
}: {
  text?: string;
  title: string;
  willClose?: () => void;
}) => {
  return swal.fire({
    title,
    text,
    icon: 'success',
    willClose,
  });
};

export const errorMessage = ({
  title,
  text,
}: {
  text?: string;
  title: string;
}) => {
  return swal.fire({
    title,
    text,
    icon: 'error',
  });
};
