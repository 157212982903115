//TODO: This file is going to replace the error actions
import type { AxiosError } from 'axios';
import axios from 'axios';
import i18n from 'src/assets/translations';
import { APIUrls } from 'src/@enums/api/urls';
import type { GenericApiError } from 'src/utils/helpers/@types';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { errorMessage } from 'src/utils/sweetalert';
import { errorToast } from 'src/utils/toast';
import type {
  ClassifyAPIError,
  ClassifyError,
  ClassifyRequestValidationError,
  ForceNoPresentation,
  HandleErrorType,
  ShowError,
} from './@types';
import { authCheck, logout } from './auth';
import { isProduction } from './environment';
import { logSentry } from './logging';

const uploadDisabledError = new Error(
  'Uploads are temporary disabled, Please contact support for more information.'
);
const tokenAbsentError = new Error('Token is absent');
export const hasPendingSurveyError = new Error('Has Pending Survey');
const missingDocumentError = new Error('Please upload the requested documents');

/**
 * handle error global function.
 *
 * This function should be able to classify, log and show errors
 * on every single imperative error we have on the whole application.
 *
 * @param handleErrorOptions
 */
export const handleError: HandleErrorType = async ({
  error,
  additionalErrorInfo,
  customMessage,
  options = {},
}) => {
  const { callback, presentation } = options;
  const errorInfo = classifyError(error, additionalErrorInfo);

  logSentry(error, errorInfo);

  await showError({ presentation, errorInfo, customMessage });

  return callback ? callback({ error, errorInfo }) : undefined;
};

const classifyError: ClassifyError = (error, additionalErrorInfo) => {
  const errorInfo = {
    title: error.name,
    message: error.message,
    description: i18n.t('global:common.errors.somethingWentWrong'),
    additionalErrorInfo,
  };
  if (error === tokenAbsentError) {
    return {
      ...errorInfo,
      asWarning: true,
    };
  }
  if (error === uploadDisabledError) {
    return {
      ...errorInfo,
      description: i18n.t('global:common.errors.uploadsDisabled'),
      asWarning: true,
    };
  }
  if (error === hasPendingSurveyError) {
    return {
      ...errorInfo,
      description: 'Has Survey Pending',
    };
  }
  if (error === missingDocumentError) {
    return {
      ...errorInfo,
      description: error.message,
    };
  }
  if (axios.isAxiosError(error)) {
    return classifyAPIError(error, errorInfo);
  }
  if ('isRequestValidationError' in error) {
    return classifyRequestValidationError(error);
  }
  return errorInfo;
};

/**
 * Handles different API errors based on the status code
 */
const apiErrorDescriptions = {
  400: (error: AxiosError<GenericApiError>): string =>
    error.response?.data.reason,
  401: (error: AxiosError<GenericApiError>): string => {
    if (
      error.config?.url === APIUrls.LOGIN ||
      error.config?.url === APIUrls.AFFILIATE_LOGIN
    ) {
      return (
        error.response?.data?.reason ||
        i18n.t('global:common.errors.invalidCredentials')
      );
    }
    return authCheck()
      ? apiErrorDescriptions['expiredToken']()
      : apiErrorDescriptions[500](error);
  },
  403: (error: AxiosError<GenericApiError>): string =>
    error.response?.data.reason,
  500: (error: AxiosError<GenericApiError>) =>
    error.response?.data.reason ||
    i18n.t('global:common.errors.somethingWentWrong'),
  undefined: () => i18n.t('global:common.errors.connectionError'),
  expiredToken: () => {
    logout();
    return i18n.t('global:common.errors.sessionExpire');
  },
};

/**
 * Classify API Error based on the response/error type.
 * @param error Axios Error Object.
 * @param errorInfo Initialized Error Info Object.
 */
const classifyAPIError: ClassifyAPIError = (error, errorInfo) => {
  errorInfo.title = `API [${
    error.response?.status || error.message
  }] Error on ${error.config.url}`;
  const getDescription: (error?: AxiosError) => string =
    apiErrorDescriptions[String(error.response?.status)] ||
    apiErrorDescriptions[500];
  error.message = errorInfo.title;
  return {
    ...errorInfo,
    asWarning: true,
    config: error.config,
    response: error.response,
    description: getDescription(error),
  };
};

/**
 * @description Classifies the error that comes from the request
 * validator.
 * @param error The `error` object to process.
 * @returns A customized object that can be handled by the application.
 */
const classifyRequestValidationError: ClassifyRequestValidationError = (
  error
) => {
  const errorDescriptions = {
    required: 'global:common.errors.required',
    pattern: 'global:common.errors.pattern',
    minLength: 'global:common.errors.minLength',
    maxLength: 'global:common.errors.maxLength',
    const: 'global:common.errors.const',
    enum: 'global:common.errors.const',
  };
  const description = error.errors
    .map((errorValue) => {
      const fieldValue =
        errorValue.keyword === 'required'
          ? errorValue.params.missingProperty
          : errorValue.instancePath.substring(
              errorValue.instancePath.lastIndexOf('/') + 1,
              errorValue.instancePath.length
            );
      return (
        ' ' +
        (i18n.t(errorDescriptions[errorValue.keyword], {
          field: fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1),
        }) || i18n.t('global:common.errors.somethingWentWrong'))
      );
    })
    .toString();
  return {
    title: error.name,
    message: error.message,
    description,
  };
};

/**
 * Presentation handler object
 */
const presentationHandlers = {
  [PresentationMethod.TOAST]: (description: string) => errorToast(description),
  [PresentationMethod.ALERT]: (description: string) =>
    errorMessage({ title: 'Error!', text: description }),
};

const forceNoPresentation: ForceNoPresentation = (errorInfo) => {
  const conditions = [
    errorInfo.response?.status === 401 &&
      errorInfo.config.url !== APIUrls.AFFILIATE_LOGIN &&
      errorInfo.config.url !== APIUrls.AFFILIATE_SECURITY,
  ];
  const isForcedToShowNoPresentation = conditions.includes(true);
  if (!isProduction() && isForcedToShowNoPresentation) {
    console.warn('Forced to show no presentation', { errorInfo });
  }
  return isForcedToShowNoPresentation;
};

/**
 * Show error message based on the presentation method.
 */
const showError: ShowError = ({ errorInfo, customMessage, presentation }) => {
  if (!isProduction()) {
    console.error(errorInfo);
  }
  if (presentation === undefined || forceNoPresentation(errorInfo)) {
    return;
  }
  return presentationHandlers[presentation](
    customMessage || errorInfo.description
  );
};
