import type { ColumnDef, RowData } from '@arringo-npm/fe-table';
import type {
  Column,
  DisplayColumn,
  GroupingColumn,
  Identifier,
} from 'src/components/Table/@types';
import {
  isAccessorColumn,
  isGroupingColumn,
  isDisplayColumn,
} from 'src/components/Table/@types';
import { assertUnreachable } from 'src/utils/assert-unreachable';

export function columnsAdapter<TData extends RowData, TValue>(
  columns: Column<TData, TValue>[]
): ColumnDef<TData, TValue>[] {
  return columns.map((column) => {
    if (isAccessorColumn(column)) {
      return {
        ...column,
        accessorKey: column.accessorKey,
        ...(column.footer ? { footer: column.footer } : {}),
        ...(column.size === undefined ? {} : { size: column.size }),
        ...(column.cell ? { cell: column.cell } : {}),
        ...(column.filter !== undefined
          ? { enableColumnFilter: true, filter: column.filter }
          : {}),
        header: column.header,
      };
    } else if (isGroupingColumn(column)) {
      return {
        ...getIdentifier(column),
        subColumns: columnsAdapter(column.subColumns),
      };
    } else if (isDisplayColumn(column)) {
      return {
        ...column,
        ...(column.size === undefined ? {} : { size: column.size }),
        ...getIdentifier(column),
        ...(column.cell ? { cell: column.cell } : {}),
      };
    } else {
      assertUnreachable(column);
    }
  });
}

function getIdentifier<TData extends RowData, TValue>(
  column: GroupingColumn<TData, TValue> | DisplayColumn<TData, TValue>
): Identifier {
  return {
    id: column.id,
    header: column.header,
  };
}
