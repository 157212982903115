import { APITypes, APIUrls } from 'src/@enums/api';
import type { MenuItemCountResponse } from 'src/@types/MenuItem';
import { instance } from 'src/api/instance';
import { addToken } from 'src/utils';

export const callGetProblematicWithdrawalsCount = () =>
  instance.post<MenuItemCountResponse>(
    APIUrls.ADMIN_CRYPTO_WITHDRAWAL,
    addToken({ type: APITypes.WITHDRAWAL_PROBLEMATIC_GET_COUNT }),
    {
      responseSchema: 'MenuItemCountResponse',
    }
  );
