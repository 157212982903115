import type { CellProps } from '..';
import { useState } from 'react';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import { EyeIcon } from '@heroicons/react/24/solid';

const AudienceType = <TData extends { clientIds?: number[] }>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();
  const { clientIds } = cell.row.original;

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {value === 'CUSTOM_IDS' ? (
        <>
          <Modal
            onClose={handleCloseModal}
            isOpen={showModal}
            title="Client IDs"
          >
            <div className="flex flex-wrap gap-1.5">
              {clientIds?.map((clientId, index) => (
                <span key={clientId}>
                  <a
                    href={`/admin/clients/${clientId}`}
                    className="text-blue-600"
                    target="_blank"
                  >
                    {clientId}
                  </a>
                  {index < clientIds.length - 1 && ','}
                </span>
              ))}
            </div>
          </Modal>
          <Button
            leadingIcon={<EyeIcon />}
            onClick={handleShowModal}
            size="small"
          >
            Custom IDs
          </Button>
        </>
      ) : (
        value
      )}
    </>
  );
};

export default AudienceType;
