import { useParams } from 'react-router-dom';
import { goToPath } from 'src/utils/pageNavigator';
import type {
  DeprecatedRoute,
  PartitionedRoutes,
  Path,
} from 'src/routes/types';
import { useEffect } from 'react';
import { getUserRole } from 'src/utils/helpers/auth';

type DeprecatedRedirectProps = {
  paramKey?: string;
  queryStringKey?: string;
  to: Path;
};

const DeprecatedRedirect = ({
  to,
  queryStringKey,
  paramKey,
}: DeprecatedRedirectProps): null => {
  const params = useParams();
  useEffect(() => {
    const path =
      // TODO: this is a temporary solution for passing the query strings, later we
      // can fix it to use `goToPath` utility function
      queryStringKey && paramKey
        ? `${to}&${queryStringKey}=${params?.[paramKey]}`
        : to;

    goToPath(path as Path);
  }, [to, queryStringKey, paramKey, params]);
  return null;
};

/*
 * All the old routingObjects that we still need to keep cause they are being used by
 * external services (e.g. CRMs and PGs).
 */
const deprecatedRoutes: PartitionedRoutes<DeprecatedRoute> = {
  '/buy': {
    component: function BuyRedirect() {
      return <DeprecatedRedirect to="/client/deposit" />;
    },
    name: 'Buy Page',
  },
  '/deposit': {
    component: function DepositRedirect() {
      return <DeprecatedRedirect to="/client/deposit" />;
    },
    name: 'Buy Page',
  },
  '/payment-callback': {
    component: function PaymentCallbackRedirect() {
      return <DeprecatedRedirect to="/client/payment-callback" />;
    },
    name: 'Payment Callback Page',
  },
  '/transaction-result-new': {
    component: function TransactionResultNewRedirect() {
      return <DeprecatedRedirect to="/client/payment-callback" />;
    },
    name: 'Transaction Result New Page',
  },
  '/reset-pwd': {
    component: function ResetPasswordNewRedirect() {
      return <DeprecatedRedirect to="/reset-password" />;
    },
    name: 'Reset Password',
  },
  '/affiliate/reset-pwd': {
    component: function ResetAffiliatePasswordNewRedirect() {
      return <DeprecatedRedirect to="/affiliate/reset-password" />;
    },
    name: 'Affiliate Reset Password',
  },
  '/widgetsv2': {
    component: function WidgetsNewRedirect() {
      return <DeprecatedRedirect to="/admin/widgets/v2" />;
    },
    name: 'Affiliate Reset Password',
  },
  '/admin/stats': {
    component: function AdminStatsRedirect() {
      return <DeprecatedRedirect to="/admin/transactions" />;
    },
    name: 'Admin Statistics',
  },
  '/transaction-history': {
    component: function AdminStatsRedirect() {
      return <DeprecatedRedirect to="/client/transactions" />;
    },
    name: 'Transactions',
  },
  '/unsubscribe&data=:token': {
    component: function UnsubscribeRedirect() {
      if (getUserRole() === 'AFFILIATE') {
        return (
          <DeprecatedRedirect
            to={'/affiliate/emailing/unsubscribe' as Path}
            queryStringKey="data"
            paramKey="token"
          />
        );
      }
      return (
        <DeprecatedRedirect
          to={'/client/emailing/unsubscribe' as Path}
          queryStringKey="data"
          paramKey="token"
        />
      );
    },
    name: 'Unsubscribe',
  },
  '/admin/widgets/wallets': {
    component: function WalletsNewRedirect() {
      return <DeprecatedRedirect to="/admin/widgets/wallets-bulgaria" />;
    },
    name: 'Wallets',
  },
};

export default deprecatedRoutes;
