import { CheckIcon } from '@heroicons/react/24/solid';

type ProgressIconProps = {
  currentStep: number;
  step: number;
  stepIsActive: boolean;
  stepIsPassed: boolean;
};

const ProgressIcon = ({
  stepIsPassed,
  stepIsActive,
  currentStep,
  step,
}: ProgressIconProps): React.ReactElement => {
  if (stepIsPassed) {
    return (
      <div className="rounded-full border-2 border-blue-600 p-1 text-blue-600">
        <CheckIcon width={20} height={20} />
      </div>
    );
  }

  if (stepIsActive) {
    return (
      <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-blue-600 p-1 text-white">
        <span className="font-semibold">{currentStep}</span>
      </div>
    );
  }

  return (
    <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 p-1 text-gray-400">
      <span className="font-semibold">{step}</span>
    </div>
  );
};

export default ProgressIcon;
