import { useContext, useMemo } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { authCheck, getUserRole, loadJwt } from 'src/utils/helpers/auth';
import { isObjectEmpty } from 'src/utils';
import UserStatusContext from 'src/contexts/UserStatusContext';
import usePermissions from 'src/hooks/usePermissions';

export const useAuthorizedCommon = () => {
  const {
    user: {
      userAccountInfo: { roleType },
    },
    isVerified,
  } = useContext(UserContext);
  const { isLoading: isUserStatusLoading } = useContext(UserStatusContext);
  const { endpointPermissions } = usePermissions();
  const isPermissionsLoaded = useMemo(
    () => !isObjectEmpty(endpointPermissions),
    [endpointPermissions]
  );
  const userIsVerified = authCheck() && isVerified;

  const isAdmin = getUserRole(loadJwt()) !== 'CLIENT';
  return {
    isLoading: !isPermissionsLoaded || isUserStatusLoading,
    isVerified: isAdmin || userIsVerified,
    isPermissionsLoaded,
    role: roleType,
  };
};
