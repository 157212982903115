import TimerFeature from 'src/features/TimerFeature';
import { serverToDate } from 'src/utils/dates/format';

type Props = {
  asUtc?: boolean;
  time: string;
};

const QueueTime = ({ time, asUtc }: Props) => {
  if (time) {
    const dateStr = serverToDate(time, asUtc);

    const date = dateStr ? new Date(dateStr) : new Date();

    return (
      <div className="flex">
        <TimerFeature className="m-auto" start={date} short />
      </div>
    );
  } else {
    return <div>{time}</div>;
  }
};

export default QueueTime;
