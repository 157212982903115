import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';

const YesNo = <TData extends RowData>({ cell }: CellProps<TData, boolean>) => {
  return (
    <>
      {cell.getValue() ? (
        <span className="text-green-600">Yes</span>
      ) : (
        <span className="text-yellow-600">No</span>
      )}
    </>
  );
};

export default YesNo;
