import { useTranslation } from 'react-i18next';
import InstacoinsLogo from 'src/components/InstacoinsLogo';

type Props = {
  name?: string;
};

const FullsizeLoader = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="FullsizeLoader_base z-50 flex h-screen w-full flex-col items-center justify-center bg-white p-6 text-center">
      <div className="flex flex-1 flex-col items-center justify-center space-y-2">
        <svg
          className="mb-4 h-12 w-12 animate-spin-fast text-brand"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <InstacoinsLogo
          variant="logoTypeWithTM"
          width={220}
          height={50}
          className="text-brand"
        />

        <p className="text-lg text-gray-800">
          {name
            ? t('global:common.texts.loadingPage', { name })
            : t('global:common.texts.loadingRequestedProcess')}
        </p>
      </div>
    </div>
  );
};

export default FullsizeLoader;
