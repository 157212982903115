import { useCallback, useMemo } from 'react';
import { tokenCookie } from 'src/config';
import useCookie from './useCookie';

const useAuthorization = () => {
  const { get, set, remove } = useCookie();

  const isAuthorized = useMemo(() => Boolean(get(tokenCookie)), [get]);

  const setToken = useCallback(
    (token: string) => {
      set(tokenCookie, token);
    },
    [set]
  );

  const removeToken = useCallback(() => {
    remove(tokenCookie);
  }, [remove]);

  return {
    isAuthorized,
    setToken,
    removeToken,
  };
};

export default useAuthorization;
