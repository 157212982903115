import RoutesContainer from 'src/routes/Routers';
import FacebookPixelComponent from 'src/components/FacebookPixelComponent';
import {
  affiliateUnprotectedRoutes,
  unprotectedRoutes,
} from 'src/routes/routingObjects/unprotectedRoutes';
import { isAffiliate } from 'src/utils/helpers/roles';

const routingObjects = {
  ...unprotectedRoutes,
  ...affiliateUnprotectedRoutes,
};

const UnprotectedRouter = () => {
  return (
    <>
      <RoutesContainer
        routingObjects={routingObjects}
        defaultPath={isAffiliate() ? '/affiliate/login' : '/login'}
      />
      <FacebookPixelComponent />
    </>
  );
};

export default UnprotectedRouter;
