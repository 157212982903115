import { useTranslation } from 'react-i18next';
import InstacoinsLogo from 'src/components/InstacoinsLogo';
import i18n from 'src/assets/translations';
import ProgressIcon from 'src/components/UnauthorizedWrapper/ProgressIcon';
import { useUserSteps } from 'src/components/UnauthorizedWrapper/StepsContext';
import styles from './Sidebar.module.css';

type SidebarStep = {
  description?: string;
  title: string;
};

const SIDEBAR_STEPS: SidebarStep[] = [
  {
    title: i18n.t('global:register.texts.titleCreateAccount'),
    description: i18n.t('global:register.texts.descriptionCreateAccount'),
  },
  {
    title: i18n.t('global:register.texts.titleYourFirstTransaction'),
  },
  {
    title: i18n.t('global:register.texts.titleAccountVerification'),
    description: i18n.t('global:register.texts.descriptionAccountVerification'),
  },
];

const Sidebar = (): React.ReactElement => {
  const { step: currentStep, pendingSteps } = useUserSteps();
  const { t } = useTranslation();

  const isStepPassed = (index: number) =>
    pendingSteps.find((step) => step === index + 1) !== undefined
      ? false
      : index + 1 < currentStep;

  return (
    <div className={styles.wrapper}>
      <InstacoinsLogo
        variant="logoTypeWithTM"
        width={220}
        height={50}
        className="text-brand"
      />

      <h2 className={styles.title}>
        {t('global:login.texts.titleCreateAccount')}
      </h2>

      <ul className={styles.stepsWrapper}>
        {SIDEBAR_STEPS.map(({ title, description }, i) => (
          <li key={i} className={styles.step}>
            <div className={styles.stepTitle}>
              <ProgressIcon
                stepIsPassed={isStepPassed(i)}
                stepIsActive={i + 1 === currentStep}
                currentStep={currentStep}
                step={i + 1}
              />

              <h4>{title}</h4>
            </div>

            <span className={styles.stepDescription}>{description}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
