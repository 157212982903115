import { useCallback, useState } from 'react';
import type {
  SnoozeUserNeedAdminMonitoringForm,
  UseSnoozeUserNeedAdminMonitoring,
} from 'src/features/AdminUsersNeedMonitoring/@types';
import {
  callSnoozeUserNeedAdminMonitoring,
  callUnSnoozeUserNeedAdminMonitoring,
} from 'src/features/AdminUsersNeedMonitoring/api';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { handleError } from 'src/utils/helpers/errors';
import { successToast } from 'src/utils/toast';
import { useForm } from 'react-hook-form';

export const useSnoozeUserNeedAdminMonitoring = (
  props: UseSnoozeUserNeedAdminMonitoring
) => {
  const { userId, isSnoozed, handleRefreshData } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formMethods = useForm<SnoozeUserNeedAdminMonitoringForm>({
    mode: 'all',
    defaultValues: {
      relativeTimeHours: null,
      exactDateTime: null,
    },
  });

  const handleShowModal = useCallback(() => setIsOpen(true), []);
  const handleCloseModal = useCallback(() => setIsOpen(false), []);

  const submit = (formData: SnoozeUserNeedAdminMonitoringForm) => {
    const { relativeTimeHours, exactDateTime } = formData;
    handleClick(() =>
      callSnoozeUserNeedAdminMonitoring({
        userId,
        relativeTimeHours: relativeTimeHours && Number(relativeTimeHours),
        exactDateTime,
      })
    );
  };

  const handleUnSnoozeButton = () => {
    handleClick(() => callUnSnoozeUserNeedAdminMonitoring(userId));
  };

  const handleClick = async (callback: () => void | Promise<unknown>) => {
    setIsLoading(true);
    try {
      await callback();

      successToast(`User ${userId} is ${isSnoozed ? 'unsnoozed' : 'snoozed'}.`);
      handleRefreshData();
    } catch (error) {
      handleError({
        error,
        options: { presentation: PresentationMethod.TOAST },
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  return {
    isOpen,
    isLoading,
    handleShowModal,
    handleCloseModal,
    formMethods,
    submit,
    handleUnSnoozeButton,
  };
};
