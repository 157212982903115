import type {
  InputVariant,
  CommonInputProps,
  InputWrapperProps,
} from './@types';
import styles from './Inputs.module.css';

type CommonInputsStyles = Pick<InputWrapperProps, 'error'> & CommonInputProps;

const inputVariant: Record<InputVariant, string> = {
  hero: styles.hero,
  underline: styles.underline,
};
const errorVariant: Record<InputVariant, string> = {
  hero: styles.heroError,
  underline: styles.underlineError,
};

export const commonInputsStyles = ({
  error,
  variant,
}: CommonInputsStyles): string => {
  return `
    ${error && errorVariant[variant]}
    ${!error && inputVariant[variant]}
  `;
};
