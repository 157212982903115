import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InstacoinsLogo from 'src/components/InstacoinsLogo';
import Button from 'src/components/Button';

export const ErrorFallback = () => {
  const { t, i18n } = useTranslation();

  /**
   * TODO: if you remove this useEffect, translation of this component will
   * fallback to default language
   **/
  useEffect(() => {
    i18n.changeLanguage(i18n.language);
  }, [i18n]);

  const refreshPage = () => {
    window?.location.reload();
  };

  return (
    <div className="min-w-screen flex min-h-screen items-center">
      <div className="mx-auto grid max-w-5xl gap-4 rounded-md bg-gray-50 p-10">
        <InstacoinsLogo
          variant="logoTypeWithTM"
          width={220}
          height={50}
          className="mx-auto text-brand"
        />
        <h1 className="text-center text-2xl font-bold">
          {t('global:common.errors.fallback.title')}
        </h1>
        <div className="mx-auto mt-2 grid max-w-xl gap-2">
          <p className="font-bold">
            {t('global:common.errors.fallback.subTitle')}
          </p>
          <ol className="ml-10 grid list-decimal gap-1">
            <li>{t('global:common.errors.fallback.list.one')}</li>
            <li>{t('global:common.errors.fallback.list.two')}</li>
            <li>{t('global:common.errors.fallback.list.three')}</li>
          </ol>
          <p>
            {t('global:common.errors.fallback.contactSupportVia')}{' '}
            <a href="mailto:support@instacoins.com" className="underline">
              support@instacoins.com
            </a>{' '}
            {t('global:common.errors.fallback.or')}{' '}
            <a href="tel:+3726189284" className="underline">
              +372 618 9284
            </a>
            .
          </p>
        </div>
        <Button shape="square" onClick={refreshPage}>
          {t('global:common.errors.fallback.retry')}
        </Button>
      </div>
    </div>
  );
};
