import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Roles } from 'src/@types/Roles';
import type { Survey } from 'src/@types';
import { ApiCallStatus } from 'src/@types';
import { callGetSurvey } from 'src/api/survey';

/**
 * A hook for keeping user's survey info updated.
 * It would try to load user's survey when they have logged in.
 * @param userId The id of current user, who has logged it.
 */
export const useSurvey = (userId: number, userRole?: Roles) => {
  const [survey, setSurvey] = useState<Survey>();
  const [loadingStatus, setLoadingStatus] = useState<ApiCallStatus>(
    ApiCallStatus.NONE
  );

  const loadUserSurvey = useCallback(async () => {
    if (userRole === 'CLIENT') {
      setLoadingStatus(ApiCallStatus.IN_PROGRESS);
      try {
        const { data } = await callGetSurvey(userId);
        setSurvey(data.surveyInfo);
        setLoadingStatus(ApiCallStatus.SUCCEEDED);
      } catch (error) {
        setLoadingStatus(ApiCallStatus.FAILED);
      }
    }
  }, [userId, userRole]);

  // Tries to load user's survey when user has logged in or their id has changed.
  useEffect(() => {
    userId && loadUserSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return useMemo(
    () => ({ survey, setSurvey, loadingStatus, loadUserSurvey }),
    [survey, setSurvey, loadingStatus, loadUserSurvey]
  );
};

export type ISurveyContext = ReturnType<typeof useSurvey>;
