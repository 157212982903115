import type { Languages } from 'src/assets/translations/types';

export default Object.freeze({
  /** Determines wheter the translation feature is enabled. */
  isEnabled: true,
  /** Determines the forced language, when the feature is disabled */
  fallbackLanguage: 'en' as Languages,

  /** Toggles the `isEnabled` parameter. Same as `! isEnabled` */
  get isDisabled() {
    return !this.isEnabled;
  },
});
