import { useEffect, useMemo } from 'react';
import { useTimer } from 'use-timer';
import { formatDateInterval } from '@arringo-npm/utility-package';
import classNames from 'classnames';

type Props = {
  className?: string;
  short?: boolean;
  start: Date;
};

const TimerFeature = ({ start, short, className }: Props) => {
  const { time, start: startTimer } = useTimer({
    initialTime: 0,
  });

  useEffect(() => {
    if (!start) return;

    startTimer();
  }, [start, startTimer]);

  const formattedInterval = useMemo(
    () =>
      formatDateInterval(
        {
          start: start,
          end: new Date(),
        },
        {
          shortFormat: short,
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [time]
  );

  if (!start) {
    return <></>;
  }

  return (
    <div className={classNames('flex', className)}>
      <span className="flex">
        <span className="m-auto">{formattedInterval}</span>
      </span>
    </div>
  );
};

export default TimerFeature;
