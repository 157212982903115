import type { RangeType } from 'src/@types/RangeType';
import type { Filter } from '..';
import { useContext, useMemo } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { isAdmin } from 'src/utils/helpers/roles';
import {
  formatDate,
  startOfDay,
  addDuration,
  subDuration,
} from '@arringo-npm/utility-package';
import DateField from './components/DateField';
import { capitalizeFirstLetter } from 'src/utils/helpers/datatypes/string';
import { useTranslation } from 'react-i18next';

const DateRangeFilter: Filter = ({ column }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const columnFilter = column.getFilterValue() as RangeType<string> | undefined;
  const filter: RangeType<string> = {
    from: columnFilter ? columnFilter.from : '',
    to: columnFilter ? columnFilter.to : '',
  };

  const maxFieldDate = new Date();

  const isUserAdmin = isAdmin(userContext?.user?.userAccountInfo?.roleType);

  const handleClearDate = (name: 'from' | 'to') => {
    const newFilter = filter;
    newFilter[name] = undefined;
    if (newFilter.from === undefined && newFilter.to === undefined) {
      column.setFilterValue(undefined);
    } else {
      column.setFilterValue(newFilter);
    }
  };

  const handleOnChange = (name: 'from' | 'to', value: Date) => {
    if (!value) {
      return;
    }

    const dateToConvert =
      name === 'to' ? startOfDay(addDuration(value, { days: 1 })) : value;
    const targetTimeZone = isUserAdmin ? 'Europe/Malta' : undefined;

    const formattedDate = formatDate(
      dateToConvert,
      "yyyy-MM-dd '00:00:00'xxx",
      targetTimeZone
    );

    const newFilter = {
      from: filter.from !== '' ? filter.from : undefined,
      to: filter.to !== '' ? filter.to : undefined,
      [name]: formattedDate,
    };
    column.setFilterValue(newFilter);
  };

  const { from, to } = filter;

  const displayTo = useMemo(() => {
    if (to) {
      const date = new Date(to.substr(0, 10));
      const yesterday = startOfDay(subDuration(date, { days: 1 }));
      return formatDate(yesterday, 'yyyy-MM-dd');
    }

    return '';
  }, [to]);

  return (
    <div className="flex flex-col space-y-1">
      <DateField
        value={from?.substring(0, 10) || ''}
        maxFieldDate={maxFieldDate}
        fieldName="from"
        placeholder={capitalizeFirstLetter(t('global:tables.from'))}
        onChange={handleOnChange}
        onClear={() => handleClearDate('from')}
      />
      <DateField
        value={displayTo}
        minFieldDate={
          from?.substring(0, 10) ? new Date(from.substring(0, 10)) : undefined
        }
        maxFieldDate={maxFieldDate}
        fieldName="to"
        placeholder={capitalizeFirstLetter(t('global:tables.to'))}
        onChange={handleOnChange}
        onClear={() => handleClearDate('to')}
      />
    </div>
  );
};

export default DateRangeFilter;
