import ReactSelect from 'react-select';
import Wrapper from 'src/components/Inputs/Wrapper';
import styles from './SelectInput.module.css';
import type { SelectInputProps } from './@types';
import type { Ref } from 'react';
import { forwardRef } from 'react';
import type Select from 'react-select/dist/declarations/src/Select';
import classNames from 'classnames';

const SelectInput = (
  {
    name,
    label,
    disabled = false,
    className = '',
    addonBefore,
    truncateMessages,
    addonAfter,
    wrapperClassName = '',
    menuPosition = 'absolute',
    error,
    hint,
    required,
    customStyles,
    ...rest
  }: SelectInputProps,
  ref: Ref<Select<unknown, boolean>>
): React.ReactElement => {
  const classes = classNames(className, styles.base, {
    [styles.errorInput]: error,
    [styles.moreRightSpaces]: error,
  });

  return (
    <Wrapper
      name={name}
      label={label}
      disabled={disabled}
      truncateMessages={truncateMessages}
      wrapperClassName={wrapperClassName}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      error={error}
      hint={hint}
      required={required}
    >
      <ReactSelect
        menuPosition={menuPosition}
        id={name}
        name={name}
        className={classes}
        classNamePrefix="selectInput"
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 200 }),
          ...customStyles,
        }}
        isDisabled={disabled}
        aria-describedby={`${name}-description`}
        ref={ref}
        {...rest}
      />
    </Wrapper>
  );
};

export default forwardRef(SelectInput);
