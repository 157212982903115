import type { Ref } from 'react';
import { forwardRef, useMemo } from 'react';
import type { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SafeOmit } from 'src/@types/utilities/SafeOmit';
import TextInput from 'src/components/Inputs/TextInput';
import type { TextInputProps } from 'src/components/Inputs/TextInput/@types';

export type InputFormFieldProps = SafeOmit<TextInputProps, 'error'> & {
  errorObject?: Partial<FieldError>;
};

/**
 * This is the input form field which is a based
 * on `TextInput` to handle react-hook-form errors
 * better or other options.
 */
const InputFormField = (
  { errorObject, ...rest }: InputFormFieldProps,
  ref: Ref<HTMLInputElement>
): React.ReactElement => {
  const { t } = useTranslation();

  const errorMessage = useMemo(
    (): string =>
      errorObject?.type === 'required'
        ? t('global:common.errors.errorRequired')
        : errorObject?.message,
    [errorObject, t]
  );

  return <TextInput error={errorMessage} ref={ref} {...rest} />;
};

export default forwardRef(InputFormField);
