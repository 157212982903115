import type { Filter } from '..';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/utils/helpers/datatypes/string';
import TwoFieldInput from 'src/components/Table/filters/components/TwoFieldInput';

const TwoField: Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue() as [
    number | null,
    number | null,
  ];
  const filterValue = useMemo(
    () => ({
      from: columnFilterValue?.[0] != null ? columnFilterValue[0] : '',
      to: columnFilterValue?.[1] != null ? columnFilterValue[1] : '',
    }),
    [columnFilterValue]
  );
  const { t } = useTranslation();

  const handleChangeValue = (name: string, value: string) => {
    const newFilter = {
      from: filterValue.from !== '' ? filterValue.from : undefined,
      to: filterValue.to !== '' ? filterValue.to : undefined,
      [name]: value !== '' ? Number(value) : undefined,
    };
    if (newFilter.from === undefined && newFilter.to === undefined) {
      column.setFilterValue(undefined);
    } else {
      column.setFilterValue([newFilter.from, newFilter.to]);
    }
  };

  return (
    <div className="flex flex-col gap-y-1">
      <TwoFieldInput
        name="from"
        onChange={handleChangeValue}
        value={filterValue.from}
        placeholder={capitalizeFirstLetter(t('global:tables.from'))}
      />
      <TwoFieldInput
        name="to"
        onChange={handleChangeValue}
        value={filterValue.to}
        placeholder={capitalizeFirstLetter(t('global:tables.to'))}
      />
    </div>
  );
};

export default TwoField;
