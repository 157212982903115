import { XCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

type ClearButtonProps = {
  error?: boolean;
  onClear: () => void;
};

const ClearButton = ({ onClear, error }: ClearButtonProps) => {
  return (
    <XCircleIcon
      className={classNames(
        'absolute right-2 top-2 cursor-pointer',
        { 'text-gray-600 hover:text-blue-600': !error },
        { 'text-red-600': error }
      )}
      width={20}
      height={20}
      onClick={onClear}
    />
  );
};

export default ClearButton;
