import { subDuration } from '@arringo-npm/utility-package';
import type { SelectOptionType } from 'src/@types/SelectType';

const MIN_AGE_SUBTRACTION = 18;
const MAX_AGE_SUBTRACTION = 121;

export const minBirthDate = subDuration(new Date(), {
  years: MAX_AGE_SUBTRACTION,
});

export const maxBirthDate = subDuration(new Date(), {
  years: MIN_AGE_SUBTRACTION,
});

export const allowedBirthYears: SelectOptionType<number>[] = Array.from(
  Array(maxBirthDate.getFullYear() - minBirthDate.getFullYear() + 1),
  (_, i) => {
    const value = minBirthDate.getFullYear() + i;
    return { value, label: value.toString() };
  }
);

const START_YEAR = 1900;

export const defaultAllowedYears: SelectOptionType<number>[] = Array.from(
  Array(new Date().getFullYear() - START_YEAR + 1),
  (_, i) => {
    const value = START_YEAR + i;
    return { value, label: value.toString() };
  }
);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthsOptions: SelectOptionType<number>[] = months.map(
  (month, i) => ({
    value: i,
    label: month,
  })
);
