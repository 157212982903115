import { useCallback, useMemo, useState } from 'react';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import { serverToDate } from 'src/utils/dates/format';
import { formatDate } from '@arringo-npm/utility-package';
import { isObject } from 'src/utils';
import { JsonViewer } from '@textea/json-viewer';
import PartyIndicator from 'src/components/Table/cells/PartyIndicator';
import type { CellProps } from '..';

type ActivityDetail1 = {
  new: string | number | boolean;
  old: string | number | boolean;
};

type ActivityDetail2 = {
  flag?: string;
  id?: number;
  isHidden?: boolean;
  lastCall?: string;
  lockAuthorId?: string;
  lockCreatedAt?: string;
  snoozeAuthorId?: number;
  snoozeCreatedAt?: string;
  snoozeUntil?: string;
  userId?: number;
};

export type Activity = {
  createdAt: string;
  details?: Record<string, Record<string, ActivityDetail1>> | ActivityDetail2;
  detailsPreview?: string;
  ip: string;
  ipCountry: string;
  sourceId: number;
  sourceName: string;
  sourceType: string;
  targetId: number;
  targetName: string;
  targetOwnerId: number;
  targetOwnerType: string;
  targetType: string;
  targetUser: string;
  transactionFriendlyId?: string;
};

const classifyDetailValue = (value: unknown) => {
  if (isObject(value)) return value;

  if (typeof value === 'undefined' || value === null) return 'N/A';

  const fixedStamp = typeof value === 'string' && serverToDate(value);
  if (fixedStamp)
    return formatDate(new Date(fixedStamp), 'yyyy-MM-dd HH:mm:ss');

  if (value.toString) return value.toString();

  return value;
};

const ActivityDetails = <TData extends Activity>({
  cell,
}: CellProps<TData, string>) => {
  const activity = cell.row.original;
  const [isOpen, setIsOpen] = useState(false);

  const details = useMemo(
    () =>
      Object.entries(activity?.details || {}).map(
        ([activityDetailKey, activityDetailValue]) => {
          return {
            title: activityDetailKey,
            hasChildItem: isObject(activityDetailValue),
            childItems: isObject(activityDetailValue)
              ? Object.entries(activityDetailValue || {}).map(
                  ([childKey, childValue]) => ({
                    title: childKey,
                    hasChildItem: isObject(childValue),
                    childItems: classifyDetailValue(childValue),
                  })
                )
              : classifyDetailValue(activityDetailValue),
          };
        }
      ),
    [activity.details]
  );

  const handleShowModal = useCallback(() => setIsOpen(true), []);
  const handleCloseModal = useCallback(() => setIsOpen(false), []);

  if (!activity.details) {
    return (
      <span>
        <Button
          size="small"
          variant="text"
          colorScheme="gray"
          className="py-4"
          disabled
        >
          N/A
        </Button>
      </span>
    );
  }

  return (
    <>
      <div className="flex w-full justify-between space-x-1">
        <span className="truncate break-words uppercase">
          {activity?.detailsPreview ||
            details
              ?.map(({ title, hasChildItem, childItems }) => {
                let detailValue = null;

                if (Array.isArray(childItems)) {
                  const [firstItem] = childItems;

                  detailValue =
                    firstItem?.childItems?.new ??
                    firstItem?.childItems ??
                    'N/A';
                }

                return `${title}: ${hasChildItem ? detailValue : childItems}`;
              })
              .join(' - ')}
        </span>
        <button
          className="appearance-none font-medium text-blue-600"
          onClick={handleShowModal}
        >
          [...]
        </button>
      </div>

      <Modal
        onClose={handleCloseModal}
        isOpen={isOpen}
        size="lg"
        title="Activity details"
      >
        <p className="mb-2">
          <strong>Recorded at:</strong>{' '}
          {formatDate(serverToDate(activity.createdAt), 'yyyy/MM/dd HH:mm:ss')}
          {activity.ip ? (
            <>
              {' '}
              from IP {activity.ip} ({activity.ipCountry})
            </>
          ) : null}
        </p>
        {activity.sourceType && (
          <p className="mb-2">
            <strong>Source:</strong>{' '}
            <PartyIndicator
              type={activity.sourceType}
              id={activity.sourceId}
              partyName={activity.sourceName}
            />
          </p>
        )}
        {activity.targetType && (
          <p className="mb-2">
            <strong>Target:</strong>{' '}
            <PartyIndicator
              type={activity.targetType}
              id={activity.targetId}
              transactionId={activity.transactionFriendlyId}
              partyName={activity.targetName}
            />
            {activity.targetOwnerId &&
              (activity.targetOwnerType !== activity.targetType ||
                activity.targetOwnerId !== activity.targetId) && (
                <>
                  {' '}
                  (owned by{' '}
                  <PartyIndicator
                    type={activity.targetOwnerType}
                    id={activity.targetOwnerId}
                    partyName={activity.targetUser}
                  />
                  )
                </>
              )}
          </p>
        )}
        <div className="mt-6 flex flex-col space-y-2">
          <JsonViewer displayDataTypes={false} value={details} />
        </div>
      </Modal>
    </>
  );
};

export default ActivityDetails;
