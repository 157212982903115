import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Filter } from '..';
import type { SelectOptionType } from 'src/@types/SelectType';
import SelectInputFilter from 'src/components/Table/filters/components/SelectInputFilter';

type Props = {
  isLoading?: boolean;
  options: SelectOptionType[];
};

function MultiSelectFilterGenerator({ options, isLoading }: Props): Filter {
  return function MultiSelectFilter({ column }) {
    const { t } = useTranslation();
    const filterValue = column.getFilterValue() as string | undefined;

    const handleChangeValues = useCallback(
      (values: SelectOptionType[]) => {
        const selectedValues = values ? values.map((v) => v.value) : [];
        column.setFilterValue(
          selectedValues.length > 0 ? selectedValues : undefined
        );
      },
      [column]
    );

    const selectedOptions = useMemo(
      () =>
        options?.filter((option) => filterValue?.includes(option.value)) || [],
      [filterValue]
    );

    return (
      <SelectInputFilter
        placeholder={t('global:common.texts.select')}
        isMulti={true}
        options={options}
        value={selectedOptions}
        isLoading={isLoading}
        onChange={(value) => {
          handleChangeValues(value as SelectOptionType[]);
        }}
      />
    );
  };
}

export default MultiSelectFilterGenerator;
