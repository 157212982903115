import { useCallback, useContext, useMemo } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import useSWR from 'swr';
import { APITypes, APIUrls } from 'src/@enums/api';
import {
  callAdminPaymentFilters,
  callGetCryptoFilters,
  callGetSystemCapabilities,
} from 'src/api';
import { customFlat } from 'src/utils/helpers/datatypes/array';
import type { FiatCurrencies } from 'src/@types/Currencies';
import { CryptoCurrencies } from 'src/@enums';
import { accurateCalc } from '@arringo-npm/utility-package';

export type ConversionFunction = (
  amount: number,
  cryptoCurrency?: CryptoCurrencies
) => number;

const useSystemCapabilities = () => {
  const userContext = useContext(UserContext);
  const roleType = userContext?.user?.userAccountInfo?.roleType;
  const fetchCryptoFilters = async () => {
    const res = await callGetCryptoFilters();
    return res.data.cryptoCurrencies;
  };

  const { data: cryptoCurrencies = [] } = useSWR(
    roleType ? APIUrls.SYSTEM_CAPABILITIES + APITypes.CRYPTO_FILTERS_GET : null,
    fetchCryptoFilters,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  const { data: clientFiatMethods = [] } = useSWR(
    roleType === 'CLIENT'
      ? APIUrls.SYSTEM_CAPABILITIES + APITypes.AVAILABLE_PAYMENTS_GET
      : null,
    () =>
      callGetSystemCapabilities().then(
        ({ data: { paymentMethods } }) => paymentMethods
      ),
    {
      revalidateOnFocus: false,
      refreshInterval: 30_000,
    }
  );

  const { data: adminFiatCurrencies = [] } = useSWR(
    roleType === 'ADMIN' || roleType === 'SUPER_ADMIN'
      ? APIUrls.ADMIN_SYSTEM_CAPABILITIES
      : null,
    () =>
      callAdminPaymentFilters().then(({ data: { fiatCurrencies } }) =>
        Array.from(new Set(fiatCurrencies)).sort()
      ),
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  const clientFiatCurrencies = useMemo<FiatCurrencies[]>(() => {
    if (!Array.isArray(clientFiatMethods)) {
      return [];
    }

    const constraints = clientFiatMethods.map((method) => method.constraints);
    const flatCurrencies = customFlat(constraints);
    const allCurrencies = flatCurrencies.map(
      (constraint) => constraint.currency
    );
    return Array.from(new Set(allCurrencies)).sort();
  }, [clientFiatMethods]);

  const convertFromIntegral: ConversionFunction = useCallback(
    (amount, cryptoCurrency = CryptoCurrencies.BTC) => {
      const currentCurrency = cryptoCurrencies.find(
        (c) => c.cryptoCurrency === cryptoCurrency
      );
      return currentCurrency
        ? accurateCalc(amount, '/', currentCurrency.integralMultiplier)
        : 0;
    },
    [cryptoCurrencies]
  );

  const convertToIntegral: ConversionFunction = useCallback(
    (amount, cryptoCurrency = CryptoCurrencies.BTC) => {
      const currentCurrency = cryptoCurrencies.find(
        (c) => c.cryptoCurrency === cryptoCurrency
      );

      return currentCurrency
        ? Math.trunc(
            accurateCalc(amount, '*', currentCurrency.integralMultiplier)
          )
        : 0;
    },
    [cryptoCurrencies]
  );

  return {
    fiatCurrencies: [...clientFiatCurrencies, ...adminFiatCurrencies],
    clientFiatMethods,
    cryptoCurrencies,
    convertFromIntegral,
    convertToIntegral,
  };
};
export default useSystemCapabilities;
