import type { FC, ReactNode } from 'react';
import FullsizeLoader from 'src/components/Design/FullsizeLoader';

type Props = {
  children: ReactNode;
  className?: string;
  loading: boolean;
  name?: string;
  whichPageLoading: { isCustomText: boolean; text: string };
};

const Loader: FC<Props> = ({ loading, children, name }: Props) => {
  return (
    <>
      {loading && <FullsizeLoader name={name} />}
      <div style={{ display: loading && 'none' }}>{children}</div>
    </>
  );
};

export default Loader;
