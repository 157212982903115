import FullsizeLoader from 'src/components/Design/FullsizeLoader';
import useRoutingLevel from 'src/routes/useRoutingLevel';
import RouteChangeTracker from './Routers/RouteChangeTracker';

/*
 * Main routing component of the project
 *
 * Selects the Routing component based on the User Role using `useRoutingLevel` hook.
 */
const Routing = () => {
  const { RoutingComponent, isReadyToRender } = useRoutingLevel();

  if (!isReadyToRender) return <FullsizeLoader />;

  return (
    <>
      <RouteChangeTracker />
      <RoutingComponent />
    </>
  );
};

export default Routing;
