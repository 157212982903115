import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { ExternalURLs } from 'src/@enums';
import { footerNavigation, paymentSystems } from 'src/config/footer';
import { translateWithFallback } from 'src/utils/translation';
import { UserContext } from 'src/contexts/UserContext';
import { fiatLicenses } from 'src/contexts/admin/ClientContext/constants';
import logoFooter from 'src/assets/images/logo/logo-footer-1.png';
import styles from './Footer.module.css';

const Footer = () => {
  const { t } = useTranslation();

  const userData = useContext(UserContext);
  const userFiatLicense = userData.user.userAccountInfo.fiatLicense;

  const fiatLicense = fiatLicenses[userFiatLicense];
  return (
    <footer className={styles.footer} aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-full px-4 pb-28 pt-10 lg:pb-10">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 flex md:col-span-8 2xl:col-span-4">
            <Image
              width={48}
              height={48}
              src={logoFooter}
              alt="Instacoins logo"
              className="h-12 flex-grow-0 opacity-30"
            />
            <p className="ml-4 text-xs text-gray-200">
              <Trans
                i18nKey={'global:common.texts.footerCopyRight'}
                values={{ fiatLicense }}
                components={[
                  <a
                    key={1}
                    className="font-bold text-blue-300 hover:underline"
                    href={ExternalURLs.FAQ}
                  >
                    FAQs
                  </a>,
                  <br key={2} />,
                ]}
              />
            </p>
          </div>

          <div className="col-span-12 mx-auto flex w-full max-w-[300px] items-start md:justify-center lg:col-span-2 lg:w-auto">
            <div className={styles.linksGrid}>
              <div>
                <h3 className={styles.linksTitle}>
                  {t('global:common.texts.usefulLinks')}
                </h3>
                <ul className={styles.linksList}>
                  {footerNavigation.terms.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={styles.linkItem}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translateWithFallback(
                          t,
                          'global',
                          item.translationKey,
                          item.name
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-span-12 mx-auto flex w-full max-w-[300px] items-start md:justify-center lg:col-span-2 lg:w-auto">
            <div className={styles.linksGrid}>
              <div>
                <h3 className={styles.linksTitle}>
                  {t('global:common.texts.support')}
                </h3>
                <ul className={styles.linksList}>
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={styles.linkItem}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translateWithFallback(
                          t,
                          'global',
                          item.translationKey,
                          item.name
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-span-12 mx-auto flex w-full max-w-[300px] items-start justify-center lg:col-span-2 lg:w-auto">
            <div className={styles.paymentMethodsGrid}>
              {paymentSystems.map((item, index) => (
                <div className="text-center" key={index}>
                  <Image
                    width={74}
                    height={32}
                    className="h-8"
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
