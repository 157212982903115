import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { toFixed } from 'src/utils/decash';
import DecashIcon from 'src/components/CustomIcon/icons/DecashIcon';
import { useTranslation } from 'react-i18next';
import { CryptoCurrencies, Currencies } from 'src/@enums';
import { useBalance } from 'src/contexts/BalanceContext';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { scientificNotationToDecimal } from 'src/utils/btc';
import BtcIcon from 'src/components/BtcIcon';
import { DECASH_ENABLED } from 'src/config/features';

const UserBalances = (): JSX.Element => {
  const [isOpen, setIsOpen] = usePersistedState<boolean>({
    defaultValue: true,
    key: 'Sidebar-UserBalances',
  });

  const { t } = useTranslation();
  const { balance, availableBalance } = useBalance();
  const [btc, ...decashTokens] = useMemo(() => {
    const btcCurrency = CryptoCurrencies.BTC;
    const decashCurrencies = Object.values(CryptoCurrencies).filter(
      (cur) => cur !== CryptoCurrencies.BTC
    );
    return [btcCurrency, ...decashCurrencies].map((currentCurrency) => {
      const balanceItem = balance.find(
        (item) => item.cryptoCurrency === currentCurrency
      );
      const availableBalanceItem = availableBalance.find(
        (item) => item.cryptoCurrency === currentCurrency
      );
      return {
        symbol: currentCurrency,
        balance: balanceItem?.amount ?? 0,
        availableBalance: availableBalanceItem?.amount ?? 0,
      };
    });
  }, [balance, availableBalance]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <div className="flex w-full flex-col space-y-4 px-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <BtcIcon className="h-4 w-4" />
              <span className="font-medium">
                {t('global:sidebar.texts.bitcoin')}
              </span>
            </div>
            <span className="text-sm font-bold">
              {scientificNotationToDecimal(btc?.availableBalance ?? 0)}{' '}
              {Currencies.BTC}
            </span>
          </div>
          {DECASH_ENABLED && (
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-2 text-sm">
                <DecashIcon className="h-4 w-4" />
                <span className="font-medium">Decash</span>
              </div>
              <div className="flex flex-col divide-y divide-gray-50 px-2 text-right text-sm font-bold">
                {decashTokens.map((e) => (
                  <span className="py-1.5" key={e.symbol}>
                    {toFixed(e.availableBalance)} {e.symbol}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="w-full px-6">
        <button
          className="flex w-full items-center justify-center space-x-2 border-t border-gray-50 py-2 text-sm font-medium text-gray-400 transition-colors duration-100 hover:text-gray-500"
          onClick={handleClick}
        >
          <div className="h-4 w-4">
            {isOpen ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
          <span>
            {isOpen
              ? t('global:sidebar.texts.hideBalances')
              : t('global:sidebar.texts.showBalances')}
          </span>
        </button>
      </div>
    </>
  );
};

export default UserBalances;
