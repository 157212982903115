import { useEffect, useMemo } from 'react';
import type { Filter } from '..';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';
import SelectInputFilter from 'src/components/Table/filters/components/SelectInputFilter';
import type { SelectOptionType } from 'src/@types/SelectType';

const ActivityTarget: Filter<{ entityTypes: string[] }> = ({
  column,
  entityTypes,
}) => {
  const filters = (column.getFilterValue() || {}) as [
    ['targetType', string],
    ['targetId', string],
  ];
  const targetType = filters?.[0]?.[1] || null;
  const targetId = filters?.[1]?.[1] || null;

  const entities = useMemo<SelectOptionType[]>(
    () => entityTypes.map((ent) => ({ label: ent, value: ent })) || [],
    [entityTypes]
  );

  useEffect(() => {
    if (targetType === null && targetId === null) {
      column.setFilterValue(undefined);
    }
  }, [targetType, targetId, column]);

  const handleChangeSelect = (selectedOption: unknown | null) => {
    const updatedTargetType =
      selectedOption === null ? '' : (selectedOption as SelectOptionType).value;
    column.setFilterValue([
      ['targetType', updatedTargetType],
      ['targetId', targetId],
    ]);
  };

  const handleChangeInput = (updatedValue: string) => {
    column.setFilterValue([
      ['targetType', targetType],
      ['targetId', updatedValue === '' ? null : +updatedValue],
    ]);
  };

  return (
    <div className="flex flex-col gap-y-1">
      <SelectInputFilter
        isClearable
        options={entities}
        value={targetType ? { label: targetType, value: targetType } : null}
        onChange={handleChangeSelect}
      />
      <TextInputFilter
        type="text"
        onChange={handleChangeInput}
        value={targetId || ''}
        autoComplete="off"
        placeholder="Target ID"
      />
    </div>
  );
};

export default ActivityTarget;
