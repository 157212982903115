export enum APITypes {
  ACTIVITY_EXPORT = 'ACTIVITY_EXPORT',
  ACTIVITY_GET = 'ACTIVITY_GET',
  ADD_CAMPAIGN = 'ADD_CAMPAIGN',
  ADD_USER_ADJUSTMENT = 'SUPER_ADMIN_ADJUSTMENT_ADD',
  ADMIN_AFFILIATE = 'AFFILIATE_LIST',
  ADMIN_AFFILIATE_EXPORT = 'ADMIN_AFFILIATE_EXPORT',
  ADMIN_AFFILIATE_GET = 'ADMIN_AFFILIATE_GET',
  ADMIN_AFFILIATE_PAYMENT_GET = 'ADMIN_AFFILIATE_PAYMENT_GET',
  ADMIN_APPROVE_BANK_TRANSFER = 'APPROVE_BANK_TRANSFER',
  ADMIN_CANCEL_SUMSUB_FLOW = 'ADMIN_CANCEL_SUMSUB_FLOW',
  ADMIN_CLIENT_BALANCE = 'ADMIN_CLIENT_BALANCE',
  ADMIN_CLIENT_GET = 'ADMIN_CLIENT_GET',
  ADMIN_CLIENT_SEARCH = 'ADMIN_CLIENT_SEARCH',
  ADMIN_DENY_BANK_TRANSFER = 'DENY_BANK_TRANSFER',
  ADMIN_GET_HISTORIC_DOC = 'ADMIN_GET_HISTORIC_DOC',
  ADMIN_GET_NEEDS_INTERVIEW_DETAILS = 'ADMIN_GET_NEEDS_INTERVIEW_DETAILS',
  ADMIN_GET_STARTED_SUMSUB_FLOWS = 'ADMIN_GET_STARTED_SUMSUB_FLOWS',
  ADMIN_GET_USER_TRANSACTIONS_LIST = 'ADMIN_GET_USER_TRANSACTIONS_LIST',
  ADMIN_MODIFY_BANK_TRANSFER = 'MODIFY_BANK_TRANSFER',
  ADMIN_NOTES_EXPORT = 'ADMIN_NOTES_EXPORT',
  ADMIN_PAYMENT_FILTERS_GET = 'ADMIN_PAYMENT_FILTERS_GET',
  ADMIN_PENDING_DOCUMENT_AND_CC_LIST = 'ADMIN_PENDING_DOCUMENT_AND_CC_LIST',
  ADMIN_REVIEW_USER = 'ADMIN_REVIEW_USER',
  ADMIN_SECURITY_SET_STATUS_2FA = 'ADMIN_SECURITY_SET_STATUS_2FA',
  ADMIN_SET_DOC_OR_CC_AS_FIXED = 'ADMIN_SET_DOC_OR_CC_AS_FIXED',
  ADMIN_SYS_CAPABILITIES_GET = 'ADMIN_SYS_CAPABILITIES_GET',
  ADMIN_TRANSACTIONS_LIST = 'ADMIN_TRANSACTIONS_LIST',
  ADMIN_TRANSACTION_INFO = 'ADMIN_TRANSACTION_INFO',
  ADMIN_TRANSACTION_LICENSE_COUNTRY_FILTER_GET = 'ADMIN_TRANSACTION_LICENSE_COUNTRY_FILTER_GET',
  ADMIN_TRANSACTION_STATUS_FILTER_GET = 'ADMIN_TRANSACTION_STATUS_FILTER_GET',
  ADMIN_UNSUBSCRIBE = 'ADMIN_UNSUBSCRIBE',
  ADMIN_USERS_UPDATE = 'ADMIN_USERS_UPDATE',
  ADMIN_USER_FORCE_CHANGE_PWD = 'ADMIN_USER_FORCE_CHANGE_PWD',
  ADMIN_USER_GET = 'ADMIN_USER_GET',
  ADMIN_USER_SET = 'ADMIN_USER_SET',
  ADMIN_USER_SET_NEW_PIN = 'ADMIN_USER_SET_NEW_PIN',
  ADMIN_USER_SUMMARY = 'ADMIN_USER_SUMMARY',
  AFFILIATE_CHANGE_PWD = 'AFFILIATE_CHANGE_PWD',
  AFFILIATE_GET = 'AFFILIATE_GET',
  AFFILIATE_LOGIN = 'AFFILIATE_LOGIN',
  AFFILIATE_LOGIN_2FA = 'AFFILIATE_LOGIN_2FA',
  AFFILIATE_LOGIN_FROM_PARTNER = 'AFFILIATE_LOGIN_FROM_PARTNER',
  AFFILIATE_PAYMENT_GET = 'AFFILIATE_PAYMENT_GET',
  AFFILIATE_RESET_PWD = 'AFFILIATE_RESET_PWD',
  AFFILIATE_SECURITY_GET_QR = 'AFFILIATE_SECURITY_GET_QR',
  AFFILIATE_SECURITY_SET_STATUS_2FA = 'AFFILIATE_SECURITY_SET_STATUS_2FA',
  AFFILIATE_SEND_EARNINGS = 'AFFILIATE_SEND_EARNINGS',
  AFFILIATE_SET_EARNINGS = 'AFFILIATE_SET_EARNINGS',
  AFFILIATE_SIGNUP = 'AFFILIATE_SIGNUP',
  AFFILIATE_STATS = 'AFFILIATE_STATS',
  AFFILIATE_TRANSACTION_GET = 'AFFILIATE_TRANSACTION_GET',
  AFFILIATE_TRANSACTION_LIST = 'AFFILIATE_TRANSACTION_LIST',
  AFFILIATE_TRANSACTION_LIST_CSV = 'AFFILIATE_TRANSACTION_LIST_CSV',
  AFFILIATE_TRANSACTION_STATUS_FILTER_GET = 'AFFILIATE_TRANSACTION_STATUS_FILTER_GET',
  AVAILABLE_PAYMENTS_GET = 'AVAILABLE_PAYMENTS_GET',
  BLACKLIST_ADD_IP = 'BLACKLIST_ADD_IP',
  BLACKLIST_ADD_USER = 'BLACKLIST_ADD_USER',
  BLACKLIST_DELETE_IP = 'BLACKLIST_DELETE_IP',
  BLACKLIST_GET_IP = 'BLACKLIST_GET_IP',
  BLACKLIST_GET_LIST_NAMES = 'BLACKLIST_GET_LIST_NAMES',
  BUY_BANK_GET_TRX_PDF = 'BUY_BANK_GET_TRX_PDF',
  BUY_BANK_TRANSFER_STATUS = 'BUY_BANK_TRANSFER_STATUS',
  BUY_CANCEL = 'BUY_CANCEL',
  BUY_CHECK_FEES = 'BUY_FEE',
  BUY_CURRENCY = 'BUY_CC',
  BUY_E_WALLET = 'BUY_E_WALLET',
  BUY_TRX_RESULT = 'BUY_TRX_RESULT',
  CALLS_STATISTICS = 'CALLS_STATISTICS',
  CANCEL_CAMPAIGN = 'CANCEL_CAMPAIGN',
  CANCEL_REFUND = 'CANCEL_REFUND',
  CANCEL_SUMSUB_FLOW = 'CANCEL_SUMSUB_FLOW',
  CARD_PAYMENTS_DISTRIBUTION_GET = 'CARD_PAYMENTS_DISTRIBUTION_GET',
  CARD_PAYMENTS_DISTRIBUTION_INSERT = 'CARD_PAYMENTS_DISTRIBUTION_INSERT',
  CARD_PAYMENTS_DISTRIBUTION_MOVE_ON_DEFAULT_DISTRIBUTION = 'CARD_PAYMENTS_DISTRIBUTION_MOVE_ON_DEFAULT_DISTRIBUTION',
  CARD_PAYMENTS_DISTRIBUTION_UPDATE = 'CARD_PAYMENTS_DISTRIBUTION_UPDATE',
  CHANGE_WALLET_ORDER = 'CHANGE_WALLET_ORDER',
  CLIENTS_EXPORT = 'ADMIN_CLIENT_EXPORT',
  CLIENTS_WITH_COUNTRY_MISMATCH = 'CLIENTS_WITH_COUNTRY_MISMATCH',
  CLIENT_UNSUBSCRIBE = 'CLIENT_UNSUBSCRIBE',
  COUNTRIES_GET = 'COUNTRIES_GET',
  COUNTRIES_LIST = 'COUNTRIES_LIST',
  COUNTRY_SET_POI_REQUIREMENT = 'COUNTRY_SET_POI_REQUIREMENT',
  COUNTRY_UPDATE_FINANCIAL_TIER = 'COUNTRY_UPDATE_FINANCIAL_TIER',
  COUNTRY_UPDATE_RISK_LEVEL = 'COUNTRY_UPDATE_RISK_LEVEL',
  CREATE_BANK_TRANSFER = 'CREATE_BANK_TRANSFER',
  CREATE_FINANCIAL_TIER = 'CREATE_FINANCIAL_TIER',
  CREATE_NEWS = 'CREATE_NEWS',
  CRYPTO_FILTERS_GET = 'CRYPTO_FILTERS_GET',
  DISABLE_CUSTOM_USER_FINANCIAL_LIMITS = 'DISABLE_CUSTOM_USER_FINANCIAL_LIMITS',
  DOC_ADMIN_LOCK_INTERVIEW = 'DOC_ADMIN_LOCK_INTERVIEW',
  DOC_APPROVE_POA = 'DOC_APPROVE_POA',
  DOC_APPROVE_POI = 'DOC_APPROVE_POI',
  DOC_GET = 'DOC_GET',
  DOC_INTERVIEW_NEEDS_ADMIN = 'DOC_INTERVIEW_NEEDS_ADMIN',
  DOC_REJECT_POA = 'DOC_REJECT_POA',
  DOC_REJECT_POI = 'DOC_REJECT_POI',
  DOC_START_REVIEW = 'DOC_START_REVIEW',
  EMAIL_MLRO = 'EMAIL_MLRO',
  ENABLE_WALLET = 'ENABLE_WALLET',
  EXCHANGE_RATE = 'EXCHANGE_RATE',
  EXPORT_MAILING_GET = 'EXPORT_MAILING_GET',
  EXPORT_MAILING_UPDATE = 'EXPORT_MAILING_UPDATE',
  FIAT_LICENSES_GET = 'FIAT_LICENSES_GET',
  GET_AFFILIATE_AVAILABLE_LICENSES = 'GET_AFFILIATE_AVAILABLE_LICENSES',
  GET_ALL_ADMINS = 'GET_ALL_ADMINS',
  GET_ALL_FINANCIAL_TIERS = 'GET_ALL_FINANCIAL_TIERS',
  GET_ALL_FLAGS = 'GET_ALL_FLAGS',
  GET_ALL_FLAGS_REASONS_CALL_STATUSES = 'GET_ALL_FLAGS_REASONS_CALL_STATUSES',
  GET_CAMPAIGNS = 'GET_CAMPAIGNS',
  GET_CC_MEDIA = 'GET_CC_MEDIA',
  GET_CONSECUTIVE_FAILED_TRANSACTIONS = 'GET_CONSECUTIVE_FAILED_TRANSACTIONS',
  GET_DOC_MEDIA = 'GET_DOC_MEDIA',
  GET_LICENSE_LIST = 'GET_LICENSE_LIST',
  GET_NEWS_LIST = 'GET_NEWS_LIST',
  GET_PAYMENT_GATEWAY_WALLET_ADDRESS = 'GET_PAYMENT_GATEWAY_WALLET_ADDRESS',
  GET_PSP_SETTLEMENTS = 'GET_PSP_SETTLEMENTS',
  GET_REFUND_LIST = 'GET_REFUND_LIST',
  GET_REFUND_LOG = 'GET_REFUND_LOG',
  GET_SURVEYS_INFO = 'GET_SURVEYS_INFO',
  GET_USER_ADJUSTMENTS = 'SUPER_ADMIN_ADJUSTMENTS_GET_FROM_USER',
  GET_USER_FINANCIAL_LIMITS = 'GET_USER_FINANCIAL_LIMITS',
  GET_USER_FINANCIAL_LIMITS_REMAINING = 'GET_USER_FINANCIAL_LIMITS_REMAINING',
  GET_USER_GUIDE = 'GET_USER_GUIDE',
  GET_USER_STATUS_FLAGS = 'GET_USER_STATUS_FLAGS',
  GLOBAL_SETTINGS_GET = 'GLOBAL_SETTINGS_GET',
  GLOBAL_SETTINGS_SET = 'GLOBAL_SETTINGS_SET',
  HIDE_FLAG = 'HIDE_FLAG',
  KYC_STATS = 'KYC_STATS',
  LOGIN = 'LOGIN',
  LOGIN_2FA = 'LOGIN_2FA',
  LOGIN_FROM_PARTNER = 'LOGIN_FROM_PARTNER',
  LOGOUT = 'LOGOUT',
  MAKE_REFUND = 'MAKE_REFUND',
  MANAGEMENT_ARCHIVE = 'MANAGEMENT_ARCHIVE',
  MANAGEMENT_CHANGE_ROLE = 'MANAGEMENT_CHANGE_ROLE',
  MANAGEMENT_GRANT_EXPORT = 'MANAGEMENT_GRANT_EXPORT',
  MANAGEMENT_REMOVE_EXPORT = 'MANAGEMENT_REMOVE_EXPORT',
  MANAGEMENT_SUSPEND = 'MANAGEMENT_SUSPEND',
  MANAGEMENT_UNARCHIVE = 'MANAGEMENT_UNARCHIVE',
  MANAGEMENT_UNSUSPEND = 'MANAGEMENT_UNSUSPEND',
  MESSAGES_DELETE = 'MSG_DELETE',
  MESSAGES_LIST = 'MSG_LIST',
  MESSAGES_SEND = 'MSG_SEND',
  MONITORING_EXPORT = 'MONITORING_EXPORT',
  MONITORING_LOCK_USER = 'MONITORING_LOCK_USER',
  MONITORING_SNOOZE_USER = 'MONITORING_SNOOZE_USER',
  MONITORING_UNLOCK_USER = 'MONITORING_UNLOCK_USER',
  MONITORING_UNSNOOZE_USER = 'MONITORING_UNSNOOZE_USER',
  NOTES_GET = 'NOTE_GET',
  NOTE_ADD_ATTACHMENT = 'NOTE_ADD_ATTACHMENT',
  NOTE_DOWNLOAD_ATTACHMENT = 'NOTE_DOWNLOAD_ATTACHMENT',
  NOTE_GET_ATTACHMENT_INFO = 'NOTE_GET_ATTACHMENT_INFO',
  NOTE_NEW = 'NOTE_NEW',
  NOTE_UPDATE = 'NOTE_UPDATE',
  OPEN_BANKING_GET_INSTITUTIONS = 'OBANKING_GET_INSTITUTIONS',
  OPEN_BANKING_GET_PAYMENT = 'OBANKING_GET_PAYMENT',
  OPEN_BANKING_GET_USER_BANK_INFO = 'OBANKING_GET_USER_BANK_INFO',
  OPEN_BANKING_PAYMENT_AUTH_URL = 'OBANKING_PAYMENT_AUTH_URL',
  PUBLISH_NEWS = 'PUBLISH_NEWS',
  READ_NEWS = 'READ_NEWS',
  REASON_TEMPLATE_CREATE = 'REASON_TEMPLATE_CREATE',
  REASON_TEMPLATE_DELETE = 'REASON_TEMPLATE_DELETE',
  REASON_TEMPLATE_LIST = 'REASON_TEMPLATE_LIST',
  REASON_TEMPLATE_UPDATE = 'REASON_TEMPLATE_UPDATE',
  REJECTREASONS_CREATE = 'REJECTREASONS_CREATE',
  REJECTREASONS_DELETE = 'REJECTREASONS_DELETE',
  REJECTREASONS_RECEIVE = 'REJECTREASONS_RECEIVE',
  REJECTREASONS_UPDATE = 'REJECTREASONS_UPDATE',
  REQUEST_LIMIT_REVIEW = 'REQUEST_LIMIT_REVIEW',
  REQUEST_REFUND = 'REQUEST_REFUND',
  REVIEW_STATS = 'REVIEW_STATS',
  ROLE_LIST = 'ROLE_LIST',
  SECURITY_CHANGE_PWD = 'SECURITY_CHANGE_PWD',
  SECURITY_GET_QR = 'SECURITY_GET_QR',
  SECURITY_RESET_PWD = 'SECURITY_RESET_PWD',
  SECURITY_SET_STATUS_2FA = 'SECURITY_SET_STATUS_2FA',
  SET_DEFAULT_FINANCIAL_TIER = 'SET_DEFAULT_FINANCIAL_TIER',
  SET_PSP_SETTLEMENT_CURRENCY = 'SET_PSP_SETTLEMENT_CURRENCY',
  SET_USER_MIN_LIMITS = 'SET_USER_MIN_LIMITS',
  SIGNUP = 'SIGNUP',
  SKIP_FIRST_TRX = 'SKIP_FIRST_TRX',
  SKIP_WIRE_INSTRUCTIONS = 'SKIP_WIRE_INSTRUCTIONS',
  STATS = 'STATS',
  SUPERADMIN_AFFILIATE_ADJUSTMENT_APPROVE = 'SUPERADMIN_AFFILIATE_ADJUSTMENT_APPROVE',
  SUPERADMIN_AFFILIATE_ADJUSTMENT_CANCEL = 'SUPERADMIN_AFFILIATE_ADJUSTMENT_CANCEL',
  SUPERADMIN_AFFILIATE_ADJUSTMENT_REQUEST = 'SUPERADMIN_AFFILIATE_ADJUSTMENT_REQUEST',
  SUPERADMIN_AFFILIATE_ARCHIVE = 'SUPERADMIN_AFFILIATE_ARCHIVE',
  SUPERADMIN_AFFILIATE_BALANCES_EXPORT = 'SUPERADMIN_AFFILIATE_BALANCES_EXPORT',
  SUPERADMIN_AFFILIATE_DISABLE = 'SUPERADMIN_AFFILIATE_DISABLE',
  SUPERADMIN_AFFILIATE_REACTIVATE = 'SUPERADMIN_AFFILIATE_REACTIVATE',
  SUPERADMIN_AFFILIATE_RESET_2FA = 'SUPERADMIN_AFFILIATE_RESET_2FA',
  SUPERADMIN_AFFILIATE_RESET_PSW = 'SUPERADMIN_AFFILIATE_RESET_PSW',
  SUPERADMIN_AFFILIATE_TRANSACTIONS_EXPORT = 'SUPERADMIN_AFFILIATE_TRANSACTIONS_EXPORT',
  SUPERADMIN_ARCHIVED_CLIENTS_WITH_ACTIVE_BALANCES_EXPORT = 'SUPERADMIN_ARCHIVED_CLIENTS_WITH_ACTIVE_BALANCES_EXPORT',
  SUPERADMIN_CLIENTS_WITH_UNUSED_BALANCES_EXPORT = 'SUPERADMIN_CLIENTS_WITH_UNUSED_BALANCES_EXPORT',
  SUPERADMIN_USER_SET = 'SUPERADMIN_USER_SET',
  SUPER_ADMIN_SYS_CAPABILITIES_SET = 'SUPER_ADMIN_SYS_CAPABILITIES_SET',
  TRANSACTION_ADMIN_SET_RECLAIMED = 'TRANSACTION_ADMIN_SET_RECLAIMED',
  TRANSACTION_EXPORT = 'TRANSACTION_EXPORT',
  TRANSACTION_GET = 'TRANSACTION_GET',
  TRANSACTION_GET_PDF = 'TRANSACTION_GET_PDF',
  TRANSACTION_STATUS_FILTER_GET = 'TRANSACTION_STATUS_FILTER_GET',
  UNDO_SKIP_FIRST_TRX = 'UNDO_SKIP_FIRST_TRX',
  UNHIDE_FLAG = 'UNHIDE_FLAG',
  UNPROCCESABLE_WITHDRAWAL_CLEAR = 'WITHDRAWAL_PROBLEMATIC_CLEAR',
  UNPROCCESABLE_WITHDRAWAL_GET = 'WITHDRAWAL_PROBLEMATIC_GET',
  UNSUBSCRIBE_DATA_GET = 'UNSUBSCRIBE_DATA_GET',
  UPDATE_COUNTRY_LICENSE = 'UPDATE_COUNTRY_LICENSE',
  UPDATE_FINANCIAL_TIER = 'UPDATE_FINANCIAL_TIER',
  UPDATE_NEWS = 'UPDATE_NEWS',
  UPDATE_REFUND_STATUS = 'UPDATE_REFUND_STATUS',
  UPDATE_SURVEY_INFO = 'UPDATE_SURVEY_INFO',
  UPDATE_USER_ADJUSTMENT = 'SUPER_ADMIN_ADJUSTMENT_UPDATE',
  UPDATE_USER_FINANCIAL_LIMITS = 'UPDATE_USER_FINANCIAL_LIMITS',
  UPDATE_WALLET_BALANCES = 'UPDATE_WALLET_BALANCES',
  URGENT_MESSAGE_ADD = 'URGENT_MESSAGE_ADD',
  URGENT_MESSAGE_DELETE = 'URGENT_MESSAGE_DELETE',
  URGENT_MESSAGE_GET = 'URGENT_MESSAGE_GET',
  URGENT_MESSAGE_UPDATE = 'URGENT_MESSAGE_UPDATE',
  USERS_NEED_ADMIN_MONITORING = 'USERS_NEED_ADMIN_MONITORING',
  USERS_NEED_ADMIN_REVIEW = 'USERS_NEED_ADMIN_REVIEW',
  USER_ACCOUNTING_GET = 'USER_ACCOUNT_GET',
  USER_ACCOUNT_WITHDRAWAL_AUTHORISABLE_COUNT = 'USER_ACCOUNT_WITHDRAWAL_AUTHORISABLE_COUNT',
  USER_ACCOUNT_WITHDRAWAL_AUTHORISE = 'USER_ACCOUNT_WITHDRAWAL_AUTHORISE',
  USER_ACCOUNT_WITHDRAWAL_CANCEL = 'USER_ACCOUNT_WITHDRAWAL_CANCEL',
  USER_CHANGE_LANG = 'USER_CHANGE_LANG',
  USER_DATA_GET = 'USER_DATA_GET',
  USER_NEEDS_INTERVIEW = 'USER_NEEDS_INTERVIEW',
  USER_START_FLOW = 'USER_START_FLOW',
  VA_INFO_GET = 'VA_INFO_GET',
  VA_PAYIN_HISTORY_GET = 'VA_PAYIN_HISTORY_GET',
  VA_PAYMENTS_EXPORT = 'VA_PAYMENTS_EXPORT',
  VA_TRANSFERS_EXPORT = 'VA_TRANSFERS_EXPORT',
  VA_TRANSFER_CREATE = 'VA_TRANSFER_CREATE',
  VA_TRANSFER_HISTORY_GET = 'VA_TRANSFER_HISTORY_GET',
  VA_WEBHOOK_GET = 'VA_WEBHOOK_GET',
  VA_WEBHOOK_SET = 'VA_WEBHOOK_SET',
  WALLET_STATS = 'WALLET_STATS',
  WITHDRAWABLE_AMOUNT_GET = 'WITHDRAWABLE_AMOUNT_GET',
  WITHDRAWAL_CANCEL = 'WITHDRAWAL_CANCEL',
  WITHDRAWAL_LICENSE_SET = 'WITHDRAWAL_LICENSE_SET',
  WITHDRAWAL_PROBLEMATIC_GET_COUNT = 'WITHDRAWAL_PROBLEMATIC_GET_COUNT',
  WITHDRAWAL_PROBLEMATIC_HASH_UPDATE = 'WITHDRAWAL_PROBLEMATIC_HASH_UPDATE',
  WITHDRAW_CRYPTO = 'WITHDRAW_CRYPTO',
}
