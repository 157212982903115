import { ClientTransactionType, Currencies, FiatLicenses } from 'src/@enums';
import { PaymentSystems } from 'src/@enums/paymentSystems';
import type { KeyValue } from 'src/@types/KeyValue';
import type { SelectOptionType } from 'src/@types/SelectType';
import type { TransactionStatusInternalType } from 'src/@types/Transaction/TransactionStatusInternalType';
import type { UserStatus } from 'src/@types/user';

import i18n from 'src/assets/translations/index';
import type { AdjustmentType } from 'src/features/AdjustmentsFeature/@types';
import type { WithdrawalStatus } from 'src/features/AdminAffiliateWithdrawal/@types';
import type {
  RefundReason,
  RefundStatus,
} from 'src/features/TransactionRefund/@types';
import type { TransactionMethod } from 'src/features/UserDeposit/@types';
import { YapilyStatus } from 'src/@enums/yapilyStatus';

export const fiatLicenseFilterConfig: SelectOptionType<FiatLicenses>[] =
  Object.entries(FiatLicenses).map(([label, value]) => ({
    value,
    label,
  }));

export const refundStatusFilterConfig: KeyValue<RefundStatus> = {
  REFUND_APPROVED: {
    value: 'REFUND_APPROVED',
    label: i18n.t('global:transactionRefund.texts.refundApproved'),
  },
  REFUND_PROCESSED: {
    value: 'REFUND_PROCESSED',
    label: i18n.t('global:transactionRefund.texts.refundProcessed'),
  },
  REFUND_REQUESTED: {
    value: 'REFUND_REQUESTED',
    label: i18n.t('global:transactionRefund.texts.refundRequested'),
  },
  REFUND_REJECTED: {
    value: 'REFUND_REJECTED',
    label: i18n.t('global:transactionRefund.texts.refundRejected'),
  },
};

export const refundStatusFilterConfigValues: SelectOptionType<RefundStatus>[] =
  Object.values(refundStatusFilterConfig);

export const adminAffiliateWithdrawalStatusFilterConfig: KeyValue<WithdrawalStatus> =
  {
    APPROVED: {
      value: 'APPROVED',
      label: 'APPROVED',
    },
    DENIED: {
      value: 'DENIED',
      label: 'DENIED',
    },
    FAILED: {
      value: 'FAILED',
      label: 'FAILED',
    },
    PENDING: {
      value: 'PENDING',
      label: 'PENDING',
    },
  };

export const adminAffiliateWithdrawalStatusFilterConfigValues: SelectOptionType<WithdrawalStatus>[] =
  Object.values(adminAffiliateWithdrawalStatusFilterConfig);

export const refundReasonConfig: KeyValue<RefundReason> = {
  ADMIN_REQUEST: {
    value: 'ADMIN_REQUEST',
    label: i18n.t('global:transactionRefund.texts.adminRequest'),
  },
  DUPLICATE_TRANSACTION: {
    value: 'DUPLICATE_TRANSACTION',
    label: i18n.t('global:transactionRefund.texts.duplicate_transaction'),
  },
  NO_CRYPTO_RECEIVED: {
    value: 'NO_CRYPTO_RECEIVED',
    label: i18n.t('global:transactionRefund.texts.no_crypto_received'),
  },
  OTHER: {
    value: 'OTHER',
    label: i18n.t('global:transactionRefund.texts.other'),
  },
  PAYSAFE_MISMATCH: {
    value: 'PAYSAFE_MISMATCH',
    label: i18n.t('global:transactionRefund.texts.paysafe_mismatch'),
  },
  NETELLER_MISMATCH: {
    value: 'NETELLER_MISMATCH',
    label: i18n.t('global:transactionRefund.texts.neteller_mismatch'),
  },
  SKRILL_MISMATCH: {
    value: 'SKRILL_MISMATCH',
    label: i18n.t('global:transactionRefund.texts.skrill_mismatch'),
  },
  SCAMMED: {
    value: 'SCAMMED',
    label: i18n.t('global:transactionRefund.texts.scammed'),
  },
};

export const refundReasonConfigValues: SelectOptionType<RefundReason>[] =
  Object.values(refundReasonConfig);

export const transactionFriendlyStatusFilterConfig: KeyValue<TransactionStatusInternalType> =
  {
    ADJUSTMENT_ACTIVE: {
      value: 'ADJUSTMENT_ACTIVE',
      label: i18n.t('global:common.texts.active'),
    },
    ADJUSTMENT_INACTIVE: {
      value: 'ADJUSTMENT_INACTIVE',
      label: i18n.t('global:common.texts.inactive'),
    },
    FAILED: {
      value: 'FAILED',
      label: i18n.t('global:common.texts.failed'),
    },
    FINISHED: {
      value: 'FINISHED',
      label: i18n.t('global:common.texts.finished'),
    },
    PENDING: {
      value: 'PENDING',
      label: i18n.t('global:common.texts.pending'),
    },
    PREAUTH_STARTED: {
      value: 'PREAUTH_STARTED',
      label: i18n.t('global:common.texts.processing'),
    },
    PREAUTH_FINISHED: {
      value: 'PREAUTH_FINISHED',
      label: i18n.t('global:common.texts.authorised'),
    },
    PREAUTH_CANCELLED: {
      value: 'PREAUTH_CANCELLED',
      label: i18n.t('global:common.texts.cancelled'),
    },
    WITHDRAWAL_AUTHORISATION_EXPIRED: {
      value: 'WITHDRAWAL_AUTHORISATION_EXPIRED',
      label: i18n.t('global:common.texts.withdrawalExpired'),
    },
    WITHDRAWAL_AUTHORISATION_PENDING: {
      value: 'WITHDRAWAL_AUTHORISATION_PENDING',
      label: i18n.t('global:common.texts.withdrawalAuthorisationPending'),
    },
    WITHDRAWAL_ADMIN_AUTHORISATION_PENDING: {
      value: 'WITHDRAWAL_ADMIN_AUTHORISATION_PENDING',
      label: i18n.t('global:common.texts.withdrawalAdminAuthorisationPending'),
    },
    WITHDRAWAL_ADDRESS_CHECK_PENDING: {
      value: 'WITHDRAWAL_ADDRESS_CHECK_PENDING',
      label: i18n.t('global:common.texts.withdrawalAddressCheckPending'),
    },
    WITHDRAWAL_CANCELLED_BY_ADMIN: {
      value: 'WITHDRAWAL_CANCELLED_BY_ADMIN',
      label: i18n.t('global:common.texts.withdrawalCancelledByAdmin'),
    },
    WITHDRAWAL_CANCELLED_BY_SYSTEM: {
      value: 'WITHDRAWAL_CANCELLED_BY_SYSTEM',
      label: i18n.t('global:common.texts.withdrawalCancelledBySystem'),
    },
    WITHDRAWAL_CANCELLED_BY_USER: {
      value: 'WITHDRAWAL_CANCELLED_BY_USER',
      label: i18n.t('global:common.texts.withdrawalCancelledByUser'),
    },
    WITHDRAWAL_CRYPTO_TX_FAILED: {
      value: 'WITHDRAWAL_CRYPTO_TX_FAILED',
      label: i18n.t('global:common.texts.withdrawalCryptoTxFailed'),
    },
    WITHDRAWAL_CRYPTO_TX_SENT: {
      value: 'WITHDRAWAL_CRYPTO_TX_SENT',
      label: i18n.t('global:common.texts.withdrawalCryptoTxSent'),
    },
    WITHDRAWAL_IN_PROGRESS: {
      value: 'WITHDRAWAL_IN_PROGRESS',
      label: i18n.t('global:common.texts.withdrawalInProgress'),
    },
    WITHDRAWAL_PENDING_MANUAL_VERIFICATION: {
      value: 'WITHDRAWAL_PENDING_MANUAL_VERIFICATION',
      label: i18n.t('global:common.texts.withdrawalPendingManualVerification'),
    },
    WITHDRAWAL_ON_HOLD_PENDING_REFUND_PROCESSING: {
      value: 'WITHDRAWAL_ON_HOLD_PENDING_REFUND_PROCESSING',
      label: i18n.t('global:common.texts.withdrawalPendingRefundProcessing'),
    },
    WITHDRAWAL_PENDING: {
      value: 'WITHDRAWAL_PENDING',
      label: i18n.t('global:common.texts.withdrawalPending'),
    },
    WITHDRAWAL_SENT_WAITING_CONFIRMATION: {
      value: 'WITHDRAWAL_SENT_WAITING_CONFIRMATION',
      label: i18n.t('global:common.texts.withdrawalSentWaitingConfirmation'),
    },
    REFUND_APPROVED: {
      value: 'REFUND_APPROVED',
      label: i18n.t('global:transactionRefund.texts.refundApproved'),
    },
    REFUND_PROCESSED: {
      value: 'REFUND_PROCESSED',
      label: i18n.t('global:transactionRefund.texts.refundProcessed'),
    },
    REFUND_REQUESTED: {
      value: 'REFUND_REQUESTED',
      label: i18n.t('global:transactionRefund.texts.refundRequested'),
    },
    REFUND_REJECTED: {
      value: 'REFUND_REJECTED',
      label: i18n.t('global:transactionRefund.texts.refundRejected'),
    },
    CUSTODY_FEE_APPLIED: {
      value: 'CUSTODY_FEE_APPLIED',
      label: i18n.t('global:transactionRefund.texts.custodyFeeApplied'),
    },
    WITHDRAWAL_SENT_TO_POLYGON: {
      value: 'WITHDRAWAL_SENT_TO_POLYGON',
      label: i18n.t('global:common.texts.withdrawalSentToPolygon'),
    },
    WITHDRAWAL_BEING_PROCESSED_ON_BLOCKCHAIN: {
      value: 'WITHDRAWAL_BEING_PROCESSED_ON_BLOCKCHAIN',
      label: i18n.t('global:common.texts.withdrawalBeingProcessedOnBlockchain'),
    },
  };

export const paymentSystemFilterConfig: SelectOptionType<PaymentSystems>[] = [
  {
    value: PaymentSystems.D,
    label: 'DW',
  },
  {
    value: PaymentSystems.FIBONATIX,
    label: 'FIBONATIX',
  },
  {
    value: PaymentSystems.ECOMMPAY,
    label: 'ECOMMPAY',
  },
  {
    value: PaymentSystems.LINK4PAY,
    label: 'LINK4PAY',
  },
  {
    value: PaymentSystems.RAPIDTRANSFER,
    label: 'RAPID TRANSFER',
  },
  {
    value: PaymentSystems.NETELLER,
    label: 'NETELLER',
  },
  {
    value: PaymentSystems.SECURETRADING,
    label: 'ST',
  },
  {
    value: PaymentSystems.SKRILL,
    label: 'SKRILL',
  },
  {
    value: PaymentSystems.TRUEVO,
    label: 'TRW',
  },
  {
    value: PaymentSystems.YAPILY,
    label: 'YAPILY',
  },
  {
    value: PaymentSystems.FINARO,
    label: 'FINARO',
  },
];

export const paymentMethodFilterConfig: SelectOptionType<
  Uppercase<TransactionMethod>
>[] = [
  {
    value: 'CARD_TRANSFER',
    label: i18n.t('global:tables.fields.card_transfer'),
  },
  {
    value: 'WIRE_TRANSFER',
    label: i18n.t('global:tables.fields.wire_transfer'),
  },
  {
    value: 'E_WALLET_TRANSFER',
    label: i18n.t('global:tables.fields.e_wallet_transfer'),
  },
  {
    value: 'OPEN_BANKING',
    label: i18n.t('global:tables.fields.open_banking'),
  },
];

export const paymentTypeFilterConfig: SelectOptionType<
  Uppercase<TransactionMethod>
>[] = [
  {
    value: 'CARD_TRANSFER',
    label: i18n.t('global:tables.fields.card_transfer'),
  },
  {
    value: 'WIRE_TRANSFER',
    label: i18n.t('global:tables.fields.wire_transfer'),
  },
  {
    value: 'E_WALLET_TRANSFER',
    label: i18n.t('global:tables.fields.e_wallet_transfer'),
  },
  {
    value: 'OPEN_BANKING',
    label: i18n.t('global:tables.fields.open_banking'),
  },
];

export const clientTransactionTypeFilterConfig: SelectOptionType<ClientTransactionType>[] =
  [
    {
      value: ClientTransactionType.BUY,
      label: i18n.t('global:tables.fields.buy'),
    },
    {
      value: ClientTransactionType.TRANSFER,
      label: i18n.t('global:tables.fields.transfer'),
    },
    {
      value: ClientTransactionType.ADJUSTMENT,
      label: i18n.t('global:tables.fields.adjustment'),
    },
    {
      value: ClientTransactionType.CUSTODY_FEE,
      label: i18n.t('global:tables.fields.custody_fee'),
    },
  ];

export const booleanFieldConfig: SelectOptionType<boolean>[] = [
  {
    value: true,
    label: 'ENABLED',
  },
  {
    value: false,
    label: 'DISABLED',
  },
];

export const invertedCheckBoxBooleanFieldConfig: SelectOptionType<boolean>[] = [
  {
    value: true,
    label: 'True',
  },
  {
    value: false,
    label: 'False',
  },
];

export const yesNoFieldConfig: SelectOptionType<boolean>[] = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const clientStatusFilterConfig: SelectOptionType<UserStatus>[] = [
  {
    value: 'MISSING_DOCS',
    label: 'MISSING DOCS',
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE',
  },
  {
    value: 'PENDING',
    label: 'PENDING',
  },
  {
    value: 'INACTIVE',
    label: 'INACTIVE',
  },
  {
    value: 'ARCHIVED',
    label: 'ARCHIVED',
  },
];

export const adjustmentTypeConfig: KeyValue<AdjustmentType> = {
  ADJUSTMENT_RECLAIM: { value: 'ADJUSTMENT_RECLAIM', label: 'Reclaim' },
  ADJUSTMENT_OTHER: { value: 'ADJUSTMENT_OTHER', label: 'Other' },
};

export const adjustmentTypeConfigValues: SelectOptionType<AdjustmentType>[] =
  Object.values(adjustmentTypeConfig);

export const currencyFieldTypes = {
  currency: Currencies.USD,
  cryptoCurrency: Currencies.BTC,
  affiliateBalance: Currencies.USD,
};

export const currencyFilterConfig = {
  currency: [
    // note: these values get overriden by some code in `useInsideTable` hook (look for "inject fiat currencies")
    {
      value: Currencies.USD,
      label: Currencies.USD,
    },
    {
      value: Currencies.EUR,
      label: Currencies.EUR,
    },
    {
      value: Currencies.GBP,
      label: Currencies.GBP,
    },
  ],
  cryptoCurrency: [
    {
      value: Currencies.BTC,
      label: Currencies.BTC,
    },
    {
      value: Currencies.USDD,
      label: Currencies.USDD,
    },
    {
      value: Currencies.EURD,
      label: Currencies.EURD,
    },
    {
      value: Currencies.GBPD,
      label: Currencies.GBPD,
    },
  ],
};

export const emailingTypeConfig = [
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
];

export const yapilyStatusFilterConfig: SelectOptionType<YapilyStatus>[] = [
  {
    value: YapilyStatus.AUTHORIZED,
    label: 'Authorized',
  },
  {
    value: YapilyStatus.AWAITING_AUTHORIZATION,
    label: 'Awaiting Authorization',
  },
  {
    value: YapilyStatus.COMPLETED,
    label: 'Completed',
  },
  {
    value: YapilyStatus.FAILED,
    label: 'Failed',
  },
  {
    value: YapilyStatus.PENDING,
    label: 'Pending',
  },
];
