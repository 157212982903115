import { Route, Routes } from 'react-router-dom';
import NoMatchRoute from 'src/routes/Routers/NoMatchRoute';
import type { Path, RouteObject } from 'src/routes/types';
import usePermissions from 'src/hooks/usePermissions';
import { Suspense } from 'react';
import FullsizeLoader from 'src/components/Design/FullsizeLoader';
import SentryReactRouterV6RouterInstrumentation from 'src/utils/sentry';

type Props<T = { [key in Path]?: RouteObject }> = {
  defaultPath: keyof T;
  routingObjects: T;
};

const RoutesContainer = ({ routingObjects, defaultPath }: Props) => {
  const { hasPermission } = usePermissions();

  return (
    <Suspense fallback={<FullsizeLoader />}>
      <SentryReactRouterV6RouterInstrumentation />
      <Routes>
        {(Object.keys(routingObjects) as Array<keyof typeof routingObjects>)
          .map((entry) => {
            if (!hasPermission(routingObjects[entry].permissions)) {
              return false;
            }
            const Component = routingObjects[entry].component;
            return (
              <Route
                path={entry}
                key={entry}
                element={<Component name={routingObjects[entry].name} />}
              />
            );
          })
          .filter((r) => r)}
        <Route path="*" element={<NoMatchRoute defaultPath={defaultPath} />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;
