import vi from 'src/assets/images/payment-systems/VI.png';
import mc from 'src/assets/images/payment-systems/MC.png';
import me from 'src/assets/images/payment-systems/ME.png';
import neteller from 'src/assets/images/payment-systems/NETELLER.png';
import skrill from 'src/assets/images/payment-systems/SKRILL.png';

export const footerNavigation = {
  support: [
    {
      name: 'support@instacoins.com',
      href: 'mailto:support@instacoins.com',
      translationKey: 'support@instacoins.com',
    },
    {
      name: '+372 618 9284',
      href: 'tel:+3726189284',
      translationKey: '+372 618 9284',
    },
  ],
  terms: [
    {
      name: 'Terms & Conditions',
      href: 'https://www.instacoins.com/terms-conditions/',
      translationKey: 'common.texts.term',
    },
    {
      name: 'Refund Policy',
      href: 'https://www.instacoins.com/refund-policy/',
      translationKey: 'common.texts.refundPolicy',
    },
    // {
    //   name: 'Privacy Policy',
    //   href: 'https://www.instacoins.com/privacy-policy/',
    //   translationKey: 'common.texts.privacy',
    // },
    {
      name: 'FAQs',
      href: 'https://www.instacoins.com/frequently-asked-questions/',
      translationKey: 'common.texts.faqs',
    },
  ],
};

export const paymentSystems = [
  {
    src: vi,
    alt: 'Visa',
  },
  {
    src: mc,
    alt: 'MasterCard',
  },
  {
    src: me,
    alt: 'MaestroCard',
  },
  {
    src: skrill,
    alt: 'Skrill',
  },
  {
    src: neteller,
    alt: 'Neteller',
  },
];
