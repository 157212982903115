import { useState, useMemo, useCallback } from 'react';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { handleError } from 'src/utils/helpers/errors';
import useSWR from 'swr';
import useTablePersistState from './useTablePersistState';
import type { FilterEntryMapper } from 'src/components/Table/@types';
import type { ColumnFiltersState } from '@arringo-npm/fe-table';

type BaseResponse = {
  data: {
    pagination: {
      count?: number;
    };
  };
};

type BaseRequest = {
  filter: {
    [k: string]: unknown;
  };
  pagination: {
    limit?: number;
    skip?: number;
  };
};

type UseTableCommonPropsParams<
  Request extends BaseRequest,
  Response extends BaseResponse,
> = {
  apiCall: (params: Request) => Promise<Response>;
  apiString: string;
  defaultPageSize?: number;
  enabledFetching?: boolean;
  externalFilters?: Record<string, unknown>;
  externalParams?: Record<string, unknown>;
  filterEntryMapper?: FilterEntryMapper;
  initialFilters?: ColumnFiltersState;
  onLoad?: () => void;
  persistTitle: string;
};

function useTableCommonProps<
  Request extends BaseRequest,
  Response extends BaseResponse,
>({
  apiString,
  defaultPageSize = 50,
  persistTitle,
  filterEntryMapper = (i) => i,
  onLoad = () => {
    return;
  },
  apiCall,
  externalFilters = {},
  externalParams = {},
  initialFilters = [],
  enabledFetching = true,
}: UseTableCommonPropsParams<Request, Response>) {
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableInitialFilters, setTableInitialFilters] =
    useState(initialFilters);

  const { debouncedFilters, setDebouncedFilters, pageSize, setPageSize } =
    useTablePersistState({ defaultPageSize, title: persistTitle });

  const cumulativeFilters = useMemo(
    () => [...tableInitialFilters, ...debouncedFilters],
    [tableInitialFilters, debouncedFilters]
  );

  const filter = useMemo(
    () => ({
      ...Object.fromEntries(
        cumulativeFilters.reduce((currFilters, { id, value }) => {
          const mappedFilter = filterEntryMapper([id, value]);
          if (Array.isArray(mappedFilter[0])) {
            currFilters.push(...mappedFilter);
          } else {
            currFilters.push(mappedFilter);
          }
          return currFilters;
        }, [])
      ),
      ...externalFilters,
    }),
    [cumulativeFilters, externalFilters, filterEntryMapper]
  );

  const { data, isValidating, mutate } = useSWR(
    enabledFetching
      ? [
          apiString,
          {
            pagination: {
              limit: pageSize,
              skip: pageIndex * pageSize,
            },
            filter,
            ...externalParams,
          },
        ]
      : null,
    async (_, params) => {
      const response = await apiCall(params as Request);
      onLoad();
      return response;
    },
    {
      revalidateOnFocus: false,
      onSuccess(data) {
        setCount(data.data?.pagination?.count || 0);
      },
      onError: (error) => {
        handleError({
          error,
          options: { presentation: PresentationMethod.TOAST },
        });
      },
    }
  );

  const handleRefreshData = useCallback(async () => {
    mutate();
  }, [mutate]);

  const commonProps = {
    paginationParams: {
      count,
      limit: pageSize,
      skip: pageIndex * pageSize,
    },
    isLoading: isValidating,
    onRefreshData: handleRefreshData,
    defaultColumnFilters: cumulativeFilters,
    onDebouncedColumnFiltersChange: (props) => {
      setTableInitialFilters([]);
      setDebouncedFilters(props);
    },
    onPageChange: setPageIndex,
    onPageSizeChange: setPageSize,
    pageIndex,
    pageSize,
    persistTitle,
  };

  return {
    data,
    totalCount: count,
    handleRefreshData,
    setPageIndex,
    filter,
    commonProps,
  };
}

export default useTableCommonProps;
