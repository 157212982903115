import { APITypes, APIUrls } from 'src/@enums/api';
import instance from 'src/api/instance';
import { addToken } from 'src/utils';

export const callLockUserNeedAdminMonitoring = (userId: number) =>
  instance.post(
    APIUrls.ADMIN_KYC,
    addToken({ type: APITypes.MONITORING_LOCK_USER, data: { userId } }),
    {
      requestSchema: 'AdminLockUserNeedMonitoringRequest',
    }
  );

export const callUnLockUserNeedAdminMonitoring = (userId: number) =>
  instance.post(
    APIUrls.ADMIN_KYC,
    addToken({ type: APITypes.MONITORING_UNLOCK_USER, data: { userId } }),
    {
      requestSchema: 'AdminUnLockUserNeedMonitoringRequest',
    }
  );

export const callSnoozeUserNeedAdminMonitoring = ({
  exactDateTime,
  relativeTimeHours,
  userId,
}: {
  exactDateTime: Date;
  relativeTimeHours: number;
  userId: number;
}) =>
  instance.post(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.MONITORING_SNOOZE_USER,
      data: {
        userId,
        snooze: {
          relativeTimeHours: relativeTimeHours,
          exactDateTime,
        },
      },
    }),
    {
      requestSchema: 'AdminSnoozeUserNeedMonitoringRequest',
    }
  );

export const callUnSnoozeUserNeedAdminMonitoring = (userId: number) =>
  instance.post(
    APIUrls.ADMIN_KYC,
    addToken({ type: APITypes.MONITORING_UNSNOOZE_USER, data: { userId } }),
    {
      requestSchema: 'AdminUnSnoozeUserNeedMonitoringRequest',
    }
  );
