import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SelectOptionType } from 'src/@types/SelectType';
import SelectInput from 'src/components/Inputs/SelectInput';

interface Props {
  currentPageSize: number;
  onChangePageSize: (newPageSize: number) => void;
  pageSizeOptions: number[];
}

const DynamicPageSize = ({
  pageSizeOptions,
  onChangePageSize,
  currentPageSize,
}: Props) => {
  const { t } = useTranslation();

  const pageSizeSelectOptions = useMemo<SelectOptionType<number>[]>(() => {
    return pageSizeOptions.map((item) => ({
      label: `${item} / ${t('global:common.pagination.page')}`,
      value: item,
    }));
  }, [pageSizeOptions, t]);

  const selectedPageSize = useMemo(
    () =>
      pageSizeSelectOptions.find(
        (pageSize) => pageSize.value === currentPageSize
      ),
    [currentPageSize, pageSizeSelectOptions]
  );

  const handleChangePageSize = (newPageSize: unknown) => {
    onChangePageSize((newPageSize as SelectOptionType<number>).value);
  };

  return (
    <SelectInput
      name="dynamicPageSize"
      className="w-40"
      options={pageSizeSelectOptions}
      onChange={handleChangePageSize}
      value={selectedPageSize}
      isSearchable={false}
    />
  );
};

export default DynamicPageSize;
