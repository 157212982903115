import DateRange from './DateRange';
import Text from './Text';
import Id from './Id';
import SpacelessInput from './SpacelessInput';
import TwoField from './TwoField';
import MultiSelect from './MultiSelect';
import Select from './Select';
import Boolean from './Boolean';
import MultiSelectTextInput from './MultiSelectTextInput';
import TransactionId from './TransactionId';
import ActivityTarget from './ActivityTarget';
import AffiliateId from './AffiliateId';
import CryptoRange from './CryptoRange';
import RecipientWallet from './RecipientWallet';
import type { Column, RowData } from '@arringo-npm/fe-table';

export type Filter<T extends object = object> = <TData extends RowData>(
  props: {
    column: Column<TData, unknown>;
  } & T
) => React.ReactElement;

export const Filters = {
  Boolean,
  Id,
  DateRange,
  Text,
  SpacelessInput,
  TwoField,
  MultiSelect,
  Select,
  MultiSelectTextInput,
  TransactionId,
  ActivityTarget,
  AffiliateId,
  CryptoRange,
  RecipientWallet,
};
