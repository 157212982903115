import type { KeyboardEventHandler } from 'react';
import { useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import type { OnChangeValue } from 'react-select';
import type { Filter } from '..';
import type { SelectOptionType } from 'src/@types/SelectType';

const components = {
  DropdownIndicator: null,
};

const MultiSelectTextInput: Filter = ({ column }) => {
  const filterValue = (column.getFilterValue() || []) as string[];
  const [inputValue, setInputValue] = useState('');

  const onChange = (val: OnChangeValue<SelectOptionType, true>) => {
    column.setFilterValue(
      val.length > 0 ? val.map((it) => it.value) : undefined
    );
  };

  const onInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        column.setFilterValue([...filterValue, inputValue]);
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      className="w-full rounded-md !p-[5px]"
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={onChange}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      placeholder="Type and press enter..."
      value={
        filterValue.map((filter) => ({
          label: filter,
          value: filter,
        })) as SelectOptionType[]
      }
    />
  );
};

export default MultiSelectTextInput;
