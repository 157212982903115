import { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Alert from 'src/components/Alert';
import UserStatusContext from 'src/contexts/UserStatusContext';
import type { Path } from 'src/routes/types';

const SumSubNotification = () => {
  const { optionalFlows } = useContext(UserStatusContext);
  const { pathname } = useLocation();
  const path = pathname as Path;

  const isKycPage = useMemo(() => path === '/client/kyc', [path]);

  const needNotification = useMemo(() => {
    if (!(optionalFlows?.length > 0) || isKycPage) return false;
    return !(
      optionalFlows.map(({ docType }) => docType).includes('CREDIT_CARD') &&
      optionalFlows.length < 2
    );
  }, [isKycPage, optionalFlows]);

  if (!needNotification) return null;

  return (
    <Alert type="warning">
      <Trans
        i18nKey="global:kyc.documentsExpiryNotification"
        components={{
          url: (
            <Link className="text-blue-600" to="/client/kyc" key="0">
              Click here
            </Link>
          ),
        }}
      />
    </Alert>
  );
};

export default SumSubNotification;
