import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import Button from 'src/components/Button';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { handleError } from 'src/utils/helpers/errors';
import { successToast } from 'src/utils/toast';
import {
  callLockUserNeedAdminMonitoring,
  callUnLockUserNeedAdminMonitoring,
} from 'src/features/AdminUsersNeedMonitoring/api';
import type { CellProps } from '..';

type Props = {
  handleRefreshData: () => void;
};

const LockUserNeedAdminMonitoring = <
  TData extends { isLocked: boolean; userId: number },
>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & Props) => {
  const { userId, isLocked } = cell.row.original;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await (isLocked
        ? callUnLockUserNeedAdminMonitoring
        : callLockUserNeedAdminMonitoring)(userId);

      successToast(`User ${userId} is ${isLocked ? 'unlocked' : 'locked'}.`);
      handleRefreshData();
    } catch (error) {
      handleError({
        error,
        options: { presentation: PresentationMethod.TOAST },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      size="small"
      shape="square"
      colorScheme={isLocked ? 'green' : 'red'}
      onClick={handleClick}
      leadingIcon={isLocked ? <LockOpenIcon /> : <LockClosedIcon />}
      loading={isLoading}
    >
      {isLocked ? 'Unlock' : 'Lock'}
    </Button>
  );
};

export default LockUserNeedAdminMonitoring;
