import { useState } from 'react';
import type { Filter } from '..';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';
import type { SelectOptionType } from 'src/@types/SelectType';
import SelectInputFilter from 'src/components/Table/filters/components/SelectInputFilter';
import { getSelectOption } from 'src/features/ClientInfo/utils';

type FilterSelectOption = SelectOptionType<'DIRECT'>;

const options: FilterSelectOption[] = [{ value: 'DIRECT', label: 'DIRECT' }];

const AffiliateIdFilter: Filter = ({ column }) => {
  const [error, setError] = useState(false);
  const filterValue = (column.getFilterValue() as string | number) || '';

  const handleSelectChange = (selectedItem: FilterSelectOption | null) => {
    column.setFilterValue(selectedItem === null ? '' : selectedItem.value);
  };

  const handleInputChange = (updatedValue: string) => {
    if (!isNaN(Number(updatedValue))) {
      setError(false);
      column.setFilterValue(updatedValue);
    } else {
      setError(true);
    }
  };

  return (
    <div className="flex flex-col gap-y-1">
      <SelectInputFilter
        value={filterValue === 'DIRECT' ? getSelectOption(filterValue) : null}
        options={options}
        onChange={(selected) =>
          handleSelectChange(selected as FilterSelectOption | null)
        }
        isClearable
      />
      <TextInputFilter
        disabled={filterValue === 'DIRECT'}
        value={filterValue !== 'DIRECT' ? (filterValue as string) : ''}
        onChange={handleInputChange}
        type="text"
        pattern="[0-9]{0,10}"
        title="Enter a number please"
        autoComplete="off"
        error={error && 'Enter a number please'}
      />
    </div>
  );
};

export default AffiliateIdFilter;
