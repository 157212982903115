import type { RowData } from '@arringo-npm/fe-table';
import type { ReactElement } from 'react-markdown/lib/react-markdown';
import type { CellProps } from '..';

const DateComponent = <TData extends RowData>({
  cell,
}: CellProps<TData, Date>): ReactElement => {
  const date = cell.getValue();
  const formatter = new Intl.DateTimeFormat();
  const dateParts = formatter.formatToParts(date);
  const day = dateParts.find(({ type }) => type === 'day').value;
  const month = dateParts.find(({ type }) => type === 'month').value;
  const year = dateParts.find(({ type }) => type === 'year').value;

  return <>{[day, month, year].join('.')}</>;
};

export default DateComponent;
