import classNames from 'classnames';
import type { Props as ReactSelectProps } from 'react-select';
import ReactSelect from 'react-select';
import selectStyles from 'src/components/Inputs/SelectInput/SelectInput.module.css';
import filterStyles from './SelectInputFilter.module.css';

const SelectInputFilter = ({
  className,
  styles,
  ...props
}: ReactSelectProps) => {
  return (
    <ReactSelect
      className={classNames(
        selectStyles.base,
        filterStyles.baseFilter,
        className
      )}
      classNamePrefix="selectInput"
      styles={{
        ...styles,
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
          fontSize: '0.75rem',
          fontWeight: '600',
        }),
      }}
      menuPortalTarget={document.body}
      {...props}
    />
  );
};

export default SelectInputFilter;
