import React, { useCallback, useMemo, useState } from 'react';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import type { CellProps } from '..';
import type { ConversionFunction } from 'src/hooks/useSystemCapabilities';
import type { CryptoBalance } from 'src/features/AdjustmentsFeature/@types';

type Props = {
  convertFromIntegral: ConversionFunction;
};

const CryptoBalances = <
  TData extends {
    cryptoBalances: CryptoBalance[];
  },
>({
  cell,
  convertFromIntegral,
}: CellProps<TData, string> & Props) => {
  const { cryptoBalances } = cell.row.original;
  const [isOpen, setIsOpen] = useState(false);

  const handleShowModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsOpen(true);
    },
    []
  );
  const handleCloseModal = useCallback(() => setIsOpen(false), []);

  const joinedString = useMemo(() => {
    return (cryptoBalances || [])
      .map(
        (cryptoBalances) =>
          `${convertFromIntegral(
            +cryptoBalances?.cryptoAmountIntegralBalance
          )} [${convertFromIntegral(
            +cryptoBalances?.cryptoAmountIntegralAvailableBalance
          )}] (${cryptoBalances.cryptoCurrency})`
      )
      .join(' - ');
  }, [cryptoBalances, convertFromIntegral]);

  const modalJSX = useMemo(() => {
    return (cryptoBalances || []).map((cryptoBalances) => {
      return (
        <div
          key={cryptoBalances.cryptoCurrency}
          className="flex flex-1 flex-col space-y-1 rounded-lg bg-gray-100 p-2"
        >
          {`${convertFromIntegral(
            +cryptoBalances?.cryptoAmountIntegralBalance
          )} [${convertFromIntegral(
            +cryptoBalances?.cryptoAmountIntegralAvailableBalance
          )}] (${cryptoBalances.cryptoCurrency})`}
        </div>
      );
    });
  }, [cryptoBalances, convertFromIntegral]);

  if (!cryptoBalances) {
    return (
      <span>
        <Button
          size="small"
          variant="text"
          colorScheme="gray"
          className="py-4"
          disabled
        >
          N/A
        </Button>
      </span>
    );
  }

  return (
    <>
      <div className="flex w-full justify-between space-x-1">
        <span className="truncate break-words uppercase">{joinedString}</span>
        <button
          className="appearance-none font-medium text-blue-600"
          onClick={handleShowModal}
        >
          [...]
        </button>
      </div>

      <Modal
        onClose={handleCloseModal}
        isOpen={isOpen}
        size="lg"
        title="Crypto Balances"
      >
        {modalJSX}
      </Modal>
    </>
  );
};

export default CryptoBalances;
