/**
 * Convert scientific notation to decimal
 * @param num Number to convert to decimal
 * @returns String version of number without scientific notation.
 */
export const scientificNotationToDecimal = (num: number | string): string => {
  const numberedValue = Number(num);
  // if the number is zero or not a number, return zero
  if (!numberedValue) {
    return '0';
  }

  if (-1 < numberedValue && numberedValue < 1) {
    return numberedValue.toFixed(8);
  }

  const stringNumber = num.toString();

  const scientificNotationCharIndex = stringNumber.indexOf('e');
  if (scientificNotationCharIndex === -1) {
    return String(num);
  }

  const isNegative = stringNumber.startsWith('-');
  const isLeading = stringNumber[scientificNotationCharIndex + 1] === '+';
  const mainPartStartsAt = isNegative ? 1 : 0;
  const zerosCount = stringNumber.slice(scientificNotationCharIndex + 1);
  let stringResult = '';

  stringResult += isLeading
    ? stringNumber.slice(mainPartStartsAt, scientificNotationCharIndex)
    : '0.';
  for (let i = 1; i < Math.abs(parseInt(zerosCount)); i++) {
    stringResult += '0';
  }
  if (!isLeading) {
    stringResult += stringNumber.slice(
      mainPartStartsAt,
      scientificNotationCharIndex
    );
  }
  if (isNegative) {
    stringResult = '-' + stringResult;
  }
  return stringResult;
};
