import type { Filter } from '..';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SelectOptionType } from 'src/@types/SelectType';
import SelectInputFilter from 'src/components/Table/filters/components/SelectInputFilter';

type Props = {
  isLoading?: boolean;
  options: SelectOptionType<boolean | string>[];
};

function SelectFilterGenerator({ options, isLoading }: Props): Filter {
  return function SelectFilter({ column }) {
    const { t } = useTranslation();
    const filterValue = column.getFilterValue() as string | undefined;

    const handleOnChange = (selectedOption: unknown | null) => {
      const newFilterValue =
        selectedOption === null
          ? ''
          : (selectedOption as SelectOptionType<boolean | string>).value;
      column.setFilterValue(newFilterValue);
    };

    const selectedOption = useMemo(
      () => options?.find((option) => option.value === filterValue) || null,
      [filterValue]
    );

    return (
      <SelectInputFilter
        placeholder={t('global:common.texts.select')}
        value={selectedOption}
        options={options}
        isLoading={isLoading}
        onChange={handleOnChange}
        isClearable
      />
    );
  };
}

export default SelectFilterGenerator;
