import type { Ref } from 'react';
import { forwardRef } from 'react';
import commonInputsStyles from 'src/components/Inputs/Wrapper.module.css';
import type { CheckboxInputProps } from './@types';
import styles from './CheckboxInput.module.css';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const CheckboxInput = (
  {
    label,
    name,
    error,
    hint,
    disabled,
    className,
    ...rest
  }: CheckboxInputProps,
  ref: Ref<HTMLInputElement>
): React.ReactElement => {
  const classes = `
    ${error && styles.error}
    ${disabled && styles.disabled}
    ${!error && styles.hero}
  `;

  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={name}
          name={name}
          type="checkbox"
          ref={ref}
          disabled={disabled}
          className={twMerge(className, classes)}
          aria-describedby={`${name}-description`}
          {...rest}
        />
      </div>

      {(label || error || hint) && (
        <div className="ml-3 flex flex-col text-sm">
          {label && (
            <label
              className={classNames(
                'font-medium text-gray-700',
                disabled && styles.disabledLabel
              )}
              htmlFor={name}
            >
              {label}
            </label>
          )}

          {error && !disabled && (
            <span
              data-testid={`${name}-error`}
              className={classNames(
                commonInputsStyles.errorMessage,
                '-bottom-4'
              )}
            >
              {error}
            </span>
          )}

          {hint && (
            <span
              id={`${name}-description`}
              className={classNames(
                commonInputsStyles.hintMessage,
                '-bottom-4'
              )}
            >
              {hint}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default forwardRef(CheckboxInput);
