import type { ReactNode } from 'react';
import { createContext, useMemo } from 'react';
import type { GetUserStatusFlagsResponse } from 'src/@types/api';
import type { UserStatus } from 'src/@types/user';
import { getCurrentUserStatusFlags } from 'src/api/client';
import { isObjectEmpty } from 'src/utils';
import { getUserRole } from 'src/utils/helpers/auth';
import { isSWRLoading } from 'src/utils/helpers/request';
import { isClient } from 'src/utils/helpers/roles';
import usePermissions from 'src/hooks/usePermissions';
import type { KeyedMutator } from 'swr';
import useSWR from 'swr';
import { APIUrls } from 'src/@enums/api';

export type SCREEN_NAME = GetUserStatusFlagsResponse['screen']['name'];

type UserStatusContextType = {
  additionalInfo: GetUserStatusFlagsResponse['screen']['data']['additionalInfo'];
  creditCardToReplace: GetUserStatusFlagsResponse['screen']['data']['creditCardToReplace'];
  currentlyRunningFlow: GetUserStatusFlagsResponse['screen']['data']['currentlyRunningFlow'];
  deniedDocs: GetUserStatusFlagsResponse['screen']['data']['latestDeniedDoc'];
  firstTrxDone: GetUserStatusFlagsResponse['screen']['data']['firstTrxDone'];
  flow: GetUserStatusFlagsResponse['screen']['data']['flow'];
  hasCreditCardToBeReplaced: boolean;
  isLoading: boolean;
  isPreviousTransactionsFailed: GetUserStatusFlagsResponse['screen']['data']['previousTransactionsFailed'];
  mutate: KeyedMutator<GetUserStatusFlagsResponse>;
  optionalFlows: GetUserStatusFlagsResponse['screen']['data']['optionalFlows'];
  pendingReason: GetUserStatusFlagsResponse['screen']['data']['pendingReason'];
  screenName: SCREEN_NAME;
  userStatus: UserStatus | undefined;
  wireInstructions: GetUserStatusFlagsResponse['screen']['data']['wireInstructions'];
};

const initialValue: UserStatusContextType = {
  userStatus: undefined,
  screenName: 'BUY',
  additionalInfo: null,
  flow: undefined,
  creditCardToReplace: undefined,
  currentlyRunningFlow: undefined,
  wireInstructions: undefined,
  optionalFlows: undefined,
  deniedDocs: undefined,
  pendingReason: undefined,
  firstTrxDone: undefined,
  isLoading: true,
  isPreviousTransactionsFailed: false,
  hasCreditCardToBeReplaced: undefined,
  mutate: undefined,
};

const UserStatusContext = createContext<UserStatusContextType>(initialValue);

type UserStatusContextWrapperProps = {
  children: ReactNode;
};

const UserStatusContextWrapper = ({
  children,
}: UserStatusContextWrapperProps) => {
  const { endpointPermissions } = usePermissions();
  const needsStatus =
    isClient(getUserRole()) && !isObjectEmpty(endpointPermissions);
  const { data, isValidating, mutate } = useSWR(
    () => (needsStatus ? APIUrls.GET_USER_STATUS_FLAGS : null),
    () => getCurrentUserStatusFlags().then((response) => response.data),
    { refreshInterval: 15_000, revalidateOnFocus: false }
  );

  const value = useMemo<Omit<UserStatusContextType, 'isLoading'>>(
    () => ({
      ...data,
      screenName: data?.screen.name,
      additionalInfo: data?.screen?.data?.additionalInfo,
      flow: data?.screen.data?.flow,
      creditCardToReplace: data?.screen?.data?.creditCardToReplace,
      currentlyRunningFlow: data?.screen.data?.currentlyRunningFlow,
      wireInstructions: data?.screen.data?.wireInstructions,
      optionalFlows: data?.screen.data?.optionalFlows,
      deniedDocs: data?.screen.data?.latestDeniedDoc,
      firstTrxDone: data?.screen.data?.firstTrxDone,
      pendingReason: data?.screen.data?.pendingReason,
      isPreviousTransactionsFailed:
        data?.screen.data?.previousTransactionsFailed,
      hasCreditCardToBeReplaced:
        data &&
        (['DOC_UPLOAD', 'DOC_UPLOAD_CONTINUE'] as SCREEN_NAME[]).includes(
          data?.screen?.name
        ) &&
        !!data?.screen?.data?.creditCardToReplace,
      mutate,
    }),
    [data, mutate]
  );

  return (
    <UserStatusContext.Provider
      value={{
        ...value,
        isLoading: isSWRLoading(isValidating, !!data),
      }}
    >
      {children}
    </UserStatusContext.Provider>
  );
};

export { UserStatusContextWrapper };
export default UserStatusContext;
