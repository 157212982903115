import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';

const InterviewReasons = <TData extends RowData>({
  cell,
}: CellProps<TData, any>) => {
  const value = cell.getValue();

  return <span>{value?.join(', ') || '-'}</span>;
};

export default InterviewReasons;
