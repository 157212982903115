import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { SweetAlertResult } from 'sweetalert2';
import type { ErrorObject } from 'ajv';
import type { Roles } from 'src/@types/Roles';

export type RequestValidationError = Error & {
  errors?: ErrorObject<string, Record<string, string>, unknown>[];
  isRequestValidationError?: boolean;
};

type ErrorParam = Error | AxiosError | RequestValidationError;
export enum PresentationMethod {
  TOAST /* A toast notification is shown for 10 seconds */,
  ALERT /* An error alert that stops user from continuing unless they dismiss it */,
}

enum Tracker {
  NOOP /* The error won't be tracked */,
  DEFAULT /* Error will be tracked by Sentry */,
}

export enum ErrorType {
  RENDER /* Render error is not presented and will be tracked */,
  NETWORK /* Network error will produce an Offline notice and won't be tracked */,
  REMOTE /* Remote error is from Back-End, it 'll be presented and tracked */,
}

enum Severity {
  LOW = 0 /* Error is treated like a normal error. */,
  HIGH = 1 /* Marks the error as high-priority for tracker */,
  CRITICAL = 2 /* Marks the error as critical and breaking */,
}

declare type ErrorInfo = {
  asWarning?: boolean;

  config?: AxiosRequestConfig;

  /**
   * A user friendly message to show to the user.
   */
  description: string;

  /**
   * Error message, comes directly from Error.message.
   */
  message: string;

  response?: AxiosResponse;

  /**
   * Error title to classify and normalize errors for logging functions.
   */
  title: string;
};

declare type HandleErrorsOptions = {
  /**
   * This function will be called after presentation & tracking are done,
   * regardless of thier failure or success.
   */
  callback?: ({
    error,
    errorInfo,
  }: {
    error: Error;
    errorInfo: ErrorInfo;
  }) => void;

  /**
   * Determines how the error should be presented to user.
   */
  presentation?: PresentationMethod;

  /**
   * Tracker for sending the error details to.
   */
  tracker?:
    | Tracker
    | ((error: Error, customMessage: string, errorInfo?: object) => void);
};

declare type HandleErrorParams = {
  /**
   * Additional object to be attached to callback
   * and tracker data.
   */
  additionalErrorInfo?: unknown;

  /**
   * Overrides error message.
   */
  customMessage?: string;

  error: ErrorParam;

  /**
   * Specifies extra options to handle the error.
   */
  options?: HandleErrorsOptions;

  /**
   * Determines the error severity. It's also sent to
   * tracker to notify the developers, if needed.
   */
  severity?: Severity;

  /**
   * Determines error type for presetting options.
   */
  type?: ErrorType;
};

export declare type HandleErrorType = (params: HandleErrorParams) => void;

export declare type ClassifyError = (
  error: ErrorParam,
  additionalErrorInfo?: unknown
) => ErrorInfo;

export declare type ClassifyAPIError = (
  error: AxiosError,
  errorInfo: ErrorInfo
) => ErrorInfo;

export declare type ClassifyRequestValidationError = (
  error: RequestValidationError
) => ErrorInfo;

declare type ShowErrorParams = {
  /**
   * If specified, description from the ErrorInfo object will be ignored.
   */
  customMessage?: string;

  errorInfo: ErrorInfo;

  /**
   * If undefined, no presentation will happen.
   */
  presentation?: PresentationMethod;
};

export declare type ForceNoPresentation = (errorInfo: ErrorInfo) => boolean;

export declare type ShowError = (
  params: ShowErrorParams
) => void | Promise<SweetAlertResult>;

export type GenericApiError = {
  reason: string;
  status: 'ERROR';
};

export interface JwtPayload {
  exp: number;
  hash: string;
  lang: string;
  roleType: Roles;
  userId: number;
}
