import toast from 'react-hot-toast';
import type { ToastOptions } from 'react-hot-toast';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

const DEFAULT_STYLES = {
  padding: '1rem 1.6rem',
  fontWeight: 500, // font-medium
  boxShadow: 'unset', // shadow-none
  marginRight: '0.6rem',
};

export const successToast = (message: string, options?: ToastOptions): void => {
  toast.success(message, {
    duration: 5000,
    position: 'top-right',
    style: {
      ...DEFAULT_STYLES,
      border: '1px solid #4ADE80',
    },
    iconTheme: {
      primary: '#4ADE80', // text-green-400
      secondary: '#FFFFFF', // text-white
    },
    ...options,
  });
};
// prevent showing the same error toast twice
const errorToasts: string[] = [];
const ERROR_TOAST_DURATION = 5000;
export const errorToast = (message: string, options?: ToastOptions): void => {
  const toastIdx = errorToasts.indexOf(message);
  if (toastIdx !== -1) {
    return;
  }
  errorToasts.push(message);
  setTimeout(() => {
    errorToasts.splice(toastIdx, 1);
  }, ERROR_TOAST_DURATION);
  toast.error(message, {
    duration: ERROR_TOAST_DURATION,
    position: 'top-right',
    style: {
      ...DEFAULT_STYLES,
      border: '1px solid #EF4444',
    },
    iconTheme: {
      primary: '#EF4444', // text-red-500
      secondary: '#FFFFFF', // text-white
    },
    ...options,
  });
};

export const infoToast = (message: string, options?: ToastOptions): void => {
  toast(message, {
    duration: 5000,
    position: 'top-right',
    style: {
      ...DEFAULT_STYLES,
      border: '1px solid #A4CAFE',
      color: '#3F83F8',
    },
    icon: <InformationCircleIcon className="w-16" />,
    iconTheme: {
      primary: '#A4CAFE', // text-red-500
      secondary: '#FFFFFF', // text-white
    },
    ...options,
  });
};
