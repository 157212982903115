import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FullsizeLoader from 'src/components/Design/FullsizeLoader';
import FacebookPixelComponent from 'src/components/FacebookPixelComponent';
import LiveChat from 'src/components/LiveChat';
import { UnauthorizedWrapper } from 'src/components/UnauthorizedWrapper';
import { UserContext } from 'src/contexts/UserContext';
import UserStatusContext from 'src/contexts/UserStatusContext';
import { AuthorizedApp } from 'src/features/App/containers/AuthorizedApp';
import { AppProvider } from 'src/features/App/context/AppContext';
import { useAuthorizedCommon } from 'src/features/AuthorizedCommon/hooks/useAuthorizedCommon';
import usePermissions from 'src/hooks/usePermissions';
import RoutesContainer from 'src/routes/Routers';
import {
  activeClientRoutes,
  protectedClientRoutes,
} from 'src/routes/routingObjects/clientRoutes';
import type { Path } from 'src/routes/types';

const ClientRouter = () => {
  const { isLoading } = useAuthorizedCommon();
  const { isVerified } = useContext(UserContext);
  const { screenName } = useContext(UserStatusContext);

  const { pathname } = useLocation();

  const showSidebar = useMemo(() => {
    return !pathname.startsWith('/client/emailing/unsubscribe');
  }, [pathname]);

  const Wrapper = useMemo(() => {
    // TODO: find a better way to handle wrapper for wire-instructions screen
    if (isVerified && ['DASHBOARD', 'DASHBOARD_PENDING'].includes(screenName)) {
      return AuthorizedApp;
    }

    return UnauthorizedWrapper;
  }, [screenName, isVerified]);

  const { hasAllPermissions } = usePermissions();

  const defaultPath = useMemo<Path>(() => {
    if (!hasAllPermissions(['BUY_CC', 'CREATE_BANK_TRANSFER'])) {
      return '/client/accounting';
    }

    return '/client/deposit';
  }, [hasAllPermissions]);

  // Here, We need to determine the layout,
  // If user is Active, then we use the Full layout
  // If user is not Active, then we use the layout with no menu.
  // Also we make activeClientRoutes
  if (isLoading) return <FullsizeLoader />;

  return (
    <AppProvider>
      <Wrapper backToLoginStatus="none" showSidebar={showSidebar}>
        <RoutesContainer
          routingObjects={
            isVerified
              ? { ...activeClientRoutes, ...protectedClientRoutes }
              : protectedClientRoutes
          }
          defaultPath={defaultPath}
        />
        <LiveChat />
        <FacebookPixelComponent />
      </Wrapper>
    </AppProvider>
  );
};

export default ClientRouter;
