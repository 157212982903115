import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import type { InputWrapperProps } from './@types';
import classNames from 'classnames';
import styles from './Wrapper.module.css';

const InputWrapper = ({
  name,
  label,
  disabled = false,
  addonBefore,
  addonAfter,
  wrapperClassName = '',
  errorClassName = '',
  truncateMessages = true,
  hideErrorIcon = false,
  error,
  headerNode,
  hint,
  children,
  required,
  inlineLabel,
}: InputWrapperProps) => {
  const wrapperClasses = classNames(wrapperClassName, styles.base, {
    [styles.disabled]: disabled,
  });

  const addonAfterClasses = classNames(styles.addonAfter, {
    [styles.addonAfterMoreSpace]: Boolean(error),
  });

  const childrenWrapperClasses = classNames(label && styles.childrenWrapper, {
    [styles.inlineChildrenWrapper]: inlineLabel && label,
  });

  const errorClasses = classNames(errorClassName, styles.errorMessage);

  return (
    <div className={wrapperClasses}>
      {label && !inlineLabel && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <sup className="text-red-500">&nbsp;*</sup>}
        </label>
      )}

      {headerNode && <div className={styles.headerNode}>{headerNode}</div>}

      <div className={childrenWrapperClasses}>
        {label && inlineLabel && (
          <label className={styles.label} htmlFor={name}>
            {label}
            {required && <sup className="text-red-500">&nbsp;*</sup>}
          </label>
        )}
        {addonBefore && (
          <small className={styles.addonBefore}>{addonBefore}</small>
        )}

        {children}

        {addonAfter && (
          <small className={addonAfterClasses}>{addonAfter}</small>
        )}

        {error && !disabled && !hideErrorIcon && (
          <ExclamationCircleIcon
            className={styles.errorIcon}
            width={20}
            height={20}
          />
        )}
      </div>

      {error && !disabled && (
        <span
          className={classNames(errorClasses, {
            truncate: truncateMessages,
          })}
          data-testid={`${name}-error`}
        >
          {error}
        </span>
      )}

      {hint && !error && (
        <span
          id={`${name}-description`}
          className={classNames(styles.hintMessage, {
            truncate: truncateMessages,
          })}
        >
          {hint}
        </span>
      )}
    </div>
  );
};

export default InputWrapper;
