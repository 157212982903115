import type {
  ClientProps,
  ClientStatus,
} from 'src/contexts/admin/ClientContext/@types';
import type { Roles } from 'src/@types/Roles';
import type { DocumentStatus, GetUserDataResponse } from 'src/@types/api';
import type { UserAccountInfo } from 'src/@types/api';

export const fiatLicenses: Record<UserAccountInfo['fiatLicense'], string> = {
  ESTONIA: 'Estonia OU',
  BULGARIA: 'Europe EOOD',
};

export const clientDefaultValue: ClientProps = {
  security: {},
  userAccountInfo: {
    id: -1,
    email: '',
    currency: '',
    status: '' as ClientStatus,
    roleType: '' as Roles,
    riskLevel: '',
    pinNumber: '-1',
    language: '',
  },
  userAddressInfo: {
    country: '',
    id: -1,
    countryCode: '',
    city: '',
    street: '',
    zip: '',
  },
  userPersonalInfo: {
    birth: '',
    firstName: '',
    lastName: '',
    id: -1,
    middleName: '',
    gender: '',
    phone: '',
    nationality: '',
  },
  messages: [],
  assignedAdmin: undefined,
  assignedAdminName: '',
  poiAttempts: undefined,
  notesCount: undefined,
  ccApprovedCount: undefined,
  ccDeniedCount: undefined,
  poaIssuedAt: '',
  poaStatus: '' as DocumentStatus,
  poiExpireAt: '',
  poiStatus: '' as DocumentStatus,
  sumsubProfile: null,
  user: {} as GetUserDataResponse,
};
