import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translationConfigs from 'src/config/features/translation';
import { UserContext } from 'src/contexts/UserContext';
import type { LanguagePickerCountries } from 'src/features/LanguagePicker/countries';
import { countries } from 'src/features/LanguagePicker/countries';
import LanguagePickerModal from 'src/features/LanguagePicker/LanguagePickerModal';
import { isAdmin } from 'src/utils/helpers/roles';
import styles from './LanguagePicker.module.css';
import { useLanguageWatcher } from './useLanguageWatcher';
import Image from 'next/image';

type Props = {
  wrapperClasses?: string;
};

const LanguagePicker = ({ wrapperClasses }: Props): React.ReactElement => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const { updateUserPreferredLanguage } = useLanguageWatcher();
  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState<LanguagePickerCountries>(
    countries[i18n.language] || countries['en']
  );

  const shouldEnable =
    translationConfigs.isEnabled && !isAdmin(user?.userAccountInfo?.roleType);

  const selectCountry = useCallback(
    (country: LanguagePickerCountries) => {
      setSelected(country);
      i18n.changeLanguage(country.value).then(() => {
        updateUserPreferredLanguage(selected.value, country.value);
        setShowDropDown((prevState) => !prevState);
      });
    },
    [i18n, updateUserPreferredLanguage, selected.value]
  );

  const handleShowDropDown = useCallback(() => setShowDropDown(true), []);
  const handleCloseDropDown = useCallback(() => setShowDropDown(false), []);

  const currentI18nLanguage = i18n.language;
  // This would change current selected countery, when language has changed from anywhere else.
  useEffect(() => {
    setSelected(countries[i18n.language] || countries['en']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentI18nLanguage, updateUserPreferredLanguage]);

  if (!shouldEnable) {
    return null;
  }

  return (
    <div className={wrapperClasses}>
      <button className={styles.trigger} onClick={handleShowDropDown}>
        <Image
          width={24}
          height={24}
          src={selected.flag}
          alt={selected.name}
          className={styles.flag}
        />
        <span className={styles.language}>{selected.value}</span>
        <ChevronDownIcon width={16} height={16} />
      </button>

      <LanguagePickerModal
        isOpen={showDropDown}
        onClose={handleCloseDropDown}
        selectCountry={selectCountry}
        selected={selected}
      />
    </div>
  );
};

export default LanguagePicker;
