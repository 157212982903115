import { APITypes, APIUrls } from 'src/@enums/api';
import type {
  FlowType,
  GetAccountingDataParams,
  GetAccountingDataResponse,
  SumSubWorkFlowResponse,
  GetUserLimitsResponse,
  GetUserStatusFlagsResponse,
  GetSkipFirstTRXResponse,
  GetUndoSkipFirstTRXResponse,
  GetSkipWireInstructionsResponse,
} from 'src/@types/api';
import type { Currencies } from 'src/@types/Currencies';
import type { GetUserStatusFlagsParams } from 'src/@types/requests/user';
import { instance } from 'src/api/instance';
import { addToken } from 'src/utils';

export const getUserLimitsCall = ({
  currency,
  userId,
}: {
  currency: Currencies;
  userId: number;
}) =>
  instance.post<GetUserLimitsResponse>(
    APIUrls.USER,
    addToken({
      type: APITypes.GET_USER_FINANCIAL_LIMITS_REMAINING,
      data: {
        currency,
        userId,
      },
    }),
    {
      responseSchema: 'GetUserLimitsResponse',
    }
  );

export const callRequestLimitReview = ({ subject, userId }) =>
  instance.post(
    APIUrls.USER,
    addToken({
      type: APITypes.REQUEST_LIMIT_REVIEW,
      data: {
        subject,
        userId,
      },
    })
  );

export const callGetAccountingData = async (
  params: GetAccountingDataParams
) => {
  const {
    data: {
      data: { transactions },
      cryptoBalance,
      pagination,
      status,
    },
  } = await instance.post<GetAccountingDataResponse>(
    APIUrls.USER_ACCOUNTING_DATA,
    addToken({
      type: APITypes.USER_ACCOUNTING_GET,
      data: params,
    }),
    {
      requestSchema: 'GetAccountingDataRequest',
      responseSchema: 'GetAccountingDataResponse',
    }
  );
  return {
    data: {
      cryptoBalance,
      transactions,
      pagination,
      status,
    },
  };
};

export const callSumSubWorkflow = (flowType: FlowType) =>
  instance.post<SumSubWorkFlowResponse>(APIUrls.SUMSUB_WORKFLOW, {
    ...addToken({ type: APITypes.USER_START_FLOW }),
    flowType,
  });

export const callCancelSumSubWorkflow = () =>
  instance.post<SumSubWorkFlowResponse>(APIUrls.SUMSUB_WORKFLOW, {
    ...addToken({ type: APITypes.CANCEL_SUMSUB_FLOW }),
  });

export const getCurrentUserStatusFlags = (params?: GetUserStatusFlagsParams) =>
  instance.post<GetUserStatusFlagsResponse>(
    APIUrls.GET_USER_STATUS_FLAGS,
    addToken({
      type: APITypes.GET_USER_GUIDE,
      ...(params ? { data: params } : {}),
    }),
    {
      requestSchema: 'GetUserStatusFlagsRequest',
      responseSchema: 'GetUserStatusFlagsResponse',
    }
  );

export const callSkipFirstTRX = () =>
  instance.post<GetSkipFirstTRXResponse>(
    APIUrls.GET_USER_STATUS_FLAGS,
    addToken({ type: APITypes.SKIP_FIRST_TRX }),
    {
      requestSchema: 'GetUserStatusFlagsRequest',
      responseSchema: 'GetSkipFirstTRXResponse',
    }
  );

export const callUndoSkipFirstTRX = () =>
  instance.post<GetUndoSkipFirstTRXResponse>(
    APIUrls.GET_USER_STATUS_FLAGS,
    addToken({ type: APITypes.UNDO_SKIP_FIRST_TRX }),
    {
      requestSchema: 'GetUserStatusFlagsRequest',
      responseSchema: 'GetUndoSkipFirstTRXResponse',
    }
  );

export const callSkipWireInstructions = () =>
  instance.post<GetSkipWireInstructionsResponse>(
    APIUrls.GET_USER_STATUS_FLAGS,
    addToken({ type: APITypes.SKIP_WIRE_INSTRUCTIONS }),
    {
      requestSchema: 'GetUserStatusFlagsRequest',
      responseSchema: 'GetSkipWireInstructionsResponse',
    }
  );
export const callGetUrgentMessage = () =>
  instance.post(
    APIUrls.URGENT_MESSAGES,
    addToken({ type: APITypes.URGENT_MESSAGE_GET })
  );
