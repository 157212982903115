import Application from 'src';
import Head from 'next/head';

function Home() {
  return (
    <>
      <Head>
        <title>Instacoins Member Area</title>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href="/public_favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://paymentpage.ecommpay.com/shared/merchant.css"
        />
      </Head>
      <Application />
    </>
  );
}

Home.getInitialProps = () => Promise.resolve({ noSsr: true });

export default Home;
