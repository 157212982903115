import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';
import { serverToDate } from 'src/utils/dates/format';
import { formatDate } from '@arringo-npm/utility-package';

const DateTime = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();
  if (!value) return null;

  const isoString = serverToDate(value);
  if (!isoString) {
    return <>-</>;
  }

  return <span>{formatDate(isoString, 'yyyy/MM/dd HH:mm:ss')}</span>;
};

export default DateTime;
