import classNames from 'classnames';
import { useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import type { BaseMenuItem } from 'src/@types/MenuItem';
import LoadingIcon from 'src/components/Button/LoadingIcon';
import { AppContext } from 'src/features/App/context/AppContext';
import { translateWithFallback } from 'src/utils/translation';
import useSWR from 'swr';
import { isSubPath } from 'src/utils';
import get from 'lodash.get';

type Props = {
  isSidebarOpen?: boolean;
  menuItem: BaseMenuItem;
};

const CustomLink = ({
  menuItem: {
    name,
    link,
    hasNestedRoute,
    translationKey,
    icon: LinkIcon,
    displayCount,
    displayCountFetcher,
    refreshInterval,
    displayCountFetcherKey,
    displayCountDataKey,
  },
  isSidebarOpen,
}: Props): JSX.Element => {
  const { isSideBarOpen, setSideBarOpen } = useContext(AppContext);
  const currentLocation = useLocation();
  const { t } = useTranslation();
  const push = useNavigate();

  const { data, isValidating } = useSWR(
    () => (displayCount ? displayCountFetcherKey : null),
    () => displayCountFetcher().then(({ data: { count } }) => count),
    {
      revalidateOnFocus: true,
      refreshInterval,
    }
  );

  const shouldLinkBeActive = useCallback(
    (link: string, hasNestedRoute: boolean) => {
      if (!isSubPath(link, currentLocation.pathname)) {
        return false;
      }
      const id = currentLocation.pathname.split('/')?.[3];
      if (/\d/g.test(id)) {
        return true;
      }
      return !(id && hasNestedRoute);
    },
    [currentLocation]
  );

  const count = useMemo(
    () => (displayCountDataKey ? get(data, displayCountDataKey) : data) || 0,
    [data, displayCountDataKey]
  );

  const classes = classNames(
    'py-2 flex items-center space-x-2 rounded-lg cursor-pointer transition-colors',
    {
      'px-4': isSidebarOpen,
      'px-2 justify-center': !isSidebarOpen,
    }
  );

  return (
    <NavLink
      to={link}
      className={classNames(
        classes,
        shouldLinkBeActive(link, hasNestedRoute)
          ? 'bg-brand text-white hover:bg-blue-800'
          : 'hover:bg-gray-100 hover:text-gray-900'
      )}
      data-testid={`${name}-link`}
      data-for={`${link}-tooltip`}
      onClick={(e) => {
        e.preventDefault();
        isSideBarOpen && setSideBarOpen(false);
        push(link);
      }}
    >
      <div className="flex items-center justify-center">
        <LinkIcon className="h-6 w-6" aria-hidden="true" />
      </div>

      <span className="flex-1 truncate font-medium">
        {translateWithFallback(t, 'global', translationKey, name)}
      </span>

      {displayCount && (
        <span className="rounded-md bg-blue-100 px-2 py-1 text-xs font-semibold text-gray-900">
          {isValidating ? <LoadingIcon /> : count || 0}
        </span>
      )}
    </NavLink>
  );
};

export default CustomLink;
