import type { Path } from 'src/routes/types';
import { useICNavigator } from 'src/hooks/useICNavigator';
import { useEffect } from 'react';

type Props = {
  defaultPath: Path;
};

// Note: We can not use `<Navigate />` component from react-router or react-router-dom,
// `<Navigate />` component removes the query params from the path, We need them for
// routingObjects like `/deposit`, `/buy`, `/payment-callback`, etc.
const NoMatchRoute = ({ defaultPath }: Props) => {
  const { goToPath } = useICNavigator();
  useEffect(() => {
    goToPath(defaultPath);
  }, [goToPath, defaultPath]);
  return null;
};

export default NoMatchRoute;
