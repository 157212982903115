import type { InitOptions } from 'i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationConfigs from 'src/config/features/translation';
import type { Languages } from './types';

const defaultLanguage = translationConfigs.fallbackLanguage;

const resources = {};
resources[defaultLanguage] = {
  global: require(`./resources/${defaultLanguage}/global.json`),
};

const initOptions = (() => {
  const options: InitOptions = {
    resources,
    fallbackLng: defaultLanguage,

    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  };

  if (translationConfigs.isDisabled) options.lng = defaultLanguage;

  return options;
})();

/** Initialize Languages Import Paths Object
 *
 * Used for Dynamically Loading Transaction Files */
const languageImportPath: {
  [key in Languages]: () => Promise<unknown>;
} = {
  en: () => import('./resources/en/global.json'),
  it: () => import('./resources/it/global.json'),
  es: () => import('./resources/es/global.json'),
};

/** Prevent reloading the fallback language by setting it dynamically to
 *  the fallbackLanguage from configs and giving it already required value
 */
languageImportPath[defaultLanguage] = () =>
  Promise.resolve(resources[defaultLanguage].global);

/** On Language Change Hook
 *
 * If the language is already loaded, Just change the language.
 * Otherwise use the `languageImportPath` with the given `lng` argument, and
 * import the resource.
 */
i18n.on('languageChanged', (lng) => {
  if (!languageImportPath[lng]) {
    return i18n.changeLanguage(defaultLanguage);
  }
  if (!resources[lng]) {
    languageImportPath[lng]().then((resource) => {
      resources[lng] = {
        global: resource,
      };
      i18n.changeLanguage(lng);
    });
  }
});

/// --- I18n initialization
let i18nSetup = i18n;

if (translationConfigs.isEnabled) {
  i18nSetup = i18nSetup.use(LanguageDetector);
}

i18nSetup
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(initOptions);

export default i18nSetup;
