import InstacoinsLogo from 'src/components/InstacoinsLogo';
import LanguagePicker from 'src/features/LanguagePicker';
import Image from 'next/image';
import { useContext, useMemo } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import md5 from 'js-md5';
import { twMerge } from 'tailwind-merge';

type Props = {
  onClickBellIcon?: () => void;
  onClickProfilePicture?: () => void;
};

const Header = ({ onClickProfilePicture }: Props) => {
  const {
    user: {
      userAccountInfo: { email, roleType },
    },
  } = useContext(UserContext);

  const avatar = useMemo(
    () => `https://www.gravatar.com/avatar/${md5(email || '')}?d=mp&s=80`,
    [email]
  );

  return (
    <div
      className={twMerge(
        'sticky top-0 z-30 flex h-16 flex-shrink-0 items-center bg-blue-800 px-6 text-white shadow',
        roleType === 'ADMIN' && 'md:hidden',
        roleType === 'SUPER_ADMIN' && 'md:hidden'
      )}
    >
      <div className="flex items-center">
        <InstacoinsLogo variant="icon" />
      </div>
      <div className="flex flex-1 items-center justify-end space-x-2">
        <Image
          src={avatar}
          alt="user avatar"
          width={32}
          height={32}
          className="rounded-full"
          onClick={onClickProfilePicture}
        />
        <LanguagePicker />
      </div>
    </div>
  );
};

export default Header;
