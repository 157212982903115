import { useEffect, useState } from 'react';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';

type Props = {
  extraValidation?: boolean;
};

const TransactionId = ({ extraValidation }: Props) => {
  return function TransactionIdFilter({ column }) {
    const [error, setError] = useState(false);
    const filterValue = (column.getFilterValue() || '') as string;
    const [value, setValue] = useState(filterValue);
    const TRANSACTION_ID_REGEX = extraValidation
      ? /^[A|D|W|C]_[0-9]{4,9}$/
      : /^[A|D|W|C]_[0-9]{9}$/;

    useEffect(() => {
      if (filterValue === '') {
        setError(false);
      }
      setValue(filterValue);
    }, [filterValue]);
    const errorMessage = extraValidation
      ? 'Transaction ID starts with A, D, W or C followed "_" and min 4 max 9 numbers'
      : 'Transaction ID starts with A, D, W or C followed "_" and by 9 numbers';

    const handleChangeValue = (updatedValue: string) => {
      setValue(updatedValue);
      if (TRANSACTION_ID_REGEX.test(updatedValue) || updatedValue === '') {
        setError(false);
        column.setFilterValue(updatedValue);
      } else {
        setError(true);
      }
    };

    return (
      <TextInputFilter
        value={value}
        onChange={handleChangeValue}
        type="text"
        autoComplete="off"
        pattern={
          extraValidation ? '^[A|D|W|C]_[0-9]{4,9}$' : '^[A|D|W|C]_[0-9]{9}$'
        }
        title={
          extraValidation
            ? 'Transaction ID starts with A, D, W or C followed "_" and min 4 max 9 numbers'
            : '^[A|D|W|C]_[0-9]{9}$'
        }
        error={error && errorMessage}
      />
    );
  };
};
export default TransactionId;
