import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UnprocessableWithdrawal } from 'src/api';
import {
  callGetSuperAdminUnproccesableWithdrawal,
  callWithdrawalProblematicClear,
} from 'src/api';
import SideMenuContent from 'src/components/Design/SideMenuContent';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import toast from 'react-hot-toast';
import usePermissions from 'src/hooks/usePermissions';
import type { Column } from 'src/components/Table/@types';
import { Filters } from 'src/components/Table';
import { Cells } from 'src/components/Table';
import Table from 'src/components/Table';
import type { FilterEntryMapper } from 'src/components/Table/@types';
import { useTableCommonProps } from 'src/components/Table/hooks';
import { APITypes, APIUrls } from 'src/@enums/api';
import {
  clientTransactionTypeFilterConfig,
  paymentSystemFilterConfig,
  paymentTypeFilterConfig,
} from 'src/config/tables';
import useSystemCapabilities from 'src/hooks/useSystemCapabilities';
import useLoading from 'src/hooks/useLoading';
import { Link } from 'react-router-dom';
import useTransactionStatusFilter from 'src/hooks/useTransactionStatusFilter';
import { countryCodes } from 'src/config/countries';
import { useRowSelection } from 'src/components/Table/hooks';

const filterEntryMapper: FilterEntryMapper = ([id, value]) => {
  if (['fiatAmount', 'cryptoFiatRate', 'cryptoAmount'].includes(id)) {
    const [from, to] = value as [number, number];
    return [
      `${id}Range`,
      {
        from,
        to,
      },
    ];
  }
  if (['createdAt', 'transactionFinishedAt', 'updatedAt'].includes(id)) {
    return [`${id}Range`, value];
  }
  if (id === 'transactionStatusUser') {
    return ['transactionStatusInternal', value];
  }
  return [id, value];
};

export const AdminUnprocessableWithdrawal: FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // This exists because the response to WITHDRAWAL_PROBLEMATIC_GET differs from all other paginated responses.
  const [localTotalCount, setLocalTotalCount] = useState(0);
  const { fiatCurrencies, cryptoCurrencies } = useSystemCapabilities();
  const { loaded } = useLoading();
  const { hasPermission } = usePermissions();
  const { transactionStatusFilters, isLoadingTransactionStatusFilters } =
    useTransactionStatusFilter();

  const { commonProps, data, handleRefreshData } = useTableCommonProps({
    apiCall: callGetSuperAdminUnproccesableWithdrawal,
    apiString: `${APIUrls.ADMIN_CRYPTO_WITHDRAWAL}${APITypes.UNPROCCESABLE_WITHDRAWAL_GET}`,
    persistTitle: 'admin/unprocessable-withdrawals',
    filterEntryMapper,
    onLoad: loaded,
  });

  const {
    rowSelection,
    handleUpdateRowSelection,
    selectedRows,
    setSelectedRows,
  } = useRowSelection({
    data: data?.data.data.withdrawals || [],
    idField: 'id',
  });

  const listOfSelectedIds = selectedRows.map((row) => row.cryptoCurrency);
  const sortSelectedIds = listOfSelectedIds.reduce((acc, item) => {
    acc.has(item) ? acc.set(item, acc.get(item) + 1) : acc.set(item, 1);
    return acc;
  }, new Map());

  const formatSelectedIds = Array.from(sortSelectedIds).map(
    ([currency, number]) => ({
      currency,
      number,
    })
  );

  useEffect(() => {
    if (typeof data !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLocalTotalCount(data?.data.data.pagination.count || 0);
    }
  }, [data]);

  const columns: Column<UnprocessableWithdrawal, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: t('global:tables.id'),
        cell: ({ cell }) => (
          <Link to={`/admin/transactions/${cell.getValue()}`}>
            {cell.getValue()}
          </Link>
        ),
        filter: Filters.TransactionId({ extraValidation: false }),
      },
      {
        accessorKey: 'createdAt',
        header: t('global:tables.created on'),
        cell: Cells.DateTime,
        filter: Filters.DateRange,
      },
      {
        accessorKey: 'transactionType',
        header: t('global:buy.texts.transactionType'),
        cell: Cells.TransactionType,
        filter: Filters.Select({ options: clientTransactionTypeFilterConfig }),
      },
      {
        accessorKey: 'fiatCurrency',
        header: t('global:tables.fiat currency'),
        filter: Filters.Select({
          options: fiatCurrencies.map((currency) => ({
            value: currency,
            label: currency,
          })),
        }),
      },
      {
        accessorKey: 'fiatAmount',
        header: t('global:tables.fiat amount'),
        cell: ({ cell }) => (
          <Cells.Currency
            cell={cell}
            currency={cell.row.original.fiatCurrency}
            fieldName="price"
          />
        ),
        filter: Filters.TwoField,
      },
      {
        accessorKey: 'cryptoFiatRate',
        header: t('global:tables.fiat rate'),
        cell: ({ cell }) => (
          <Cells.Currency
            cell={cell}
            currency={cell.row.original.fiatCurrency}
          />
        ),
        filter: Filters.TwoField,
      },
      {
        accessorKey: 'cryptoCurrency',
        header: t('global:tables.crypto ccy'),
        filter: Filters.Select({
          options: cryptoCurrencies.map(({ cryptoCurrency }) => ({
            label: cryptoCurrency,
            value: cryptoCurrency,
          })),
        }),
      },
      {
        accessorKey: 'cryptoAmount',
        header: t('global:tables.crypto amount'),
        cell: ({ cell }) => (
          <Cells.Currency
            cell={cell}
            currency={cell.row.original.cryptoCurrency}
          />
        ),
        filter: Filters.TwoField,
      },
      {
        accessorKey: 'transactionFinishedAt',
        header: t('global:tables.transaction finished at'),
        cell: Cells.DateTime,
        filter: Filters.DateRange,
      },
      {
        accessorKey: 'sourceWallet',
        header: t('global:tables.source wallet'),
        filter: Filters.Text,
      },
      {
        accessorKey: 'recipientWallet',
        header: t('global:tables.recipient wallet'),
        cell: Cells.Wallet,
        filter: Filters.RecipientWallet,
      },
      {
        accessorKey: 'cryptoTransactionHash',
        header: t('global:tables.transaction hash'),
        cell: ({ cell }) => (
          <Cells.EditableCryptoTransactionHash
            cell={cell}
            handleRefreshData={handleRefreshData}
          />
        ),
        size: 300,
      },
      {
        accessorKey: 'cryptoTransactionIndex',
        header: t('global:tables.transaction index'),
        cell: Cells.CryptoTransactionHash,
      },
      {
        accessorKey: 'descriptionInternal',
        header: t('global:tables.internal description'),
        filter: Filters.SpacelessInput,
        size: 250,
      },
      {
        accessorKey: 'updatedAt',
        header: t('global:tables.updated at'),
        cell: Cells.DateTime,
        filter: Filters.DateRange,
      },
      {
        accessorKey: 'transactionStatusUser',
        header: t('global:tables.transaction status'),
        cell: ({ cell }) => {
          return (
            <Cells.Status label={cell.getValue()} value={cell.getValue()} />
          );
        },
        filter: Filters.MultiSelect({
          options: transactionStatusFilters,
          isLoading: isLoadingTransactionStatusFilters,
        }),
      },
      {
        accessorKey: 'paymentType',
        header: t('global:tables.payment type'),
        cell: Cells.PaymentType,
        filter: Filters.Select({ options: paymentTypeFilterConfig }),
      },
      {
        accessorKey: 'creditCard',
        header: t('global:tables.credit card'),
        filter: Filters.Text,
      },
      {
        accessorKey: 'paymentId',
        header: t('global:tables.payment id'),
        filter: Filters.Text,
      },
      {
        accessorKey: 'paymentProviderName',
        header: t('global:tables.psp'),
        filter: Filters.Select({ options: paymentSystemFilterConfig }),
      },
      {
        accessorKey: 'countryCode',
        header: t('global:tables.code'),
        cell: Cells.CountryCode,
        filter: Filters.Select({
          options: Object.keys(countryCodes).map((countryCode) => ({
            label: countryCodes[countryCode].name,
            value: countryCode,
          })),
        }),
      },
      {
        accessorKey: 'firstName',
        header: t('global:tables.first name'),
        cell: ({ cell }) => (
          <Cells.ClientInfo cell={cell} hasPermission={hasPermission} />
        ),
        filter: Filters.Text,
      },
      {
        accessorKey: 'lastName',
        header: t('global:tables.last name'),
        cell: ({ cell }) => (
          <Cells.ClientInfo cell={cell} hasPermission={hasPermission} />
        ),
        filter: Filters.Text,
      },
      {
        accessorKey: 'email',
        header: t('global:tables.email'),
        cell: ({ cell }) => (
          <Cells.ClientInfo cell={cell} hasPermission={hasPermission} />
        ),
        filter: Filters.Text,
      },
    ],
    [
      cryptoCurrencies,
      fiatCurrencies,
      handleRefreshData,
      hasPermission,
      isLoadingTransactionStatusFilters,
      t,
      transactionStatusFilters,
    ]
  );

  const titleView = useMemo(() => {
    return (
      <div className="flex">
        <span className="flex-1">
          {t('global:unprocessableWithdrawals.texts.title')}
        </span>
      </div>
    );
  }, [t]);

  const getTransactionHashes = () =>
    selectedRows.map((row) => row.cryptoTransactionHash);

  const modifyAction = async () => {
    setIsModalOpen(true);
  };

  const onClickSend = async () => {
    try {
      setIsModalOpen(true);

      const { data } = await callWithdrawalProblematicClear(
        getTransactionHashes()
      );

      if (data.status === 'OK') {
        toast.success(t('global:unprocessableWithdrawals.texts.clearSucceed'));
        setSelectedRows([]);
        await handleRefreshData();
      }
    } catch (error) {
      return;
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <SideMenuContent title={titleView}>
      <Table
        columns={columns}
        data={data?.data.data.withdrawals || []}
        totalCount={localTotalCount}
        hideTotalCount
        showCheckbox={hasPermission(['WITHDRAWAL_PROBLEMATIC_CLEAR'])}
        action={
          hasPermission(['WITHDRAWAL_PROBLEMATIC_CLEAR'])
            ? {
                callBack: modifyAction,
                title: t('global:unprocessableWithdrawals.texts.clearSelected'),
              }
            : undefined
        }
        rowSelection={
          hasPermission(['WITHDRAWAL_PROBLEMATIC_CLEAR'])
            ? rowSelection
            : undefined
        }
        onRowSelectionChange={
          hasPermission(['WITHDRAWAL_PROBLEMATIC_CLEAR'])
            ? handleUpdateRowSelection
            : undefined
        }
        {...commonProps}
        paginationParams={{
          ...commonProps.paginationParams,
          count: localTotalCount,
        }}
      />
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={t('global:unprocessableWithdrawals.texts.warning')}
      >
        {formatSelectedIds?.map((item) => (
          <>
            <p>
              <span className="px-1 text-red-600">{item.number}</span>
              <span className="font-bold">{item.currency}</span> withdrawal will
              be affected. <span className="font-bold">{item.currency}</span>{' '}
              will be sent!
            </p>
          </>
        ))}
        <Button
          shape="square"
          colorScheme="red"
          type="button"
          className="ml-auto mt-3"
          onClick={onClickSend}
        >
          {t('global:unprocessableWithdrawals.texts.clear')}
        </Button>
      </Modal>
    </SideMenuContent>
  );
};
