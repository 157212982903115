import type { ReactNode } from 'react';
import classNames from 'classnames';
import BackToLogin from 'src/components/BackToLogin';
import PageFooter from 'src/components/Design/CommonComponents/PageFooter';
import InstacoinsLogo from 'src/components/InstacoinsLogo';
import useAuthorization from 'src/hooks/useAuthorization';
import type { BackToLoginStatus } from 'src/components/BackToLogin/@types';
import FooterRoutes from './FooterRoutes';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { UserStepsProvider } from './StepsContext';
import styles from './UnauthorizedWrapper.module.css';

type UnauthorizedWrapperProps = {
  backToLoginStatus: BackToLoginStatus;
  children: ReactNode;
  footerRoutes?: boolean;
  showSidebar?: boolean;
};

export const UnauthorizedWrapper = ({
  children,
  backToLoginStatus,
  footerRoutes = false,
  showSidebar,
}: UnauthorizedWrapperProps) => {
  const { isAuthorized } = useAuthorization();

  return (
    <UserStepsProvider>
      <div className={styles.wrapper}>
        <div className={styles.navbarWrapper}>
          <Navbar enableLogout={isAuthorized} />
        </div>

        {backToLoginStatus !== 'none' && (
          <BackToLogin backToLoginStatus={backToLoginStatus} />
        )}

        {!showSidebar && (
          <InstacoinsLogo
            className="text-white"
            width={220}
            height={50}
            variant="logoTypeWithTM"
          />
        )}

        <div
          className={classNames(styles.childWrapper, {
            [styles.hasSidebar]: showSidebar,
          })}
        >
          <div className={styles.contentWrapper}>
            {showSidebar && <Sidebar />}

            {children}
          </div>

          <div className={styles.footerWrapper}>
            {footerRoutes && <FooterRoutes />}

            <PageFooter />
          </div>
        </div>
      </div>
    </UserStepsProvider>
  );
};
