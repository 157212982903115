import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

export type ConversionFunction = ({
  amount,
  cryptoCurrency,
}: {
  amount: number;
  cryptoCurrency: string;
}) => number;

type IAppContext = {
  isSideBarOpen: boolean;
  setSideBarOpen: (value: boolean) => void;
};

export const AppContext = createContext<IAppContext>({
  isSideBarOpen: false,
  setSideBarOpen: () => {
    return;
  },
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [isSideBarOpen, setSideBarOpen] = useState<boolean>(false);

  const data: IAppContext = {
    isSideBarOpen,
    setSideBarOpen,
  };

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};
