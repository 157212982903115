import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { reviewUser } from 'src/api/admin';
import Alert from 'src/components/Alert';
import Button from 'src/components/Button';
import CheckboxInputFormField from 'src/components/FormFields/CheckboxInputFormField';
import InputFormField from 'src/components/FormFields/InputFormField';
import Modal from 'src/components/Modal';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { handleError } from 'src/utils/helpers/errors';
import { successToast } from 'src/utils/toast';
import type { CellProps } from '..';

type FormProps = {
  adminReason: string;
  archived: boolean;
};

type ReviewActionProps = {
  handleRefreshData: () => void;
};

const ReviewAction = <TData extends { reason: string; reviewId: number }>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & ReviewActionProps) => {
  const { reviewId, reason } = cell.row.original;

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<FormProps>({
    mode: 'onSubmit',
  });

  const handleShowModal = useCallback(() => setIsOpen(true), []);
  const handleCloseModal = useCallback(() => setIsOpen(false), []);

  const handleReview = useCallback(
    async (data: FormProps) => {
      try {
        await reviewUser({
          id: reviewId,
          ...data,
        });

        successToast('The client has been reviewed');
        reset();
        setIsOpen(false);
        handleRefreshData();
      } catch (error) {
        handleError({
          error,
          options: {
            presentation: PresentationMethod.TOAST,
          },
        });
      }
    },
    [handleRefreshData, reset, reviewId]
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal} title="Review">
        <form
          className="grid grid-cols-1 gap-y-6"
          onSubmit={handleSubmit(handleReview)}
        >
          <InputFormField
            {...register('adminReason', {
              required: t('global:common.errors.errorRequired'),
            })}
            type="text"
            errorObject={errors.adminReason}
            label="Reason"
            variant="hero"
            required
          />
          <CheckboxInputFormField
            {...register('archived')}
            label="Archive this client?"
          />

          {reason === 'RED_FINAL_SUMSUB' && (
            <Alert type="warning">
              Before continuing, please make sure that all the necessary changes
              have been made to the documents.
            </Alert>
          )}

          <Button type="submit" loading={isSubmitting}>
            Review
          </Button>
        </form>
      </Modal>

      <Button size="small" onClick={handleShowModal}>
        Review
      </Button>
    </>
  );
};

export default ReviewAction;
