import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children?: ReactNode;
  className?: string;
  hideTitle?: boolean;
  title?: ReactNode;
};

const SideMenuContent = ({ children, title, hideTitle, className }: Props) => (
  <div
    className={twMerge(
      'relative mb-4 flex h-full flex-col bg-white bg-cover bg-no-repeat px-4 pb-28 sm:pt-6 md:bg-auto md:px-10 lg:p-8',
      className
    )}
  >
    {!hideTitle && title && (
      <div className="mb-4 py-2">
        <h2 className="min-w-max text-xl font-bold text-brand md:text-3xl">
          {title}
        </h2>
      </div>
    )}

    <div className="absolute inset-0 hidden bg-[url(/images/IC-decash.jpg)] bg-cover bg-no-repeat sm:block md:bg-auto"></div>
    <div className="relative">{children}</div>
  </div>
);

export default SideMenuContent;
