import Button from 'src/components/Button';
import { callAdminCancelSumsubFlow } from 'src/features/AdminRunningFlows/api';
import { handleError } from 'src/utils/helpers/errors';
import { successToast } from 'src/utils/toast';
import type { CellProps } from '..';
import { useCallback } from 'react';

type CancelFlowProps = {
  handleRefreshData: () => void;
};

const CancelFlow = <TData extends { userId: number }>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & CancelFlowProps) => {
  const { userId } = cell.row.original;

  const handleCancelFlow = useCallback(async () => {
    try {
      await callAdminCancelSumsubFlow(userId);
      successToast('Flow canceled successfully.');
      handleRefreshData();
    } catch (error) {
      handleError(error);
    }
  }, [handleRefreshData, userId]);

  return (
    <Button size="small" variant="solid" onClick={handleCancelFlow}>
      Cancel Flow
    </Button>
  );
};

export default CancelFlow;
