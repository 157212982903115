import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { ServerPagination } from 'src/api/types';
import DynamicPageSize from './DynamicPageSize';
import PaginationNav from './PaginationNav';
import { useTablePagination } from 'src/components/Table/hooks';
import type { RowSelectionState } from '@arringo-npm/fe-table';
import GoToPage from './GoToPage';
import classNames from 'classnames';

interface PaginationProps {
  currentPageSize: number;
  onChangePageSize: (newPageSize: number) => void;
  onPageChange: (index: number) => void;
  onRefreshData: () => void;
  onRowSelectionChange?: (selection: RowSelectionState) => void;
  pageIndex: number;
  pageSize: number;
  pageSizeOptions: number[];
  paginationParams: ServerPagination;
  totalCount: number;
  wrapperClassName?: string;
}

const Pagination: FC<PaginationProps> = ({
  pageSizeOptions,
  onChangePageSize,
  paginationParams,
  currentPageSize,
  pageSize,
  pageIndex,
  totalCount,
  wrapperClassName,
  onPageChange,
  onRefreshData,
}) => {
  const { t } = useTranslation();
  const {
    pagesCount,
    canGoToNextPage,
    canGoToPrevPage,
    handleGoToPrevPage,
    handleGoToNextPage,
    handleGoToPage,
    handleChangePageSize,
  } = useTablePagination({
    pageIndex,
    pageSize,
    totalCount,
    paginationParams,
    onPageChange,
    onChangePageSize,
    onRefreshData,
  });

  const pageIndexFromOne = pageIndex + 1;

  return (
    <div
      className={classNames(
        'flex flex-wrap items-center justify-between gap-y-4',
        wrapperClassName
      )}
      role="navigation"
    >
      <p>
        {t('global:common.pagination.showingResults', {
          totalCount,
          start: Math.min(paginationParams.skip + 1, totalCount),
          end: Math.min(
            paginationParams.skip + paginationParams.limit,
            totalCount
          ),
        })}
      </p>
      <div className="ml-auto flex flex-wrap items-center justify-center gap-4 lg:flex-nowrap">
        <PaginationNav
          canGoToPrevPage={canGoToPrevPage}
          canGoToNextPage={canGoToNextPage}
          pagesCount={pagesCount}
          currentPage={pageIndexFromOne}
          onClickPrev={handleGoToPrevPage}
          onClickNext={handleGoToNextPage}
          onGoToPage={handleGoToPage}
        />
        <GoToPage
          currentPage={pageIndexFromOne}
          pagesCount={pagesCount}
          onChangePageNumber={handleGoToPage}
        />
        <DynamicPageSize
          pageSizeOptions={pageSizeOptions}
          onChangePageSize={handleChangePageSize}
          currentPageSize={currentPageSize}
        />
      </div>
    </div>
  );
};

export default Pagination;
