import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken,
  InternalAxiosRequestConfig,
} from 'axios';
import axios from 'axios';
import { goPageLogin } from 'src/utils/pageNavigator';
import { validateRequest } from 'src/utils/helpers/request';
import { setToken } from 'src/utils';
import { getUserToken } from 'src/utils/helpers/auth';
import Cookie from 'js-cookie';
import { tokenCookie } from 'src/config';
import type { GenericApiError } from 'src/utils/helpers/@types';

const isCancel = axios.isCancel;
const cancelToken = axios.CancelToken;

const cancelTokens: { [k: string]: CancelToken } = {};
const cancelMethods: { [k: string]: Canceler } = {};

const createCancelToken = (userToken) => {
  if (!cancelTokens[userToken]) {
    cancelTokens[userToken] = new cancelToken((c) => {
      cancelMethods[userToken] = c;
    });
  }
  return cancelTokens[userToken];
};

const retryCancelledRequest = (error: AxiosError<GenericApiError>) => {
  const config = JSON.parse(error?.message) as AxiosRequestConfig;

  // replace the new JWT
  const newJwt = getUserToken();

  if (!cancelTokens[newJwt]) {
    cancelTokens[newJwt] = new cancelToken((c) => {
      cancelMethods[newJwt] = c;
    });
  }

  const data = JSON.parse(config.data);

  data.jwt = newJwt;

  config.data = JSON.stringify(data);
  config.cancelToken = cancelTokens[newJwt];

  return axios(config);
};

const replaceIncomingToken = (renewedJWT: string, response: AxiosResponse) => {
  const { jwt: oldJwt } = JSON.parse(response.config.data);
  setToken(renewedJWT);

  if (oldJwt) {
    cancelMethods[oldJwt]?.(JSON.stringify(response.config));
    cancelMethods[oldJwt] = null; // destroy it afterwards
  }
};

export const onRequest = async (request: InternalAxiosRequestConfig) => {
  if (request.requestSchema) {
    await validateRequest(request.requestSchema, request.data, request.url);
  }

  request.cancelToken = createCancelToken(getUserToken());
  return request;
};

export const onResponse = (response: AxiosResponse) => {
  const renewedJWT: string | undefined =
    response.headers?.['renewed-jwt'] || response.headers?.['Renewed-JWT'];

  if (renewedJWT) {
    replaceIncomingToken(renewedJWT, response);
  }

  if (response.config.responseSchema) {
    // validateResponse(response.config.responseSchema, response.data);
  }

  return response;
};

export const onError = (error: AxiosError<GenericApiError>) => {
  if (!error?.config?.data) {
    return Promise.reject(error);
  }

  if (isCancel(error)) {
    return retryCancelledRequest(error);
  }

  error = error as AxiosError<GenericApiError>;

  /* Makes the user logged out when receives `UNAUTHORIZED` as data.status. */
  if (
    error?.response &&
    (error?.response.status === 401 || error?.response.status === 403)
  ) {
    error.response.data['reason'] ??=
      'Your session has expired. Please login again.';

    const data = JSON.parse(error.config.data) || {};

    if (data?.jwt) {
      Cookie.remove(tokenCookie);
      goPageLogin();
    }
  }

  return Promise.reject(error);
};
