import Tippy from '@tippyjs/react';
import type { Placement } from '@popperjs/core';
import type { ReactNode, ReactElement } from 'react';

type Props = {
  children: ReactElement;
  content: ReactNode;
  isDisabled?: boolean;
  placement?: Placement;
  theme?: 'gray' | 'light';
};

const Tooltip = ({
  content,
  placement,
  children,
  isDisabled = false,
  theme = 'gray',
}: Props): React.ReactElement => {
  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <Tippy
      touch="hold"
      content={content}
      placement={placement}
      theme={theme}
      arrow={false}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
