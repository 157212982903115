import type {
  DocumentPersonalType,
  DocumentStatus,
  DocumentType,
} from 'src/@types/api';
import badgeStyles from 'src/styles/Badge.module.css';

export const mapDocumentType: Record<DocumentType, string> = {
  ADDRESS: 'Address',
  ADDRESS_ADDITIONAL: 'Address Additional',
  AVATAR: 'Avatar',
  OTHER: 'Other',
  PERSONAL: 'Personal',
  PERSONAL_ADDITIONAL: 'Personal Additional',
};

export const mapDocumentPersonalType: Record<DocumentPersonalType, string> = {
  DRIVING_LICENSE: 'Driving License',
  ID_CARD: 'ID Card',
  OTHER: 'Other',
  PASSPORT: 'Passport',
  RESIDENCE_CARD: 'Residence Card',
};

export const documentStatusClassName: Record<DocumentStatus, string> = {
  APPROVED: badgeStyles.greenBadge,
  DENIED: badgeStyles.blueBadge,
  MISSING: badgeStyles.redBadge,
  PENDING: badgeStyles.yellowBadge,
  EXPIRED: badgeStyles.grayBadge,
};
