import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useGoogleTagManager from 'src/hooks/useGoogleTagManager';

/**
 * Handle and Track Route Changes.
 */
const RouteChangeTracker = () => {
  const { onRouteChangeEvent: onRouteChangeGoogleTagManagerEvent } =
    useGoogleTagManager();
  const location = useLocation();

  useEffect(() => {
    onRouteChangeGoogleTagManagerEvent(location);
  }, [location, onRouteChangeGoogleTagManagerEvent]);

  return null;
};

export default RouteChangeTracker;
