import type { SelectOptionType } from 'src/@types/SelectType';

export const TIME_ITEMS: SelectOptionType<number>[] = [
  {
    label: '1 day',
    value: 24,
  },
  {
    label: '3H',
    value: 3,
  },
  {
    label: '2H',
    value: 2,
  },
  {
    label: '1H',
    value: 1,
  },
];
