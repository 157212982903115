import Button from 'src/components/Button';
import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import {
  admin_callGetUserTransactionsList,
  callTransactionHashUpdate,
} from 'src/api';
import InputFormField from 'src/components/FormFields/InputFormField';
import { APIUrls } from 'src/@enums/api';
import Modal from 'src/components/Modal';
import usePermissions from 'src/hooks/usePermissions';
import type { CellProps } from '..';
import type { RowData } from '@arringo-npm/fe-table';

type EditableCryptoTransactionHash = {
  handleRefreshData: () => void;
};

const EditableCryptoTransactionHash = <TData extends RowData>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & EditableCryptoTransactionHash) => {
  const value = cell.getValue();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState(false);
  const [affectedTransactions, setAffectedTransactions] = useState(0);

  const { hasPermission } = usePermissions();

  const { register, formState, getValues } = useForm<{
    transactionHash: string;
  }>({
    defaultValues: {
      transactionHash: value,
    },
  });

  const { mutate } = useSWR(
    state ? APIUrls.SUPERADMIN_UNPROCCESABLE_WITHDRAWAL : null,
    () =>
      callTransactionHashUpdate({
        customHash: value,
        newHash: getValues('transactionHash'),
      }).then(() => {
        handleRefreshData();
      }),
    {}
  );

  const handleShowModal = useCallback(() => setIsOpen(true), []);
  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    setIsEditing(!isEditing);
  }, [isEditing]);

  const handleUpdate = () => {
    setState(true);
    mutate();
    handleCloseModal();
  };

  const handleEdit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isEditing) {
      const {
        data: {
          pagination: { count: affectedTransactionsCount },
        },
      } = await admin_callGetUserTransactionsList({
        filter: {
          cryptoTransactionHashList: [getValues().transactionHash],
          transactionStatusInternal: ['WITHDRAWAL_PENDING_MANUAL_VERIFICATION'],
        },
        pagination: {
          limit: 0,
        },
      });

      setAffectedTransactions(affectedTransactionsCount);
      handleShowModal();
    } else {
      setIsEditing(!isEditing);
    }
  };

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        isOpen={isOpen}
        size="md"
        title="Warning"
      >
        <div className="grid gap-6">
          <div className="text-lg font-bold">
            <span className="text-orange-400">WARNING:</span> This action will
            not result in bitcoin sending
            <p>
              <span className="text-red-500">{affectedTransactions}</span> item
              will be affected.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <Button
              type="button"
              shape="square"
              variant="outline"
              loading={formState.isSubmitting}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              shape="square"
              variant="solid"
              loading={formState.isSubmitting}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-center gap-2">
        {isEditing ? (
          <InputFormField
            {...register('transactionHash')}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <p className="truncate">{value}</p>
        )}

        {hasPermission(['WITHDRAWAL_PROBLEMATIC_HASH_UPDATE']) && value && (
          <Button
            type="button"
            shape="square"
            variant={isEditing ? 'outline' : 'solid'}
            size="small"
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
            onClick={handleEdit}
          >
            {isEditing ? (
              <CheckIcon className="h-4 w-4 text-brand" />
            ) : (
              <PencilIcon className="h-4 w-4 text-white" />
            )}
          </Button>
        )}
      </div>
    </>
  );
};

export default EditableCryptoTransactionHash;
