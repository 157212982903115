import {
  ArchiveBoxIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { UserStatusProps } from 'src/features/UserStatus/components/@types';

const statusStyles: Record<UserStatusProps['status'], string> = {
  active: 'bg-green-50 text-green-600',
  pending: 'bg-yellow-50 text-yellow-600',
  archived: 'bg-gray-50 text-gray-600',
  inactive: 'bg-gray-50 text-gray-600',
  missing_docs: 'bg-red-50 text-red-600',
};

const statusIcon: Record<UserStatusProps['status'], JSX.Element> = {
  active: <ShieldCheckIcon />,
  pending: <DocumentTextIcon />,
  archived: <ArchiveBoxIcon />,
  inactive: <ShieldExclamationIcon />,
  missing_docs: <DocumentMagnifyingGlassIcon />,
};

type Props = {
  status: UserStatusProps['status'];
};

const StatusBadge = ({ status }: Props): JSX.Element => {
  const { t } = useTranslation();

  const classes = classNames(
    'flex items-center space-x-2 px-2 py-1 text-xs font-medium rounded-md max-w-max',
    statusStyles[status]
  );

  return (
    <div className={classes}>
      <span>{t(`global:user.statusBadge.${status}`)}</span>
      <div className="h-4 w-4">{statusIcon[status]}</div>
    </div>
  );
};

export default StatusBadge;
