const { gray } = require('tailwindcss/colors');
const colors = require('tailwindcss/colors');

/** @type {import("'tailwindcss'").Config } */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    fontFamily: {
      sans: ['Overpass', 'sans-serif'],
      mono: ['monospace'],
    },
    backgroundSize: {
      loadingSize: '3rem',
      auto: 'auto',
      cover: 'cover',
      contain: 'contain',
    },
    extend: {
      colors: {
        // Old styled-component theme colors
        'primary': '#08227c',
        'secondary': '#002E8A',
        'neutral': '#4A4A4A',
        'success': '#52c41a',
        'danger': '#ff4d4f',
        'warning': '#E9AD00',
        'info': '#8296BE',
        'background': '#FAFAFA',
        'white': '#FFFFFF',
        'black': '#000000',
        'dark': '#DFDFDF',
        'light': '#FFFFFF',
        'bgDark': '#001133',
        'bgLight': '#FFFFFF',
        'darkGrey': '#A9A9A9',
        'grey': '#DDDDDD',
        // conflicted names with tailwind colors
        // 'green': '#64DD17',
        // 'yellow': '#e9de41',
        // 'blue': '#2356C6',
        'happyBlue': '#00a0e4',
        'lightBlue': '#AABCE9',
        'lightGrey': '#dedede',
        'textLight': '#F0F0F0',

        'current': 'currentColor',
        'brand': '#012C8A',
        'blue': {
          10: '#F7FBFC',
        },
        'custom-gray': {
          '300': '#c5c5c5',
        },
        'custom-blue': {
          '100': '#E6F7FF',
          '200': '#d7dfee',
        },
        'sky': colors.sky,
        'lime': colors.lime,
        'yellow': colors.amber,
        'green': colors.emerald,
        'purple': colors.violet,
        'blueGray': colors.slate,
        'coolGray': gray,
        'gray': colors.zinc,
        'trueGray': colors.neutral,
        'warmGray': colors.stone,
      },
      boxShadow: {
        innerBlue: 'rgb(0, 46, 138) 0px 3px 5px inset',
        innerBold: 'inset 0px 4px 10px -5px #000000',
      },
      height: {
        'screen-90': '90vh',
      },
      minHeight: {
        '800px': '800px',
      },
      minWidth: {
        '100px': '100px',
        '440px': '440px',
        '720px': '720px',
      },
      width: {
        '26': '6.5rem',
        '38': '9.5rem',
      },
      animation: {
        'spin-fast': 'spin 0.75s cubic-bezier(0.4, 0, 0.2, 1) infinite',
        'fadeIn': 'fadeIn 0.6s linear forwards',
      },
      keyframes: {
        fadeIn: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      backgroundImage: {
        loading: `url(/images/loading.gif)`,
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
  ],
  corePlugins: {
    float: false,
  },
};
