import Tooltip from 'src/components/Tooltip';
import styles from './StatusPill.module.css';
import type { UserStatusProps } from './@types';

const getStyles: Record<UserStatusProps['status'], string> = {
  active: styles.active,
  pending: styles.pending,
  missing_docs: styles.missing,
  archived: '',
  inactive: '',
};

const StatusPill = ({
  title,
  status,
  statusTranslated,
  tooltipText,
}: UserStatusProps): React.ReactElement => {
  const classes = `
    ${styles.base}
    ${getStyles[status]}
  `;

  return (
    <Tooltip content={tooltipText}>
      <div className={classes}>
        <span className={styles.title}>{title}</span>
        <span className={styles.status} data-testid="userStatusPill">
          {statusTranslated}
        </span>
      </div>
    </Tooltip>
  );
};

export default StatusPill;
