import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';

const Boolean = <TData extends RowData>({ cell }: CellProps<TData, string>) => {
  return (
    <>
      {cell.getValue() ? (
        <span className="text-green-600">ENABLED</span>
      ) : (
        <span className="text-red-600">DISABLED</span>
      )}
    </>
  );
};

export default Boolean;
