import { useContext } from 'react';
import { LoadingContext } from 'src/contexts/LoadingContext';

const useLoading = () => {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingContextWrapper');
  }

  return context;
};

export default useLoading;
