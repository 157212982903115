import { CheckIcon } from '@heroicons/react/24/solid';
import { useRef } from 'react';
import Modal from 'src/components/Modal';
import { countries } from 'src/features/LanguagePicker/countries';
import type { LanguagePickerCountries } from './countries';
import styles from './LanguagePicker.module.css';
import Image from 'next/image';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectCountry: (country: LanguagePickerCountries) => void;
  selected: LanguagePickerCountries;
};

const LanguagePickerModal = ({
  isOpen,
  onClose,
  selectCountry,
  selected,
}: Props) => {
  const languageSelectorRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Change Language"
      initialFocus={languageSelectorRef}
    >
      <div className={styles.wrapper} ref={languageSelectorRef}>
        {Object.keys(countries).map((key, index) => {
          const country = countries[key];
          return (
            <div
              key={index}
              className={styles.languageItem}
              onClick={() => selectCountry(country)}
            >
              <div className={styles.contentWrapper}>
                <Image
                  width={24}
                  height={24}
                  className={styles.icon}
                  src={country.flag}
                  alt={country.name}
                  loading="lazy"
                />
                <span className={styles.title}>{country.name}</span>
              </div>
              {selected?.name === country.name && (
                <CheckIcon
                  width={20}
                  height={20}
                  className={styles.checkIcon}
                />
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default LanguagePickerModal;
