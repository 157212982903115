import type { Ref } from 'react';
import { forwardRef, useMemo } from 'react';
import type { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SafeOmit } from 'src/@types/utilities/SafeOmit';
import CheckboxInput from 'src/components/Inputs/CheckboxInput';
import type { CheckboxInputProps } from 'src/components/Inputs/CheckboxInput/@types';

type Props = SafeOmit<CheckboxInputProps, 'error'> & {
  errorObject?: FieldError;
};

/**
 * This is the input form field which is a based
 * on `CheckboxInput` to handle react-hook-form errors
 * better or other options.
 */
const CheckboxInputFormField = (
  { errorObject, ...rest }: Props,
  ref: Ref<HTMLInputElement>
): React.ReactElement => {
  const { t } = useTranslation();

  const errorMessage = useMemo(
    (): string =>
      errorObject?.type === 'required'
        ? t('global:common.errors.errorRequired')
        : errorObject?.message,
    [errorObject, t]
  );

  return <CheckboxInput error={errorMessage} ref={ref} {...rest} />;
};

export default forwardRef(CheckboxInputFormField);
