import { isDateValid } from '@arringo-npm/utility-package';

/**
 * Instacoins Back-End usually sends the dates in an inconvinient format, e.g:
 * 2021-04-04 03:30:00+02
 * This is not parseable by JavaScript, unless it's converted to a format that
 * looks *more similar* to ISO, e.g:
 * 2021-04-04T03:30:00+02
 *
 * @param serverTime
 * @param asUtc
 * @returns Possibly, an ISO-compatible date and time string
 */
export const serverToDate = (
  serverTime: string,
  asUtc = false
): Date | undefined => {
  if (!serverTime) {
    return;
  }

  if (asUtc) {
    serverTime = serverTime + '+00';
  }

  const ISOStringDate = serverTime
    .trim()
    .replace(' ', 'T')
    .replace(/\+00$/, 'Z');

  const date = new Date(ISOStringDate);
  if (!isDateValid(date)) {
    return;
  }

  return date;
};
