import type { Survey } from 'src/@types';
import { instance } from 'src/api/instance';
import { addToken } from 'src/utils';

export const callGetSurvey = (userId: number) => {
  return instance.post<{ surveyInfo?: Survey }>(
    '/survey',
    addToken({
      type: 'GET_SURVEY_INFO',
      data: { userId },
    }),
    {
      requestSchema: 'GetSurveyInfoRequest',
    }
  );
};

export const callUpdateSurvey = (survey: Survey) => {
  return instance.post(
    '/survey',
    addToken({
      type: 'UPDATE_SURVEY_INFO',
      data: survey,
    }),
    {
      requestSchema: 'UpdateSurveyInfoRequest',
    }
  );
};

export const callChangeRequiredFlag = (userId: number, required?: boolean) => {
  return instance.post(
    '/survey',
    addToken({
      type: 'CHANGE_SURVEY_REQUIRED_FLAG',
      data: {
        userId,
        required,
      },
    }),
    {
      requestSchema: 'ChangeSurveyRequiredFlagRequest',
    }
  );
};
