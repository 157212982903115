import React, { useMemo, useState } from 'react';
import type { DocumentInterviewItemState } from 'src/@types';
import { lockInterview } from 'src/api/admin';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { handleError } from 'src/utils/helpers/errors';
import type { Path } from 'src/routes/types';
import { useICNavigator } from 'src/hooks/useICNavigator';
import Button from 'src/components/Button';
import type { CellProps } from '..';

type InterviewQueueActionCallback = (params: {
  docId: number;
  redirecturl: string;
  refreshData: () => void;
  userId: number;
}) => void;

type InterviewQueueActionProps = {
  handleRefreshData: () => void;
};

const interviewQueueActionValueMapping = {
  START: 'Start Interview',
  IN_PROGRESS: 'Go to Interview',
  REVIEW: 'Review Documents',
};

const InterviewQueueAction = <
  TData extends {
    docId: number;
    redirecturl: string;
    state: string;
    userId: number;
  },
>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & InterviewQueueActionProps) => {
  const { docId, userId, state, redirecturl } = cell.row.original;

  const [isProcessing, setIsProcessing] = useState(false);
  const { goToPath } = useICNavigator();
  const interviewQueueActionCallbackMapping = useMemo<{
    [p in DocumentInterviewItemState]: InterviewQueueActionCallback;
  }>(
    () => ({
      START: async ({ redirecturl, docId }) => {
        try {
          setIsProcessing(true);
          await lockInterview({
            docId,
          });
          window.open(redirecturl, '_blank', 'noopener');
        } catch (error) {
          handleError({
            error,
            options: {
              presentation: PresentationMethod.TOAST,
            },
          });
        } finally {
          setIsProcessing(false);
          handleRefreshData();
        }
      },
      IN_PROGRESS: ({ redirecturl }) => {
        window.open(redirecturl, '_blank', 'noopener');
      },
      REVIEW: ({ userId }) => {
        goToPath(`/admin/clients/${userId}/documents` as Path);
      },
    }),
    [goToPath, handleRefreshData]
  );
  return (
    <Button
      shape="square"
      size="small"
      onClick={() =>
        interviewQueueActionCallbackMapping[state]({
          userId,
          redirecturl,
          docId,
        })
      }
      disabled={isProcessing}
    >
      {isProcessing ? 'Processing...' : interviewQueueActionValueMapping[state]}
    </Button>
  );
};

export default InterviewQueueAction;
