import type { Roles } from 'src/@types/Roles';
import type { UserStatusProps } from 'src/features/UserStatus/components/@types';
import type { SidebarProps } from 'src/components/Sidebar/@types';
import StatusBadge from 'src/components/StatusBadge';

type Props = {
  roleType: Roles;
  status: UserStatusProps['status'];
} & SidebarProps['userInfo'];

const AccountDetails = ({
  firstName,
  lastName,
  avatar,
  status,
  roleType,
}: Props): JSX.Element => {
  const shouldDisplayStatus = roleType === 'CLIENT';
  const user = `${firstName} ${lastName}`;

  return (
    <div className="flex w-full items-center justify-start space-x-4">
      <img
        src={avatar}
        alt={`${user}'s avatar`}
        className="h-w-14 w-14 rounded-full border border-gray-100"
      />

      <div className="flex flex-col space-y-1">
        <span className="font-medium">{user}</span>

        {shouldDisplayStatus && <StatusBadge status={status} />}
      </div>
    </div>
  );
};

export default AccountDetails;
