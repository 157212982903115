import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { VERSION } from 'src/config/features';
import { useUserStatusPill } from 'src/features/UserStatus/hooks/useUserStatusPill';
import AccountDetails from 'src/components/AccountDetails';
import UserBalances from 'src/components/UserBalances';
import type { SidebarProps } from './@types';
import styles from './Sidebar.module.css';
import SidebarMenuItem from './components/SidebarMenuItem';

const Sidebar = ({
  menuItems,
  userInfo: { firstName = '', lastName = '', avatar },
  isOpen,
  onChange,
}: SidebarProps): React.ReactElement => {
  const { t } = useTranslation();
  const { roleType, status } = useUserStatusPill();

  const shouldDisplayBalances = roleType === 'CLIENT';

  return (
    <div
      className={`${
        isOpen ? 'absolute flex' : 'hidden lg:flex'
      } lg:z-0' z-20 w-full lg:static lg:w-72 ${styles.sidebarHeight}`}
    >
      <nav
        id="sidebar"
        role="navigation"
        className={`
      absolute  w-4/5 flex-col space-y-2 overflow-y-auto border-0 border-r bg-white p-4 shadow-sm sm:w-72 lg:relative ${
        isOpen ? 'flex h-full' : ''
      }
      ${!isOpen ? 'hidden lg:flex' : ''}
      
      `}
      >
        <div className="flex flex-1 flex-col">
          <div
            id="account-info"
            className={`flex flex-col items-center justify-center space-y-5 py-1`}
          >
            <AccountDetails
              firstName={firstName}
              lastName={lastName}
              avatar={avatar}
              roleType={roleType}
              status={status}
            />
            {shouldDisplayBalances && <UserBalances />}
          </div>

          <div
            id="menu"
            className="no-scrollbar flex flex-1 flex-col space-y-1 overflow-y-auto text-sm"
          >
            {menuItems.map((menuItem, index) => (
              <SidebarMenuItem key={index} menuItem={menuItem} />
            ))}
          </div>
        </div>

        <div
          id="version"
          className="rounded-lg border border-gray-50 text-center text-xs font-medium text-gray-400 transition-colors duration-100 hover:border-gray-100 hover:text-gray-600"
        >
          <Link
            href="/version"
            className="inline-block h-full w-full px-4 py-2"
          >
            {`${t('global:common.texts.version')}: ${VERSION}`}
          </Link>
        </div>
      </nav>
      {isOpen && (
        <div
          onClick={() => onChange(false)}
          className="h-full w-full cursor-pointer bg-gray-900 bg-opacity-50 lg:hidden"
        ></div>
      )}
    </div>
  );
};

export default Sidebar;
