/**
 * Represents an API call progress status
 */
export enum ApiCallStatus {
  FAILED = 'Failed',
  IN_PROGRESS = 'InProgress',
  NONE = 'None',
  SUCCEEDED = 'Succeeded',
}

export * from './survey';
export * from './userClientInfo';
export * from './currentStats';
export * from './documents';
