import { Link } from 'react-router-dom';
import type { CellProps } from '..';
import type { GlobalAllEndpointMessageTypes } from 'src/api/@types/endpoints';

type ClientInfoProps = {
  hasPermission: (required?: GlobalAllEndpointMessageTypes[]) => boolean;
};

const ClientInfo = <TData extends { userId: number }>({
  cell,
  hasPermission,
}: CellProps<TData, string> & ClientInfoProps) => {
  const { userId } = cell.row.original;

  return (
    <>
      {hasPermission(['ADMIN_USER_GET']) ? (
        <Link to={`/admin/clients/${userId}`}>{cell.getValue()}</Link>
      ) : (
        <>{cell.getValue()}</>
      )}
    </>
  );
};

export default ClientInfo;
