import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';
import classNames from 'classnames';

const ClientStatus = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  return (
    <span
      className={classNames('font-medium', {
        'text-green-500': value === 'ACTIVE',
        'text-red-500': value === 'MISSING_DOCS',
        'text-yellow-500': value !== 'ACTIVE' && value !== 'MISSING_DOCS',
      })}
    >
      {value}
    </span>
  );
};

export default ClientStatus;
