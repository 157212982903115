import type { RowData } from '@arringo-npm/fe-table';
import { Trans } from 'react-i18next';
import type { CellProps } from '..';

const PaymentType = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  return (
    value && (
      <Trans i18nKey={`global:tables.fields.${value.toLowerCase()}`}>
        {value.toLowerCase()}
      </Trans>
    )
  );
};

export default PaymentType;
