import { useCallback, useContext, useMemo } from 'react';
import type {
  GlobalAllEndpointMessageTypes,
  GlobalEndpointURLs,
} from 'src/api/@types/endpoints';
import { PermissionsContext } from 'src/contexts/PermissionsContext';
import { hasEveryValues, hasSomeValues } from 'src/api/helpers/array';
import { isObjectEmpty } from 'src/utils';
import useAuthorization from './useAuthorization';

const usePermissions = () => {
  const { isAuthorized } = useAuthorization();
  const { setPermissions, endpointPermissions, flatPermissions } =
    useContext(PermissionsContext);

  const isPermissionsLoaded = useMemo(() => {
    if (!isAuthorized) return true;
    return !isObjectEmpty(flatPermissions);
  }, [flatPermissions, isAuthorized]);

  const hasAccessToEndpoint = (
    url: GlobalEndpointURLs,
    type: GlobalAllEndpointMessageTypes
  ) => {
    const messageTypes: GlobalAllEndpointMessageTypes[] =
      endpointPermissions[url];

    if (!messageTypes) {
      return false;
    }

    return messageTypes.some((t) => t === type);
  };

  const hasPermission = useCallback(
    (required: GlobalAllEndpointMessageTypes[] = []) => {
      return required.length > 0
        ? hasSomeValues(required, flatPermissions)
        : true;
    },
    [flatPermissions]
  );

  const hasAllPermissions = useCallback(
    (required: GlobalAllEndpointMessageTypes[] = []) => {
      return required.length > 0
        ? hasEveryValues(required, flatPermissions)
        : true;
    },
    [flatPermissions]
  );

  return {
    endpointPermissions,
    flatPermissions,
    hasPermission,
    hasAccessToEndpoint,
    hasAllPermissions,
    setPermissions,
    isPermissionsLoaded,
  };
};

export default usePermissions;
