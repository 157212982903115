import { useState, useEffect } from 'react';

type Params<T> = {
  defaultValue: T;
  key: string;
};

export const usePersistedState = <T>({
  key,
  defaultValue,
}: Params<T>): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // Reads data from localStorage otherwise returns the default value
  const [state, setState] = useState<T>(() => {
    const valueInStorage = window.localStorage.getItem(key);

    if (valueInStorage) return JSON.parse(valueInStorage);
    return defaultValue;
  });

  // Save state to localStorage
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
