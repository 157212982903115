import { memo } from 'react';
import type { ReactNode } from 'react';
import InformationCircleIcon from '@heroicons/react/24/solid/InformationCircleIcon';
import classNames from 'classnames';

export type AlertType = 'danger' | 'warning' | 'info' | 'success';

type Props = {
  children: ReactNode;
  className?: string;
  ping?: boolean;
  type: AlertType;
};

const Alert = ({ type, children, className, ping }: Props) => {
  const getWrapperClassName: Record<AlertType, string> = {
    danger: 'bg-red-50 border border-red-200',
    info: 'bg-blue-50 border border-blue-200',
    warning: 'bg-yellow-50 border border-yellow-200',
    success: 'bg-green-50 border border-green-200',
  };

  const getPingBG: Record<AlertType, string> = {
    danger: 'bg-red-300',
    info: 'bg-blue-300',
    warning: 'bg-yellow-300',
    success: 'bg-green-300',
  };

  const getTextClassName: Record<AlertType, string> = {
    danger: 'text-red-600',
    info: 'text-blue-600',
    warning: 'text-yellow-600',
    success: 'text-green-600',
  };

  return (
    <div
      className={classNames(
        'relative w-auto rounded-lg p-2 md:p-4',
        getWrapperClassName[type],
        className
      )}
    >
      <div className="flex">
        {ping && (
          <span
            className={classNames(
              '-top-1 animate-ping',
              getPingBG[type],
              'absolute -left-1 inline-flex h-4 w-4 rounded-full opacity-75'
            )}
          />
        )}
        <div className="shrink-0 self-center">
          <InformationCircleIcon
            className={classNames(
              'h-5 w-5 text-opacity-80',
              getTextClassName[type]
            )}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={classNames('text-base', getTextClassName[type])}>
            {children}
          </p>
        </div>
        {ping && (
          <span
            className={classNames(
              '-top-1 animate-ping',
              getPingBG[type],
              'absolute -right-1 inline-flex h-4 w-4 rounded-full opacity-75'
            )}
          />
        )}
      </div>
    </div>
  );
};

export default memo(Alert);
