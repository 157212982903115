import type { RowData } from '@arringo-npm/fe-table';
import Tooltip from 'src/components/Tooltip';
import { copyToClipBoard } from 'src/utils/helpers';
import { successToast } from 'src/utils/toast';
import type { CellProps } from '..';

const Note = <TData extends RowData>({ cell }: CellProps<TData, string>) => {
  const value = cell.getValue();

  return (
    <Tooltip content={<>{value}</>}>
      <a
        onClick={() => {
          copyToClipBoard(value);
          successToast('Copied to clipboard.');
        }}
        className="block truncate"
      >
        {value}
      </a>
    </Tooltip>
  );
};

export default Note;
