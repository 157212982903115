import { useTranslation } from 'react-i18next';

import StatusPill from 'src/features/UserStatus/components/StatusPill';
import { useUserStatusPill } from 'src/features/UserStatus/hooks/useUserStatusPill';

const UserStatus = () => {
  const { status, roleType, tooltipText, statusTranslated } =
    useUserStatusPill();
  const { t } = useTranslation();

  if (!status || roleType !== 'CLIENT') return null;

  return (
    <StatusPill
      tooltipText={tooltipText}
      title={t('global:user.status.title')}
      status={status}
      statusTranslated={statusTranslated}
    />
  );
};

export default UserStatus;
