import type { CellProps } from '..';
import { getCurrentEnvironment } from 'src/utils/helpers/environment';

const CryptoTransactionHash = <TData extends Record<string, unknown>>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();
  const currentEnv = getCurrentEnvironment();

  const prodlink =
    currentEnv === 'production' || currentEnv === 'stage'
      ? 'http://www.polyscan.com/tx/'
      : 'https://mumbai.polygonscan.com/tx/';

  const link =
    (cell.row.original.cryptoCurrency as TData['cryptoCurrency']) === 'BTC'
      ? `https://www.blockchain.com/btc/tx/${value}`
      : `${prodlink}${value}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  );
};

export default CryptoTransactionHash;
