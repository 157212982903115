import type { RowData } from '@arringo-npm/fe-table';
import type { TableCustomizations } from '@arringo-npm/fe-table/dist/@types';
import classNames from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import { useCallback } from 'react';
import type { ReactElement } from 'react-markdown/lib/react-markdown';
import type { SafeExclude } from 'src/@types/utilities/SafeExclude';

type Props<TData extends RowData> = ComponentPropsWithoutRef<
  SafeExclude<TableCustomizations<TData>['TableBodyRow'], string>
> & {
  className?: string;
};

const BodyRow = <TData extends RowData>({
  row,
  className,
  onClick,
  ...restProps
}: Props<TData>): ReactElement => {
  const handleClick = useCallback(() => {
    if (row.getCanSelect()) {
      row.toggleSelected();
    }

    if (onClick) {
      onClick(row.original);
    }
  }, [row, onClick]);

  return (
    <tr
      className={classNames(
        {
          'bg-custom-blue-100': row.getIsSelected(),
          'cursor-pointer': row.getCanSelect() || onClick,
          'odd:bg-black/10': !row.getCanSelect(),
        },
        className
      )}
      onClick={handleClick}
      {...restProps}
    />
  );
};

export default BodyRow;
