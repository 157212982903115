import type { RowSelectionState } from '@arringo-npm/fe-table';
import { useMemo, useState } from 'react';

const useRowSelection = <T extends Record<string, unknown>>({
  data,
  idField,
}: {
  data: T[];
  idField: keyof T;
}) => {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const rowSelection = useMemo(() => {
    const rowSelectionIndexes: RowSelectionState = {};
    data.forEach((item, index) => {
      if (selectedRows.find((row) => row[idField] === item[idField])) {
        rowSelectionIndexes[index] = true;
      }
    });
    return rowSelectionIndexes;
  }, [data, idField, selectedRows]);

  const handleUpdateRowSelection = (selection: RowSelectionState) => {
    let copySelectedRows = [...selectedRows];

    const oldSelectionKeys = Object.keys(rowSelection);
    const newSelectionKeys = Object.keys(selection);

    // Add new selected rows to copySelectedRows
    const indexesToSelect = newSelectionKeys.filter(
      (selectionIndex) => !rowSelection[selectionIndex]
    );
    copySelectedRows.push(...indexesToSelect.map((index) => data[index]));

    // Remove deselected rows from copySelectedRows
    const indexesToRemove = oldSelectionKeys.filter(
      (selectionIndex) => !selection[selectionIndex]
    );
    const idsToRemove = indexesToRemove.map((index) => data[index][idField]);
    copySelectedRows = copySelectedRows.filter(
      (row) => !idsToRemove.includes(row.id)
    );

    setSelectedRows(copySelectedRows);
  };

  return {
    selectedRows,
    setSelectedRows,
    rowSelection,
    handleUpdateRowSelection,
  };
};

export default useRowSelection;
