import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Button from 'src/components/Button';
import InstacoinsLogo from 'src/components/InstacoinsLogo';
import LanguagePicker from 'src/features/LanguagePicker';
import UserStatus from 'src/features/UserStatus/containers/UserStatusContainer';
import { useICNavigator } from 'src/hooks/useICNavigator';

type NavbarProps = {
  enableLogout?: boolean;
};

const Navbar = ({ enableLogout }: NavbarProps): React.ReactElement => {
  const { t } = useTranslation();
  const { goPageLogout } = useICNavigator();
  const location = useLocation();

  const onLogout = () => {
    goPageLogout();
  };

  return (
    <div className="flex h-14 items-center justify-between bg-white px-5 md:px-6">
      <div className="flex items-center space-x-2 md:space-x-6">
        <InstacoinsLogo variant="icon" className="text-brand" />
        <UserStatus />
      </div>

      <div className="flex items-center space-x-2 md:space-x-6">
        {enableLogout && (
          <Button
            size="small"
            shape="square"
            variant="outline"
            colorScheme="gray"
            onClick={onLogout}
          >
            <div className="flex items-center space-x-2">
              <ArrowRightOnRectangleIcon width={18} height={18} />
              <span>{t('global:common.menu.Logout')}</span>
            </div>
          </Button>
        )}

        {!location.pathname.startsWith('/affiliate') && <LanguagePicker />}
      </div>
    </div>
  );
};

export default Navbar;
