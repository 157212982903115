import type {
  PartitionedRoutes,
  ProtectedAffiliateRoute,
} from 'src/routes/types';
import deprecatedRoutes from './deprecatedRoutes';
import { lazy } from 'react';

/**
 * Affiliate Protected Routes
 *
 * Authentication Required
 * Only Affiliate Role
 * Permissions based on route
 */
export const affiliateProtectedRoutes: PartitionedRoutes<ProtectedAffiliateRoute> =
  {
    ...deprecatedRoutes,
    '/affiliate/summary': {
      component: lazy(() => import('src/pages/affiliate/summary')),
      name: 'Summary',
      permissions: ['AFFILIATE_GET', 'AFFILIATE_STATS'],
      roles: ['AFFILIATE'],
    },
    '/affiliate/emailing/unsubscribe&data=:token': {
      component: lazy(() => import('src/pages/EmailUnsubscribe')),
      name: 'Unsubscribe',
      roles: ['AFFILIATE'],
    },
    '/affiliate/transactions': {
      component: lazy(() => import('src/pages/affiliate/transactions')),
      name: 'Transactions',
      permissions: ['AFFILIATE_TRANSACTION_GET'],
      roles: ['AFFILIATE'],
    },
    '/affiliate/security': {
      component: lazy(() => import('src/pages/affiliate/security')),
      roles: ['AFFILIATE'],
      permissions: ['AFFILIATE_CHANGE_PWD'],
      name: 'Security',
    },
    '/affiliate/withdraw': {
      component: lazy(() => import('src/pages/affiliate/withdraw')),
      name: 'Withdraw',
      permissions: ['AFFILIATE_GET'],
      roles: ['AFFILIATE'],
    },
    '/affiliate/logout': {
      component: lazy(() => import('src/pages/affiliate/logout')),
      roles: ['AFFILIATE'],
      name: 'Logout',
    },
  };
