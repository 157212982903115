import { logSentry } from 'src/utils/helpers/logging';
import type { SumSubFlowTypeCounter } from 'src/@types/api';
import {
  TABLE_FILTERS,
  SUMSUB_COUNTER,
  TABLE_PAGER_LIMIT,
  TABLE_COLUMN_SIZING,
} from 'src/utils/constants/localStorage';
import type { ColumnSizingState } from '@arringo-npm/fe-table';

export const setFiltersInStorage = (
  title: string,
  data: Record<string, unknown>
) => {
  try {
    localStorage.setItem(getTableFilterKey(title), JSON.stringify(data));
  } catch (err) {
    logSentry(err, { functionName: 'setFiltersInStorage' });
  }
};

export const setPagerLimitInStorage = (title: string, limit: number) => {
  try {
    localStorage.setItem(getTablePagerLimitKey(title), JSON.stringify(limit));
  } catch (err) {
    logSentry(err, { functionName: 'setPagerLimitInStorage' });
  }
};

export const setColumnSizingInStorage = (
  title: string,
  columnSizing: ColumnSizingState
) => {
  try {
    localStorage.setItem(
      getTableColumnSizingKey(title),
      JSON.stringify(columnSizing)
    );
  } catch (err) {
    logSentry(err, { functionName: 'setColumnSizingInStorage' });
  }
};

export const getFiltersFromStorage = (title: string) => {
  try {
    const stringified = localStorage.getItem(getTableFilterKey(title));
    return JSON.parse(stringified);
  } catch (err) {
    logSentry(err, { functionName: 'getFiltersFromStorage' });
  }
};

export const getPagerLimitFromStorage = (title: string): number | null => {
  try {
    const stringified = localStorage.getItem(getTablePagerLimitKey(title));
    return JSON.parse(stringified);
  } catch (err) {
    logSentry(err, { functionName: 'getPagerLimitFromStorage' });
  }
};

export const getColumnSizingFromStorage = (
  title: string
): ColumnSizingState | null => {
  try {
    const stringified = localStorage.getItem(getTableColumnSizingKey(title));
    return JSON.parse(stringified);
  } catch (err) {
    logSentry(err, { functionName: 'getColumnSizingFromStorage' });
  }
};

export const setSumSubFlowCountInStorage = (counter: SumSubFlowTypeCounter) => {
  try {
    localStorage.setItem(SUMSUB_COUNTER, JSON.stringify(counter));
  } catch (err) {
    logSentry(err, { functionName: 'setSumSubFlowCountInStorage' });
  }
};

export const getSumSubFlowCountFromStorage = (): SumSubFlowTypeCounter => {
  try {
    const counter = localStorage.getItem(SUMSUB_COUNTER);
    return JSON.parse(counter);
  } catch (err) {
    logSentry(err, { functionName: 'getSumSubFlowCountFromStorage' });
  }
};

export const removeSumSubFlowCountFromStorage = () => {
  try {
    localStorage.removeItem(SUMSUB_COUNTER);
  } catch (err) {
    logSentry(err, { functionName: 'removeSumSubFlowCountFromStorage' });
  }
};

export const removeFiltersFromStorage = (title: string) => {
  try {
    localStorage.removeItem(getTableFilterKey(title));
  } catch (err) {
    logSentry(err, { functionName: 'removeFiltersFromStorage' });
  }
};

const getCurrentLocationPath = () => {
  return window?.location.pathname.replace(/\//g, '');
};

const getTableFilterKey = (title: string) => {
  return `${TABLE_FILTERS}_${getCurrentLocationPath()}_${title}`;
};

const getTablePagerLimitKey = (title: string) => {
  return `${TABLE_PAGER_LIMIT}_${getCurrentLocationPath()}_${title}`;
};

const getTableColumnSizingKey = (title: string) => {
  return `${TABLE_COLUMN_SIZING}_${title}`;
};
