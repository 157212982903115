import { useContext, useMemo } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { useTranslation } from 'react-i18next';
import useUserStatus from 'src/hooks/useUserStatus';
import type { UserStatusProps } from 'src/features/UserStatus/components/@types';

export const useUserStatusPill = () => {
  const { userStatus } = useUserStatus();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const status = useMemo<UserStatusProps['status']>(
    () => userStatus?.toLowerCase() as UserStatusProps['status'],
    [userStatus]
  );
  const statusTranslated = t(`global:user.status.${status}`);
  const tooltipText = useMemo(() => {
    return t(`global:user.status.tip.${status}`);
  }, [status, t]);

  return {
    status,
    roleType: user?.userAccountInfo?.roleType,
    tooltipText,
    statusTranslated,
  };
};
