import type { Dispatch } from 'react';
import { createContext, useReducer } from 'react';
import type { Currencies } from 'src/@enums';
import type { CryptoCurrencies } from 'src/@enums';

type Action = {
  payload: Partial<WalletContext>;
  type: 'UPDATE_WALLET';
};

type WalletContext = {
  confirmedTerms: boolean;
  cryptoCurrency: CryptoCurrencies;
  cryptoCurrencyPrice: number;
  cs: string;
  dispatch?: Dispatch<Action>;
  fiatAmount: number;
  fiatCurrency: Currencies;
  walletAddress: string;
};

const WALLET_INITIAL_STATE = {};
const WalletContext = createContext<WalletContext>(
  WALLET_INITIAL_STATE as WalletContext
);

export const WalletProvider = ({ children }) => {
  const reducer = (state: WalletContext, action: Action) => {
    switch (action.type) {
      case 'UPDATE_WALLET':
        return { ...state, ...action.payload };

      default:
        break;
    }
  };

  const [state, dispatch] = useReducer(
    reducer,
    WALLET_INITIAL_STATE as WalletContext
  );
  return (
    <WalletContext.Provider value={{ ...state, dispatch }}>
      {children}
    </WalletContext.Provider>
  );
};
