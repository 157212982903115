import { APITypes, APIUrls } from 'src/@enums/api';
import type {
  ClientNeededInterviews,
  DemandUserInterviewParams,
  GetClientDocumentsParams,
  GetClientDocumentsResponse,
  GetClientNeededInterviewsParams,
  GetClientStatusFlagsParams,
  GetClientStatusFlagsResponse,
  GetInterviewQueueParams,
  GetInterviewQueueResponse,
  GetKycStatsResponse,
  GetNeedReviewQueueParams,
  GetUsersNeedAdminMonitoringResponse,
  LockInterviewParams,
  LockInterviewResponse,
  NeedReviewResponse,
  PendingDocResponse,
  ReviewUserParams,
} from 'src/@types/api';
import instance from 'src/api/instance';
import { addToken } from 'src/utils';
import { prepareTableQuery } from './helpers';
import type { AdminSetDocOrCcAsFixedParams } from 'src/@types/api';

export const getInterviewQueue = (data: GetInterviewQueueParams) =>
  instance.post<GetInterviewQueueResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.DOC_INTERVIEW_NEEDS_ADMIN,
      data,
    })
  );

export const reviewUser = (data: ReviewUserParams) =>
  instance.post(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.ADMIN_REVIEW_USER,
      data,
    })
  );

export const getNeedReviewItems = (data: GetNeedReviewQueueParams) =>
  instance.post<NeedReviewResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.USERS_NEED_ADMIN_REVIEW,
      data,
    })
  );

export const getPendingDocsOrCcsItems = (params) =>
  instance.post<PendingDocResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.ADMIN_PENDING_DOCUMENT_AND_CC_LIST,
      data: params,
    })
  );

export const callGetUsersNeedAdminMonitoring = (rawData) =>
  instance.post<GetUsersNeedAdminMonitoringResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.USERS_NEED_ADMIN_MONITORING,
      data: prepareTableQuery(rawData, []),
    })
  );

export const lockInterview = (data: LockInterviewParams) =>
  instance.post<LockInterviewResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.DOC_ADMIN_LOCK_INTERVIEW,
      data,
    })
  );
export const callAdminSetDocOrCcAsFixed = (
  data: AdminSetDocOrCcAsFixedParams
) =>
  instance.post<LockInterviewResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.ADMIN_SET_DOC_OR_CC_AS_FIXED,
      data,
    })
  );

export const getClientDocuments = (data: GetClientDocumentsParams) =>
  instance.post<GetClientDocumentsResponse>(
    APIUrls.DOCUMENT,
    addToken({
      type: APITypes.DOC_GET,
      data: {
        document: data,
      },
    }),
    {
      responseSchema: 'GetClientDocumentsResponse',
    }
  );

export const getKycStats = () =>
  instance.post<GetKycStatsResponse>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.KYC_STATS,
    })
  );

export const getClientNeededInterviews = (
  data: GetClientNeededInterviewsParams
) =>
  instance.post<ClientNeededInterviews>(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.ADMIN_GET_NEEDS_INTERVIEW_DETAILS,
      data,
    })
  );

export const getClientStatusFlags = (data: GetClientStatusFlagsParams) =>
  instance.post<GetClientStatusFlagsResponse>(
    APIUrls.ADMIN_GET_USER_STATUS_FLAGS,
    addToken({
      type: APITypes.GET_USER_STATUS_FLAGS,
      data,
    }),
    {
      requestSchema: 'GetClientStatusFlagsRequest',
      responseSchema: 'GetClientStatusFlagsResponse',
    }
  );

export const demandUserInterview = (data: DemandUserInterviewParams) =>
  instance.post(
    APIUrls.ADMIN_KYC,
    addToken({
      type: APITypes.USER_NEEDS_INTERVIEW,
      data,
    })
  );
