import { ClockIcon } from '@heroicons/react/24/solid';
import { Controller } from 'react-hook-form';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import { TIME_ITEMS } from 'src/features/AdminUsersNeedMonitoring/constants';
import { useSnoozeUserNeedAdminMonitoring } from 'src/features/AdminUsersNeedMonitoring/hooks/useSnoozeUserNeedAdminMonitoring';
import type { CellProps } from '..';
import DatePicker from 'react-datepicker';
import styles from './DatePicker.module.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { formatDate, addDuration } from '@arringo-npm/utility-package';
import { useMemo } from 'react';

type Props = {
  handleRefreshData: () => void;
};

const EXACT_DATE_TIME_INTERVALS = 15;

const SnoozeUserNeedAdminMonitoring = <
  TData extends { isLocked: boolean; isSnoozed: boolean; userId: number },
>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & Props) => {
  const { isLocked, isSnoozed, userId } = cell.row.original;

  const {
    isOpen,
    isLoading,
    handleShowModal,
    handleCloseModal,
    formMethods,
    submit,
    handleUnSnoozeButton,
  } = useSnoozeUserNeedAdminMonitoring({
    isSnoozed,
    userId,
    handleRefreshData,
  });

  const { control, handleSubmit, setValue, reset, getValues, watch } =
    formMethods;

  const exactDateTime = watch('exactDateTime');
  const relativeTimeHours = watch('relativeTimeHours');
  const selectedSnoozeDate: Date = useMemo(() => {
    if (exactDateTime) return exactDateTime;
    const now = new Date();
    return addDuration(now, {
      hours: relativeTimeHours,
    });
  }, [exactDateTime, relativeTimeHours]);

  return (
    <div>
      <Modal
        onClose={handleCloseModal}
        isOpen={isOpen}
        size="md"
        title="Time to live of the snooze"
      >
        <form onSubmit={handleSubmit(submit)} className="grid gap-6">
          <Controller
            name="exactDateTime"
            control={control}
            rules={{
              required: !getValues().relativeTimeHours,
            }}
            render={({ field: { name, onChange, value } }) => {
              const now = new Date();
              const nearestSelectableDate = new Date();
              const nearestSelectableHour =
                now.getMinutes() > 60 - EXACT_DATE_TIME_INTERVALS
                  ? now.getHours() + 1
                  : now.getHours();
              const nearestSelectableMinutes =
                now.getMinutes() > 60 - EXACT_DATE_TIME_INTERVALS
                  ? 0
                  : Math.floor(
                      (now.getMinutes() + EXACT_DATE_TIME_INTERVALS) /
                        EXACT_DATE_TIME_INTERVALS
                    ) * EXACT_DATE_TIME_INTERVALS;
              nearestSelectableDate.setHours(
                nearestSelectableHour,
                nearestSelectableMinutes
              );
              const selected =
                value && value > now ? new Date(value) : nearestSelectableDate;
              const isTodaySelected =
                !!selected &&
                new Date(selected).setHours(0, 0, 0, 0) ===
                  new Date().setHours(0, 0, 0, 0);
              return (
                <DatePicker
                  name={name}
                  inline
                  showTimeSelect
                  calendarClassName={styles.wrapper}
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                  }) => {
                    return (
                      <div className="flex justify-between">
                        <ChevronLeftIcon
                          width={20}
                          height={20}
                          className={styles.arrow}
                          onClick={decreaseMonth}
                        />
                        <p className="font-extrabold">
                          {/* FIXME type needs to be fixed in @ririck/utility-package */}
                          {/* 
                        // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
                        // @ts-ignore */}
                          {formatDate(date, 'MMMM yyyy')}
                        </p>
                        <ChevronRightIcon
                          width={20}
                          height={20}
                          className={styles.arrow}
                          onClick={increaseMonth}
                        />
                      </div>
                    );
                  }}
                  timeIntervals={EXACT_DATE_TIME_INTERVALS}
                  selected={exactDateTime && selected}
                  onChange={(date) => {
                    setValue('relativeTimeHours', null);
                    onChange(date > new Date() ? date : nearestSelectableDate);
                  }}
                  minDate={now}
                  minTime={isTodaySelected ? now : null}
                  maxTime={
                    isTodaySelected
                      ? new Date(new Date().setHours(23, 59))
                      : null
                  }
                />
              );
            }}
          />
          <Controller
            name="relativeTimeHours"
            control={control}
            rules={{
              required: !getValues().exactDateTime,
            }}
            render={({ field }) => (
              <div className="flex justify-between space-x-2">
                {TIME_ITEMS.map((option) => (
                  <Button
                    className="flex-1"
                    shape="square"
                    variant={field.value === option.value ? 'solid' : 'text'}
                    type="button"
                    key={option.value}
                    onClick={() => {
                      setValue('exactDateTime', null);
                      field.onChange(option.value);
                    }}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
            )}
          />
          <div className="bg-gray-100 px-4 py-2 text-center text-xl font-bold">
            {`${formatDate(selectedSnoozeDate, 'd MMMM yyyy')} at ${formatDate(
              selectedSnoozeDate,
              'HH:mm'
            )}`}
          </div>
          <div className="flex justify-between space-x-2">
            <Button
              shape="square"
              type="button"
              variant="text"
              className="flex-1"
              loading={isLoading}
              onClick={() => reset()}
            >
              Clear
            </Button>
            <Button
              shape="square"
              className="flex-1"
              type="submit"
              loading={isLoading}
              disabled={!exactDateTime && !relativeTimeHours}
            >
              Confirm
            </Button>
          </div>
        </form>
      </Modal>
      <Button
        size="small"
        shape="square"
        onClick={isSnoozed ? handleUnSnoozeButton : handleShowModal}
        leadingIcon={<ClockIcon />}
        disabled={isLocked}
        loading={isLoading}
      >
        {isSnoozed ? 'Unsnooze' : 'Snooze'}
      </Button>
    </div>
  );
};

export default SnoozeUserNeedAdminMonitoring;
