import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import type { ModalProps } from './@types';
import styles from './Modal.module.css';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const Modal = ({
  title,
  children,
  onClose,
  isOpen,
  size = 'md',
  initialFocus,
  icon,
  hideCloseButton = false,
  titleClassName,
}: ModalProps): React.ReactElement => {
  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className={styles.base}
          open={isOpen}
          initialFocus={initialFocus}
          onClose={
            hideCloseButton
              ? () => {
                  return;
                }
              : onClose
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.overlayWrapper}>
            <Transition.Child
              as={Fragment}
              enter={styles.overlayEnter}
              enterFrom={styles.overlayEnterFrom}
              enterTo={styles.overlayEnterTo}
              leave={styles.overlayLeave}
              leaveFrom={styles.overlayLeaveFrom}
              leaveTo={styles.overlayLeaveTo}
            >
              <Dialog.Overlay className={styles.overlay} />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className={styles.position} aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter={styles.modalEnter}
              enterFrom={styles.modalEnterFrom}
              enterTo={styles.modalEnterTo}
              leave={styles.modalLeave}
              leaveFrom={styles.modalLeaveFrom}
              leaveTo={styles.modalLeaveTo}
            >
              <div
                className={classNames(
                  styles.wrapper,
                  size === 'lg' ? 'w-full sm:max-w-2xl' : 'w-full sm:max-w-lg'
                )}
              >
                <div className={styles.headerWrapper}>
                  {icon}
                  <Dialog.Title
                    as="h3"
                    className={twMerge(styles.title, titleClassName)}
                  >
                    {title}
                  </Dialog.Title>
                  {!hideCloseButton && (
                    <div
                      className={styles.closeTrigger}
                      onClick={() => onClose()}
                      role="button"
                    >
                      <XMarkIcon width={20} height={20} />
                    </div>
                  )}
                </div>

                <div className={styles.childrenWrapper}>{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Modal;
