export enum PaymentSystems {
  D = 'D',
  ECOMMPAY = 'ECOMMPAY',
  FIBONATIX = 'FIBONATIX',
  FINARO = 'FINARO',
  LINK4PAY = 'LINK4PAY',
  NETELLER = 'NETELLER',
  RAPIDTRANSFER = 'RAPIDTRANSFER',
  SECURETRADING = 'SECURETRADING',
  SKRILL = 'SKRILL',
  TRUEVO = 'TRUEVO',
  YAPILY = 'YAPILY',
}
