import {
  Cog6ToothIcon,
  AdjustmentsVerticalIcon,
  CogIcon,
  CursorArrowRaysIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnLeftIcon,
  BanknotesIcon,
  Bars4Icon,
  BookmarkSquareIcon,
  CalculatorIcon,
  ChartBarIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentMagnifyingGlassIcon,
  DocumentCheckIcon,
  FolderArrowDownIcon,
  HandThumbDownIcon,
  IdentificationIcon,
  LockClosedIcon,
  MinusCircleIcon,
  NewspaperIcon,
  NoSymbolIcon,
  RectangleStackIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  ServerIcon,
} from '@heroicons/react/24/outline';
import { MenuLabels } from 'src/@enums';
import i18n from 'src/assets/translations';
import type { BaseMenuItem } from 'src/@types/MenuItem';
import { callGetProblematicWithdrawalsCount } from 'src/features/AdminUnprocessableWithdrawal';
import { USE_NEW_ACTIVITIES } from './features';
import { APIUrls } from 'src/@enums/api';

export const sideBarItems: BaseMenuItem[] = [
  {
    icon: DocumentMagnifyingGlassIcon,
    link: '/admin/clients/bulk-operations',
    name: i18n.t('global:common.menu.Bulk User Update'),
    roles: ['SUPER_ADMIN'],
    permissions: ['ADMIN_CLIENT_SEARCH', 'ADMIN_USERS_UPDATE'],
  },
  {
    icon: IdentificationIcon,
    link: '/admin/kyc',
    name: i18n.t('global:common.menu.KYC'),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['ADMIN_CLIENT_KYC_PENDING_GET'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: ClockIcon,
    link: '/admin/surveys',
    name: i18n.t('global:common.menu.Survey Pending'),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['UPDATE_SURVEY_INFO', 'GET_SURVEYS_INFO'],
  },
  {
    icon: UsersIcon,
    link: '/admin/clients',
    name: i18n.t('global:common.menu.Clients'),
    hasNestedRoute: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['ADMIN_CLIENT_GET'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: ArrowUturnLeftIcon,
    link: '/admin/refunds',
    name: i18n.t('global:common.menu.Refunds'),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['GET_REFUND_LIST'],
  },
  {
    icon: UserGroupIcon,
    link: '/admin/admins',
    name: i18n.t('global:common.menu.Admins'),
    roles: ['SUPER_ADMIN'],
    permissions: ['GET_ALL_ADMINS'],
  },
  {
    icon: RectangleStackIcon,
    link: '/admin/transactions',
    name: i18n.t('global:common.menu.Transactions'),
    hasNestedRoute: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['ADMIN_TRANSACTIONS_LIST'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: HandThumbDownIcon,
    link: '/admin/transactions/withdrawals/unprocessable',
    name: i18n.t('global:common.menu.Unprocessable Withdrawals'),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['WITHDRAWAL_PROBLEMATIC_GET'],
    displayCount: true,
    refreshInterval: 60_000,
    displayCountFetcher: callGetProblematicWithdrawalsCount,
    alwaysShowTooltip: true,
  },
  {
    icon: NoSymbolIcon,
    link: '/admin/black-list',
    name: i18n.t('global:common.menu.Black List'),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['BLACKLIST_GET_IP'],
  },
  {
    icon: FolderArrowDownIcon,
    link: '/admin/export',
    name: i18n.t('global:common.menu.Export'),
    roles: ['SUPER_ADMIN', 'ADMIN'],
    permissions: [
      'SUPERADMIN_CLIENTS_WITH_UNUSED_BALANCES_EXPORT',
      'SUPERADMIN_ARCHIVED_CLIENTS_WITH_ACTIVE_BALANCES_EXPORT',
      'SUPERADMIN_AFFILIATE_TRANSACTIONS_EXPORT',
      'SUPERADMIN_AFFILIATE_BALANCES_EXPORT',
    ],
  },
  {
    icon: DocumentCheckIcon,
    link: '/admin/reports',
    name: i18n.t('global:common.menu.Reports'),
    roles: ['SUPER_ADMIN'],
    permissions: ['CALLS_STATISTICS'],
  },
  {
    icon: CreditCardIcon,
    link: '/client/deposit',
    name: i18n.t('global:common.menu.Make Deposit'),
    translationKey: 'common.menu.Deposit',
    roles: ['CLIENT'],
    permissions: ['BUY_CC', 'CREATE_BANK_TRANSFER'],
    mobileTab: {
      name: i18n.t('global:user.mobileNavbar.buy'),
      order: 2,
    },
  },
  {
    icon: BanknotesIcon,
    link: '/client/withdraw',
    name: i18n.t('global:common.menu.Withdraw'),
    translationKey: 'common.menu.Withdraw',
    roles: ['CLIENT'],
    label: MenuLabels.WITHDRAW,
    permissions: ['WITHDRAW_CRYPTO'],
    mobileTab: {
      order: 1,
    },
  },
  {
    icon: NewspaperIcon,
    link: '/admin/messages',
    name: i18n.t('global:common.menu.messages'),
    translationKey: 'common.menu.messages',
    roles: ['SUPER_ADMIN'],
    permissions: [],
  },
  {
    icon: RectangleStackIcon,
    link: '/client/transactions',
    name: i18n.t('global:common.menu.Transaction History'),
    translationKey: 'common.menu.Transaction History',
    roles: ['CLIENT'],
    permissions: ['TRANSACTION_GET'],
    mobileTab: {
      name: i18n.t('global:user.mobileNavbar.transactions'),
      order: 0,
    },
  },
  {
    icon: NewspaperIcon,
    link: '/client/news-center',
    name: i18n.t('global:common.menu.News Center'),
    translationKey: 'common.menu.News Center',
    roles: ['CLIENT'],
    permissions: [],
    displayCount: true,
    displayCountFetcherKey: APIUrls.GET_USER_STATUS_FLAGS,
    displayCountDataKey: 'screen.data.unreadNews',
    hideDisplayCountOnZero: true,
  },
  {
    icon: CalculatorIcon,
    link: '/client/accounting',
    name: i18n.t('global:common.menu.User Accounting'),
    translationKey: 'common.menu.User Accounting',
    roles: ['CLIENT'],
    label: MenuLabels.USER_ACCOUNTING,
    permissions: ['USER_ACCOUNT_GET'],
  },

  {
    icon: UserCircleIcon,
    link: '/admin/affiliates',
    name: i18n.t('global:common.menu.Affiliates'),
    hasNestedRoute: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['AFFILIATE_LIST'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: RectangleStackIcon,
    link: '/admin/affiliates/transactions',
    name: i18n.t('global:common.menu.Affiliates Transactions'),
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['AFFILIATE_TRANSACTION_LIST'],
  },
  {
    icon: BanknotesIcon,
    link: '/admin/affiliates/withdrawals',
    name: i18n.t('global:common.menu.Affiliates Withdrawals'),
    roles: ['SUPER_ADMIN'],
    permissions: ['ADMIN_AFFILIATE_PAYMENT_GET'],
  },
  {
    icon: BookmarkSquareIcon,
    link: '/admin/reasons',
    name: i18n.t('global:common.menu.Reason Templates'),
    roles: ['SUPER_ADMIN'],
    permissions: ['REJECTREASONS_RECEIVE'],
  },
  {
    icon: AdjustmentsVerticalIcon,
    link: '/admin/settings',
    name: i18n.t('global:common.menu.App Settings'),
    roles: ['SUPER_ADMIN'],
    permissions: ['COUNTRIES_GET', 'EXPORT_MAILING_GET'],
  },
  {
    icon: Cog6ToothIcon,
    link: '/admin/payment-settings',
    name: 'Payment Settings',
    roles: ['SUPER_ADMIN'],
    permissions: ['COUNTRIES_GET', 'EXPORT_MAILING_GET'],
  },
  {
    icon: ServerIcon,
    link: '/admin/virtual-accounts',
    name: i18n.t('global:common.menu.virtualAccounts'),
    roles: ['SUPER_ADMIN'],
    permissions: ['VA_TRANSFER_HISTORY_GET'],
  },
  {
    icon: Bars4Icon,
    link: '/admin/logs',
    name: i18n.t('global:common.menu.Account Logs'),
    translationKey: 'common.menu.Account Logs',
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['ACTIVITY_GET'],
    disabled: USE_NEW_ACTIVITIES,
  },
  {
    icon: ShieldCheckIcon,
    link: '/admin/system-audit',
    name: i18n.t('global:common.menu.System Audit'),
    translationKey: 'common.menu.System Audit',
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['ACTIVITY_GET'],
    disabled: !USE_NEW_ACTIVITIES,
  },
  {
    icon: Bars4Icon,
    link: '/client/logs',
    name: i18n.t('global:common.menu.Account Logs'),
    translationKey: 'common.menu.Account Logs',
    roles: ['CLIENT'],
    permissions: ['ACTIVITY_GET'],
    disabled: USE_NEW_ACTIVITIES,
  },
  {
    icon: ChartBarIcon,
    link: '/affiliate/summary',
    name: i18n.t('global:common.menu.Affiliate Summary'),
    roles: ['AFFILIATE'],
    permissions: ['AFFILIATE_GET', 'AFFILIATE_STATS'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: BanknotesIcon,
    link: '/affiliate/withdraw',
    name: i18n.t('global:common.menu.Request Withdrawal'),
    roles: ['AFFILIATE'],
    permissions: ['AFFILIATE_GET'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: RectangleStackIcon,
    link: '/affiliate/transactions',
    name: i18n.t('global:common.menu.Transaction History'),
    roles: ['AFFILIATE'],
    permissions: [],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: LockClosedIcon,
    link: '/admin/security',
    name: i18n.t('global:common.menu.Password and Security'),
    translationKey: 'common.menu.Password and Security',
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: ['SECURITY_CHANGE_PWD', 'SECURITY_SET_STATUS_2FA'],
  },
  {
    icon: CogIcon,
    link: '/client/security',
    name: i18n.t('global:common.menu.Account Settings'),
    translationKey: 'common.menu.Account Settings',
    roles: ['CLIENT'],
    items: [
      {
        icon: LockClosedIcon,
        link: '/client/security',
        name: i18n.t('global:common.menu.Password and Security'),
        translationKey: 'common.menu.Password and Security',
        roles: ['CLIENT'],
        permissions: ['SECURITY_CHANGE_PWD', 'SECURITY_SET_STATUS_2FA'],
      },
      {
        icon: CursorArrowRaysIcon,
        link: '/client/activities',
        name: i18n.t('global:common.menu.Activities'),
        translationKey: 'client.texts.Activities',
        roles: ['CLIENT'],
        permissions: ['ACTIVITY_GET'],
        disabled: !USE_NEW_ACTIVITIES,
      },
      {
        icon: MinusCircleIcon,
        link: '/client/financial-limits',
        name: i18n.t('global:common.menu.Your Limits'),
        translationKey: 'common.menu.Your Limits',
        roles: ['CLIENT'],
        permissions: ['GET_USER_FINANCIAL_LIMITS_REMAINING'],
      },
    ],
  },
  {
    icon: LockClosedIcon,
    link: '/affiliate/security',
    name: i18n.t('global:common.menu.Password and Security'),
    translationKey: 'common.menu.Password and Security',
    roles: ['AFFILIATE'],
    permissions: ['AFFILIATE_CHANGE_PWD'],
    mobileTab: {
      order: 0,
    },
  },
  {
    icon: ArrowRightOnRectangleIcon,
    link: '/admin/logout',
    name: i18n.t('global:common.menu.Logout'),
    translationKey: 'common.menu.Logout',
    roles: ['ADMIN', 'SUPER_ADMIN'],
    permissions: [],
  },
  {
    icon: ArrowRightOnRectangleIcon,
    link: '/client/logout',
    name: i18n.t('global:common.menu.Logout'),
    translationKey: 'common.menu.Logout',
    roles: ['CLIENT'],
    permissions: [],
  },
  {
    icon: ArrowRightOnRectangleIcon,
    link: '/affiliate/logout',
    name: i18n.t('global:common.menu.Logout'),
    translationKey: 'common.menu.Logout',
    roles: ['AFFILIATE'],
    permissions: [],
  },
];
