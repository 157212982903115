import {
  ArrowLeftOnRectangleIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import type { BackToLoginProps } from './@types';
import { useICNavigator } from 'src/hooks/useICNavigator';

const BackToLogin = ({
  backToLoginStatus,
}: BackToLoginProps): React.ReactElement => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const { goPageLogin, goPageLogout } = useICNavigator();

  const isLoggedIn = useMemo(
    () => backToLoginStatus === 'logout',
    [backToLoginStatus]
  );

  const buttonText = useMemo(
    () =>
      isLoggedIn
        ? t('global:login.buttons.logout')
        : t('global:login.buttons.login'),
    [t, isLoggedIn]
  );

  const onButtonClick = useCallback(
    () => (isLoggedIn ? goPageLogout() : goPageLogin()),
    [isLoggedIn, goPageLogout, goPageLogin]
  );

  const handleClose = () => {
    setOpen(false);
  };

  if (!open) {
    return <></>;
  }

  return (
    <div className="relative right-0 grid w-full place-items-end px-4 md:px-8 lg:absolute lg:top-24">
      <div className="z-50 grid w-full space-y-4 rounded-xl border border-gray-200 bg-white p-4 lg:w-max">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <QuestionMarkCircleIcon
                width={24}
                height={24}
                className="text-brand"
              />

              <h4 className="text-lg font-bold text-gray-600">
                Already a customer?
              </h4>
            </div>

            <button
              className="rounded-lg p-1 transition-all duration-200 hover:bg-gray-50"
              onClick={handleClose}
            >
              <XMarkIcon width={20} height={20} />
            </button>
          </div>

          <p className="max-w-xs pl-10">
            {backToLoginStatus === 'backToLogin'
              ? t('global:login.texts.haveAccount')
              : t('global:login.texts.anotherAccount')}
          </p>
        </div>

        <Button
          shape="square"
          onClick={onButtonClick}
          className="ml-auto min-w-100px"
          leadingIcon={<ArrowLeftOnRectangleIcon />}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default BackToLogin;
