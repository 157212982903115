import { usePagination } from '@arringo-npm/fe-table';
import { useCallback, useEffect } from 'react';
import type { ServerPagination } from 'src/api/types';

type UseTablePaginationParams = {
  onChangePageSize: (newPageSize: number) => void;
  onPageChange: (index: number) => void;
  onRefreshData: () => void;
  pageIndex: number;
  pageSize: number;
  paginationParams: ServerPagination;
  totalCount: number;
};

const useTablePagination = ({
  pageIndex,
  pageSize,
  totalCount,
  paginationParams,
  onChangePageSize,
  onPageChange,
  onRefreshData,
}: UseTablePaginationParams) => {
  const {
    goToNextPage,
    goToPrevPage,
    pagesCount,
    canGoToNextPage,
    canGoToPrevPage,
  } = usePagination({
    goToPage: (index) => {
      onPageChange(index);
    },
    pageIndex,
    pageSize,
    totalCount,
  });

  useEffect(() => {
    if (!paginationParams || !paginationParams?.count) {
      return;
    }
    const lastPage = Math.ceil(paginationParams.count / paginationParams.limit);
    // If we are on an off page, navigate us back to the last available page. This happens when the last entry on the last page is deleted.
    if (paginationParams.skip >= paginationParams.count) {
      onPageChange(lastPage - 1);
    }
  }, [paginationParams, pagesCount, onPageChange]);

  const handleUpdatePagination = useCallback(
    (pageNumber: number) => {
      onPageChange(pageNumber - 1);
      onRefreshData();
    },
    [onPageChange, onRefreshData]
  );

  const handleGoToPrevPage = () => {
    if (pageIndex > 0) {
      handleUpdatePagination(pageIndex - 1);
      goToPrevPage();
    }
  };

  const handleGoToNextPage = () => {
    if (pageIndex <= pagesCount - 1) {
      handleUpdatePagination(pageIndex + 1);
      goToNextPage();
    }
  };

  const handleGoToPage = (pageIndex: number) => {
    if (pageIndex > 0 && pageIndex <= pagesCount) {
      handleUpdatePagination(pageIndex);
    }
  };

  const handleChangePageSize = (newPageSize: number) => {
    onPageChange(0);
    onChangePageSize(newPageSize);
  };

  return {
    pagesCount,
    canGoToNextPage,
    canGoToPrevPage,
    handleGoToPrevPage,
    handleGoToNextPage,
    handleGoToPage,
    handleChangePageSize,
  };
};

export default useTablePagination;
