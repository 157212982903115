import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import UserStatusContext from 'src/contexts/UserStatusContext';
import type { Path } from 'src/routes/types';
import { authCheck, getUserRole } from 'src/utils/helpers/auth';
import { isClient } from 'src/utils/helpers/roles';
import usePermissions from './usePermissions';
import { useLocationState } from './useLocationState';
import { useICNavigator } from './useICNavigator';

const useUserStatus = () => {
  const { userStatus, screenName, isLoading, hasCreditCardToBeReplaced } =
    useContext(UserStatusContext);
  const { isVerified } = useContext(UserContext);
  const state = useLocationState<{
    wireTransfer?: boolean;
  }>();
  const { pathname } = useLocation();
  const { hasPermission } = usePermissions();
  const { goToPath } = useICNavigator();

  const currentPath = pathname as Path;

  const isTransactionResultPage = useMemo(
    () =>
      currentPath === '/client/payment-result' ||
      currentPath === '/transaction-result-new',
    [currentPath]
  );

  const isUnsubscribePage = useMemo(
    () => currentPath.startsWith('/client/emailing/unsubscribe'),
    [currentPath]
  );

  const isTransactionResultNewPage = useMemo(
    () =>
      currentPath === '/client/payment-callback' ||
      currentPath === '/payment-callback',
    [currentPath]
  );

  const isBankInfoPage = useMemo(
    () => currentPath.startsWith('/client/bank-info'),
    [currentPath]
  );

  const isLogoutPage = useMemo(
    () => currentPath === '/client/logout',
    [currentPath]
  );

  const isAuthenticated = authCheck();
  const shouldPreventRedirections = useMemo(
    () =>
      isUnsubscribePage ||
      isLoading ||
      !isAuthenticated ||
      !isClient(getUserRole()) ||
      isTransactionResultPage ||
      isTransactionResultNewPage ||
      isLogoutPage ||
      (isVerified && screenName !== 'WIRE_INSTRUCTIONS') ||
      (isVerified && screenName === 'WIRE_INSTRUCTIONS' && isBankInfoPage),
    [
      isLoading,
      isAuthenticated,
      isTransactionResultPage,
      isTransactionResultNewPage,
      isLogoutPage,
      isVerified,
      isBankInfoPage,
      isUnsubscribePage,
      screenName,
    ]
  );
  const redirectUser = useCallback(
    (path: Path) => {
      if (currentPath === path) return;
      return goToPath(path, state);
    },
    [currentPath, goToPath, state]
  );

  /**
   * This use effect, handles the redirections based on the user status.
   * It wait until the data is loaded and user is authenticated.
   *
   * First, check for the preAuth, if no preAuth is done, that means user needs to
   * do a preAuth first, therefore they should have only access to buyPage,
   * so if they are not in the buy page, we redirect them there.
   *
   * There is once exception to the first condition, If user wants to do
   * their transaction using WireTransfer, then they need to upload documents first,
   * so we redirect user with an route state (location.state.wireTransfer) to
   * the /kyc page. This is the only redirection to BuyPage.
   *
   * The second condition and redirection is to the Kyc (SumSub) page,
   * If the user has done preAuth already, Then we check for the hasNeededDocuments
   * There is no exception, if user has done preAuth and needs documents, they must
   * be redirected to the kyc Page.
   *
   // * The last condition and redirection is the survey page,
   * If user has done preAuth and has no needed documents to upload
   * and the needSurvey is true, Then user will be redirected to the survey form page.
   */
  useEffect(() => {
    if (shouldPreventRedirections) return;
    if (['DASHBOARD', 'DASHBOARD_PENDING', 'BUY'].includes(screenName)) {
      if (hasPermission(['BUY_CC', 'CREATE_BANK_TRANSFER'])) {
        return redirectUser('/client/deposit');
      } else {
        return redirectUser('/client/suspended');
      }
    }
    if (
      [
        'DOC_COMMENTS',
        'CC_COMMENTS',
        'DOC_UPLOAD',
        'DOC_UPLOAD_CONTINUE',
        'PENDING',
      ].includes(screenName) ||
      hasCreditCardToBeReplaced
    ) {
      return redirectUser('/client/kyc');
    }
    if (screenName === 'SURVEY') return redirectUser('/client/survey');
    if (screenName === 'WIRE_INSTRUCTIONS') {
      return redirectUser('/client/bank-info');
    }
  }, [
    redirectUser,
    screenName,
    hasPermission,
    shouldPreventRedirections,
    hasCreditCardToBeReplaced,
  ]);

  return {
    userStatus,
  };
};

export default useUserStatus;
