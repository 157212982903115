import type { CellProps } from '..';
import { getCurrentEnvironment } from 'src/utils/helpers/environment';

const Wallet = <TData extends Record<string, unknown>>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  const currentEnv = getCurrentEnvironment();

  const prodlink =
    currentEnv === 'production' || currentEnv === 'stage'
      ? 'http://www.polyscan.com/address/'
      : 'https://mumbai.polygonscan.com/address/';

  const link =
    (cell.row.original.cryptoCurrency as TData['cryptoCurrency']) === 'BTC'
      ? `https://www.blockchain.com/btc/address/${value}`
      : `${prodlink}${value}`;

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()} // TODO: fix this hack
    >
      {value}
    </a>
  );
};

export default Wallet;
