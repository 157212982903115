import { Router } from 'next/dist/client/router';
import { useEffect } from 'react';
import { isProduction } from 'src/utils/helpers/environment';

const FacebookPixelComponent = () => {
  useEffect(() => {
    if (isProduction()) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init('2228836204007130', undefined, {
            debug: false,
            autoConfig: true,
          });
          ReactPixel.pageView();
          ReactPixel.fbq('track', 'PageView');

          Router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        });
    }
  });
  return <div />;
};

export default FacebookPixelComponent;
