/* BASE URL */
export { BASE_URL } from 'src/config';

export enum APIUrls {
  ACTIVITY_V2 = '/activity-v2',
  ADJUSTMENT_API = '/adjustment',
  ADMIN_ACTIVITY_V2 = '/admin/activity-v2',
  ADMIN_AFFILIATE = '/admin/affiliate',
  ADMIN_CRYPTO_WITHDRAWAL = '/admin/crypto-withdrawal',
  ADMIN_FIAT_LICENSE = '/admin/fiat-license',
  ADMIN_GET_USER_STATUS_FLAGS = '/admin/getUserStatusFlags',
  ADMIN_IP_BLACKLIST = '/admin/ip-blacklist',
  ADMIN_KYC = '/admin/kyc',
  ADMIN_NOTES_EXPORT = '/admin/notes/export',
  ADMIN_OPEN_BANKING = '/admin/open-banking',
  ADMIN_REASON_TEMPLATE = '/admin/reason-template',
  ADMIN_REFUND = '/admin/refund',
  ADMIN_SYSTEM_CAPABILITIES = '/admin/system-capabilities',
  ADMIN_USER = '/admin/user',
  ADMIN_USER_SUMMARY = '/admin/user-summary',
  AFFILIATE = '/affiliate',
  AFFILIATE_LOGIN = '/affiliate/login',
  AFFILIATE_PAYMENT = '/affiliate/payment',
  AFFILIATE_SECURITY = '/affiliate/security',
  AFFILIATE_SIGNUP = '/affiliate/signup',
  AFFILIATE_STATS = '/affiliate/stats',
  AFFILIATE_TRANSACTION = '/affiliate/transaction',
  BUY = '/buy',
  BUY_BANK = '/buy/bank',
  BUY_OB = '/buy-ob',
  BUY_PREAUTH = '/buy-preauth',
  BUY_PS = '/buy-ps',
  BUY_RESULT = '/buy/result',
  CANCEL_PREAUTH_TRANSACTION = '/admin/cancel/preauth',
  CARD_PAYMENTS_DISTRIBUTION = '/admin/card-payments-distribution',
  COUNTRIES = '/countries',
  DOCUMENT = '/document',
  EXPORT_CLIENTS = '/admin/client/export',
  EXPORT_MAILING = '/export/mailing',
  FILES = '/file',
  FINANCIAL_LIMITS = '/financialLimits',
  FINANCIAL_TIERS = '/financialTiers',
  GET_MEDIA = '/getMedia',
  GET_USER_STATUS_FLAGS = '/getUserStatusFlags',
  HISTORIC_DOCUMENT = '/historic-document',
  LOAD_CLIENTS = '/admin/client',
  LOGIN = '/login',
  LOGOUT = '/logout',
  MANAGEMENT = '/admin/management',
  MESSAGES = '/msg',
  MLRO = '/admin/email',
  NEWS = '/news',
  NOTES = '/admin/notes',
  PAYMENT_GATEWAY_TOP_UP = '/superadmin/pg-tu',
  PSP_SETTLEMENT_CURRENCY = '/superadmin/psp-settlement-currency',
  RATE = '/exchange',
  RECOVER_AFFILIATE_PASSWORD = '/affiliate/email',
  RECOVER_PASSWORD = '/email',
  REJECT_REASONS = '/reject-reasons',
  ROLES = '/admin/roles',
  SECURITY = '/security',
  SIGNUP = '/signup',
  STATS = '/stats',
  SUMSUB_WORKFLOW = '/sumsub/workflow',
  SUPERADMIN_AFFILIATE = '/superadmin/affiliate',
  SUPERADMIN_CLIENT_EXPORT = '/superadmin/client/export',
  SUPERADMIN_EMAIL_CAMPAIGNS = '/superadmin/emailCampaigns',
  SUPERADMIN_FLAGS = '/superadmin/app-settings/flags',
  SUPERADMIN_GLOBAL_PARAMETER = '/superadmin/global-settings',
  SUPERADMIN_NEWS = '/superadmin/news',
  SUPERADMIN_REPORTS = '/superadmin/reports',
  SUPERADMIN_UNPROCCESABLE_WITHDRAWAL = '/superadmin/crypto-withdrawal',
  SURVEY_PENDING = '/survey',
  SYSTEM_CAPABILITIES = '/system-capabilities',
  TRANSACTIONS_HISTORY = '/transaction',
  UNSUBSCRIBE_EMAIL = '/unsubscribe',
  URGENT_MESSAGES = '/urgentMessages',
  USER = '/user',
  USER_ACCOUNTING_DATA = '/user/accounting',
}
