import type { Filter } from '..';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';

const TextFilter: Filter = ({ column }) => {
  const value = (column.getFilterValue() || '') as string;

  const handleChangeValue = (updatedValue: string) => {
    column.setFilterValue(updatedValue);
  };

  return (
    <TextInputFilter
      type="text"
      onChange={handleChangeValue}
      value={value}
      autoComplete="off"
    />
  );
};

export default TextFilter;
