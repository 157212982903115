import type { RowData } from '@arringo-npm/fe-table';
import { formatCurrency } from 'src/utils';
import type { CellProps } from '..';

type CurrencyProps = {
  currency: string;
  fieldName?: string;
  hideSymbol?: boolean;
  parentheses?: boolean;
};

const Currency = <TData extends RowData>({
  cell,
  currency,
  fieldName,
  parentheses = false,
  hideSymbol = false,
}: CellProps<TData, number> & CurrencyProps) => {
  return (
    <span>
      {parentheses ? '(' : ''}
      {formatCurrency(cell.getValue(), currency, fieldName, hideSymbol)}
      {parentheses ? ')' : ''}
    </span>
  );
};

export default Currency;
