import type { PropsWithChildren } from 'react';
import { createContext, useMemo, useState } from 'react';
import type {
  GlobalAllEndpointMessageTypes,
  GlobalEndpointTypes,
} from 'src/api/@types/endpoints';
import { flattenPermissions } from 'src/api/helpers';
import { isObjectEmpty } from 'src/utils';

type PermissionsContextType = {
  endpointPermissions: Partial<GlobalEndpointTypes>;
  flatPermissions: GlobalAllEndpointMessageTypes[];
  setPermissions: (permissions: Partial<GlobalEndpointTypes>) => void;
};

export const PermissionsContext = createContext<PermissionsContextType>({
  endpointPermissions: {} as Partial<GlobalEndpointTypes>,
  flatPermissions: [],
  setPermissions: () => ({}),
});

const PermissionsProvider = PermissionsContext.Provider;

type Props = PropsWithChildren<Record<string, unknown>>;

const PermissionsContextWrapper = ({ children }: Props) => {
  const [endpointPermissions, setEndpointPermissions] = useState<
    Partial<GlobalEndpointTypes>
  >({} as Partial<GlobalEndpointTypes>);

  const flatPermissions = useMemo<GlobalAllEndpointMessageTypes[]>(
    () =>
      !isObjectEmpty(endpointPermissions)
        ? flattenPermissions(endpointPermissions)
        : [],
    [endpointPermissions]
  );

  const setPermissions = (permissions: Partial<GlobalEndpointTypes>) => {
    setEndpointPermissions(permissions);
  };

  return (
    <PermissionsProvider
      value={{
        endpointPermissions,
        flatPermissions,
        setPermissions,
      }}
    >
      {children}
    </PermissionsProvider>
  );
};

export default PermissionsContextWrapper;
