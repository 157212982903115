import classNames from 'classnames';
import { cloneElement } from 'react';
import type { ButtonIconSize, ButtonProps, ButtonSize } from './@types';
import LoadingIcon from './LoadingIcon';

const iconSize: Record<ButtonSize, ButtonIconSize> = {
  small: { width: 16, height: 16 },
  medium: { width: 18, height: 18 },
  large: { width: 20, height: 20 },
};

const LeadingIconComponent = ({
  leadingIcon,
  size = 'medium',
}: Pick<ButtonProps, 'leadingIcon' | 'size'>): React.ReactElement | null =>
  leadingIcon
    ? cloneElement(leadingIcon, {
        width: iconSize[size].width,
        height: iconSize[size].height,
      })
    : null;

const TrailingIconComponent = ({
  trailingIcon,
  size = 'medium',
}: Pick<ButtonProps, 'trailingIcon' | 'size'>): React.ReactElement | null =>
  trailingIcon
    ? cloneElement(trailingIcon, {
        width: iconSize[size].width,
        height: iconSize[size].height,
      })
    : null;

const Button = ({
  variant = 'solid',
  colorScheme = 'blue',
  size = 'medium',
  shape = 'round',
  leadingIcon,
  trailingIcon,
  children,
  block,
  loading = false,
  disabled = false,
  className = '',
  ...rest
}: ButtonProps): React.ReactElement => {
  return (
    <button
      className={classNames(
        `
      relative inline-flex items-center justify-center
      space-x-2 overflow-hidden border-2 font-medium
      transition-colors duration-150 ease-in-out
      focus:outline-none
      disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50`,
        {
          small: 'px-2.5 py-1.5 text-sm',
          medium: 'px-4 py-2 text-base',
          large: 'px-6 py-3 text-lg',
        }[size],
        {
          'solid-blue':
            'solid bg-blue-700 text-white hover:bg-blue-800 focus:ring-blue-600',
          'solid-green':
            'solid bg-green-700 text-white hover:bg-green-800 focus:ring-green-600',
          'solid-gray':
            'solid bg-gray-700 text-white hover:bg-gray-800 focus:ring-gray-600',
          'solid-red':
            'solid bg-red-700 text-white hover:bg-red-800 focus:ring-red-600',
          'solid-orange':
            'solid bg-orange-500 text-white hover:bg-orange-800 focus:ring-orange-600',
          'outline-blue': 'border-blue-600 text-blue-700 outline outline-1',
          'outline-green': 'border-green-600 text-green-700 outline outline-1',
          'outline-gray': 'border-gray-600 text-gray-700 outline outline-1',
          'outline-red': 'border-red-600 text-red-700 outline outline-1',
          'outline-orange':
            'border-orange-600 text-orange-700 outline outline-1',
          'text-blue': 'text hover:text-blue-800',
          'text-green': 'text hover:text-green-800',
          'text-gray': 'text hover:text-gray-800',
          'text-red': 'text text-red-500 hover:bg-red-100 hover:text-red-800',
          'text-orange':
            'text text-orange-500 hover:bg-orange-100 hover:text-orange-800',
        }[`${variant}-${colorScheme}`],
        {
          round: 'rounded-full',
          square: 'rounded-lg',
        }[shape],
        {
          'w-full': block,
        },
        className
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && (
        <LoadingIcon
          width={iconSize[size].width}
          height={iconSize[size].height}
        />
      )}

      {!loading && (
        <LeadingIconComponent leadingIcon={leadingIcon} size={size} />
      )}

      <span>{children}</span>

      <TrailingIconComponent trailingIcon={trailingIcon} size={size} />
    </button>
  );
};

export default Button;
