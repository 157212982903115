const riskLevelColor = {
  GOLD: '#FFCB39',
  ORANGE: '#FF8C00',
  RED: '#D0021B',
};

export const countryCodes = {
  'AF': { name: 'Afghanistan', color: riskLevelColor.ORANGE },
  'AL': { name: 'Albania' },
  'DZ': { name: 'Algeria' },
  'AS': { name: 'American Samoa' },
  'AD': { name: 'Andorra' },
  'AO': { name: 'Angola' },
  'AI': { name: 'Anguilla' },
  'AQ': { name: 'Antarctica' },
  'AG': { name: 'Antigua and Barbuda' },
  'AR': { name: 'Argentina' },
  'AM': { name: 'Armenia' },
  'AW': { name: 'Aruba' },
  'AU': { name: 'Australia' },
  'AT': { name: 'Austria' },
  'AZ': { name: 'Azerbaijan' },
  'BS': { name: 'Bahamas', color: riskLevelColor.ORANGE },
  'BH': { name: 'Bahrain' },
  'BD': { name: 'Bangladesh' },
  'BB': { name: 'Barbados' },
  'BY': { name: 'Belarus' },
  'BE': { name: 'Belgium' },
  'BZ': { name: 'Belize' },
  'BJ': { name: 'Benine' },
  'BM': { name: 'Bermuda' },
  'BT': { name: 'Bhutan' },
  'BA': { name: 'Bosnia and Herzegovina' },
  'BO': { name: 'Bolivia' },
  'BW': { name: 'Botswana', color: riskLevelColor.ORANGE },
  'BV': { name: 'Bouvet Island' },
  'BR': { name: 'Brazil' },
  'IO': { name: 'British Indian Ocean Territory' },
  'BN': { name: 'Brunei Darussalam' },
  'BG': { name: 'Bulgaria' },
  'BF': { name: 'Burkina' },
  'BI': { name: 'Burundi' },
  'KH': { name: 'Cambodia' },
  'CM': { name: 'Cameroon' },
  'CA': { name: 'Canada' },
  'CV': { name: 'Cape Verde' },
  'KY': { name: 'Cayman Islands' },
  'CF': { name: 'Central African Republic' },
  'TD': { name: 'Chad' },
  'CL': { name: 'Chile' },
  'CN': { name: 'China' },
  'CX': { name: 'Christmas Island' },
  'CI': { name: 'Cote dIvoire' },
  'CC': { name: 'Cocos (Keeling) Islands' },
  'CO': { name: 'Colombia' },
  'KM': { name: 'Comoros' },
  'CG': { name: 'Congo (Congo-Brazzaville)' },
  'CD': {
    name: 'Democratic Republic of the Congo',
    color: riskLevelColor.ORANGE,
  },
  'CK': { name: 'Cook Islands' },
  'CR': { name: 'Costa Rica' },
  'HR': { name: 'Croatia' },
  'CU': { name: 'Cuba' },
  'CW': { name: 'Curaçao' },
  'CY': { name: 'Cyprus' },
  'CZ': { name: 'Czech Republic' },
  'DK': { name: 'Denmark' },
  'DJ': { name: 'Djibouti' },
  'DM': { name: 'Dominica' },
  'DO': { name: 'Dominican Republic' },
  'EC': { name: 'Ecuador' },
  'EG': { name: 'Egypt', color: riskLevelColor.RED },
  'SV': { name: 'El Salvador' },
  'GQ': { name: 'Equatorial Guinea' },
  'ER': { name: 'Eritrea' },
  'EE': { name: 'Estonia' },
  'ET': { name: 'Ethiopia', color: riskLevelColor.ORANGE },
  'FK': { name: 'Falkland Islands (Malvinas)' },
  'FO': { name: 'Faroe Islands' },
  'FJ': { name: 'Fiji' },
  'FI': { name: 'Finland' },
  'FR': { name: 'France' },
  'GF': { name: 'French Guiana' },
  'PF': { name: 'French Polynesia' },
  'TF': { name: 'French Southern Territories' },
  'GA': { name: 'Gabon' },
  'GM': { name: 'Gambia' },
  'GE': { name: 'Georgia' },
  'DE': { name: 'Germany' },
  'GH': { name: 'Ghana', color: riskLevelColor.ORANGE },
  'GI': { name: 'Gibraltar' },
  'GR': { name: 'Greece' },
  'GL': { name: 'Greenland' },
  'GD': { name: 'Grenada' },
  'GP': { name: 'Guadeloupe' },
  'GU': { name: 'Guam' },
  'GT': { name: 'Guatemala' },
  'GG': { name: 'Guernsey' },
  'GN': { name: 'Guinea' },
  'GW': { name: 'Guinea-Bissau', color: riskLevelColor.ORANGE },
  'GY': { name: 'Guyana' },
  'HT': { name: 'Haiti' },
  'HM': { name: 'Heard Island and McDonald Islands' },
  'VA': { name: 'Holy See (Vatican City State)' },
  'HN': { name: 'Honduras' },
  'HK': { name: 'Hong Kong' },
  'HU': { name: 'Hungary' },
  'IS': { name: 'Iceland' },
  'IN': { name: 'India', color: riskLevelColor.RED },
  'ID': { name: 'Indonesia', color: riskLevelColor.RED },
  'IR': { name: 'Iran', color: riskLevelColor.RED },
  'IQ': { name: 'Iraq', color: riskLevelColor.ORANGE },
  'IE': { name: 'Ireland' },
  'IM': { name: 'Isle of Man' },
  'IL': { name: 'Israel' },
  'IT': { name: 'Italy' },
  'JM': { name: 'Jamaica' },
  'JP': { name: 'Japan' },
  'JE': { name: 'Jersey' },
  'JO': { name: 'Jordan', color: riskLevelColor.RED },
  'KZ': { name: 'Kazakhstan' },
  'KE': { name: 'Kenya' },
  'KI': { name: 'Kiribati' },
  'KP': { name: 'North Korea', color: riskLevelColor.RED },
  'KR': { name: 'South Korea' },
  'KW': { name: 'Kuwait' },
  'KG': { name: 'Kyrgyzstan', color: riskLevelColor.RED },
  'LA': { name: 'Laos' },
  'LV': { name: 'Latvia' },
  'LB': { name: 'Lebanon', color: riskLevelColor.ORANGE },
  'LS': { name: 'Lesotho' },
  'LR': { name: 'Liberia' },
  'LY': { name: 'Libya', color: riskLevelColor.ORANGE },
  'LI': { name: 'Liechtenstein' },
  'LT': { name: 'Lithuania' },
  'LU': { name: 'Luxembourg' },
  'MO': { name: 'Macao' },
  'MK': { name: 'Macedonia (FYROM)' },
  'MG': { name: 'Madagascar' },
  'MW': { name: 'Malawi' },
  'MY': { name: 'Malaysia' },
  'MV': { name: 'Maldives' },
  'ML': { name: 'Mali' },
  'MT': { name: 'Malta' },
  'MH': { name: 'Marshall Islands' },
  'MQ': { name: 'Martinique' },
  'MR': { name: 'Mauritania' },
  'MU': { name: 'Mauritius' },
  'YT': { name: 'Mayotte' },
  'MX': { name: 'Mexico' },
  'FM': { name: 'Micronesia, Federated States of' },
  'MD': { name: 'Moldova, Republic of' },
  'MC': { name: 'Monaco' },
  'MN': { name: 'Mongolia' },
  'ME': { name: 'Montenegro' },
  'MS': { name: 'Montserrat' },
  'MA': { name: 'Morocco', color: riskLevelColor.RED },
  'MZ': { name: 'Mozambique' },
  'MM': { name: 'Myanmar (formerly Burma)' },
  'NA': { name: 'Namibia' },
  'NR': { name: 'Nauru' },
  'NP': { name: 'Nepal', color: riskLevelColor.RED },
  'NL': { name: 'Netherlands' },
  '--': { ame: 'Netherlands Antilles' },
  'NC': { name: 'New Caledonia' },
  'NZ': { name: 'New Zealand' },
  'NI': { name: 'Nicaragua' },
  'NE': { name: 'Niger' },
  'NG': { name: 'Nigeria' },
  'NU': { name: 'Niue' },
  'NF': { name: 'Norfolk Island' },
  'MP': { name: 'Northern Mariana Islands' },
  'NO': { name: 'Norway' },
  'OM': { name: 'Oman' },
  'PK': { name: 'Pakistan', color: riskLevelColor.RED },
  'PW': { name: 'Palau' },
  'PS': { name: 'Palestine State' },
  'PA': { name: 'Panama', color: riskLevelColor.ORANGE },
  'PG': { name: 'Papua New Guinea' },
  'PY': { name: 'Paraguay' },
  'PE': { name: 'Peru' },
  'PH': { name: 'Philippines' },
  'PN': { name: 'Pitcairn' },
  'PL': { name: 'Poland' },
  'PT': { name: 'Portugal' },
  'PR': { name: 'Puerto Rico' },
  'QA': { name: 'Qatar' },
  'RE': { name: 'Reunion' },
  'RO': { name: 'Romania' },
  'RU': { name: 'Russian Federation' },
  'RW': { name: 'Rwanda' },
  'BL': { name: 'Saint Barthélemy' },
  'SH': { name: 'Saint Helena' },
  'KN': { name: 'Saint Kitts and Nevis' },
  'LC': { name: 'Saint Lucia' },
  'MF': { name: 'Saint Martin (French part)' },
  'PM': { name: 'Saint Pierre and Miquelon' },
  'VC': { name: 'Saint Vincent and the Grenadines' },
  'WS': { name: 'Samoa' },
  'SM': { name: 'San Marino' },
  'ST': { name: 'Sao Tome and Principe' },
  'SA': { name: 'Saudi Arabia', color: riskLevelColor.RED },
  'SN': { name: 'Senegal' },
  'RS': { name: 'Serbia' },
  'SC': { name: 'Seychelles' },
  'SL': { name: 'Sierra Leone' },
  'SG': { name: 'Singapore' },
  'SX': { name: 'Saint Maarten (Dutch part)' },
  'SK': { name: 'Slovakia' },
  'SI': { name: 'Slovenia' },
  'SB': { name: 'Solomon Islands' },
  'SO': { name: 'Somalia', color: riskLevelColor.ORANGE },
  'ZA': { name: 'South Africa' },
  'GS': { name: 'South Georgia and the South Sandwich Islands' },
  'SS': { name: 'South Sudan', color: riskLevelColor.ORANGE },
  'ES': { name: 'Spain' },
  'LK': { name: 'Sri Lanka', color: riskLevelColor.ORANGE },
  'SD': { name: 'Sudan' },
  'SR': { name: 'Suriname' },
  'SJ': { name: 'Svalbard and Jan Mayen' },
  'SZ': { name: 'Swaziland' },
  'SE': { name: 'Sweden' },
  'CH': { name: 'Switzerland' },
  'SY': { name: 'Syria', color: riskLevelColor.ORANGE },
  'TW': { name: 'Taiwan', color: riskLevelColor.RED },
  'TJ': { name: 'Tajikistan' },
  'TZ': { name: 'Tanzania, United Republic of' },
  'TH': { name: 'Thailand', color: riskLevelColor.RED },
  'TL': { name: 'Timor-Leste' },
  'TG': { name: 'Togo', color: riskLevelColor.GOLD },
  'TK': { name: 'Tokelau' },
  'TO': { name: 'Tonga' },
  'TT': { name: 'Trinidad and Tobago', color: riskLevelColor.ORANGE },
  'TN': { name: 'Tunisia', color: riskLevelColor.ORANGE },
  'TR': { name: 'Turkey' },
  'TM': { name: 'Turkmenistan' },
  'TC': { name: 'Turks and Caicos' },
  'TV': { name: 'Tuvalu' },
  'UG': { name: 'Uganda' },
  'UA': { name: 'Ukraine' },
  'AE': { name: 'United Arab Emirates' },
  'GB': { name: 'United Kingdom' },
  'US': { name: 'United States' },
  'UM': { name: 'United States Minor Outlying Islands' },
  'UY': { name: 'Uruguay' },
  'UZ': { name: 'Uzbekistan' },
  'VU': { name: 'Vanuatu' },
  'VE': { name: 'Venezuela', color: riskLevelColor.ORANGE },
  'VN': { name: 'Vietnam', color: riskLevelColor.RED },
  'VG': { name: 'Virgin Islands, British' },
  'VI': { name: 'Virgin Islands, U.S.' },
  'WF': { name: 'Wallis and Futuna' },
  'EH': { name: 'Western Sahara' },
  'YE': { name: 'Yemen', color: riskLevelColor.ORANGE },
  'ZM': { name: 'Zambia' },
  'ZW': { name: 'Zimbabwe' },
} as const;
