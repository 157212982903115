import type { RowData } from '@arringo-npm/fe-table';
import Tooltip from 'src/components/Tooltip';
import { countryCodes } from 'src/config/countries';
import type { CellProps } from '..';

const CountryCode = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  return (
    <Tooltip content={countryCodes[value]?.name || ''}>
      <p>{value}</p>
    </Tooltip>
  );
};

export default CountryCode;
