import type { ReactNode } from 'react';
import { Suspense } from 'react';
import AffiliateNotification from 'src/components/AffiliateNotification';
import FullsizeLoader from 'src/components/Design/FullsizeLoader';
import { getUserRole } from 'src/utils/helpers/auth';
import { isAdmin } from 'src/utils/helpers/roles';
import SumSubNotification from 'src/features/SumSubKyc/components/SumSubNotification';
import Footer from 'src/features/App/components/Footer';
import Header from 'src/features/App/components/Header';
import Sidebar from 'src/features/App/components/Sidebar';
import { MobileLayout } from 'src/features/App/components/MobileLayout';
import { CreditCardExpiryAlert } from 'src/features/CreditCardExpiry';
import UrgentAlert from 'src/components/UrgentMessage/UrgentAlert';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { twMerge } from 'tailwind-merge';

const AppWrapper = ({ children }: { children: ReactNode }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const role = getUserRole();
  const { pathname } = useLocation();

  const isNotAdmin = !isAdmin(role);
  const rolesToDisplayUrgentAlert: ReturnType<typeof getUserRole>[] = [
    'ADMIN',
    'CLIENT',
  ];
  const shouldShowUrgentAlert =
    rolesToDisplayUrgentAlert.includes(role) &&
    // kyc and deposit page has their own urgent alert
    !['/client/kyc', '/client/deposit'].includes(pathname);

  const content = (
    <div className="relative flex flex-1 flex-col bg-white lg:bg-gray-100">
      <Header
        onClickBellIcon={() => console.log('bell')}
        onClickProfilePicture={() => console.log('profile')}
      />

      <div className="relative flex h-full flex-1">
        <Sidebar />
        <main
          className={twMerge(
            'flex h-full w-1 flex-1 flex-col   focus:outline-none',
            isNotAdmin &&
              'max-h-[calc(100vh-64px)] min-h-[calc(100vh-64px)] overflow-y-auto'
          )}
        >
          <Suspense fallback={<FullsizeLoader />}>
            <div className="grid flex-none gap-4 px-5 py-6 md:px-10">
              <SumSubNotification />
              {shouldShowUrgentAlert && <UrgentAlert />}
              <CreditCardExpiryAlert />
              <AffiliateNotification />
            </div>
            <div className="flex-1">{children}</div>
            <div className="flex-none">{isNotAdmin && <Footer />}</div>
          </Suspense>
        </main>
      </div>
    </div>
  );

  return (
    <>
      {isTabletOrMobile ? (
        <div className="flex flex-1 lg:hidden">
          <MobileLayout>{content}</MobileLayout>
        </div>
      ) : (
        <div className="hidden lg:flex lg:flex-1">{content}</div>
      )}
    </>
  );
};

export default AppWrapper;
