import type { GlobalEndpointTypes } from 'src/api/@types/endpoints';
import { customFlat } from 'src/utils/helpers/datatypes/array';

export const flattenPermissions = (
  permissions: Partial<GlobalEndpointTypes>
) => {
  return permissions ? customFlat(Object.values(permissions)) : [];
};

const castToNumber = (obj: Record<string, string | number>, keys: string[]) => {
  const entries = Object.entries(obj);
  const castedEntries = entries.map(([k, v]) => {
    if (keys.includes(k)) {
      return [k, Number(v)];
    }
    return [k, v];
  });

  return Object.fromEntries(castedEntries);
};

export const prepareTableQuery = (rawData, numericKeys: string[]) => {
  const data: {
    filter?: Record<string, string | number | boolean>;
    pagination?: Record<string, number>;
  } = {};
  if (rawData.filter) {
    data.filter = castToNumber(rawData.filter, numericKeys);
  }
  if (rawData.pagination) {
    data.pagination = rawData.pagination;
  }

  return data;
};
