import type { Languages } from 'src/assets/translations/types';
import ItalianFlag from 'src/assets/images/countries/italian.png';
import EnglishFlag from 'src/assets/images/countries/english.png';
import SpanishFlag from 'src/assets/images/countries/spanish.png';

export type LanguagePickerCountries = {
  flag: string;
  name: 'Italian' | 'English' | 'Spanish';
  value: Languages;
};

export const countries: {
  [key in Languages]: LanguagePickerCountries;
} = {
  en: {
    name: 'English',
    flag: EnglishFlag,
    value: 'en',
  },
  it: {
    name: 'Italian',
    flag: ItalianFlag,
    value: 'it',
  },
  es: {
    name: 'Spanish',
    flag: SpanishFlag,
    value: 'es',
  },
};
