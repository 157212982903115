import { useMemo } from 'react';
import { getPaginationDisplayedRange } from 'src/components/Table/utils/pagination';

type UsePaginationNavItemsParams = {
  currentPage: number;
  pageRangeDisplayed: number;
  pagesCount: number;
};

const usePaginationNavItems = ({
  currentPage,
  pagesCount,
  pageRangeDisplayed,
}: UsePaginationNavItemsParams) => {
  const paginationItems = useMemo(() => {
    const pageNumbers: number[] = [1];

    const { start, end } = getPaginationDisplayedRange({
      pagesCount,
      currentPage,
      range: pageRangeDisplayed,
    });

    if (pagesCount > 1) {
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(pagesCount);
    }

    return pageNumbers;
  }, [currentPage, pageRangeDisplayed, pagesCount]);

  return paginationItems;
};

export default usePaginationNavItems;
