import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { GetUserDataResponse } from 'src/@types/api';
import type { SafeOmit } from 'src/@types/utilities/SafeOmit';
import { apiGetPendingWithdrawals } from './api';
import type { ISurveyContext } from './useSurvey';
import { useSurvey } from './useSurvey';
import { logSentry } from 'src/utils/helpers/logging';
import useSWR from 'swr';
import { authCheck, getUserRole } from 'src/utils/helpers/auth';
import { callLoadAffiliate, callLoadUser } from 'src/api';
import usePermissions from 'src/hooks/usePermissions';
import { transformAffiliate } from 'src/utils/helpers';
import UserStatusContext from 'src/contexts/UserStatusContext';
import useCookie from 'src/hooks/useCookie';

type User = Partial<
  SafeOmit<GetUserDataResponse, 'status'> & {
    flowType: string;
  }
>;

export type CryptoStat = {
  availableBalance: number;
  balance: number;
  symbol: string;
};

export type IContext = {
  affiliateId: number;
  getUserPendingWithdrawals?: () => Promise<number>;
  isVerified: boolean;
  pendingWithdrawalsCount: number;
  refreshUserContextUpdatableData?: () => void;
  setTempJwt: (token: string) => void;
  setUser: Dispatch<SetStateAction<User>>;
  surveyContext?: ISurveyContext;
  user: User;
  userId: number;
  userStatus: string;
  wipeContext?: () => void;
};

const defaultUser: User = {
  userAccountInfo: {} as User['userAccountInfo'],
  userAddressInfo: {} as User['userAddressInfo'],
  userPersonalInfo: {} as User['userPersonalInfo'],
};

// TODO: Move defaultValue into the context and fill it from initialized states.
const defaultValue: IContext = {
  isVerified: false,
  setTempJwt: () => ({}),
  setUser: () => ({}),
  userId: undefined,
  affiliateId: undefined,
  userStatus: '',
  user: {},
  pendingWithdrawalsCount: 0,
};

export const UserContext = createContext<IContext>(defaultValue);
const UserProvider = UserContext.Provider;

const UserContextWrapper = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const [user, setUser] = useState<User>(defaultUser);
  const [pendingWithdrawalsCount, setPendingWithdrawalsCount] = useState(0);
  const { setPermissions } = usePermissions();
  const { isLoading, userStatus } = useContext(UserStatusContext);
  const { set: setCookie } = useCookie();

  const isVerified = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return userStatus === 'ACTIVE';
  }, [userStatus, isLoading]);

  useSWR(
    () =>
      authCheck() && getUserRole() !== 'AFFILIATE' ? 'USER_GET_DATA' : null,
    () => {
      return callLoadUser().then((response) => response.data);
    },
    {
      onSuccess: (data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setPermissions(data.permissions);
      },
      revalidateOnFocus: false,
    }
  );

  useSWR(
    () =>
      authCheck() && getUserRole() === 'AFFILIATE' ? 'GET_AFFILIATE' : null,
    () => {
      return callLoadAffiliate().then((response) =>
        transformAffiliate(response.data)
      );
    },
    {
      onSuccess: (data) => {
        // TODO: Fix the type of the data, Why Affiliate Context is the same as User Context when they have different data?
        setUser((prev) => ({ ...prev, ...(data as unknown as User) }));
        setPermissions(data.permissions);
      },
      revalidateOnFocus: false,
    }
  );

  const wipeContext = useCallback(() => {
    setUser(defaultUser);
  }, []);

  const userId = user?.userAccountInfo?.id;
  const userRole = user?.userAccountInfo?.roleType;

  const surveyContext = useSurvey(userId, userRole);

  const getUserPendingWithdrawals = useCallback(async () => {
    try {
      if (userRole !== 'CLIENT') return 0;
      const {
        data: { count },
      } = await apiGetPendingWithdrawals();
      setPendingWithdrawalsCount(count);
      return count;
    } catch (error) {
      logSentry(error, { functionName: 'getUserPendingWithdrawals' });
      setPendingWithdrawalsCount(0);
      return 0;
    }
  }, [userRole]);

  useEffect(() => {
    getUserPendingWithdrawals();
  }, [getUserPendingWithdrawals]);

  useEffect(() => {
    const {
      userAccountInfo: { roleType },
    } = user;
    if (roleType !== 'CLIENT' || !userId) return;
  }, [user, userId]);

  const setTempJwt = useCallback(
    (token: string) => {
      setCookie('tempJwt', token);
    },
    [setCookie]
  );

  return (
    <UserProvider
      value={{
        isVerified,
        setTempJwt,
        setUser,
        userId:
          user && user.userAccountInfo ? user.userAccountInfo.id : undefined,
        affiliateId:
          user && user.userAccountInfo
            ? user.userAccountInfo.affiliateId
            : undefined,
        userStatus:
          user && user.userAccountInfo
            ? user.userAccountInfo.status
            : undefined,
        user: user,
        surveyContext,
        pendingWithdrawalsCount,
        getUserPendingWithdrawals,
        wipeContext,
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContextWrapper;
