import type { Filter } from '..';
import { useEffect, useState } from 'react';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';

const ID_REGEX = /^[0-9]{0,10}$/;

const IdFilter: Filter = ({ column }) => {
  const [error, setError] = useState(false);
  const filterValue = (column.getFilterValue() || '') as string;
  const [value, setValue] = useState(filterValue);

  useEffect(() => {
    if (filterValue === '') {
      setError(false);
    }
    setValue(filterValue);
  }, [filterValue]);

  const handleChangeValue = (updatedValue: string) => {
    setValue(updatedValue);
    if (ID_REGEX.test(updatedValue) || updatedValue === '') {
      setError(false);
      column.setFilterValue(updatedValue);
    } else {
      setError(true);
    }
  };

  return (
    <TextInputFilter
      value={value}
      onChange={handleChangeValue}
      type="text"
      autoComplete="off"
      pattern="^[0-9]{0,10}$"
      title="ID consists only of numbers"
      error={error && 'Please enter a number'}
    />
  );
};

export default IdFilter;
