import { Link } from 'react-router-dom';
import styles from './Indicator.module.css';

type Props = {
  id?: number;
  partyName?: string;
  transactionId?: string;
  type?: string;
};

const PartyIndicator = ({ type, id, transactionId, partyName }: Props) => {
  if (!type) {
    return <>N/A</>;
  }

  if (type === 'USER' && id) {
    return (
      <Link
        className={styles.reference}
        target="_blank"
        to={`/admin/clients/${id}`}
      >
        {partyName || `USER ${id}`}
      </Link>
    );
  }

  if (type === 'TRANSACTION' && transactionId) {
    return (
      <Link
        className={styles.reference}
        target="_blank"
        to={`/admin/transactions/${transactionId}`}
      >
        TRANSACTION {transactionId} {id && `- ${id}`}
      </Link>
    );
  }

  return (
    <span className={styles.indicator}>
      {type} {partyName || id}
    </span>
  );
};

export default PartyIndicator;
