import AppWrapper from 'src/features/App/components/AppWrapper';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const AuthorizedApp = ({ children }: Props) => {
  return <AppWrapper>{children}</AppWrapper>;
};
