type Props = React.SVGProps<SVGSVGElement>;

const DecashIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.79443 15.4088L8.96636 3.32667L12.8281 9.60937L7.3112 20H9.2421L10.6213 17.1003H19.7242C20 17.1003 20 16.617 20 16.617L19.4483 15.4088L16.414 9.60937L14.759 12.2674L16.9657 16.1337L11.173 16.1337L17.5174 4.77651H15.8623L14.759 6.70965C13.9314 4.93761 12.0557 1.20021 11.173 0.426955C10.2903 -0.346302 9.33412 0.104765 8.96636 0.426955C6.29986 4.69597 0.801355 13.669 0.139331 15.4088C-0.522694 17.1486 1.33468 17.1003 2.34612 17.1003H7.86295V16.1337H2.34612C1.6841 16.1337 1.70249 15.6504 1.79443 15.4088Z" />
    </svg>
  );
};

export default DecashIcon;
