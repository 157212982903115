import { APITypes, APIUrls } from 'src/@enums/api';
import { instance } from 'src/api/instance';
import { addToken } from 'src/utils';
import type { ChangeLanguageResponse } from 'src/@types/api';
import type { UserChangeLanguageRequest } from 'src/@types/requests/user';

const URL_LANGUAGE_LIST = APIUrls.USER;

export const apiChangeUserLanguage = (language: string) => {
  return instance.post<ChangeLanguageResponse>(
    URL_LANGUAGE_LIST,
    addToken({
      type: APITypes.USER_CHANGE_LANG,
      data: {
        userAccountInfo: { language },
      },
    }) as UserChangeLanguageRequest,
    {
      requestSchema: 'UserChangeLanguageRequest',
      responseSchema: 'ChangeLanguageResponse',
    }
  );
};
