import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';
import Tooltip from 'src/components/Tooltip';

const TextWithTooltip = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  return (
    <Tooltip content={<>{value}</>}>
      <span className="block truncate">{value}</span>
    </Tooltip>
  );
};

export default TextWithTooltip;
