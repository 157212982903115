import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import { createContext, useCallback, useState } from 'react';

type Props = {
  children: ReactNode;
};

type Context = {
  get: (key: string) => string | null;
  remove: (key: string) => void;
  set: (key: string, value: string) => void;
};

const CookieContext = createContext<Context | undefined>(undefined);

export const CookieContextWrapper = ({ children }: Props) => {
  const [cookies, setCookies] = useState(
    Cookies.get() as { [p: string]: string | null }
  );

  const get = useCallback<Context['get']>(
    (value) => cookies[value] ?? null,
    [cookies]
  );

  const set = useCallback<Context['set']>((key: string, value: string) => {
    Cookies.set(key, value);
    setCookies(() => Cookies.get());
  }, []);

  const remove = useCallback<Context['remove']>((key) => {
    Cookies.remove(key);
    setCookies(() => Cookies.get());
  }, []);

  return (
    <CookieContext.Provider value={{ get, set, remove }}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContext;
