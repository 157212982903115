import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';
import { documentStatusClassName } from 'src/utils/constants/documents';

const DocumentStatus = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  return <span className={documentStatusClassName[value]}>{value}</span>;
};

export default DocumentStatus;
