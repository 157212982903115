import type { ReactNode } from 'react';
import { createContext, useCallback, useState } from 'react';
import Loader from 'src/components/Loader';

type ILoading = {
  isLoading: (payload?: string) => void;
  load: boolean;
  loaded: () => void;
  loadingText: string;
  whichComponentLoading: { isCustomText: boolean; text: string };
  whichComponentLoadingChange: (path: string) => Promise<void>;
};

const defaultData: ILoading = {
  load: true,
  whichComponentLoading: {
    text: '',
    isCustomText: false,
  },
  loadingText: '',
  loaded: () => undefined,
  isLoading: () => undefined,
  whichComponentLoadingChange: async () => undefined,
};

export const LoadingContext = createContext<ILoading>(defaultData);

type Props = {
  children: ReactNode;
  name: string;
};

const LoadingContextWrapper = ({ children, name }: Props) => {
  const [state, setState] = useState(defaultData);

  const loaded = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      load: false,
      whichComponentLoading: { text: '', isCustomText: false },
    }));
  }, []);

  const isLoading = useCallback((payload?: string) => {
    if (payload) {
      setState((prevState) => ({
        ...prevState,
        load: true,
        loadingText: payload,
        whichComponentLoading: { text: payload, isCustomText: true },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        load: true,
      }));
    }
  }, []);

  const whichComponentLoadingAction = useCallback((payload) => {
    setState((prevState) => ({
      ...prevState,
      whichComponentLoading: { text: payload, isCustomText: false },
    }));
  }, []);

  const whichComponentLoadingChange = useCallback(async (path: string) => {
    const { loadingText } = state;
    if (!loadingText) {
      whichComponentLoadingAction(path);
    } else {
      whichComponentLoadingAction(loadingText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { Provider } = LoadingContext;

  const value = {
    ...state,
    loaded,
    isLoading,
    whichComponentLoadingChange,
  };

  return (
    <Provider value={value}>
      <LoadingContext.Consumer>
        {({ load, whichComponentLoading }) => {
          return (
            <Loader
              loading={load}
              name={name}
              whichPageLoading={whichComponentLoading}
            >
              {children}
            </Loader>
          );
        }}
      </LoadingContext.Consumer>
    </Provider>
  );
};

export default LoadingContextWrapper;
