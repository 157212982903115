import type { Ref } from 'react';
import { forwardRef } from 'react';
import { commonInputsStyles } from 'src/components/Inputs/Styles';
import Wrapper from 'src/components/Inputs/Wrapper';
import type { TextInputSpaces, TextInputProps } from './@types';
import styles from './TextInput.module.css';
import classNames from 'classnames';

const textInputSpaces: Record<TextInputSpaces, string> = {
  base: styles.baseSpaces,
  moreLeft: styles.moreLeftSpaces,
  moreRight: styles.moreRightSpaces,
};

const TextInput = (
  {
    label,
    variant = 'hero',
    name,
    disabled = false,
    className = '',
    addonBefore,
    headerNode,
    hideErrorIcon,
    truncateMessages,
    addonAfter,
    wrapperClassName = '',
    errorClassName = '',
    inputWrapperClassName = '',
    error,
    hint,
    inlineLabel,
    unit,
    required,
    ...rest
  }: TextInputProps,
  ref: Ref<HTMLInputElement>
): React.ReactElement => {
  const classes = classNames(
    className,
    commonInputsStyles({
      error,
      variant,
    }),
    textInputSpaces.base,
    {
      [textInputSpaces.moreLeft]: Boolean(addonBefore),
      [textInputSpaces.moreRight]: Boolean(addonAfter),
    }
  );

  const inputWrapperClasses = classNames(
    styles.inputWrapper,
    inputWrapperClassName,
    {
      [styles.inputWrapperUnit]: Boolean(unit),
    }
  );

  const unitClasses = classNames(styles.unit, {
    [styles.unitError]: Boolean(error),
  });

  return (
    <Wrapper
      name={name}
      label={label}
      disabled={disabled}
      wrapperClassName={wrapperClassName}
      errorClassName={errorClassName}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      truncateMessages={truncateMessages}
      error={error}
      headerNode={headerNode}
      hideErrorIcon={hideErrorIcon || Boolean(unit)}
      hint={hint}
      inlineLabel={inlineLabel}
      required={required}
    >
      <div className={inputWrapperClasses}>
        <input
          id={name}
          name={name}
          ref={ref}
          className={classes}
          disabled={disabled}
          aria-describedby={`${name}-description`}
          {...rest}
        />
        {Boolean(unit) && <div className={unitClasses}>{unit}</div>}
      </div>
    </Wrapper>
  );
};

export default forwardRef(TextInput);
