import Marquee from 'react-fast-marquee';
import { useUrgentMessage } from 'src/hooks/useUrgentMessage';

const UrgentAlert = () => {
  const { isLoading, urgentMessages } = useUrgentMessage();
  if (isLoading) return null;
  if (!urgentMessages?.length) return null;
  return (
    <div className="py-2">
      <Marquee
        speed={100}
        gradient={false}
        className="rounded-lg bg-red-200 p-2 text-red-600"
      >
        {urgentMessages.map((urgentMessage) => (
          <span key={urgentMessage.id} className="mr-10">
            {urgentMessage.messageText}
          </span>
        ))}
      </Marquee>
    </div>
  );
};
export default UrgentAlert;
