import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { Affiliate } from 'src/@types/api';
import { UserContext } from 'src/contexts/UserContext';
import { getUserRole } from 'src/utils/helpers/auth';
import Alert from 'src/components/Alert';

const AffiliateNotification = () => {
  const role = getUserRole();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const isAffiliate = role === 'AFFILIATE';

  const showNotification =
    isAffiliate &&
    (user?.userAccountInfo as unknown as Affiliate)?.status === 'DISABLED';

  if (!showNotification) {
    return null;
  }

  return (
    <Alert type="danger">
      {t('global:affiliate.texts.disabledNotification')}
    </Alert>
  );
};

export default AffiliateNotification;
