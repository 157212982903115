import type { SVGProps } from 'react';
import React, { createElement } from 'react';

export type MobileTabProps = (
  | (React.ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' })
  | (React.AnchorHTMLAttributes<HTMLAnchorElement> & { as: 'a' })
) & {
  icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  selected?: boolean;
  selectedIcon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  title: string;
};

export function MobileTab({
  title,
  icon,
  selected = false,
  selectedIcon = icon,
  as = 'button',
  ...rest
}: MobileTabProps) {
  return createElement(
    as,
    {
      className: `flex flex-1 flex-col justify-between items-center py-1 text-xs space-y-2 cursor-pointer ${
        selected
          ? `${
              selectedIcon === icon ? 'text-brand' : 'text-red-600'
            } bg-blue-10`
          : 'bg-brand text-white'
      }`,
      ...rest,
    },
    <>
      <span className="flex flex-1 items-center justify-center">
        {createElement(selected ? selectedIcon : icon, {
          className: 'h-6',
        })}
      </span>
      <span className="text-xs">{title}</span>
    </>
  );
}
