import { useCallback, useContext, useEffect } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { apiChangeUserLanguage } from './api';
import translationConfigs from 'src/config/features/translation';
import { setToken } from 'src/utils';

export const useLanguageWatcher = () => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const userPreferredLanguage = user?.userAccountInfo?.language;
  const userId = user?.userAccountInfo?.id;

  // Sets current language to user's preferred one.
  useEffect(() => {
    if (translationConfigs.isDisabled) return;

    if (
      userPreferredLanguage &&
      userPreferredLanguage !== '' &&
      userPreferredLanguage !== i18n.language
    )
      i18n.changeLanguage(userPreferredLanguage);
  }, [i18n, userPreferredLanguage]);

  /**
   * Notifies selected language to server and cares about changing JWT
   */

  const updateUserPreferredLanguage = useCallback(
    (currentLanguage: string | undefined, newLanguage: string) => {
      if (!userId) return;
      if (currentLanguage === newLanguage) return;

      apiChangeUserLanguage(newLanguage)
        .then((res) => {
          const jwt = res.data.jwt;
          if (typeof jwt === 'string' && jwt.length > 10) {
            setToken(jwt);
          }
          // There are users (admins, agents, superadmins, etc.) with no currentLanaguage,
          // without the following condition, they will stuck in a refresh loop.
          if (currentLanguage) window?.location.reload();
        })
        .catch(() => {
          console.warn('Cannot change language to ', newLanguage);
        });
    },
    [userId]
  );

  /**
   * When translation feature has been disabled,
   * it updates user's preferred language with the determined forced language.
   */
  useEffect(() => {
    const forcedLang = translationConfigs.fallbackLanguage;
    if (translationConfigs.isDisabled && userPreferredLanguage !== forcedLang) {
      updateUserPreferredLanguage(undefined, forcedLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationConfigs, updateUserPreferredLanguage, userPreferredLanguage]);

  return {
    updateUserPreferredLanguage,
  };
};
