import classNames from 'classnames';

type StatusProps = {
  label: string;
  value: string;
};

const Status = ({ label, value }: StatusProps) => {
  const classes = {
    finished: 'text-green-500',
    pending: 'text-yellow-500',
    cancelled: 'text-red-500',
    refund_approved: 'text-blue-500',
    refund_processed: 'text-green-500',
    refund_requested: 'text-yellow-500',
    refund_rejected: 'text-red-500',
    completed: 'text-green-500',
    failed: 'text-red-500',
    approved: 'text-green-500',
  };

  return (
    <span
      className={classNames(
        'font-bold',
        classes[value?.toLowerCase()] ?? 'text-gray-500'
      )}
    >
      {label ?? value}
    </span>
  );
};

export default Status;
