import type { RowData } from '@arringo-npm/fe-table';
import { formatRelative } from '@arringo-npm/utility-package';
import { serverToDate } from 'src/utils/dates/format';
import type { CellProps } from '..';

const RelativeDatetime = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();
  if (!value) return null;

  const isoString = serverToDate(value);
  if (!isoString) {
    return <>-</>;
  }

  return <span>{formatRelative(new Date(isoString), new Date())}</span>;
};

export default RelativeDatetime;
