import { callGetUrgentMessage } from 'src/api/client';
import useSWR from 'swr';
import { APITypes, APIUrls } from 'src/@enums/api';

export type UrgentMessage = {
  creationTimestamp: string;
  creatorId: number;
  expirationTimestamp: string;
  id: number;
  lastUpdateTimestamp: string;
  messageText: string;
};

export const useUrgentMessage = () => {
  const { data, error, mutate } = useSWR(
    APIUrls.URGENT_MESSAGES + APITypes.URGENT_MESSAGE_GET,
    callGetUrgentMessage,
    {
      refreshInterval: 1000 * 60 * 5,
    }
  );

  return {
    urgentMessages: (data?.data?.urgentMessages || []) as UrgentMessage[],
    error,
    mutate,
    isLoading: !error && !data,
  };
};
