import FullsizeLoader from 'src/components/Design/FullsizeLoader';
import FacebookPixelComponent from 'src/components/FacebookPixelComponent';
import LiveChat from 'src/components/LiveChat';
import AppWrapper from 'src/features/App/components/AppWrapper';
import { AppProvider } from 'src/features/App/context/AppContext';
import { useAuthorizedCommon } from 'src/features/AuthorizedCommon/hooks/useAuthorizedCommon';
import RoutesContainer from 'src/routes/Routers';
import { affiliateProtectedRoutes } from 'src/routes/routingObjects/affiliateProtectedRoutes';

const AffiliateRouter = () => {
  const { isPermissionsLoaded } = useAuthorizedCommon();

  if (!isPermissionsLoaded) return <FullsizeLoader />;

  return (
    <AppProvider>
      <AppWrapper>
        <RoutesContainer
          routingObjects={affiliateProtectedRoutes}
          defaultPath="/affiliate/summary"
        />
        <LiveChat />
        <FacebookPixelComponent />
      </AppWrapper>
    </AppProvider>
  );
};

export default AffiliateRouter;
