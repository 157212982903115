type PaginationDisplayedRange = {
  end: number;
  start: number;
};

export const getPaginationDisplayedRange = ({
  pagesCount,
  currentPage,
  range,
}: {
  currentPage: number;
  pagesCount: number;
  range: number;
}): PaginationDisplayedRange => {
  if (currentPage < range) {
    return {
      start: Math.min(2, pagesCount),
      end: Math.min(pagesCount - 1, range),
    };
  } else if (currentPage > pagesCount - range + 1) {
    return { start: pagesCount - range + 1, end: pagesCount - 1 };
  }

  const halfRange = Math.floor(range / 2);

  return {
    start: currentPage - halfRange,
    end: currentPage + halfRange,
  };
};
