import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseMenuItem } from 'src/@types/MenuItem';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { translateWithFallback } from 'src/utils/translation';
import styles from 'src/components/Sidebar/Sidebar.module.css';
import Link from './CustomLink';

type Props = {
  menuItem: BaseMenuItem;
};

const ExpandableSidebarMenu = ({
  menuItem: { name, icon: LinkIcon, translationKey, items },
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = usePersistedState<boolean>({
    defaultValue: true,
    key: `Sidebar-${name?.replace(' ', '-')}`,
  });
  const { t } = useTranslation();

  const toggleTriggerClasses = classNames(
    styles.link,
    'appearance-none justify-between'
  );

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className={toggleTriggerClasses} onClick={handleToggleClick}>
        <div className="flex items-center space-x-2 font-medium">
          <div className={styles.linkIconWrapper}>
            <LinkIcon className={styles.linkIcon} aria-hidden="true" />
          </div>

          <span className={styles.linkLabel}>
            {translateWithFallback(t, 'global', translationKey, name)}
          </span>
        </div>

        {isOpen ? (
          <ChevronDownIcon width={16} height={16} />
        ) : (
          <ChevronRightIcon width={16} height={16} />
        )}
      </button>

      {isOpen && (
        <div className="rounded-md bg-blue-10 p-2">
          {items?.map((menuItem, index) => (
            <Link key={index} menuItem={menuItem} />
          ))}
        </div>
      )}
    </>
  );
};

const SidebarMenuItem = ({ menuItem }: Props): JSX.Element => {
  const hasChildItems = !!menuItem?.items?.length;

  if (hasChildItems) {
    return <ExpandableSidebarMenu menuItem={menuItem} />;
  }

  return <Link menuItem={menuItem} />;
};

export default memo(SidebarMenuItem);
