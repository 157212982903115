import type { ReactNode } from 'react';
import { Component } from 'react';
import { ErrorFallback } from './ErrorFallback';
import { logSentry } from 'src/utils/helpers/logging';

type States = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Record<string, ReactNode>, States> {
  state: States = {
    hasError: false,
  };

  static getDerivedStateFromError(): States {
    return { hasError: true };
  }

  componentDidCatch(
    error: Error & { fingerprint: string },
    errorInfo: unknown
  ): void {
    error.fingerprint = `"RENDER" => ${error.name}`;
    logSentry(error, { ...(errorInfo as object), renderError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
