import { APITypes, APIUrls } from 'src/@enums/api';
import type { GetPendingWithdrawalsCountResponse } from 'src/@types/api';
import { instance } from 'src/api/instance';
import { addToken } from 'src/utils';

export const apiGetPendingWithdrawals = () =>
  instance.post<GetPendingWithdrawalsCountResponse>(
    APIUrls.USER_ACCOUNTING_DATA,
    addToken({
      type: APITypes.USER_ACCOUNT_WITHDRAWAL_AUTHORISABLE_COUNT,
    }),
    {
      requestSchema: 'GetPendingWithdrawalsCountRequest',
      responseSchema: 'GetPendingWithdrawalsCountResponse',
    }
  );
