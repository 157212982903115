import type {
  PartitionedRoutes,
  UnprotectedAffiliateRoute,
  UnprotectedRoute,
} from 'src/routes/types';
import deprecatedRoutes from './deprecatedRoutes';
import { lazy } from 'react';

/**
 * Unprotected Routes
 *
 * No authentication, No roles, No permissions required
 * Works for Clients and Admins
 */
export const unprotectedRoutes: PartitionedRoutes<UnprotectedRoute> = {
  ...deprecatedRoutes,
  '/client/emailing/unsubscribe&data=:token': {
    component: lazy(() => import('src/pages/EmailUnprotectedUnsubscribe')),
    name: 'Unsubscribe',
  },
  '/login': {
    component: lazy(() => import('src/pages/login')),
    name: 'Login',
  },
  '/register': {
    component: lazy(() => import('src/pages/register')),
    name: 'Signup',
  },
  '/authorize-2fa': {
    component: lazy(() => import('src/pages/authorize-2fa')),
    name: 'Authorization 2FA',
  },
  '/forgot-password': {
    component: lazy(() => import('src/pages/forgot-password')),
    name: 'Forgot Password',
  },
  '/reset-password': {
    component: lazy(() => import('src/pages/reset-password')),
    name: 'Reset Password',
  },
};
/**
 * Unprotected Affiliate Routes
 *
 * No authentication, No roles, No permissions required
 * Only works for Affiliates
 */
export const affiliateUnprotectedRoutes: PartitionedRoutes<UnprotectedAffiliateRoute> =
  {
    ...deprecatedRoutes,
    '/affiliate/login': {
      component: lazy(() => import('src/pages/affiliate/login')),
      name: 'Login as Affiliate',
    },
    '/affiliate/register': {
      component: lazy(() => import('src/pages/affiliate/register')),
      name: 'Register as Affiliate',
    },
    '/affiliate/forgot-password': {
      component: lazy(() => import('src/pages/affiliate/forgot-password')),
      name: 'Forgot Password',
    },
    '/affiliate/reset-password': {
      component: lazy(() => import('src/pages/affiliate/reset-password')),
      name: 'Reset Password',
    },
    '/affiliate/authorize-2fa': {
      component: lazy(() => import('src/pages/affiliate/authorize-2fa')),
      name: 'Authorization 2FA',
    },
  };
