import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSystemCapabilities from 'src/hooks/useSystemCapabilities';
import type { Filter } from '..';
import TwoFieldsInput from 'src/components/Table/filters/components/TwoFieldInput';
import { capitalizeFirstLetter } from 'src/utils/helpers/datatypes/string';

type CryptoRangeFilterValues = {
  from?: number | string;
  to?: number | string;
};

const CryptoRangeFilter: Filter = ({ column }) => {
  const { t } = useTranslation();
  const { convertToIntegral } = useSystemCapabilities();

  const columnFilterValue = column.getFilterValue() as CryptoRangeFilterValues;
  const filterValue = useMemo(
    () =>
      ({
        from: columnFilterValue?.from != null ? columnFilterValue.from : '',
        to: columnFilterValue?.to != null ? columnFilterValue.to : '',
      }) as CryptoRangeFilterValues,
    [columnFilterValue?.from, columnFilterValue?.to]
  );

  const handleChangeValue = (name: string, value: string) => {
    const newFilter = {
      from: filterValue.from !== '' ? filterValue.from : undefined,
      to: filterValue.to !== '' ? filterValue.to : undefined,
      [name]: value !== '' ? convertToIntegral(Number(value)) : undefined,
    };
    if (newFilter.from === undefined && newFilter.to === undefined) {
      column.setFilterValue(undefined);
    } else {
      column.setFilterValue(newFilter);
    }
  };

  return (
    <div className="flex flex-col gap-y-1">
      <TwoFieldsInput
        name="from"
        onChange={handleChangeValue}
        value={filterValue.from}
        placeholder={capitalizeFirstLetter(t('global:tables.from'))}
      />
      <TwoFieldsInput
        name="to"
        onChange={handleChangeValue}
        value={filterValue.to}
        placeholder={capitalizeFirstLetter(t('global:tables.to'))}
      />
    </div>
  );
};

export default CryptoRangeFilter;
