import { Fragment } from 'react';
import * as Pagination from './components';

import { usePaginationNavItems } from 'src/components/Table/hooks';

type PaginationNavProps = {
  breakLabel?: string;
  canGoToNextPage: boolean;
  canGoToPrevPage: boolean;
  currentPage: number;
  onClickNext: () => void;
  onClickPrev: () => void;
  onGoToPage: (page: number) => void;
  pageRangeDisplayed?: number;
  pagesCount: number;
};

const PaginationNav = ({
  canGoToPrevPage,
  canGoToNextPage,
  pagesCount,
  breakLabel = '...',
  pageRangeDisplayed = 5,
  currentPage,
  onClickPrev,
  onClickNext,
  onGoToPage,
}: PaginationNavProps) => {
  const paginationItems = usePaginationNavItems({
    currentPage,
    pagesCount,
    pageRangeDisplayed,
  });

  return (
    <Pagination.Wrapper>
      <Pagination.Prev disabled={!canGoToPrevPage} onClick={onClickPrev} />
      {paginationItems.map((paginationItem, index) => {
        const showBreakLabel =
          Math.abs(paginationItem - paginationItems[index - 1]) > 1;
        return (
          <Fragment key={index}>
            {showBreakLabel && (
              <Pagination.NavItem>{breakLabel}</Pagination.NavItem>
            )}
            <Pagination.NavItem
              isActive={paginationItem === currentPage}
              onClick={() => onGoToPage(paginationItem)}
            >
              {paginationItem}
            </Pagination.NavItem>
          </Fragment>
        );
      })}
      <Pagination.Next disabled={!canGoToNextPage} onClick={onClickNext} />
    </Pagination.Wrapper>
  );
};

export default PaginationNav;
