import { callAdminSetDocOrCcAsFixed } from 'src/api/admin';
import Button from 'src/components/Button';
import { handleError } from 'src/utils/helpers/errors';
import { successToast } from 'src/utils/toast';
import type { CellProps } from '..';

type Props = {
  handleRefreshData: () => void;
};

const SetAsFixedAction = <
  TData extends { fixable: boolean; reviewId: number },
>({
  cell,
  handleRefreshData,
}: CellProps<TData, string> & Props) => {
  const { reviewId, fixable } = cell.row.original;

  const handleSetAsFixed = async () => {
    try {
      await callAdminSetDocOrCcAsFixed({
        id: reviewId,
      });
      successToast('Set as fixed successfully.');
      handleRefreshData();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      colorScheme="blue"
      size="small"
      disabled={!fixable}
      onClick={handleSetAsFixed}
    >
      Set as fixed
    </Button>
  );
};

export default SetAsFixedAction;
