import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type WrapperProps = {
  children: ReactNode;
};

export const Wrapper = ({ children }: WrapperProps) => {
  return (
    <nav
      className="flex -space-x-px rounded-lg shadow-sm"
      aria-label="Pagination"
    >
      {children}
    </nav>
  );
};

type ButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const Prev = ({ disabled, onClick }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      className="flex items-center justify-center rounded-l-lg py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 md:px-2.5"
      disabled={disabled}
      onClick={onClick}
    >
      <span className="sr-only">{t('global:common.pagination.previous')}</span>
      <ChevronLeftIcon width={20} height={20} />
    </button>
  );
};

export const Next = ({ disabled, onClick }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      className="flex items-center justify-center rounded-r-lg py-2  text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 md:px-2.5"
      disabled={disabled}
      onClick={onClick}
    >
      <span className="sr-only">{t('global:common.pagination.next')}</span>
      <ChevronRightIcon width={20} height={20} />
    </button>
  );
};

type NavItemProps = {
  children: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
};

export const NavItem = ({
  onClick,
  isActive = false,
  children,
}: NavItemProps) => {
  const isClickable = typeof onClick !== 'undefined';

  return (
    <button
      className={classNames(
        'flex items-center px-2 py-2 text-sm font-semibold ring-1 ring-inset focus:outline-offset-0 md:px-4',
        {
          'bg-blue-700 text-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600':
            isActive,
          'text-gray-900 ring-gray-300': !isActive,
          'cursor-default': !isClickable,
          'hover:bg-gray-50': !isActive && isClickable,
        }
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
