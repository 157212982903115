import { formatDate } from '@arringo-npm/utility-package';
import type { SelectOptionType } from 'src/@types/SelectType';
import { normalizePhoneNumber } from 'src/utils/helpers';

const baseInputs = [
  'city',
  'email',
  'firstName',
  'lastName',
  'state',
  'street',
  'zip',
  'checkVpn',
];

const selectInputs = ['currency', 'riskLevel', 'nationality'];

export const transformValuesToPayload = (values, fields) => {
  return Object.keys(fields).reduce((acc, type) => {
    if (baseInputs.includes(type)) {
      acc[type] = values[type];
      return acc;
    }

    if (selectInputs.includes(type)) {
      acc[type] = values[type]?.value;
      return acc;
    }

    if (type === 'phone') {
      acc[type] = normalizePhoneNumber(values[type]);
      return acc;
    }

    if (type === 'birth') {
      const formattedValue = formatDate(values[type], 'yyyy-MM-dd');
      acc[type] = formattedValue;
      return acc;
    }

    if (type === 'country') {
      acc[type] = values[type]?.value;
      acc['countryCode'] = values[type]?.code;
      return acc;
    }

    return acc;
  }, {});
};

/**
 * transform a single string or number to react-select type
 */
export const getSelectOption = <T extends string | number>(
  value: T
): SelectOptionType<T> => {
  return value
    ? {
        label: value.toString(),
        value,
      }
    : undefined;
};
