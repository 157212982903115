import { useCallback, useEffect, useState } from 'react';
import type { Filter } from '..';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';

const RECIPIENT_WALLET_REGEX = /^[A-Za-z0-9]{0,35}$/;

const RecipientWalletFilter: Filter = ({ column }) => {
  const [error, setError] = useState(false);
  const filterValue = (column.getFilterValue() || '') as string;
  const [value, setValue] = useState(filterValue);

  useEffect(() => {
    if (filterValue === '') {
      setError(false);
    }
    setValue(filterValue);
  }, [filterValue]);

  const handleChangeValue = useCallback(
    (updatedValue: string) => {
      setValue(updatedValue);
      if (RECIPIENT_WALLET_REGEX.test(updatedValue) || updatedValue === '') {
        setError(false);
        column.setFilterValue(updatedValue);
      } else {
        setError(true);
      }
    },
    [column]
  );

  return (
    <TextInputFilter
      type="text"
      onChange={handleChangeValue}
      value={value}
      autoComplete="off"
      error={
        error &&
        'Invalid wallet address: only use alphanumeric characters with a maximum length of 35 letters'
      }
    />
  );
};

export default RecipientWalletFilter;
