import type {
  ColumnFiltersState,
  ColumnSizingState,
  RowData,
  RowSelectionState,
  TableProps,
} from '@arringo-npm/fe-table';
import { TableBodyCell } from '@arringo-npm/fe-table';
import { TableComponent } from '@arringo-npm/fe-table';
import { t } from 'i18next';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { useCallback, useMemo } from 'react';
import type { ServerPagination } from 'src/api/types';
import BodyRow from './components/BodyRow';
import styles from './index.module.css';
import { useDebouncedCallback } from 'use-debounce';
import Button from 'src/components/Button';
import Pagination from './components/Pagination';
import { type Column } from './@types';
import { columnsAdapter } from './utils/adapters';
import { twMerge } from 'tailwind-merge';
import {
  getColumnSizingFromStorage,
  setColumnSizingInStorage,
} from './utils/localStorage';
import classNames from 'classnames';

export { Cells } from './cells';
export { Filters } from './filters';
export { BodyRow };

const InstaTableBodyCell: typeof TableBodyCell = (props) => {
  const cellValue = props.cell.getValue();
  return (
    <TableBodyCell
      {...props}
      className={twMerge(props.className, styles.tableBodyCell)}
    >
      <span
        {...(typeof cellValue === 'string' && {
          title: cellValue,
        })}
      >
        {props.children}
      </span>
    </TableBodyCell>
  );
};

interface Props<TData extends RowData, TValue> {
  RowComponent?: typeof BodyRow;
  action?: {
    callBack: (...args: unknown[]) => void;
    title: string;
  };
  columns: Column<TData, TValue>[];
  containerClassName?: string;
  customizations?: TableProps<TData, TValue>['customizations'];
  data: TData[];
  defaultColumnFilters?: ColumnFiltersState;
  disableResizeableColumns?: boolean;
  hasStickyHeader?: boolean;
  hideTableWrapper?: boolean;
  hideTotalCount?: boolean;
  isLoading?: boolean;
  minHeight?: number;
  onDebouncedColumnFiltersChange?: (filters: ColumnFiltersState) => void;
  onPageChange?: (index: number) => void;
  onPageSizeChange?: (size: number) => void;
  onRefreshData?: () => void;
  onRowClick?: (row: TData) => void;
  onRowSelectionChange?: (selection: RowSelectionState) => void;
  pageIndex?: number;
  pageSize?: number;
  pageSizeOptions?: number[];
  paginationParams?: ServerPagination;
  persistTitle: string;
  rowSelection?: RowSelectionState;
  showCheckbox?: boolean;
  title?: string;
  totalCount?: number;
}

const DEBOUNCE_TIME = 1_500;

const Table = <TData extends RowData, TValue>({
  data,
  onPageChange = () => {
    return;
  },
  pageIndex,
  pageSize,
  pageSizeOptions = [10, 20, 30, 50],
  // TODO: total count value is also available in paginationParams.
  // Further investigate if it is a good idea removing this
  totalCount = 0,
  isLoading,
  defaultColumnFilters,
  onDebouncedColumnFiltersChange = () => {
    return;
  },
  onPageSizeChange,
  onRefreshData = () => {
    return;
  },
  disableResizeableColumns = false,
  paginationParams,
  columns,
  onRowSelectionChange,
  containerClassName,
  onRowClick,
  rowSelection,
  title,
  hideTotalCount = false,
  RowComponent,
  minHeight,
  showCheckbox,
  action,
  customizations,
  hideTableWrapper = false,
  persistTitle,
  hasStickyHeader,
}: Props<TData, TValue>): ReactElement => {
  const [columnFilters, setColumnFilters] = useState(
    defaultColumnFilters || []
  );

  const debouncedHandleColumnFilterChange = useDebouncedCallback((params) => {
    onPageChange(0);
    onDebouncedColumnFiltersChange(params);
  }, DEBOUNCE_TIME);

  const finalColumnFiltersChange = useCallback(
    (filters: ColumnFiltersState) => {
      setColumnFilters(filters);
      debouncedHandleColumnFilterChange(filters);
    },
    [debouncedHandleColumnFilterChange]
  );

  const finalColumns = useMemo(() => {
    return columnsAdapter(columns);
  }, [columns]);

  const initialColumnSizing = useMemo(
    () => getColumnSizingFromStorage(persistTitle),
    [persistTitle]
  );

  const handleColumnSizingChange = useDebouncedCallback(
    (columnSizing: ColumnSizingState) => {
      if (Object.keys(columnSizing).length === 0) {
        return;
      }
      setColumnSizingInStorage(persistTitle, columnSizing);
    },
    100
  );

  const showTitle = title !== undefined;
  const showTotalCount = !hideTotalCount && totalCount != null;
  const showAction = typeof action !== 'undefined';
  const showResetFilters = columnFilters.length > 0;

  const showTopWrapper =
    showTitle || showTotalCount || showAction || showResetFilters;

  return (
    <div
      className={twMerge(
        hideTableWrapper ? null : styles.tableAndPagination,
        hasStickyHeader ? 'flex h-screen-90 flex-col overflow-visible' : null,
        containerClassName
      )}
    >
      {showTopWrapper && (
        <div className="mb-8 flex items-center justify-between">
          {showTitle && <p className={styles.header}>{title}</p>}
          {showTotalCount && (
            <p className={styles.header}>
              {`${t('global:tables.totalCount')}: ${totalCount}`}
            </p>
          )}
          {showAction && (
            <Button
              disabled={Object.keys(rowSelection).length === 0}
              onClick={action.callBack}
            >
              {action.title}
            </Button>
          )}
          {showResetFilters && (
            <Button onClick={() => finalColumnFiltersChange([])}>
              {t('global:common.buttons.resetFilters')}
            </Button>
          )}
        </div>
      )}
      <TableComponent<TData, TValue>
        overlayWrapperClassName="!overflow-auto"
        isLoading={isLoading}
        columnFilters={columnFilters}
        onColumnFiltersChange={finalColumnFiltersChange}
        columns={finalColumns}
        columnsResizable={!disableResizeableColumns}
        customizations={{
          TableHeadCell: styles.tableHeadCell,
          Table: styles.table,
          TableBodyRow: RowComponent || BodyRow,
          TableBodyCell: InstaTableBodyCell,
          TableHead: classNames(styles.tableHead, {
            'sticky top-0 bg-white': hasStickyHeader,
          }),
          TableBody: styles.tableBody,
          TableHeadRow: showCheckbox
            ? styles.tableHeadRowWithCheckbox
            : undefined,
          TableSelectRowCheckbox: 'text-center border [&>input]:rounded',
          // TODO: we need to strict css selector for table-col-size-adjuster
          TableWrapper:
            '_table_ overflow-auto flex-1' + customizations?.TableWrapper,
          ...customizations,
        }}
        data={data}
        rowSelection={rowSelection || {}}
        onRowSelectionChange={onRowSelectionChange}
        minHeight={minHeight}
        onRowClick={onRowClick}
        showCheckbox={showCheckbox}
        filterWrapperClassName="py-2 px-1 flex-wrap"
        initialColumnSizing={initialColumnSizing || {}}
        onColumnSizingChange={
          !disableResizeableColumns && handleColumnSizingChange
        }
      />
      {paginationParams && (
        <Pagination
          pageSizeOptions={pageSizeOptions}
          currentPageSize={pageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalCount={totalCount}
          paginationParams={paginationParams}
          wrapperClassName="mt-4"
          onPageChange={onPageChange}
          onRefreshData={onRefreshData}
          onChangePageSize={onPageSizeChange}
        />
      )}
    </div>
  );
};

export default Table;
