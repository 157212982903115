import type { CellProps } from '..';

const LinkAudienceType = <TData extends { linkToClientIdList?: string }>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();
  const { linkToClientIdList } = cell.row.original;

  return (
    <>
      {value === 'CUSTOM_IDS' ? (
        <a
          href={linkToClientIdList}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      ) : (
        value
      )}
    </>
  );
};

export default LinkAudienceType;
