import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import type { Path } from 'src/routes/types';
import { isAffiliate } from 'src/utils/helpers/roles';
import styles from './FooterRoutes.module.css';

type RouterPath = {
  login: Path;
  register: Path;
};

const FooterRoutes = () => {
  const { t } = useTranslation();

  const routes = useMemo<RouterPath>(() => {
    if (isAffiliate()) {
      return {
        login: '/affiliate/login',
        register: '/affiliate/register',
      };
    } else {
      return {
        login: '/login',
        register: '/register',
      };
    }
  }, []);

  return (
    <>
      <div className={styles.base}>
        <Link to={routes.login}>{t('global:login.buttons.backToLogin')}</Link>
        <span className={styles.divider}>{t('global:login.buttons.or')}</span>
        <Link to={routes.register}>
          {t('global:login.buttons.createAccount')}
        </Link>
      </div>
    </>
  );
};

export default memo(FooterRoutes);
