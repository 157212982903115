import { useEffect } from 'react';
import { BalanceContextWrapper } from 'src/contexts/BalanceContext';
import PermissionsContextWrapper from 'src/contexts/PermissionsContext';
import { CookieContextWrapper } from 'src/contexts/StorageContext/CookieContext';
import UserContextWrapper from 'src/contexts/UserContext';
import { UserStatusContextWrapper } from 'src/contexts/UserStatusContext';
import { WalletProvider } from 'src/contexts/UserWallet/walletContext';
import Routing from 'src/routes';
import { getPartnerId, isPartner, logAnalytics } from 'src/utils/helpers';
import { SWRConfig } from 'swr';
import { AppContainer } from 'src/features/App/components/AppContainer';
import RouterProvider from 'src/routes/router-provider';

export const App = () => {
  useEffect(() => {
    let category = 'Payment';
    const action = 'New Visit Started';
    let value = 0;
    if (isPartner()) {
      value = getPartnerId();
      category = `${category} Partner`;
    }
    logAnalytics({ category, action, value });
  }, []);

  const config = {
    revalidateOnFocus: false,
  };

  return (
    <SWRConfig value={config}>
      <CookieContextWrapper>
        <PermissionsContextWrapper>
          <UserStatusContextWrapper>
            <UserContextWrapper>
              <WalletProvider>
                <RouterProvider>
                  <BalanceContextWrapper>
                    <AppContainer>
                      <Routing />
                    </AppContainer>
                  </BalanceContextWrapper>
                </RouterProvider>
              </WalletProvider>
            </UserContextWrapper>
          </UserStatusContextWrapper>
        </PermissionsContextWrapper>
      </CookieContextWrapper>
    </SWRConfig>
  );
};
