import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';
import { convertSatoshiToBitcoin } from '@arringo-npm/utility-package';

const convertSatoshi = (value) => {
  if (isNaN(value)) {
    return '';
  }
  const btc = convertSatoshiToBitcoin(value);
  if (isNaN(btc)) {
    return '';
  }
  return btc.toFixed(8);
};

const SatoshiAmount = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();
  return (
    <span className="text-right">
      {convertSatoshi(value) || <span>&mdash;</span>}
    </span>
  );
};

export default SatoshiAmount;
