import type { Location } from 'react-router-dom';
import { useCallback } from 'react';
import type { DataLayerArgs } from 'react-gtm-module';
import TagManager from 'react-gtm-module';
import type { PaymentType } from 'src/@types/Transaction/PaymentType';
import { isProduction } from 'src/utils/helpers/environment';
import { logSentryMessage } from 'src/utils/helpers/logging';

interface EnhancedDataLayerArgs extends DataLayerArgs {
  dataLayer: {
    /**
     * Event Name
     */
    event: string;
  };
}

/**
 * Log GoogleTagManger Actions.
 */
const logGoogleTagManagerAction = (payload: EnhancedDataLayerArgs) => {
  // eslint-disable-next-line no-console
  return console.debug(
    `Google Tag Manager: ${payload.dataLayer.event}`,
    payload
  );
};

/**
 * Google Tag Manager Event Methods.
 */
const useGoogleTagManager = () => {
  const sendEvent = (payload: EnhancedDataLayerArgs) => {
    try {
      if (!isProduction()) {
        return logGoogleTagManagerAction(payload);
      }
      return TagManager.dataLayer(payload);
    } catch (error) {
      logSentryMessage(error, { level: 'info' });
    }
  };

  /**
   * Send 'pageChanged' event to Google Tag Manager.
   */
  const onRouteChangeEvent = useCallback((location: Location) => {
    const payload = {
      dataLayer: {
        event: 'pageChanged',
        url: `${location.pathname}${location.search}`,
      },
    } as EnhancedDataLayerArgs;
    return sendEvent(payload);
  }, []);

  /**
   * Send 'logIn' event to Google Tag Manager.
   */
  const onLoginEvent = (userId: number) => {
    const payload = {
      dataLayer: {
        event: 'logIn',
        userId,
      },
    } as EnhancedDataLayerArgs;
    return sendEvent(payload);
  };

  /**
   * Send 'signUp' event to Google Tag Manager.
   */
  const onSignupEvent = (userId: number) => {
    const payload = {
      dataLayer: {
        event: 'signUp',
        userId,
      },
    } as EnhancedDataLayerArgs;
    return sendEvent(payload);
  };

  /**
   * Send 'purchase' event to Google Tag Manager.
   */
  const onPurchaseEvent = (
    userId: number,
    amount: number,
    paymentMethod: PaymentType
  ) => {
    const payload = {
      dataLayer: {
        event: 'purchase',
        userId,
        amount,
        paymentMethod,
      },
    } as EnhancedDataLayerArgs;
    return sendEvent(payload);
  };

  return {
    onRouteChangeEvent,
    onLoginEvent,
    onSignupEvent,
    onPurchaseEvent,
  };
};

export default useGoogleTagManager;
