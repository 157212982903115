import type { Filter } from '..';
import TextInputFilter from 'src/components/Table/filters/components/TextInputFilter';

const SpacelessInputFilter: Filter = ({ column }) => {
  const value = (column.getFilterValue() || '') as string;

  const handleChangeValue = (updatedValue: string) => {
    const maskedValue =
      typeof updatedValue === 'string'
        ? updatedValue.replace(/ /g, '')
        : updatedValue;
    column.setFilterValue(maskedValue);
  };

  return (
    <TextInputFilter
      type="text"
      onChange={handleChangeValue}
      value={value}
      autoComplete="off"
    />
  );
};

export default SpacelessInputFilter;
