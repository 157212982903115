import { useState, useCallback } from 'react';
import {
  removeFiltersFromStorage,
  getFiltersFromStorage,
  getPagerLimitFromStorage,
  setFiltersInStorage,
  setPagerLimitInStorage,
} from 'src/components/Table/utils/localStorage';

type Params = {
  defaultPageSize: number;
  title: string;
};

const useTablePersistState = ({ defaultPageSize, title }: Params) => {
  // remove old filter format if still exists
  removeFiltersFromStorage(title);
  // title to use for new filter structure
  const signedTitle = title + '@fe-table';
  const [debouncedFilters, setDebouncedFilters] = useState(
    title ? getFiltersFromStorage(signedTitle) || [] : []
  );
  const [pageSize, setPageSize] = useState(
    title ? getPagerLimitFromStorage(title) || defaultPageSize : defaultPageSize
  );

  const persistedSetDebouncedFilters = useCallback(
    (filters) => {
      setDebouncedFilters(filters);
      if (title) {
        setFiltersInStorage(signedTitle, filters);
      }
    },
    [signedTitle, title]
  );

  const persistedSetPageSize = useCallback(
    (size) => {
      setPageSize(size);
      if (title) {
        setPagerLimitInStorage(title, size);
      }
    },
    [title]
  );

  return {
    pageSize,
    setPageSize: persistedSetPageSize,
    debouncedFilters,
    setDebouncedFilters: persistedSetDebouncedFilters,
  };
};

export default useTablePersistState;
