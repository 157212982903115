import { t } from 'i18next';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
  currentPage: number;
  onChangePageNumber: (value: number) => void;
  pagesCount: number;
};

const DEBOUNCE_TIME = 1_500;

const GoToPage = ({ currentPage, pagesCount, onChangePageNumber }: Props) => {
  const [pageNumberInput, setPageNumberInput] = useState<string>(
    currentPage.toString()
  );

  const debouncedOnChange = useDebouncedCallback((value) => {
    if (value >= 1 && value <= pagesCount) {
      onChangePageNumber(value);
    } else {
      setPageNumberInput(currentPage.toString());
    }
  }, DEBOUNCE_TIME);

  useEffect(() => {
    setPageNumberInput(currentPage.toString());
  }, [currentPage]);

  const handleChangePageNumberInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPageNumberInput(value);
    debouncedOnChange(+value);
  };

  return (
    <div className="whitespace-nowrap">
      <span>{t('global:common.pagination.goTo')}</span>
      <input
        className="ml-1.5 inline-block h-9 min-w-[68px] max-w-[85px] rounded-lg border border-solid border-lightGrey bg-white text-center outline-none focus:border-primary"
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        min="1"
        max={pagesCount > 0 ? pagesCount : 1}
        value={pageNumberInput}
        onChange={handleChangePageNumberInput}
      />
    </div>
  );
};

export default GoToPage;
