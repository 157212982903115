import { forwardRef } from 'react';
import type { ForwardedRef, Ref } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import DatePicker from 'react-datepicker';
import { commonInputsStyles } from 'src/components/Inputs/Styles';
import Wrapper from 'src/components/Inputs/Wrapper';
import type { DatePickerInputProps, DatePickerInputSpaces } from './@types';
import { defaultAllowedYears, monthsOptions } from './constants';
import styles from './DatePickerInput.module.css';
import classNames from 'classnames';
import SelectInputFilter from 'src/components/Table/filters/components/SelectInputFilter';
import { getSelectOption } from 'src/features/ClientInfo/utils';
import type { SelectOptionType } from 'src/@types/SelectType';

const datePickerInputSpaces: Record<DatePickerInputSpaces, string> = {
  base: styles.baseSpaces,
  moreLeft: styles.moreLeftSpaces,
  moreRight: styles.moreRightSpaces,
};

const DatePickerInput = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  {
    label,
    variant = 'hero',
    name,
    disabled = false,
    className = '',
    addonBefore,
    headerNode,
    placeholder,
    addonAfter,
    truncateMessages,
    wrapperClassName = '',
    error,
    hint,
    minDate,
    maxDate,
    allowedYears = defaultAllowedYears,
    required,
    ...rest
  }: DatePickerInputProps<CustomModifierNames, WithRange>,
  ref: Ref<HTMLInputElement>
): React.ReactElement => {
  const classes = classNames(
    className,
    commonInputsStyles({
      error,
      variant,
    }),
    datePickerInputSpaces.base,
    addonBefore && datePickerInputSpaces.moreLeft,
    addonAfter && datePickerInputSpaces.moreRight
  );

  return (
    <Wrapper
      name={name}
      label={label}
      disabled={disabled}
      wrapperClassName={wrapperClassName}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      truncateMessages={truncateMessages}
      error={error}
      headerNode={headerNode}
      hint={hint}
      required={required}
    >
      <DatePicker<CustomModifierNames, WithRange>
        minDate={minDate}
        maxDate={maxDate}
        popperClassName={styles.popper}
        wrapperClassName={styles.wrapper}
        customInput={<input ref={ref} className={classes} type="text" />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div className={styles.headerWrapper}>
            <ChevronLeftIcon
              width={20}
              height={20}
              className={styles.arrow}
              onClick={decreaseMonth}
            />
            <SelectInputFilter
              value={getSelectOption(date.getFullYear())}
              options={allowedYears}
              onChange={(selectedYear) =>
                changeYear((selectedYear as SelectOptionType<number>).value)
              }
              className="w-[85px] text-left text-sm"
            />
            <SelectInputFilter
              value={monthsOptions.find(
                (month) => month.value === date.getMonth()
              )}
              options={monthsOptions}
              onChange={(selectedMonth) => {
                changeMonth((selectedMonth as SelectOptionType<number>).value);
              }}
              className="w-32 text-left text-sm"
            />
            <ChevronRightIcon
              width={20}
              height={20}
              className={styles.arrow}
              onClick={increaseMonth}
            />
          </div>
        )}
        placeholderText={placeholder}
        dropdownMode="select"
        aria-describedby={`${name}-description`}
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        {...rest}
      />
    </Wrapper>
  );
};

export default forwardRef(DatePickerInput) as <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: DatePickerInputProps<CustomModifierNames, WithRange> & {
    ref?: ForwardedRef<HTMLInputElement>;
  }
) => ReturnType<typeof DatePickerInput>;
