import ReactGA from 'react-ga';
import type {
  AdminAffiliateGetResponse,
  AffiliateGetResponse,
} from 'src/@types/api';
import { isProduction } from './environment';
import { addDuration, formatDateInterval } from '@arringo-npm/utility-package';

export const isPartner = () => {
  const params = new URLSearchParams(window?.location.search);
  const p = params.get('p');
  return !!p;
};

export const copyToClipBoard = (value) => {
  navigator.clipboard.writeText(value);
};

export const transformAffiliate = (
  data: AffiliateGetResponse | AdminAffiliateGetResponse
) => {
  const {
    accountInfo: {
      id,
      firstName,
      lastName,
      email,
      roleType,
      fee,
      skype,
      status2fa,
      wallet,
      link,
      createdAt,
      lastLoginAt,
      affiliateId,
      balance,
      flowType,
      apiKey,
      status,
      country,
    },
  } = data;

  return {
    userAccountInfo: {
      id,
      affiliateId,
      email,
      roleType,
      status2fa,
      skype,
      wallet,
      link,
      fee,
      balance,
      createdAt,
      lastLoginAt,
      flowType,
      apiKey,
      status,
      country,
    },
    userPersonalInfo: {
      id,
      firstName,
      lastName,
    },
    permissions: 'permissions' in data ? data.permissions : {},
  };
};

export const logAnalytics = ({
  category = '-',
  action = '-',
  label = '-',
  value = 0,
}) => {
  if (isProduction()) {
    ReactGA.event({
      category,
      action,
      value,
      label,
    });
  }
};

export const getPartnerId = () => {
  try {
    const partnerId = window?.location.search?.split('p=')[1] || '0';
    return parseInt(partnerId, 10);
  } catch (error) {
    return 0;
  }
};

export const filterSpaceInPayload: <T>(
  payload: T,
  fields: Array<string> | string
) => T = (payload, fields) => {
  const filteredPayload = { ...payload };
  const filterableFields = Array.isArray(fields) ? fields : [fields];
  filterableFields.forEach((field) => {
    filteredPayload[field] = (filteredPayload[field] as string).replace(
      / /g,
      ''
    );
  });

  return filteredPayload;
};

export const humanizedNextTransactionCountdown = (seconds: number) => {
  const baseDate = new Date();

  const interval = { start: baseDate, end: addDuration(baseDate, { seconds }) };

  return formatDateInterval(interval, { delimiter: ' and ' });
};

export const normalizePhoneNumber = (phoneNumber: string) => {
  try {
    return phoneNumber.length !== 1
      ? phoneNumber.replace(/^(\+)|\D/g, '$1')
      : '';
  } catch {
    return '';
  }
};
