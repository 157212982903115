import { useContext } from 'react';
import CookieContext from 'src/contexts/StorageContext/CookieContext';

const useCookie = () => {
  const context = useContext(CookieContext);

  if (context === undefined) {
    throw new Error('useCookie must be used within a CookieContextWrapper');
  }

  return context;
};

export default useCookie;
