import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { GetAccountingDataParams } from 'src/@types/api';
import { callGetAccountingData } from 'src/api/client';
import type { Balance } from 'src/features/AdjustmentsFeature/@types';
import useSystemCapabilities from 'src/hooks/useSystemCapabilities';
import { PresentationMethod } from 'src/utils/helpers/@types';
import { handleError } from 'src/utils/helpers/errors';
import { UserContext } from 'src/contexts/UserContext';
import { useLocation } from 'react-router-dom';

export type IContext = {
  availableBalance: Balance[];
  balance: Balance[];
  getAccountingData: (params: GetAccountingDataParams) => Promise<unknown>;
};

const BLOCKLIST = ['/login'];
export const BalanceContext = createContext<IContext>({
  balance: [],
  availableBalance: [],
  getAccountingData: () => Promise.resolve(),
});

const BalanceProvider = BalanceContext.Provider;

export const BalanceContextWrapper = ({ children }) => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const [balance, setBalance] = useState<Balance[]>([]);
  const [availableBalance, setAvailableBalance] = useState<Balance[]>([]);

  const { convertFromIntegral, cryptoCurrencies } = useSystemCapabilities();

  const getAccountingData = useCallback(
    async (params: GetAccountingDataParams) => {
      const {
        userAccountInfo: { roleType },
      } = user;
      if (roleType !== 'CLIENT') return;
      try {
        const result = await callGetAccountingData(params);

        setBalance(
          result.data.cryptoBalance.map((cryptoBalance) => ({
            cryptoCurrency: cryptoBalance.cryptoCurrency,
            amount: convertFromIntegral(
              cryptoBalance.cryptoAmountIntegralBalance,
              cryptoBalance.cryptoCurrency
            ),
          }))
        );
        setAvailableBalance(
          result.data.cryptoBalance.map((cryptoBalance) => ({
            cryptoCurrency: cryptoBalance.cryptoCurrency,
            amount: convertFromIntegral(
              cryptoBalance.cryptoAmountIntegralAvailableBalance,
              cryptoBalance.cryptoCurrency
            ),
          }))
        );
        return result;
      } catch (error) {
        handleError({
          error,
          options: {
            presentation: PresentationMethod.ALERT,
          },
        });
      }
    },
    [convertFromIntegral, user]
  );

  useEffect(() => {
    if (cryptoCurrencies.length && !BLOCKLIST.includes(location.pathname)) {
      getAccountingData({
        pagination: {
          limit: 0,
          skip: 0,
        },
      });
    }
  }, [cryptoCurrencies, getAccountingData, location]);

  return (
    <BalanceProvider
      value={{
        balance,
        availableBalance,
        getAccountingData,
      }}
    >
      {children}
    </BalanceProvider>
  );
};

export const useBalance = () => {
  return useContext(BalanceContext);
};
