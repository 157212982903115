import type { RowData } from '@arringo-npm/fe-table';
import type { Cell } from 'src/components/Table/cells';
import type { Filter } from 'src/components/Table/filters';

type BaseColumn = {
  footer?: string;
  header?: string;
  size?: number;
};

export type Identifier =
  | { header?: string; id: string }
  | { header: string; id?: string };

type AccessorColumn<TData extends RowData, TValue> = BaseColumn & {
  accessorKey: keyof TData;
  cell?: Cell<TData, TValue>;
  cellClassName?: string;
  filter?: Filter;
  headerClassName?: string;
  id?: string;
  subColumns?: never;
};

export type GroupingColumn<TData extends RowData, TValue> = BaseColumn & {
  accessorKey?: never;
  cell?: Cell<TData, TValue>;
  filter?: never;
  subColumns: Column<TData, TValue>[];
} & Identifier;

export type DisplayColumn<TData extends RowData, TValue> = BaseColumn & {
  accessorKey?: never;
  cell?: Cell<TData, TValue>;
  filter?: never;
  subColumns?: never;
} & Identifier;

export type Column<TData extends RowData, TValue> =
  | AccessorColumn<TData, TValue>
  | GroupingColumn<TData, TValue>
  | DisplayColumn<TData, TValue>;

export function isAccessorColumn<TData extends RowData, TValue>(
  column: Column<TData, TValue>
): column is AccessorColumn<TData, TValue> {
  return (
    column.subColumns === undefined &&
    column.accessorKey !== undefined &&
    typeof column.accessorKey === 'string'
  );
}

export function isGroupingColumn<TData extends RowData, TValue>(
  column: Column<TData, TValue>
): column is GroupingColumn<TData, TValue> {
  return column.subColumns !== undefined && column.accessorKey === undefined;
}

export function isDisplayColumn<TData extends RowData, TValue>(
  column: Column<TData, TValue>
): column is DisplayColumn<TData, TValue> {
  return !isAccessorColumn(column) && !isGroupingColumn(column);
}

export type FilterEntryMapper = (
  filterEntry: [id: string, value: unknown]
) => [id: string, value: unknown] | Array<[id: string, value: unknown]>;
