import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import useSWR from 'swr';
import { APITypes, APIUrls } from 'src/@enums/api';
import {
  callGetAdminTransactionStatusFilters,
  callGetAffiliateTransactionStatusFilters,
  callGetClientTransactionStatusFilters,
} from 'src/api';
import { transactionFriendlyStatusFilterConfig } from 'src/config/tables';
import type { Roles } from 'src/@types/Roles';

const transactionStatusFilterCacheKey: Record<Roles, string> = {
  ADMIN: APITypes.ADMIN_TRANSACTION_STATUS_FILTER_GET,
  SUPER_ADMIN: APITypes.ADMIN_TRANSACTION_STATUS_FILTER_GET,
  CLIENT: APITypes.TRANSACTION_STATUS_FILTER_GET,
  AFFILIATE: APITypes.AFFILIATE_TRANSACTION_STATUS_FILTER_GET,
};

const useTransactionStatusFilter = () => {
  const userContext = useContext(UserContext);
  const roleType = userContext?.user?.userAccountInfo?.roleType;

  const { data, isValidating } = useSWR(
    `${APIUrls.TRANSACTIONS_HISTORY}${transactionStatusFilterCacheKey[roleType]}`,
    () => {
      switch (roleType) {
        case 'SUPER_ADMIN':
        case 'ADMIN': {
          return callGetAdminTransactionStatusFilters();
        }
        case 'AFFILIATE': {
          return callGetAffiliateTransactionStatusFilters();
        }
        case 'CLIENT': {
          return callGetClientTransactionStatusFilters();
        }
      }
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  const transactionStatusFilters = data?.data.transactionStatusFilter.map(
    (it) => ({
      value: it,
      label: transactionFriendlyStatusFilterConfig?.[it]?.label ?? it,
    })
  );

  return {
    transactionStatusFilters,
    isLoadingTransactionStatusFilters: isValidating,
  };
};

export default useTransactionStatusFilter;
