import type { CellContext, RowData } from '@arringo-npm/fe-table';
import Currency from './Currency';
import Wallet from './Wallet';
import DateTime from './DateTime';
import Date from './Date';
import QueueTime from './QueueTime';
import Note from './Note';
import YesNo from './YesNo';
import Checkbox from './Checkbox';
import Status from './Status';
import SatoshiAmount from './SatoshiAmount';
import ClientStatus from './ClientStatus';
import ClientSignupDate from './ClientSignupDate';
import TransactionType from './TransactionType';
import CryptoTransactionHash from './CryptoTransactionHash';
import PaymentType from './PaymentType';
import CryptoBalances from './CryptoBalances';
import LockUserNeedAdminMonitoring from './LockUserNeedAdminMonitoring';
import SnoozeUserNeedAdminMonitoring from './SnoozeUserNeedAdminMonitoring';
import SetAsFixedAction from './SetAsFixedAction';
import CancelFlow from './CancelFlow';
import Boolean from './Boolean';
import CountryCode from './CountryCode';
import RelativeDatetime from './RelativeDateTime';
import InterviewQueueState from './InterviewQueueState';
import ActivityDetails from './ActivityDetails';
import PartyIndicator from './PartyIndicator';
import TextWithTooltip from './TextWithTooltip';
import AudienceType from './AudienceType';
import EditableCryptoTransactionHash from './EditableCryptoTransactionHash';
import ClientInfo from './ClientInfo';
import InterviewQueueAction from './InterviewQueueAction';
import ReviewAction from './ReviewAction';
import DocumentStatus from './DocumentStatus';
import InterviewReasons from './InterviewReasons';
import LinkAudienceType from './LinkAudienceType';

export type CellProps<TData extends RowData, TValue> = {
  cell: CellContext<TData, TValue>['cell'];
};

export type Cell<TData extends RowData, TValue> = (
  props: CellProps<TData, TValue>
) => React.ReactElement;

export const Cells = {
  Boolean,
  Currency,
  DateTime,
  Checkbox,
  Date,
  YesNo,
  Status,
  Wallet,
  QueueTime,
  Note,
  SatoshiAmount,
  ClientStatus,
  ClientSignupDate,
  TransactionType,
  CryptoTransactionHash,
  PaymentType,
  CryptoBalances,
  LockUserNeedAdminMonitoring,
  SnoozeUserNeedAdminMonitoring,
  SetAsFixedAction,
  CancelFlow,
  CountryCode,
  RelativeDatetime,
  ActivityDetails,
  PartyIndicator,
  TextWithTooltip,
  AudienceType,
  EditableCryptoTransactionHash,
  ClientInfo,
  DocumentStatus,
  InterviewQueueState,
  InterviewQueueAction,
  ReviewAction,
  InterviewReasons,
  LinkAudienceType,
};
