import type { RowData } from '@arringo-npm/fe-table';
import type { CellProps } from '..';
import { twMerge } from 'tailwind-merge';

const TransactionType = <TData extends RowData>({
  cell,
}: CellProps<TData, string>) => {
  const value = cell.getValue();

  const classes = {
    Deposit: 'text-green-500',
    Buy: 'text-green-500',
    Withdrawal: 'text-yellow-500',
    Transfer: 'text-yellow-500',
    Adjustment: 'text-indigo-500',
    Purchase: 'text-purple-500',
  };

  return (
    <span className={twMerge('font-bold text-gray-700', classes[value])}>
      {value}
    </span>
  );
};

export default TransactionType;
