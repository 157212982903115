import { lazy } from 'react';
import type {
  ActiveClientRoute,
  PartitionedRoutes,
  ProtectedClientRoute,
} from 'src/routes/types';
import deprecatedRoutes from './deprecatedRoutes';

/**
 * Client Protected Routes
 *
 * Authentication Required
 * Only Client Role
 * Permissions based on route
 */
export const protectedClientRoutes: PartitionedRoutes<ProtectedClientRoute> = {
  ...deprecatedRoutes,
  '/client/deposit': {
    component: lazy(() => import('src/pages/client/deposit')),
    name: 'Deposit',
    roles: ['CLIENT'],
    permissions: ['BUY_CC', 'CREATE_BANK_TRANSFER'],
  },
  // TODO: make it /client/deposit/:payment-provider
  '/client/open-bank': {
    component: lazy(() => import('src/pages/DepositPage/OpenBankInstitutions')),
    name: 'Open Bank Institutions',
    roles: ['CLIENT'],
    permissions: ['BUY_CC', 'CREATE_BANK_TRANSFER'],
  },
  '/client/emailing/unsubscribe&data=:token': {
    component: lazy(() => import('src/pages/EmailUnsubscribe')),
    name: 'Unsubscribe',
  },
  '/client/logout': {
    component: lazy(() => import('src/pages/client/logout')),
    roles: ['CLIENT'],
    name: 'Logout',
  },
  '/client/bank-info/:id': {
    component: lazy(() => import('src/pages/client/bank-info/[id]')),
    roles: ['CLIENT'],
    name: 'Bank Info',
    permissions: ['TRANSACTION_GET'],
  },
  '/client/bank-info': {
    component: lazy(() => import('src/pages/client/bank-info')),
    roles: ['CLIENT'],
    name: 'Bank Info',
    permissions: ['TRANSACTION_GET'],
  },
  '/client/withdraw': {
    component: lazy(() => import('src/pages/client/withdraw')),
    roles: ['CLIENT'],
    name: 'Withdraw',
    permissions: ['WITHDRAW_CRYPTO'],
  },
  '/client/transactions': {
    component: lazy(() => import('src/pages/client/transactions')),
    roles: ['CLIENT'],
    name: 'Transactions',
    permissions: ['TRANSACTION_GET'],
  },
  '/client/accounting': {
    component: lazy(() => import('src/pages/client/accounting')),
    name: 'Accounting',
    roles: ['CLIENT'],
    permissions: ['USER_ACCOUNT_GET'],
  },
  '/client/payment-callback': {
    component: lazy(() => import('src/pages/client/payment-callback')),
    name: 'Transaction Result',
    roles: ['CLIENT'],
    permissions: ['TRANSACTION_GET'],
  },
  '/client/kyc': {
    component: lazy(() => import('src/pages/client/kyc')),
    name: 'KYC',
    roles: ['CLIENT'],
  },
  '/client/survey': {
    component: lazy(() => import('src/pages/client/survey')),
    name: 'Survey',
    roles: ['CLIENT'],
  },
  '/client/suspended': {
    component: lazy(() => import('src/pages/client/suspended')),
    name: 'Suspended',
    roles: ['CLIENT'],
  },
};
/**
 * Active Client Protected Routes
 *
 * Authentication Required
 * Only Client Role
 * Permissions based on route
 * ONLY ACTIVE CLIENTS
 */
export const activeClientRoutes: PartitionedRoutes<ActiveClientRoute> = {
  ...deprecatedRoutes,
  '/client/transactions/:id': {
    component: lazy(() => import('src/pages/client/transactions/[id]')),
    name: 'Transaction Info',
    permissions: [
      'TRANSACTION_GET',
      'USER_ACCOUNT_WITHDRAWAL_AUTHORISE',
      'REQUEST_REFUND',
    ],
    roles: ['CLIENT'],
  },
  '/client/account': {
    component: lazy(() => import('src/pages/client/account')),
    name: 'Account',
    permissions: [],
    roles: ['CLIENT'],
  },
  '/client/payment-result': {
    component: lazy(() => import('src/pages/client/payment-result')),
    permissions: ['BUY_TRX_RESULT'],
    roles: ['CLIENT'],
    name: 'Payment Result',
  },
  '/client/security': {
    component: lazy(() => import('src/pages/client/security')),
    roles: ['CLIENT'],
    permissions: ['SECURITY_CHANGE_PWD', 'SECURITY_SET_STATUS_2FA'],
    name: 'Security',
  },
  '/client/logs': {
    component: lazy(() => import('src/pages/client/logs')),
    name: 'Account Logs',
    permissions: ['ACTIVITY_GET'],
    roles: ['CLIENT'],
  },
  '/client/financial-limits': {
    component: lazy(() => import('src/pages/client/financial-limits')),
    name: 'Account Financial Limits',
    permissions: ['GET_USER_FINANCIAL_LIMITS_REMAINING'],
    roles: ['CLIENT'],
  },
  '/client/activities': {
    component: lazy(() => import('src/pages/client/activities')),
    name: 'Activities',
    permissions: ['ACTIVITY_GET'],
    roles: ['CLIENT'],
  },
  '/client/news-center': {
    component: lazy(() => import('src/pages/client/news-center')),
    name: 'New Center',
    permissions: [],
    roles: ['CLIENT'],
  },
};
